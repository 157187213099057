import Avatar from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button/Button';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableRow from '@material-ui/core/TableRow/TableRow';
import Typography from '@material-ui/core/Typography/Typography';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store, Question } from '../../types';

export const positiveStyle = {
	color: '#fff',
	backgroundColor: lightGreen[500]
};

export const negativeStyle = {
	color: '#fff',
	backgroundColor: red[500]
};

interface Props {
	question: Question;
	open: boolean;
	close: () => void;
	correct: boolean;
}

const Feedback = (props: Props) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const {
		open,
		question,
		question: { feedback }
	} = props;

	const handleClose = () => {
		props.close();
	};

	const renderIcon = () => {
		if (props.correct) {
			return (
				<Avatar style={positiveStyle}>
					<ThumbUp data-test-id="feedback_thumbs_up" />
				</Avatar>
			);
		}

		return (
			<Avatar style={negativeStyle}>
				<ThumbDown />
			</Avatar>
		);
	};

	const showResponses = () => {
		return (
			<React.Fragment>
				<Typography style={{ paddingTop: '1em' }}>
					{I18n.t('selfTestQuiz.answers', { locale })}:
				</Typography>
				<Table>
					<TableBody>
						{Object.keys(question.answers).map((key, index) => {
							const answer = question.answers[index];

							return (
								<TableRow key={key} data-test-id="feedback_responses">
									{question.correctAnswers && (
										<TableCell padding="default">
											{question.correctAnswers.includes(index) ? (
												<Check
													style={positiveStyle}
													data-test-id="feedback_responses_positive"
												/>
											) : (
												<Close
													style={negativeStyle}
													data-test-id="feedback_responses_negative"
												/>
											)}
										</TableCell>
									)}
									<TableCell>{answer.title}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</React.Fragment>
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			scroll="body"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			data-test-id="alert-dialog"
		>
			<DialogTitle id="alert-dialog-title">{feedback}</DialogTitle>

			<DialogContent>
				{question.answers && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							paddingBottom: '0.7em'
						}}
					>
						{renderIcon()}
					</div>
				)}
				<DialogContentText id="alert-dialog-description">{feedback && feedback}</DialogContentText>

				{question.answers && showResponses()}
			</DialogContent>

			<DialogActions>
				<Button
					onClick={handleClose}
					color="primary"
					variant="contained"
					fullWidth
					data-test-id="alert_dialog_btn"
				>
					{I18n.t('selfTestQuiz.okay', { locale })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Feedback;
