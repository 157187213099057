import { Institution, User } from '../../types';
import analyticsClient from './analyticsClient';
import { Indexes } from './indexes';
import { AxiosRequestConfig } from 'axios';
import { PWA_ANALYTICS_SOURCE } from '../../config/constants';

export enum ActivityLabel {
	ACCOUNT_PAGE_VIEW = 'account_page_view',
	BOOKMARK_PAGE_VIEW = 'bookmark_page_view',
	MODULE_PAGE_VIEW = 'module_page_view',
	COURSE_PAGE_VIEW = 'course_page_view',
	QUIZ_RESULTS_PAGE_VIEW = 'quiz_results_page_view',
	HELP_PAGE_VIEW = 'help_page_view',
	ABOUT_PAGE_VIEW = 'about_page_view',
	LOGGED_IN = 'logged_in',
	LOGGED_OUT = 'logged_out',
	CHECKED_FOR_UPDATES = 'checked_for_updates',
	COURSE_DETAIL_PAGE_VIEW = 'courses_detail_page_view',
	COMMENCED_QUIZ = 'commenced_quiz',
	COMMENCED_SURVEY = 'commenced_survey',
	BOOKMARKED_PAGE = 'bookmarked_page',
	QUIZ_SUBMITTED = 'quiz_submitted',
	SESSION_VIEWED = 'session_viewed'
}

export interface UserActivityPayload {
	user_api_token: User['token'];
	institution_id: Institution['id'];
	timestamp: number;
	label: ActivityLabel;
	description: string;
}

export const sendActivityData = async (payload: UserActivityPayload, config?: AxiosRequestConfig) => {
	return await analyticsClient.sendDataToIndex(
		Indexes.USER_ACTIVITY_V1,
		{ ...payload, source: PWA_ANALYTICS_SOURCE },
		config
	);
};
