import withStyles from '@material-ui/core/styles/withStyles';
import I18n from 'i18n-js';
import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, WithStyles } from '@material-ui/core/styles';
import { Locale } from '../../types';

const styles = (theme: Theme) =>
	createStyles({
		badge: {
			backgroundColor: theme.palette.secondary.main,
			color: 'white',
			padding: '5px 13px',
			borderRadius: '4px',
			fontSize: '13px',
			lineHeight: '10px'
		}
	});

interface Props extends WithStyles<typeof styles> {
	locale: Locale;
}

const QuizBadge = ({ classes, locale }: Props) => (
	<div className={classes.badge}>{I18n.t('badge.quiz', { locale })}</div>
);

export default withStyles(styles)(QuizBadge);
