import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { QuestionTypeProps } from '../QuestionAnswers';

const CompoundedMultipleChoiceAnswers = ({
	question,
	userResponse,
	handleOnChange,
	isReadOnly
}: QuestionTypeProps) => (
	<React.Fragment>
		<FormControl component="fieldset">
			{question &&
				Object.keys(question.answers).map((key, index) => {
					const answer = question.answers[index];

					//TODO: Fix type definition to prevent need for this
					//convert userResponses to string since all answerIDs from the backend are currently string
					userResponse = (userResponse as []).map(String);

					return (
						<FormControlLabel
							data-test-id="question-answers_compounded-multiple-choice-answers"
							key={answer.id}
							disabled={isReadOnly}
							control={
								<Checkbox
									data-test-id="compounded_multiple_choice_answers_checkbox"
									className="checkbox"
									color="primary"
									checked={userResponse.includes(answer.id.toString())}
									onChange={() => {
										handleOnChange && handleOnChange(answer.id.toString());
									}}
								/>
							}
							label={answer.title}
						/>
					);
				})}
		</FormControl>
	</React.Fragment>
);

export default CompoundedMultipleChoiceAnswers;
