import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { doneValidateSession } from '../redux/actions/actionCreators';
import CourseDialog from '../components/course/CourseDialog';
import CourseList from '../components/course/CourseList';
import PurchaseCoursesButton from '../components/course/PurchaseCoursesButton';
import CourseIcon from '../components/icons/CourseIcon';
import CheckForUpdatesButton from '../components/updates/CheckForUpdatesButton';
import MySnackbar from '../components/utils/MySnackbar';
import { SURVEY } from '../config/constants';
import {
	COURSE_INFO,
	COURSE_QUIZ,
	COURSE_SURVEY,
	COURSES,
	MODULE_COURSE_INFO,
	MODULE_COURSE_QUIZ,
	MODULE_COURSE_SURVEY,
	MODULES
} from '../config/routes';
import generateUrl from '../services/generateUrl';
import ModuleManager from '../services/module/ModuleManager';
import QuizManager from '../services/quiz/QuizManager';
import { Content, Course, Store } from '../types';
import { ContentParams } from '../services/RouteResolver';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1em',
		backgroundColor: 'white',
		height: '100%',
		position: 'absolute',
		top: '0px',
		textAlign: 'center'
	},
	icon: {
		color: theme.palette.background.default,
		fontSize: '60vmin'
	},
	purchaseMoreCard: {
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '15px 20px',
		margin: '10px'
	}
}));

interface Params extends Pick<ContentParams, 'moduleHashID'> {}

interface Props extends RouteComponentProps<Params>, Pick<Content, 'courses'> {}

export const CourseScreen = (props: Props) => {
	const myProps = useSelector(
		({
			content,
			content: { quizzes, isSessionFailValidating, modules, courses: allCourses },
			currentUser: {
				institution: { isMerchant }
			},
			updates: { hasUpdates },
			settings: { locale }
		}: Store) => {
			const courses = props.courses || content.courses;

			return {
				courses,
				allCourses,
				quizzes,
				isSessionFailValidating,
				locale,
				isMerchant,
				hasUpdates,
				modules
			};
		}
	);

	const {
		courses,
		isSessionFailValidating,
		locale,
		hasUpdates,
		isMerchant,
		quizzes,
		modules,
		allCourses
	} = myProps;

	const classes = useStyles();

	const dispatch = useDispatch();

	const [course, setCourse] = useState<Course | null>(null);

	const handleNext = (course: Course) => {
		const quiz = course.quizHashID && QuizManager.getQuiz(quizzes, course.quizHashID);

		const { moduleHashID } = props.match.params;

		const getCourseRoute = () => {
			if (quiz && quiz.type === SURVEY) {
				if (moduleHashID) {
					return MODULE_COURSE_SURVEY;
				}
				return COURSE_SURVEY;
			} else {
				if (moduleHashID) {
					return MODULE_COURSE_QUIZ;
				}
				return COURSE_QUIZ;
			}
		};

		let url = generateUrl(moduleHashID ? MODULE_COURSE_INFO : COURSE_INFO, {
			':moduleHashID': moduleHashID,
			':courseHashID': course.hashID
		});

		if (quiz && QuizManager.isQuizzable(quiz)) {
			url = generateUrl(getCourseRoute(), {
				':moduleHashID': moduleHashID,
				':courseHashID': course.hashID,
				':quizHashID': course.quizHashID,
				':surveyHashID': course.quizHashID
			});
		}

		return props.history.push(url);
	};

	const handleOpenInfo = (course: Course) => {
		setCourse(course);
	};

	const handleCloseInfo = () => {
		setCourse(null);
	};

	const showPurchaseCourseButton = () => {
		if (!isMerchant) {
			return null;
		}

		return (
			<Card data-test-id="course-screen_purchase-more-courses" className={classes.purchaseMoreCard}>
				<Typography
					variant="subtitle1"
					color="textSecondary"
					align="center"
					style={{ margin: '0.3em 0' }}
				>
					{I18n.t('courses.purchaseMore', { locale })}
				</Typography>
				<PurchaseCoursesButton locale={locale} />
			</Card>
		);
	};

	//if all courses are in modules, force a redirect to the modules page
	if (
		modules &&
		Object.keys(modules).length > 0 &&
		ModuleManager.isAllCoursesInModules(modules, allCourses) &&
		props.match.path === COURSES
	) {
		return <Redirect to={MODULES} />;
	}

	/**
	 * No courses available
	 */

	if ((!courses || Object.keys(courses).length === 0) && !hasUpdates && isMerchant) {
		return (
			<Grid container direction="column" alignItems="center" className={classes.root}>
				<Grid item data-test-id="course-screen_empty-state" className="screen-centered">
					<div style={{ margin: 0 }}>
						<CourseIcon className={classes.icon} />
					</div>

					<Typography variant="subtitle1" color="textSecondary" style={{ margin: '0.3em 0' }}>
						{I18n.t('courses.noPurchases', { locale })}
					</Typography>

					<PurchaseCoursesButton locale={locale} />
				</Grid>
			</Grid>
		);
	}

	if (!courses || Object.keys(courses).length === 0) {
		return (
			<Grid container direction="column" alignItems="center" className={classes.root}>
				<Grid item data-test-id="course-screen_empty-state" className="screen-centered">
					<div style={{ margin: 0 }}>
						<CourseIcon className={classes.icon} />
					</div>

					<Typography variant="subtitle1" color="textSecondary" style={{ margin: '0.3em 0' }}>
						{I18n.t('courses.none', { locale })}
					</Typography>

					<CheckForUpdatesButton data-test-id="course-screen_check-for-updates-button" />
				</Grid>
			</Grid>
		);
	}

	/**
	 * Courses available
	 */
	return (
		<div>
			<CourseList
				data-test-id="course-screen_courses-list"
				courses={courses}
				handleNext={handleNext}
				handleInfo={handleOpenInfo}
			/>
			{showPurchaseCourseButton()}

			{course && (
				<CourseDialog
					course={course}
					handleNext={handleNext}
					handleClose={handleCloseInfo}
					locale={locale}
				/>
			)}

			{isSessionFailValidating && (
				<MySnackbar
					open
					data-test-id="course-screen_snackbar"
					variant="error"
					message={I18n.t('send.sms.validation.fail', { locale })}
					onClose={() => {
						dispatch(doneValidateSession);
					}}
				/>
			)}
		</div>
	);
};

export default withRouter(CourseScreen as any);
