import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { setResponseFiles, setUserResponses } from '../redux/actions/actionCreators';
import RequiredBadge from '../components/badges/RequiredBadge';
import CustomExpansionPanel from '../components/custom/CustomExpansionPanel';
import NotFound from '../components/NotFound';
import Feedback from '../components/quiz/Feedback';
import HelperText from '../components/quiz/HelperText';
import QuestionAnswers from '../components/quiz/QuestionAnswers';
import QuestionFooter from '../components/quiz/QuestionFooter';
import { GRADED, MAXIMUM_FILES_PER_QUESTION } from '../config/constants';
import { COURSES } from '../config/routes';
import QuizManager from '../services/quiz/QuizManager';
import { Locale, Question, QuestionResponse, Quiz, QuizTypeEnum, Store, UserResponse } from '../types';
import { Theme } from '@material-ui/core';
import { ContentParams } from '../services/RouteResolver';
import { useTextDirection } from '../services/quiz/useTextDirection';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import FileUploadWidget, { FileData } from '../components/FileUploadWidget';
import { selectQuizResponseFiles } from '../redux/selectors/content';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import { selectLocale } from '../redux/selectors/settings';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';

const styles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2)
	},
	preamble: {
		borderStart: `1.5px solid ${theme.palette.primary.main}`,
		paddingStart: '20px',
		color: 'black',
		margin: '20px 0px',
		fontSize: '16px',
		lineHeight: '25px',
		fontWeight: 'bold'
	},
	questionImage: {
		width: '100%',
		height: 'auto',
		maxWidth: '400px'
	}
}));

export interface QuizReviewProps {
	locale: Locale;
	quiz: Quiz;
	questionIndex: string;
	question: Question;
	totalQuestions: number;
	questionResponse: QuestionResponse[] | null;
}

interface Params extends Pick<QuizReviewProps, 'questionIndex'>, Pick<ContentParams, 'quizHashID'> {}

interface Props extends RouteComponentProps<Params> {}

export const TooManyFilesDialog = ({ open = false, onOk }: { open?: boolean; onOk: () => void }) => {
	const locale = useSelector(selectLocale);
	return (
		<Dialog open={open}>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{I18n.t('question.maxFiles', {
						locale,
						number: MAXIMUM_FILES_PER_QUESTION
					})}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onOk} autoFocus>
					{I18n.t('selfTestQuiz.okay', { locale })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const QuestionScreen = (props: Props) => {
	//TODO: Refactor
	const myProps = useSelector((state: Store) => {
		const {
			content: { quizzes }
		} = state;
		const {
			match: { params }
		} = props;

		const quiz = params.quizHashID ? QuizManager.getQuiz(quizzes, params.quizHashID) : null;
		const questionIndex = params.questionIndex;

		//If the Quiz exists... get the Question and user responses
		if (!quiz) {
			return {};
		}

		//If there is no Question... return empty object
		const question = QuizManager.getQuestion(quiz, parseInt(questionIndex));

		if (!question) {
			return {};
		}

		const userResponse = question && quiz.userResponses[(question as Question).hashID];

		return {
			quiz,
			questionIndex,
			totalQuestions: quiz.questions ? quiz.questions.length : 0,
			question,
			userResponse,
			locale: state.settings.locale
		};
	});

	const {
		quiz,
		quiz: { hashID: quizHashID } = {},
		question: { hashID: questionHashID, type: questionType } = {},
		question,
		questionIndex,
		totalQuestions = 0,
		userResponse,
		locale = 'en'
	} = myProps;

	const quizResponseFiles = useSelector(selectQuizResponseFiles(quiz?.hashID)) ?? {};

	const [feedback, setFeedback] = useState(false);
	const [alertDialogOpen, setAlertDialogOpen] = useState(false);

	const [textDirection] = useTextDirection(myProps.question?.title || '');

	const theme = createMuiTheme({
		direction: textDirection
	});

	const dispatch = useDispatch();

	const closeAlert = () => {
		setAlertDialogOpen(false);
	};

	const handleResponseChange = (response: UserResponse) => {
		if (quizHashID && questionHashID && questionType) {
			dispatch(
				setUserResponses({
					quizHashID,
					questionHashID,
					questionType,
					response: response
				})
			);
		}
	};

	const handleFilesChange = (files: FileData[]) => {
		if (files.length > MAXIMUM_FILES_PER_QUESTION) {
			setAlertDialogOpen(true);
			return false;
		}

		if (quizHashID && questionHashID) {
			dispatch(
				setResponseFiles({
					quizHashID,
					questionHashID,
					fileData: files
				})
			);
		}
		return true;
	};

	const openFeedback = () => {
		setFeedback(true);
	};

	const closeFeedback = () => {
		setFeedback(false);
	};

	const classes = styles();

	if (!question?.hashID) {
		return <NotFound {...props} />;
	}

	if (quiz && quiz.submitted) {
		return <Redirect to={COURSES} />;
	} else if (question && questionIndex) {
		const showFileUpload = question.uploadFileAnswer && quiz?.type !== QuizTypeEnum.self_assessment;
		return (
			<div className="content-layout">
				<TooManyFilesDialog onOk={closeAlert} open={alertDialogOpen} />
				<Grid container direction="column" className={classes.root}>
					<div dir={textDirection}>
						<ThemeProvider theme={theme}>
							<Grid item>
								<Typography
									variant="subtitle2"
									color="textSecondary"
									className="joyride-question-indicator"
									data-test-id="question-screen-index"
								>
									{I18n.t('question.question', { locale })}{' '}
									{parseInt(questionIndex, 10) + 1} {I18n.t('question.of', { locale })}{' '}
									{totalQuestions}
								</Typography>

								{question.preamble && question.preamble.toLowerCase() !== 'null' && (
									<div
										data-test-id="question-preamble"
										className={classes.preamble}
										dangerouslySetInnerHTML={{
											__html: question.preamble
										}}
									/>
								)}

								<Typography
									data-test-id="question-screen_title"
									variant="h6"
									gutterBottom
									style={{ fontSize: '1.75rem' }}
								>
									{question.title}
								</Typography>
								{question.image && (
									<img
										data-test-id="question-image"
										src={question.image.url}
										alt="question"
										className={classes.questionImage}
									/>
								)}
							</Grid>
							{question.hint && question.hint.toLowerCase() !== 'null' && (
								<CustomExpansionPanel>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<Typography>{I18n.t('question.hint', { locale })}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<span
											dangerouslySetInnerHTML={{
												__html: question.hint
											}}
										/>
									</AccordionDetails>
								</CustomExpansionPanel>
							)}

							<Grid item>
								<HelperText type={question.type} locale={locale as string} />
							</Grid>
							<Grid item>
								<QuestionAnswers
									question={question}
									userResponse={userResponse}
									handleResponseChange={handleResponseChange}
									locale={locale}
								/>
							</Grid>
							{showFileUpload ? (
								<Grid item style={{ padding: '0.75rem 1rem' }}>
									<FileUploadWidget
										files={quizResponseFiles[question.hashID]}
										onFilesChange={handleFilesChange}
										id={question.hashID}
										key={question.hashID}
										isRequired={question.uploadFileAnswer?.isRequired}
									/>
								</Grid>
							) : null}
						</ThemeProvider>
						<Grid item>
							{quiz?.type === GRADED && question.required && <RequiredBadge locale={locale} />}
						</Grid>
					</div>
				</Grid>

				{quiz && (
					<QuestionFooter
						openFeedback={openFeedback}
						userResponse={userResponse}
						locale={locale}
						totalQuestions={totalQuestions}
						question={question}
						quiz={quiz}
						questionIndex={questionIndex}
					/>
				)}

				{quiz?.type !== GRADED && (
					<Feedback
						open={feedback}
						close={closeFeedback}
						question={question}
						userResponse={userResponse}
						locale={locale}
					/>
				)}
			</div>
		);
	}

	return <div />;
};

export default QuestionScreen;
