import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';
import CourseListItem from './CourseListItem';
import { Course } from '../../types';

const styles = makeStyles((theme) => ({
	background: {
		backgroundColor: theme.palette.background.default
	}
}));

export interface CourseListProps {
	handleNext: (course: Course) => void;
	handleInfo: (course: Course) => void;
	courses: {
		[index: string]: Course;
	};
}
const CourseList = ({ courses, handleNext, handleInfo }: CourseListProps) => {
	const classes = styles();

	return (
		<div className={classnames(classes.background, 'joyride-course-items')}>
			{Object.keys(courses).map((key, index) => {
				let course = courses[key];
				return (
					<CourseListItem
						className="joyride-course-item"
						data-test-id="course-list_course-list-item"
						key={index}
						course={course}
						handleNext={handleNext}
						handleInfo={handleInfo}
					/>
				);
			})}
		</div>
	);
};

export default CourseList;
