import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '../icons/SearchIcon';
import { Divider, InputBase } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Store, Category, SearchResult } from '../../types';
import generateUrl from '../../services/generateUrl';
import { COURSE_INFO, MODULE_COURSE_LIST, SESSION_DETAIL } from '../../config/routes';
import SearchCategories from './SearchCategories';
import SearchResultsList from './SearchResultsList';
import ModuleIcon from '../icons/ModuleIcon';
import CourseIcon from '../icons/CourseIcon';
import FolderIcon from '../icons/FolderIcon';
import debounce from 'lodash/debounce';
import I18n from 'i18n-js';

const useStyles = makeStyles((theme) => ({
	divider: {
		backgroundColor: theme.palette.primary.main,
		height: '2px'
	},
	searchFieldWrapper: {
		margin: '10px 0px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '0px 10px'
	},
	searchField: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	resultsWrapper: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	result: {
		borderBottom: '1px solid rgb(0 0 0 / 12%);'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	categoriesLabel: {
		fontSize: '13px !important',
		marginRight: '5px !important',
		position: 'relative',
		top: '2px'
	}
}));

const Transition = (props: any) => {
	return <Slide direction="up" {...props} />;
};

export default function FullScreenDialog(props: any) {
	const classes = useStyles();

	const { open, handleModalClose } = props;
	const [category, setCategory] = useState<Category>(Category.modules);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [searchResults, setSearchResults] = useState<Array<SearchResult> | null>(null);
	const [isSearching, setIsSearching] = useState<boolean>(false);

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const modules = useSelector(({ content: { modules } }: Store) => {
		return Object.keys(modules).length > 0
			? Object.keys(modules).map((module) => ({
					title: modules[module].name,
					hashID: module,
					icon: <ModuleIcon />,
					url: generateUrl(MODULE_COURSE_LIST, { ':moduleHashID': module })
			  }))
			: [];
	});

	const courses = useSelector(({ content: { courses } }: Store) => {
		return Object.keys(courses).length > 0
			? Object.keys(courses).map((course) => ({
					title: courses[course].title,
					hashID: course,
					image: courses[course].image ? courses[course].image!.url : undefined,
					icon: <CourseIcon />,
					url: generateUrl(COURSE_INFO, {
						':courseHashID': course
					})
			  }))
			: [];
	});

	const folders = useSelector(({ content: { folders } }: Store) => {
		return Object.keys(folders).map((folder) => ({
			title: folders[folder].title,
			hashID: folder,
			courseHashID: folders[folder].courseHashID,
			icon: <FolderIcon />,
			url: generateUrl(COURSE_INFO, {
				':courseHashID': folders[folder].courseHashID
			})
		}));
	});

	const sessions = useSelector(({ content: { sessions } }: Store) => {
		return Object.keys(sessions).length > 0
			? Object.keys(sessions).map((session) => ({
					title: sessions[session].title,
					hashID: session,
					courseHashID: sessions[session].courseHashID,
					folderHashID: sessions[session].folderHashID,
					url: generateUrl(SESSION_DETAIL, {
						':courseHashID': sessions[session].courseHashID,
						':sessionHashID': session
					})
			  }))
			: [];
	});

	const doSearch = (searchQuery: string, category: Category): void => {
		let results: SearchResult[] | null = null;

		switch (category) {
			case Category.modules:
				results = modules.filter((module) => {
					return module.title.toLowerCase().includes(searchQuery.trim().toLowerCase());
				});
				break;
			case Category.courses:
				results = courses.filter((course) => {
					return course.title.toLowerCase().includes(searchQuery.trim().toLowerCase());
				});
				break;
			case Category.folders:
				results = folders.filter((folder) => {
					return folder.title.toLowerCase().includes(searchQuery.trim().toLowerCase());
				});
				break;
			case Category.sessions:
				results = sessions.filter((session) => {
					return session.title.toLowerCase().includes(searchQuery.trim().toLowerCase());
				});
				break;
		}

		setSearchResults(results);
		setIsSearching(false);
	};

	const debouncedSearch = useCallback(
		debounce((searchQuery, category) => doSearch(searchQuery, category), 1000),
		[]
	);

	const handleSearchChange = (e: any): void => {
		setIsSearching(true);
		setSearchQuery(e.target.value);
	};

	//perform debounced search when searchQuery changes
	/* eslint-disable */
	useEffect(() => {
		debouncedSearch(searchQuery, category);
	}, [searchQuery]);

	//perform normal search if category changes
	useEffect(() => {
		doSearch(searchQuery, category);
	}, [category]);

	const routeToResult = useCallback((url: string) => {
		handleModalClose();
		return props.history.push(url);
	}, []);
	/* eslint-enable */

	return (
		<div>
			<Dialog
				data-test-id="search-modal_dialog"
				fullScreen
				open={open}
				TransitionComponent={Transition}
			>
				<Divider className={classes.divider} />
				<div className="content">
					<Paper elevation={3} className={classes.searchFieldWrapper}>
						<IconButton className="joyride-search-button" color="inherit">
							<SearchIcon />
						</IconButton>
						<InputBase
							onChange={handleSearchChange}
							value={searchQuery}
							className={classes.searchField}
							inputProps={{
								'aria-label': 'naked',
								placeholder: I18n.t('searchModal.search', { locale })
							}}
							data-testid="search_modal_input"
						/>
						<Button data-test-id="search-modal_dialog_cancel-btn" onClick={handleModalClose}>
							{I18n.t('searchModal.cancel', { locale })}
						</Button>
					</Paper>
					<SearchCategories category={category} handleCategoryChange={setCategory} />

					<SearchResultsList
						searchResults={searchResults}
						isFilled={searchQuery.trim() !== ''}
						routeToResult={routeToResult}
						isSearching={isSearching}
					/>
				</div>
			</Dialog>
		</div>
	);
}
