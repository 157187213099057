import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import RouteResolver from '../../services/RouteResolver';
import { Store } from '../../types';

interface Props extends RouteComponentProps {}

const DocumentTitle = (props: Props): null => {
	const myProps = useSelector(
		({
			settings: { locale },
			currentUser: {
				institution: { name: institutionName }
			}
		}: Store) => {
			const route = RouteResolver.resolve(props.location);
			const title = route ? RouteResolver.resolveTitle(route, locale) : '';

			return { title, institutionName };
		}
	);

	const { title, institutionName } = myProps;

	useEffect(() => {
		setTitle(title);
	}, [title]); //eslint-disable-line

	const setTitle = (title: string): void => {
		let subtitle: string = institutionName && title !== institutionName ? title + ' - ' : '';
		document.title = subtitle + (institutionName || process.env.REACT_APP_APP_NAME);
	};

	return null;
};

export default withRouter(DocumentTitle);
