import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import GoogleAnalytics from '../components/analytics/GoogleAnalytics';
import { LOGIN } from '../config/routes';
import generateUrl from '../services/generateUrl';
import { LOGIN_STATE_LOGGED_IN } from '../redux/store/initialState';
import { Settings, Store, User } from '../types';

interface MyProps extends Pick<RouteComponentProps, 'location' | 'history'> {
	loggedIn: boolean;
	consent: Settings['consent'];
	token?: User['token'];
	component: typeof React.Component;
}

type MyState = {};

const PrivateRoute = ({ component, ...rest }: any) => (
	<Route {...rest} render={(props) => <PrivateComponent component={component} {...props} />} />
);

export class PrivateInnerComponent extends Component<MyProps, MyState> {
	render() {
		const { component: Component, loggedIn, consent, token, ...props } = this.props;
		const shouldPush = props.history.action === 'PUSH';

		if (loggedIn) {
			if (!consent && token) {
				return <Redirect to={generateUrl(LOGIN, { ':token': token })} />;
			}

			return (
				<React.Fragment>
					<GoogleAnalytics />
					<Component {...props} />
				</React.Fragment>
			);
		}

		return <Redirect push={shouldPush} to={{ pathname: '/', state: { from: props.location } }} />;
	}
}

const mapStateToProps = ({ currentUser: { loginState, token }, settings: { consent } }: Store) => ({
	loggedIn: loginState === LOGIN_STATE_LOGGED_IN,
	consent,
	token
});

export const PrivateComponent = connect(mapStateToProps)(PrivateInnerComponent);

export default PrivateRoute;
