export const GRADED = 'graded';
export const SELF_ASSESSMENT = 'self_assessment';
export const SURVEY = 'survey';

export const SHORT_EXACT_QUESTION = 'short_exact';
export const MULTIPLE_CHOICE_QUESTION = 'multiple_choice';
export const COMPOUNDED_MULTIPLE_CHOICE_QUESTION = 'checkbox';
export const NUMERICAL_RANGE_QUESTION = 'numerical_range';
export const SEQUENCE_QUESTION = 'sequence';
export const ESSAY_QUESTION = 'essay';
export const RATING_SCALE_QUESTION = 'rating_scale';

export const HTML_SESSION_CONTENT = 'html';
export const BLOCK_SESSION_CONTENT = 'block';

export const QUIZ_SUBMISSION_DEFAULT_TIMEOUT_IN_SECONDS = 60;

export const QUIZ_SUBMISSION_WITH_FILE_UPLOAD_TIMEOUT_IN_SECONDS = 240;

export const MAXIMUM_FILES_PER_QUESTION = 5;

export const PWA_ANALYTICS_SOURCE = 'PWA';
