import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
	toolbar: theme.mixins.toolbar
});

interface Props extends WithStyles<typeof styles> {}

const ToolbarGap = ({ classes: { toolbar } }: Props) => <div className={toolbar} />;

export default withStyles(styles)(ToolbarGap);
