import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import I18n from 'i18n-js';
import { Card, CircularProgress, Typography, CardContent, Grid } from '@material-ui/core';
import { Store } from '../../types';

const UserProgressPanel = () => {
	const viewedSessions = useSelector(
		({
			content: {
				viewed: { sessions }
			}
		}: Store) => sessions
	);

	const allSessions = useSelector(({ content: { sessions } }: Store) => sessions);
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const allSessionLength = Object.keys(allSessions).length;

	const [viewedNum, setViewedNum] = useState<number>(0);
	const [totalSessions, setTotalSessions] = useState<number>(allSessionLength);
	const [percentCompleted, setPercentCompleted] = useState<number>(0);

	useEffect(() => {
		setTotalSessions(Object.keys(allSessions).length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allSessionLength]);

	useEffect(() => {
		const currentViewedSessions = viewedSessions.filter((session) =>
			Object.keys(allSessions).includes(session)
		);
		setViewedNum(currentViewedSessions.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewedSessions]);

	useEffect(() => {
		if (viewedNum && totalSessions) {
			const percent = (viewedNum / totalSessions) * 100;
			setPercentCompleted(parseInt(percent.toFixed(0)));
		}
	}, [viewedNum, totalSessions]);

	return (
		<Card style={{ position: 'relative', marginTop: '10px' }}>
			<CardContent>
				<Grid container direction="row">
					<Grid item style={{ marginRight: '20px' }}>
						<div style={{ width: 90, height: 90, position: 'relative' }}>
							<CircularProgress
								variant="determinate"
								value={percentCompleted}
								size={90}
								style={{ position: 'absolute', top: 0, left: 0 }}
							/>
							<Typography
								data-id="percentage"
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									fontSize: '24px',
									transform: 'translate(-50%,-50%)'
								}}
							>
								{percentCompleted}%
							</Typography>
						</div>
					</Grid>
					<Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Typography variant="h5">{I18n.t('account.progress.title', { locale })}</Typography>
						<Typography data-id="fraction" variant="body2" color="textSecondary">
							{I18n.t('account.progress.subtitle', {
								locale,
								read: viewedNum,
								remaining: totalSessions
							})}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default UserProgressPanel;
