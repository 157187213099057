import Avatar from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button/Button';
import lightGreen from '@material-ui/core/colors/lightGreen';
import Typography from '@material-ui/core/Typography/Typography';
import ThumbUp from '@material-ui/icons/ThumbUp';
import I18n from 'i18n-js';
import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { QuizNavigation } from '../../services/quiz/QuizNavigation';
import { Locale } from '../../types';

const positiveStyle = {
	color: '#fff',
	backgroundColor: lightGreen[500]
};

interface Props extends RouteComponentProps {
	locale: Locale;
}

export class SubmittedQuiz extends Component<Props, {}> {
	render() {
		const { locale } = this.props;

		return (
			<div className="screen-centered">
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						paddingBottom: '0.7em'
					}}
				>
					<Avatar style={positiveStyle}>
						<ThumbUp />
					</Avatar>
				</div>

				<Typography variant="h5" style={{ paddingBottom: '0.3em' }}>
					{I18n.t('quiz.submit.success', { locale })}
				</Typography>

				<Button
					variant="contained"
					color="primary"
					onClick={() => new QuizNavigation({ ...this.props }).quizEnd()}
					data-test-id="submitted_quiz_button"
				>
					{I18n.t('quiz.continue', { locale })}
				</Button>
			</div>
		);
	}
}

export default withRouter(SubmittedQuiz);
