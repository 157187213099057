import gql from 'graphql-tag';

export default gql`
	query hasUpdates($dateLastUpdated: DateTime, $courseHashIDs: String) {
		hasUpdates(dateLastUpdated: $dateLastUpdated, courseHashIDs: $courseHashIDs) {
			hasUpdates
			size
		}
	}
`;
