import Button from '@material-ui/core/Button/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import I18n from 'i18n-js';
import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdates, sendEventToAnalyticsEngine } from '../../redux/actions/actionCreators';
import CheckForUpdatesIcon from '../icons/CheckForUpdatesIcon';
import { Theme } from '@material-ui/core';
import { Store } from '../../types';
import { WithStyles } from '@material-ui/core/styles';
import { ActivityLabel } from '../../services/analytics/userActivityClient';

const styles = (theme: Theme) => ({
	icon: {
		marginRight: theme.spacing(1)
	}
});

interface Props extends WithStyles<typeof styles> {
	fullWidth?: boolean;
	customText?: string;
}

export const CheckForUpdatesButton = (props: Props) => {
	const myProps = useSelector(
		({
			currentUser: { token },
			content: { isFetching: isFetchingContent, updatedAt },
			updates: { hasUpdates, isFetching: isFetchingUpdates },
			settings: { locale }
		}: Store) => ({
			hasUpdates,
			isFetchingContent,
			isFetchingUpdates,
			updatedAt,
			locale,
			token
		})
	);

	const { token, updatedAt, locale, isFetchingUpdates, isFetchingContent, hasUpdates } = myProps;
	const dispatch = useDispatch();

	const handleUpdate = () => {
		ReactGA.event({
			category: 'Update',
			action: 'Manual Update Check',
			label: String(updatedAt)
		});

		dispatch(
			sendEventToAnalyticsEngine({
				label: ActivityLabel.CHECKED_FOR_UPDATES,
				description: `${token} checked for updates manually`
			})
		);

		dispatch(getUpdates(updatedAt));
	};

	return (
		<Button
			className={'joyride-check-for-updates-button'}
			data-test-id="check-for-updates-button_button"
			variant="contained"
			color="primary"
			fullWidth={props.fullWidth}
			disabled={hasUpdates || isFetchingContent || isFetchingUpdates}
			onClick={handleUpdate}
		>
			<CheckForUpdatesIcon
				data-test-id="check-for-updates-button_icon"
				className={props.classes.icon}
			/>
			{props.customText ?? I18n.t('update.checkForUpdates', { locale })}
		</Button>
	);
};

export default withStyles(styles)(CheckForUpdatesButton);
