import * as Sentry from '@sentry/browser';
import { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Institution, Store, User } from '../../types';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
	institution: Institution;
	token: User['token'];
	name?: string;
}

export class GoogleAnalytics extends Component<Props, {}> {
	constructor(props: Props) {
		super(props);

		const { analyticsCode } = this.props.institution;

		// Initialize Google Analytics
		if (typeof analyticsCode === 'string') {
			ReactGA.initialize(analyticsCode, {
				titleCase: false
			});

			console.info('Analytics initialized');
		}

		// Set the Analytics User ID
		this.setAnalyticsID();

		// Fire logged in event
		ReactGA.event({
			category: 'Login',
			action: 'Logged in',
			label: props.token || undefined
		});

		// Initial page load - only fired once
		this.sendPageChange(props.location.pathname, props.location.search);
	}

	componentDidMount() {
		this.setSentryUser();
	}

	componentDidUpdate(prevProps: Props, prevState: Props) {
		// URL change
		if (
			this.props.location.pathname !== prevProps.location.pathname ||
			this.props.location.search !== prevProps.location.search
		) {
			this.sendPageChange(this.props.location.pathname, this.props.location.search);
		}

		if (prevProps.token !== this.props.token) {
			console.info('Analytics user ID changed');
			this.setAnalyticsID();
		}
	}

	// Set the Analytics user ID
	setAnalyticsID = () => {
		if (this.props.token) {
			ReactGA.set({
				userId: this.props.token
			});

			console.info('Analytics user ID set');
		}
	};

	// Set the Sentry User
	setSentryUser = () => {
		Sentry.configureScope((scope) => {
			scope.setUser({
				id: this.props.token || undefined,
				username: this.props.name
			});
			scope.setTag('institution', this.props.institution.name || 'Chalkboard');
		});
	};

	// Send Page Change
	sendPageChange = (pathname: string, search = '') => {
		const page = pathname + search;

		ReactGA.set({ page });
		ReactGA.pageview(page);
	};

	render() {
		return null;
	}
}

const mapStateToProps = ({ currentUser: { firstName, lastName, token: userToken, institution } }: Store) => {
	let name = null;
	let token = null;

	if (firstName || lastName) {
		name = `${firstName} ${lastName}`;
	}

	if (userToken) {
		token = userToken.toString().toLowerCase();
	}

	return {
		name,
		token,
		institution
	};
};

export default withRouter(connect(mapStateToProps)(GoogleAnalytics));
