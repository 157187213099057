import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Popover from '@material-ui/core/Popover/Popover';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography/Typography';
import Smartphone from '@material-ui/icons/Smartphone';
import Sms from '@material-ui/icons/Sms';
import ClipboardJS from 'clipboard';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { Locale, Session } from '../../types';

interface Props {
	locale: Locale;
	unblockCode?: Session['unblockCode'];
}

const NotWorking = (props: Props) => {
	const { unblockCode, locale } = props;

	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		new ClipboardJS('#phone-number', {
			target: function(trigger: any) {
				return trigger;
			}
		});

		new ClipboardJS('#validation-code', {
			target: function(trigger: any) {
				return trigger;
			}
		});
	}, []);

	const handlePopoverOpen = (e: { target: any }) => {
		setAnchorEl(e.target);

		setTimeout(() => handlePopoverClose(), 1000);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Typography variant="subtitle1" color="textSecondary">
				{I18n.t('send.sms.notworking.label', { locale })}
			</Typography>
			<div>
				<TextField
					id="phone-number"
					data-clipboard-target="#phone-number"
					defaultValue={process.env.REACT_APP_API_PHONE_NUMBER}
					label={I18n.t('send.sms.notworking.toPhone', { locale })}
					onClick={handlePopoverOpen}
					fullWidth={true}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Smartphone />
							</InputAdornment>
						)
					}}
				/>
			</div>
			<div style={{ marginTop: '1em' }}>
				<TextField
					id="validation-code"
					data-clipboard-target="#validation-code"
					defaultValue={unblockCode}
					label={I18n.t('send.sms.notworking.unblockCode', {
						locale
					})}
					onClick={handlePopoverOpen}
					fullWidth={true}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Sms />
							</InputAdornment>
						)
					}}
				/>
			</div>
			<Popover
				data-test-id="pop_over"
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				<Typography style={{ margin: '0.75em' }}>Copied</Typography>
			</Popover>
		</div>
	);
};

export default NotWorking;
