import gql from 'graphql-tag';

export default gql`
	{
		quizResults {
			id
			quiz {
				hashID
				title
				type
				description
				questions {
					hashID
					title
					type
					answers {
						id
						title
						correct
						feedback
					}
				}
			}
			grade
			totalCorrect
			responses {
				questionHashID
				text
				correct
				answerID
			}
		}
	}
`;
