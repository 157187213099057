// @ts-nocheck
// Limitation of withRouter(connect(...)))

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import I18n from 'i18n-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getContent } from '../../redux/actions/actionCreators';
import {
	COURSE_QUIZ,
	FOLDER_QUIZ,
	HOME,
	LOGIN,
	REGISTER,
	SESSION_DISCUSSION,
	SESSION_QUIZ,
	WELCOME
} from '../../config/routes';
import RouteResolver from '../../services/RouteResolver';
import DownloadIcon from '../icons/DownloadIcon';
import MySnackbar from '../utils/MySnackbar';
import ToolbarGap from '../utils/ToolbarGap';
import UpdateDialog from './UpdateDialog';
import { Settings, Store } from '../../types';
import { styles } from './Updates.styles';
import classNames from 'classnames';

interface Props
	extends RouteComponentProps,
		WithStyles<typeof styles>,
		Pick<Store, 'content' | 'updates' | 'network'>,
		Pick<Settings, 'locale'> {
	dispatch?: any;
	path?: string;
	isLoginScreen?: boolean;
}

interface State {
	isAlreadyUpToDate: boolean;
	isErrorWhileCheckingUpdates: boolean;
	isErrorWhileUpdating: boolean;
	isUpdated: boolean;
	spoolCompleted: number;
	isErrorDownloadingFiles: boolean;
}

const DEFAULT_STATE: State = {
	isAlreadyUpToDate: false,
	isErrorWhileCheckingUpdates: false,
	isErrorWhileUpdating: false,
	isUpdated: false,
	spoolCompleted: 0,
	isErrorDownloadingFiles: false
};

const FULL_HEIGHT_PATHS = [COURSE_QUIZ, FOLDER_QUIZ, SESSION_QUIZ, WELCOME, SESSION_DISCUSSION];

export class Updates extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = DEFAULT_STATE;
	}

	componentWillReceiveProps(nextProps: Readonly<Props>) {
		const { isFetching: currentIsFetching } = this.props.updates;
		const { hasUpdates, isErrorFetching, isFetching } = nextProps.updates;
		const { spoolErrors } = this.props.content;

		// If there were errors and it's done spooling
		if (
			this.props.content.spool.total > 0 &&
			nextProps.content.spool.total === 0 &&
			spoolErrors.total > 0
		) {
			return this.setState({
				isErrorDownloadingFiles: true,
				spoolCompleted: 0,
				isUpdated: true
			});
		}

		if (this.props.content.spool.total > 0 && nextProps.content.spool.total === 0) {
			return this.setState({ spoolCompleted: 0, isUpdated: true });
		}

		const isErrorWhileCheckingUpdates = isErrorFetching && currentIsFetching && !isFetching;

		if (isErrorWhileCheckingUpdates) {
			return this.setState({ isErrorWhileCheckingUpdates: true });
		}

		const isErrorWhileUpdating =
			this.props.content.isFetching &&
			nextProps.content.isErrorFetching &&
			!nextProps.content.isFetching &&
			hasUpdates;

		if (isErrorWhileUpdating) {
			return this.setState({ isErrorWhileUpdating: true });
		}

		const isAlreadyUpToDate = !isErrorFetching && currentIsFetching && !isFetching && !hasUpdates;

		if (isAlreadyUpToDate) {
			return this.setState({ isAlreadyUpToDate: true });
		}

		this.setState({
			isUpdated: false,
			isErrorWhileCheckingUpdates: false,
			isErrorWhileUpdating: false
		});
	}

	/**
	 * Calculate update size in KB/MB
	 */
	updateSize = () => {
		const {
			locale,
			updates: { size }
		} = this.props;

		// In KB
		let type = I18n.t('update.KB', { locale });
		let updateSize = size / Math.pow(1024, 1);

		// In MB
		if (updateSize > 1024) {
			type = I18n.t('update.MB', { locale });
			updateSize = size / Math.pow(1024, 2);
		}

		// Round to 2 decimal places
		updateSize = Math.round(updateSize * 100) / 100;

		return (
			<React.Fragment>
				<strong>
					{I18n.t('update.downloadSize', {
						size: updateSize,
						type: type,
						locale
					})}
				</strong>
				{I18n.t('update.toDownload', { locale })}
			</React.Fragment>
		);
	};

	handleRequestClose = () => {
		this.setState({
			isUpdated: false,
			isAlreadyUpToDate: false,
			isErrorWhileCheckingUpdates: false,
			isErrorWhileUpdating: false
		});
	};

	handleLoad = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.props.dispatch(getContent());
	};

	info() {
		const { content, locale, network, updates, isLoginScreen, classes } = this.props;
		/**
		 * No Auth
		 */
		if (isLoginScreen) {
			return null;
		}

		/**
		 * Offline
		 */
		if (!network.isOnline) {
			return (
				<div className="updates-container" data-test-id="updates_container">
					<small>{I18n.t('update.offline', { locale })}</small>
				</div>
			);
		}

		/**
		 * Downloading content
		 */
		const spoolTotal = content.spool.total;
		const spoolUncompleted = content.spool.texts.length + content.spool.files.length;
		const spoolCompleted = spoolTotal - spoolUncompleted;
		const percentSpoolCompleted = spoolTotal > 0 ? Math.round((spoolCompleted * 100) / spoolTotal) : 100;

		if (percentSpoolCompleted < 100) {
			return (
				<React.Fragment>
					<UpdateDialog
						percentSpoolCompleted={percentSpoolCompleted}
						locale={locale}
						data-test-id="percent_spool_update_dialog"
					/>

					<SnackbarContent
						data-test-id="percent_spool_snackbar"
						classes={{ root: classes.downloading }}
						message={
							<Grid container>
								<Grid item xs={9}>
									{I18n.t('update.downloadingContent', {
										locale
									})}
									:
								</Grid>
								<Grid item container xs={3} justifyContent="flex-end">
									{percentSpoolCompleted}%
								</Grid>

								<Grid item xs={12}>
									<LinearProgress
										variant="determinate"
										value={percentSpoolCompleted}
										data-test-id="percent_spool_linear_progress"
									/>
								</Grid>
							</Grid>
						}
					/>
				</React.Fragment>
			);
		}

		/**
		 * Errors downloading
		 */
		if (this.state.isErrorDownloadingFiles) {
			return (
				<Dialog
					data-test-id="error_downloading_files"
					open={this.state.isErrorDownloadingFiles}
					onClose={() => this.setState({ isErrorDownloadingFiles: false })}
				>
					<DialogTitle>{I18n.t('error.spoolError.title', { locale })}</DialogTitle>
					<DialogContent>{I18n.t('error.spoolError.description', { locale })}</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							onClick={() =>
								this.setState({
									isErrorDownloadingFiles: false
								})
							}
						>
							{I18n.t('custom.gotIt', { locale })}
						</Button>
					</DialogActions>
				</Dialog>
			);
		}

		/**
		 * Fetching content to be downloaded
		 */
		if (content.isFetching) {
			return (
				<SnackbarContent
					data-test-id="content_isFetching"
					classes={{ root: classes.content }}
					message={I18n.t('update.updating', { locale })}
				/>
			);
		}

		/**
		 * Fetching quiz results to be downloaded
		 */
		if (content.isQuizResultFetching) {
			return (
				<SnackbarContent
					data-test-id="content_isFetching"
					classes={{ root: classes.content }}
					message={I18n.t('update.fetchingQuizResults', { locale })}
				/>
			);
		}

		/**
		 * Checking for content updates
		 */
		if (updates.isFetching) {
			return (
				<SnackbarContent
					data-test-id="updates_isFetching"
					classes={{ root: classes.content }}
					message={I18n.t('update.checking', { locale })}
				/>
			);
		}

		/**
		 * Content updates exist
		 */
		if (updates.hasUpdates) {
			return (
				<SnackbarContent
					data-test-id="joyride-update-snackbar"
					classes={{
						root: classes.content + ' joyride-update-snackbar'
					}}
					message={<p>{this.updateSize()}</p>}
					action={
						<Button
							variant="contained"
							color="primary"
							onClick={this.handleLoad}
							className="joyride-update-snackbar-button"
							data-test-id="joyride-update-button"
						>
							<DownloadIcon className={classes.downloadIcon} />
							{I18n.t('update.label', { locale })}
						</Button>
					}
				/>
			);
		}
	}

	render() {
		const { content, path, classes, locale } = this.props;

		if (!path) {
			return null;
		}

		return (
			<React.Fragment>
				<div
					className={classNames({
						[classes.fixed]: FULL_HEIGHT_PATHS.includes(path)
					})}
					data-test-id="updates_body"
				>
					<ToolbarGap />

					{/* Update information... */}
					{this.info()}
				</div>

				<MySnackbar
					open={this.state.isErrorWhileCheckingUpdates}
					variant="error"
					message={I18n.t('update.errorWhileCheckingUpdates', {
						locale
					})}
					onClose={this.handleRequestClose}
					data-test-id="snackbar_isErrorWhileCheckingUpdates"
				/>
				<MySnackbar
					open={this.state.isAlreadyUpToDate}
					message={I18n.t('update.upToDate', { locale })}
					onClose={this.handleRequestClose}
					data-test-id="snackbar_isAlreadyUpToDate"
				/>
				<MySnackbar
					open={this.state.isUpdated && content.spoolErrors.total > 0}
					variant="warning"
					message={I18n.t('update.warning', { locale })}
					onClose={this.handleRequestClose}
					data-test-id="snackbar_spoolErrors_above_0"
				/>
				<MySnackbar
					open={this.state.isUpdated && content.spoolErrors.total === 0}
					variant="success"
					message={I18n.t('update.success', { locale })}
					onClose={this.handleRequestClose}
					data-test-id="snackbar_spoolErrors_equal_0"
				/>
				<MySnackbar
					open={this.state.isErrorWhileUpdating}
					variant="error"
					message={I18n.t('update.errorWhileUpdating', { locale })}
					onClose={this.handleRequestClose}
					data-test-id="snackbar_isErrorWhileUpdating"
				/>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ content, network, settings: { locale }, updates }: Store, props: Props) {
	const route = RouteResolver.resolve(props.location);
	const path = route && route.path;
	let isLoginScreen = true;

	if (route && ![LOGIN, WELCOME, HOME, REGISTER].includes(path)) {
		isLoginScreen = false;
	}

	return { content, locale, network, updates, path, isLoginScreen };
}

// @ts-ignore
export default withRouter(connect(mapStateToProps)(withStyles(styles)(Updates))) as any;
