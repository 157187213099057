import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import EndIcon from '../icons/EndIcon';
import NextIcon from '../icons/NextIcon';
import PreviousIcon from '../icons/PreviousIcon';
import { Theme } from '@material-ui/core';
import { Store } from '../../types';
import { WithStyles } from '@material-ui/core/styles';
import { TooltipRenderProps } from 'react-joyride';

const styles = (theme: Theme) => ({
	paper: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		width: '95vw'
	},
	title: {
		marginBottom: theme.spacing(2)
	},
	footer: {
		paddingTop: theme.spacing(2)
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	}
});

interface Props extends WithStyles<typeof styles>, TooltipRenderProps {
	continuous: boolean;
	index: number;
	isLastStep: boolean;
}

const Tooltip: React.FC<Props> = ({
	continuous,
	index,
	step,
	backProps,
	closeProps,
	primaryProps,
	tooltipProps,
	classes,
	isLastStep
}) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	return (
		<Paper className={classes.paper}>
			<div {...tooltipProps}>
				{step.title && (
					<Typography
						variant="h6"
						align="center"
						className={classes.title}
						data-test-id="tooltip_title"
					>
						{step.title}
					</Typography>
				)}

				<Typography data-test-id="tooltip_content">
					<span dangerouslySetInnerHTML={{ __html: step.content as string }} />
				</Typography>

				{/* Footer */}
				<Grid container spacing={8} className={classes.footer}>
					<Grid item xs={6}>
						{index > 0 && (
							<Button
								variant="outlined"
								color="primary"
								fullWidth
								{...backProps}
								data-test-id="tooltip_btn_previous"
							>
								{I18n.t('guided-tour.button.previous', {
									locale
								})}
								<PreviousIcon className={classes.leftIcon} />
							</Button>
						)}
					</Grid>
					{continuous ? (
						isLastStep ? (
							<Grid item xs={6}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									{...closeProps}
									data-test-id="tooltip_btn_finish"
								>
									{I18n.t('guided-tour.button.finish', {
										locale
									})}
									<EndIcon className={classes.rightIcon} />
								</Button>
							</Grid>
						) : (
							<Grid item xs={6}>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									{...primaryProps}
									data-test-id="tooltip_btn_next"
								>
									{I18n.t('guided-tour.button.next', {
										locale
									})}
									<NextIcon className={classes.rightIcon} />
								</Button>
							</Grid>
						)
					) : (
						<Grid item xs={6}>
							<Button
								variant="contained"
								color="primary"
								fullWidth
								{...closeProps}
								data-test-id="tooltip_btn_finish_2"
							>
								{I18n.t('guided-tour.button.finish', {
									locale
								})}
								<EndIcon className={classes.rightIcon} />
							</Button>
						</Grid>
					)}
				</Grid>
			</div>
		</Paper>
	);
};

export default withStyles(styles)(Tooltip);
