import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import MessangerIcon from '@material-ui/icons/Facebook';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../types';

const styles = makeStyles({
	chipAvatar: {
		width: 32,
		height: 32
	}
});

export default () => {
	const { firstName, lastName, locale } = useSelector(
		({ currentUser: { firstName, lastName }, settings: { locale } }: Store) => ({
			firstName,
			lastName,
			locale
		})
	);

	const classes = styles();

	const mailAddress = process.env.REACT_APP_SUPPORT_EMAIL;
	const phoneNumber = process.env.REACT_APP_SUPPORT_PHONE_NUMBER;
	const messenger = process.env.REACT_APP_SUPPORT_MESSENGER;

	const handleEmail = () => {
		window.location.assign(
			`mailto:${mailAddress}?Subject=Need%20Help%20%2D%20${firstName}%20${lastName}%20%28${phoneNumber}%29`
		);
	};
	const handleMessanger = () => {
		window.location.assign(`${messenger}`);
	};

	const handlePhone = () => {
		window.location.assign(`tel:${phoneNumber}`);
	};

	const handleWhatsapp = () => {
		window.location.assign(`https://wa.me/${phoneNumber}`);
	};

	return (
		<Card data-id="support-panel">
			<CardHeader
				disableTypography
				title={
					<Typography data-id="title" variant="h5">
						{I18n.t('account.support.title', { locale })}
					</Typography>
				}
				subheader={
					<Typography data-id="subtitle" variant="body2" color="textSecondary">
						{I18n.t('account.support.subtitle', { locale })}
					</Typography>
				}
			/>

			<CardContent>
				<Grid item container spacing={1} direction="column">
					<Grid item>
						<Chip
							onClick={handleEmail}
							data-id="email-button"
							className={'joyride-support-panel-email'}
							color="primary"
							avatar={
								<Avatar className={classes.chipAvatar}>
									<MailIcon />
								</Avatar>
							}
							label={mailAddress}
						/>
					</Grid>
					<Grid item>
						<Chip
							onClick={handlePhone}
							className={'joyride-support-panel-phone'}
							data-id="call-button"
							color="primary"
							avatar={
								<Avatar className={classes.chipAvatar}>
									<PhoneIcon />
								</Avatar>
							}
							label={phoneNumber}
						/>
					</Grid>
					<Grid item>
						<Chip
							onClick={handleWhatsapp}
							className={'joyride-support-panel-whatsapp'}
							data-id="call-button"
							color="primary"
							avatar={
								<Avatar className={classes.chipAvatar}>
									<WhatsappIcon />
								</Avatar>
							}
							label="Whatsapp"
						/>
					</Grid>
					<Grid item>
						<Chip
							onClick={handleMessanger}
							className={'joyride-support-panel-whatsapp'}
							data-id="call-button"
							color="primary"
							avatar={
								<Avatar className={classes.chipAvatar}>
									<MessangerIcon />
								</Avatar>
							}
							label="Facebook Messanger"
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
