import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { receiveValidateSessionSMS } from '../../redux/actions/actionCreators';
import { Locale, Session } from '../../types';

interface Props {
	open: boolean;
	locale: Locale;
	sessionHashID: Session['hashID'];
	progressCode: Session['progressCode'];
	handleClose: () => void;
}

const Transition = (props: any) => {
	return <Slide direction="up" {...props} />;
};

const ProgressCodeModal = (props: Props) => {
	const { open, handleClose, locale, progressCode, sessionHashID } = props;
	const [code, setCode] = useState('');

	const dispatch = useDispatch();

	const submit = () => {
		if (typeof progressCode === 'string' && code !== progressCode) {
			console.log(code);
			return alert('Incorrect Code');
		}
		return dispatch(receiveValidateSessionSMS(sessionHashID));
	};

	return (
		<Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}>
			<DialogTitle data-test-id="progress-code-modal_title" id="alert-dialog-slide-title">
				{I18n.t('send.sms.progressCode.title', { locale })}
			</DialogTitle>

			<DialogContent>
				<TextField
					data-test-id="progress-code-modal_text-field"
					label={I18n.t('send.sms.progressCode.placeholder', {
						locale
					})}
					multiline
					fullWidth
					margin="normal"
					variant="outlined"
					value={code}
					onChange={(e) => setCode(e.target.value)}
				/>
			</DialogContent>

			<DialogActions>
				<Button data-test-id="progress-code-modal_cancel-btn" onClick={handleClose} color="primary">
					{I18n.t('send.sms.progressCode.cancel', { locale })}
				</Button>
				<Button
					data-test-id="progress-code-modal_submit-btn"
					onClick={submit}
					color="primary"
					disabled={!code || code.length !== 20}
				>
					{I18n.t('send.sms.progressCode.submit', { locale })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProgressCodeModal;
