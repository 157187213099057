import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import MobileStepper from '@material-ui/core/MobileStepper/MobileStepper';
import Slide from '@material-ui/core/Slide/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AddToHomescreenIcon from '../components/icons/onboarding/AddToHomescreenIcon';
import AssignmentIcon from '../components/icons/onboarding/AssignmentIcon';
import DevicesIcon from '../components/icons/onboarding/DevicesIcon';
import DiscussionsIcon from '../components/icons/onboarding/DiscussionsIcon';
import OfflineIcon from '../components/icons/onboarding/OfflineIcon';
import AddToHomescreenInstruction from '../components/onboarding/AddToHomescreenInstruction';
import { MODULES } from '../config/routes';
import { isAddToHomescreenCompatible } from '../services/addToHomescreenService';
import { Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';

declare var window: any;

const styles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		padding: '1.5em',
		minHeight: '100vh',
		textAlign: 'center',
		overflow: 'hidden'
	},
	stepper: {
		backgroundColor: theme.palette.primary.main
	},
	dotActive: {
		backgroundColor: theme.palette.secondary.main
	},
	icon: {
		color: theme.palette.background.default,
		fontSize: '50vmin'
	},
	instruction: {
		marginTop: '0.7em',
		backgroundColor: theme.palette.background.default,
		padding: '1.2em 1em',
		borderRadius: '4px',
		elevation: '1px '
	},
	image: {
		marginTop: '1em',
		borderRadius: '4px'
	}
}));

interface Props extends Pick<RouteComponentProps, 'history'> {}

const OnboardingScreen = (props: Props) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const [step, setStep] = useState(0);
	const [total] = useState(isAddToHomescreenCompatible() ? 5 : 4);

	const handleNext = () => {
		if (step === total - 1) {
			/**
			 * If Add To Homescreen was deferred, show the deferred
			 * prompt and route to the modules page
			 * after user choice
			 */
			if (window.deferredPrompt) {
				window.deferredPrompt.prompt();

				window.deferredPrompt.userChoice.then(() => {
					props.history.push(MODULES);
				});
			} else {
				props.history.push(MODULES);
			}
		} else {
			setStep(step + 1);
		}
	};

	const handleSkip = () => {
		props.history.push(MODULES);
	};

	// const handleBack = () => {
	// 	setStep(step - 1);
	// };

	const typographyText = () => {
		const titles = [
			'onboarding.0.title',
			'onboarding.1.title',
			'onboarding.2.title',
			'onboarding.3.title',
			'onboarding.4.title'
		];

		const subtitles = [
			'onboarding.0.subtitle',
			'onboarding.1.subtitle',
			'onboarding.2.subtitle',
			'onboarding.3.subtitle',
			'onboarding.4.subtitle'
		];

		return (
			<React.Fragment>
				<Typography variant="h5">
					<b>{I18n.t(titles[step], { locale })}</b>
				</Typography>
				<Typography variant="subtitle1" color="textSecondary">
					{I18n.t(subtitles[step], { locale })}
				</Typography>
			</React.Fragment>
		);
	};

	const buttonText = () => {
		return I18n.t(
			step === total - 1
				? window.deferredPrompt
					? 'onboarding.addToHomescreen'
					: 'onboarding.getStarted'
				: 'onboarding.next',
			{ locale }
		);
	};

	const showOnboarding = () => {
		const classes = styles();

		return (
			<React.Fragment>
				<Slide direction="left" in={step === 0} mountOnEnter unmountOnExit exit={false}>
					<div>
						<Grid item>
							<AssignmentIcon className={classes.icon} />
						</Grid>
						<Grid item>{typographyText()}</Grid>
					</div>
				</Slide>

				<Slide direction="left" in={step === 1} mountOnEnter unmountOnExit exit={false}>
					<div>
						<Grid item>
							<OfflineIcon className={classes.icon} />
						</Grid>
						<Grid item>{typographyText()}</Grid>
					</div>
				</Slide>

				<Slide direction="left" in={step === 2} mountOnEnter unmountOnExit exit={false}>
					<div>
						<Grid item>
							<DevicesIcon className={classes.icon} />
						</Grid>
						<Grid item>{typographyText()}</Grid>
					</div>
				</Slide>

				<Slide direction="left" in={step === 3} mountOnEnter unmountOnExit exit={false}>
					<div>
						<Grid item>
							<DiscussionsIcon className={classes.icon} />
						</Grid>
						<Grid item>{typographyText()}</Grid>
					</div>
				</Slide>

				<Slide direction="left" in={step === 4} mountOnEnter unmountOnExit exit={false}>
					<div>
						<Grid item>
							<AddToHomescreenIcon className={classes.icon} />
						</Grid>
						<Grid item>{typographyText()}</Grid>
						<Grid item>
							<AddToHomescreenInstruction />
						</Grid>
						<Grid item>
							{window.deferredPrompt && (
								<span
									style={{
										marginTop: '0.7em',
										marginBottom: '0.7em'
									}}
								>
									{I18n.t('onboarding.4.instructions.clickButton')}
								</span>
							)}
						</Grid>
					</div>
				</Slide>
			</React.Fragment>
		);
	};

	const classes = styles();

	return (
		<Grid container justify="space-between" direction="column" className={classes.root}>
			<Grid item container direction="row" justify="flex-end">
				<Grid item>
					<Button variant="text" color="secondary" onClick={handleSkip}>
						{I18n.t('onboarding.skip', { locale })}
					</Button>
				</Grid>
			</Grid>

			<Grid item container direction="column" id="showOnboarding">
				{showOnboarding()}
			</Grid>

			<Grid item container direction="column" spacing={4} alignItems="center">
				<Grid item>
					<Button
						data-test-id="on-boarding-screen_proceed-button"
						variant="contained"
						color="secondary"
						onClick={handleNext}
						id="on-boarding-screen_proceed-button"
					>
						{buttonText()}
					</Button>
				</Grid>

				<Grid item>
					<MobileStepper
						variant="dots"
						steps={total}
						position="static"
						activeStep={step}
						classes={{
							root: classes.stepper,
							dotActive: classes.dotActive
						}}
						nextButton={null}
						backButton={null}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default OnboardingScreen;
