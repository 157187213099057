import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ProgressCodeModal from '../components/session/ProgressCodeModal';
import ValidatedSession from '../components/session/ValidatedSession';
import NotWorking from '../components/sms/NotWorking';
import CourseManager from '../services/CourseManager';
import { Session, Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../services/RouteResolver';

interface Props extends Pick<RouteComponentProps<Pick<ContentParams, 'sessionHashID'>>, 'match'> {}

const SendSMSScreen = (props: Props) => {
	const myProps = useSelector((state: Store) => {
		const {
			currentUser: { token },
			content: { sessions },
			settings: { locale }
		} = state;

		const {
			match: {
				params: { sessionHashID }
			}
		} = props;

		let session: Session | null = CourseManager.getSession(sessions, sessionHashID);

		if (!session) {
			session = null;
		}

		const nextSession = session && CourseManager.getNextSession(sessions, session);

		return { locale, session, nextSession, token };
	});

	const { locale, nextSession, session } = myProps;

	let unblockCode, progressCode;

	if (session) {
		unblockCode = session.unblockCode;
		progressCode = session.progressCode;
	}
	// const { unblockCode, progressCode } = session;

	const [showNotWorking, setShowNotWorking] = useState(false);
	const [progressModal, setProgressModal] = useState(false);

	const openSMSAppLink = (unblockCode?: string | null) => {
		if (/iPhone|iPad|iPod|Mac/i.test(navigator.userAgent)) {
			if (/iP(hone|od|ad)/i.test(navigator.userAgent)) {
				let v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
				if (v) {
					//Below iOS 8
					if (parseInt(v[1], 10) < 8) {
						return `sms:${process.env.REACT_APP_API_PHONE_NUMBER};body=${unblockCode}`;
					}
				}
			}

			//iOS 8 and above
			return `sms:${process.env.REACT_APP_API_PHONE_NUMBER}&body=${unblockCode}`;
		}

		//Every other platform
		return `sms:${process.env.REACT_APP_API_PHONE_NUMBER}?body=${unblockCode}`;
	};

	const openProgressModal = () => {
		setProgressModal(true);
	};

	const closeProgressModal = () => {
		setProgressModal(false);
	};

	if (session && session.progressed) {
		return <ValidatedSession session={session} nextSession={nextSession} locale={locale} />;
	}

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			justify="center"
			spacing={8}
			className="screen-centered"
		>
			<Grid item>
				<Typography data-test-id="send-sms-screen_title" variant="h6">
					{I18n.t('send.sms.title', { locale })}
				</Typography>
			</Grid>

			<Grid item>
				<Typography data-test-id="send-sms-screen_label" variant="subtitle1">
					{I18n.t('send.sms.label', { locale })}
				</Typography>
			</Grid>

			<Grid item container spacing={8} justify="center">
				{!showNotWorking && (
					<React.Fragment>
						<Grid item>
							<Button
								data-test-id="send-sms-screen_send-sms-button"
								variant="contained"
								href={openSMSAppLink(unblockCode)}
								target="_blank"
								color="primary"
							>
								{I18n.t('send.sms.button', { locale })}
							</Button>
						</Grid>

						<Grid item>
							<Button
								data-test-id="send-sms-screen_sms-not-working-button"
								onClick={() => setShowNotWorking(true)}
								variant="outlined"
								color="secondary"
								style={{ fontSize: '12px' }}
							>
								{I18n.t('send.sms.notworking.button', {
									locale
								})}
							</Button>
						</Grid>
					</React.Fragment>
				)}

				{showNotWorking && (
					<Grid
						data-test-id="send-sms-screen_sms-not-working-screen"
						item
						container
						direction="column"
					>
						<NotWorking unblockCode={unblockCode} locale={locale} />
					</Grid>
				)}
			</Grid>

			<Grid item>
				<Button variant="contained" color="secondary" onClick={openProgressModal}>
					{I18n.t('send.sms.progressCode.button', { locale })}
				</Button>
			</Grid>

			{session && typeof progressCode === 'string' && (
				<ProgressCodeModal
					open={progressModal}
					sessionHashID={session.hashID}
					progressCode={progressCode}
					handleClose={closeProgressModal}
					locale={locale}
				/>
			)}
		</Grid>
	);
};

export default SendSMSScreen;
