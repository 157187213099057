import Card from '@material-ui/core/Card';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { COMPOUNDED_MULTIPLE_CHOICE_QUESTION, ESSAY_QUESTION } from '../../../config/constants';
import Responses from '../feedback/Responses';
import { QuestionResponse, Store } from '../../../types';
import { QuizReviewProps } from '../../../containers/QuestionScreen';
import { useSelector } from 'react-redux';
import I18n from 'i18n-js';

const useStyles = makeStyles(() => ({
	pendingSolution: {
		backgroundColor: 'rgba(128, 128, 128, 0.24)',
		color: 'rgba(0, 0, 0, 0.53)',
		fontStyle: 'italic',
		width: '100%',
		padding: '13px 24px'
	}
}));

interface Props extends Pick<QuizReviewProps, 'questionResponse' | 'question' | 'locale'> {
	userResponse: any;
}

const QuestionSolution = (props: Props) => {
	const { question, questionResponse, userResponse } = props;

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const solution = () => {
		if (question.type === COMPOUNDED_MULTIPLE_CHOICE_QUESTION) {
			let correct = false;
			let count = 0;

			const correctAnswers = question.answers.filter((answer) => answer.correct === true);

			const sortedAnswers = correctAnswers.map((answer) => answer.id);

			(userResponse as QuestionResponse['text'][]).forEach((response: any) => {
				if (sortedAnswers.includes(response.toString())) {
					count++;
				}

				if (count === sortedAnswers.length && userResponse.length === sortedAnswers.length) {
					correct = true;
				}
			});

			return correct;
		}

		return questionResponse && questionResponse.length > 0 ? questionResponse[0].correct : null;
	};

	const CORRECT_COLOR = '#8bc34a';
	const WRONG_COLOR = '#f44336';

	const classes = useStyles();

	if (solution() !== null) {
		if (question.type === ESSAY_QUESTION) {
			return (
				<ExpansionPanel expanded={false} data-test-id="question-solution_essay_question">
					<ExpansionPanelSummary
						style={{
							backgroundColor: solution() ? CORRECT_COLOR : WRONG_COLOR,
							color: 'white'
						}}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							{solution()
								? I18n.t('quizReview.correct', { locale })
								: I18n.t('quizReview.wrong', { locale })}
						</Typography>
					</ExpansionPanelSummary>
				</ExpansionPanel>
			);
		}

		return (
			<ExpansionPanel data-test-id="question-solution_question">
				<ExpansionPanelSummary
					style={{
						backgroundColor: solution() ? CORRECT_COLOR : WRONG_COLOR,
						color: 'white'
					}}
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					// disabled={}
				>
					<Typography>
						{solution()
							? I18n.t('quizReview.correct', { locale })
							: I18n.t('quizReview.wrong', { locale })}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					{question.answers && <Responses question={question} locale={locale} />}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}

	return (
		<Card className={classes.pendingSolution}>
			<Typography>{I18n.t('quizReview.notMarked', { locale })}</Typography>
		</Card>
	);
};

export default QuestionSolution;
