import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Typography from '@material-ui/core/Typography/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { ESSAY_QUESTION, SEQUENCE_QUESTION } from '../../../config/constants';
import ResponseContent from './ResponseContent';
import { Locale, Question } from '../../../types';

interface Props {
	locale: Locale;
	question: Question;
}

const Responses = ({ question, locale }: Props) => {
	let { answers } = question;

	if (question.type === SEQUENCE_QUESTION) {
		answers = JSON.parse(answers[0].title);
	}

	return (
		<Grid container direction="column">
			{question.type !== ESSAY_QUESTION ? (
				<Grid item data-test-id="self_test_quiz_answers">
					<Typography style={{ paddingTop: '1em' }}>
						{I18n.t('selfTestQuiz.answers', { locale })}
					</Typography>
				</Grid>
			) : null}

			<Table>
				<TableBody>
					<ResponseContent
						questionType={question.type}
						answers={answers}
						locale={locale}
						data-test-id="response_content"
					/>
				</TableBody>
			</Table>
		</Grid>
	);
};

export default Responses;
