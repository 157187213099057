import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { addViewedFolder, toggleFolder } from '../redux/actions/actionCreators';
import FolderList from '../components/folder/FolderList';
import { SURVEY } from '../config/constants';
import { FOLDER_QUIZ, FOLDER_SURVEY, MODULE_FOLDER_QUIZ, MODULE_FOLDER_SURVEY } from '../config/routes';
import CourseManager from '../services/CourseManager';
import generateUrl from '../services/generateUrl';
import QuizManager from '../services/quiz/QuizManager';
import { Folder, Store } from '../types';
import { ContentParams } from '../services/RouteResolver';

const styles = makeStyles((theme) => ({
	padded: {
		padding: theme.spacing(2),
		paddingBottom: 0
	}
}));

interface Params extends Pick<ContentParams, 'courseHashID' | 'moduleHashID'> {}

interface Props extends RouteComponentProps<Params> {}

export const FolderFragment = (props: Props) => {
	const myProps = useSelector(
		({ content, content: { quizzes, sessions, viewed, toggled }, settings: { locale } }: Store) => {
			const folders = CourseManager.getFoldersFromCourse(
				content.folders,
				props.match.params.courseHashID
			);
			const viewedSessions = viewed.sessions;
			const viewedFolders = viewed.folders;
			const toggledFolders = toggled.folders;

			return {
				folders,
				locale,
				quizzes,
				sessions,
				viewedSessions,
				viewedFolders,
				toggledFolders
			};
		}
	);

	const dispatch = useDispatch();

	const { folders, sessions, viewedSessions, viewedFolders, locale, quizzes, toggledFolders } = myProps;
	const moduleHashID = props.match.params.moduleHashID;

	useEffect(() => {
		let folderHashIDs = Object.keys(folders);

		folderHashIDs.forEach((folderHashID) => {
			let folderSessions = CourseManager.getSessionsFromFolder(sessions, folderHashID);
			let sessionHashIDs = Object.keys(folderSessions);
			let allFolderSessionsRead = true;

			sessionHashIDs.forEach((session) => {
				if (!viewedSessions.includes(session)) {
					allFolderSessionsRead = false;
				}
			});

			if (allFolderSessionsRead) {
				if (!viewedFolders.includes(folderHashID)) {
					dispatch(addViewedFolder(folderHashID));
				}
			}
		});
	}, []); //eslint-disable-line

	// Check if folder is toggled open or close
	const expanded = (folderHashID: Folder['hashID']) => {
		return toggledFolders.includes(folderHashID);
	};

	const getFolderQuizRoute = (quiz: { type: string }) => {
		if (quiz && quiz.type === SURVEY) {
			if (moduleHashID) {
				return MODULE_FOLDER_SURVEY;
			}
			return FOLDER_SURVEY;
		} else {
			if (moduleHashID) {
				return MODULE_FOLDER_QUIZ;
			}
			return FOLDER_QUIZ;
		}
	};

	// Open quiz route for folder
	const handleQuiz = (folder: Folder) => {
		const quiz = folder.quizHashID && QuizManager.getQuiz(quizzes, folder.quizHashID);

		if (quiz) {
			return props.history.push(
				generateUrl(getFolderQuizRoute(quiz), {
					':moduleHashID': moduleHashID,
					':courseHashID': folder.courseHashID || undefined,
					':folderHashID': folder.hashID || undefined,
					':surveyHashID': folder.quizHashID || undefined,
					':quizHashID': folder.quizHashID || undefined
				})
			);
		}
	};

	const classes = styles();

	if (folders && Object.keys(folders).length > 0) {
		return (
			<React.Fragment>
				<Typography variant="h6" className={classes.padded}>
					Folders
				</Typography>

				<Divider />

				<div className="content-layout" style={{ width: '100%' }}>
					<FolderList
						className="joyride-folder-items"
						data-test-id="folder-screen_folder-list"
						folders={folders}
						viewedFolders={viewedFolders}
						sessions={sessions}
						quizzes={quizzes}
						locale={locale}
						handleQuiz={handleQuiz}
						handleToggle={(folderHashID: Folder['hashID']) =>
							dispatch(toggleFolder(folderHashID))
						}
						expanded={expanded}
					/>
				</div>
			</React.Fragment>
		);
	}

	return null;
};

export default withRouter(FolderFragment);
