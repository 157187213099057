export const lowerNumber = (num1: number | string, num2: number | string) => {
	let a = Number(num1);
	let b = Number(num2);

	return a < b ? a : b;
};

export const higherNumber = (num1: number | string, num2: number | string) => {
	let a = Number(num1);
	let b = Number(num2);

	return a > b ? a : b;
};
