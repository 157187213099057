import IconButton from '@material-ui/core/IconButton/IconButton';
import React from 'react';
import DiscussionIcon from '../icons/DiscussionIcon';

interface Props {
	handleDiscussion: () => void;
}

const Discussion = ({ handleDiscussion }: Props) => (
	<IconButton
		className="joyride-discuss-button"
		color="inherit"
		onClick={handleDiscussion}
		data-test-id="joyride-discuss-button"
	>
		<DiscussionIcon />
	</IconButton>
);

export default Discussion;
