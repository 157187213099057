import { useEffect, useState } from 'react';
import bidiFactory from 'bidi-js';

export const useTextDirection = (sampleText: string) => {
	const [textDirection, setTextDirection] = useState<'ltr' | 'rtl'>('ltr');

	useEffect(() => {
		setTextDirection(getDirection(sampleText));
	}, [sampleText]);

	return [textDirection];
};

export function getDirection(text: string): 'rtl' | 'ltr' {
	const bidi = bidiFactory();

	const { levels } = bidi.getEmbeddingLevels(text);
	let direction: 'ltr' | 'rtl' = 'ltr';
	for (let i = 0; i < levels.length; i++) {
		if (isOdd(levels[i])) {
			direction = 'rtl';
			break;
		}
	}
	return direction;
}

function isOdd(number: number) {
	return number % 2 !== 0;
}
