import I18n from 'i18n-js';
import { matchPath, RouteComponentProps } from 'react-router-dom';
import * as routes from '../config/routes';
import store from '../redux/store/store';
import CourseManager from './CourseManager';
import ModuleManager from './module/ModuleManager';
import QuizManager from './quiz/QuizManager';
import { Course, Folder, Locale, Module, Quiz, Session } from '../types';
import { match } from 'react-router';

const APP_NAME = process.env.REACT_APP_APP_NAME;

export interface ContentParams {
	moduleHashID: Module['hashID'];
	quizHashID: Quiz['hashID'];
	surveyHashID: Quiz['hashID'];
	courseHashID: Course['hashID'];
	folderHashID: Folder['hashID'];
	sessionHashID: Session['hashID'];
	questionIndex: string;
}

export default {
	/**
	 * @param {string} pathname from Object
	 * @returns {Object|undefined}
	 */
	resolve({ pathname }: RouteComponentProps['location']) {
		return Object.values(routes)
			.map((path) => {
				return matchPath<ContentParams>(pathname, { path, exact: true });
			})
			.find((match: match<ContentParams>) => match !== null);
	},

	/**
	 * @param {string} path from Object
	 * @param {Object} params from Object
	 * @param locale
	 * @returns {string}
	 */
	resolveTitle({ path, params }: match<ContentParams>, locale: Locale): string {
		let course = undefined;
		let folder = undefined;
		let session = undefined;
		let quiz = undefined;

		const institutionName = store.getState().currentUser.institution.name;

		switch (path) {
			case routes.WELCOME:
				return I18n.t('routes.welcome', { locale });
			case routes.HELP:
				return I18n.t('routes.help', { locale });
			case routes.ABOUT:
				return I18n.t('routes.about', { locale });
			case routes.ACCOUNT:
				return I18n.t('routes.account', { locale });
			case routes.MODULES:
				return I18n.t('routes.modules', { locale });
			case routes.MODULE_COURSE_LIST:
				const module = ModuleManager.getModule(store.getState().content.modules, params.moduleHashID);
				const routeTitle = module ? module.name : '';
				return `Module: ${routeTitle}`;
			case routes.COURSES:
				return I18n.t('routes.courses', { locale });
			case routes.COURSE_QUIZ:
			case routes.MODULE_COURSE_QUIZ:
			case routes.COURSE_QUIZ_SUBMIT:
			case routes.MODULE_COURSE_QUIZ_SUBMIT:
			case routes.FOLDER_QUIZ:
			case routes.MODULE_FOLDER_QUIZ:
			case routes.FOLDER_QUIZ_SUBMIT:
			case routes.MODULE_FOLDER_QUIZ_SUBMIT:
			case routes.SESSION_QUIZ:
			case routes.MODULE_SESSION_QUIZ:
			case routes.SESSION_QUIZ_SUBMIT:
			case routes.MODULE_SESSION_QUIZ_SUBMIT:
				quiz = QuizManager.getQuiz(store.getState().content.quizzes, params.quizHashID);

				return quiz ? quiz.title : I18n.t('routes.quiz', { locale });

			case routes.COURSE_QUIZ_QUESTION:
			case routes.MODULE_COURSE_QUIZ_QUESTION:
			case routes.FOLDER_QUIZ_QUESTION:
			case routes.MODULE_FOLDER_QUIZ_QUESTION:
			case routes.SESSION_QUIZ_QUESTION:
			case routes.MODULE_SESSION_QUIZ_QUESTION:
				quiz = QuizManager.getQuiz(store.getState().content.quizzes, params.quizHashID);
				let questionNumber = parseInt(params.questionIndex, 10) + 1;

				return quiz
					? quiz.title + ' - ' + I18n.t('routes.question', { locale }) + ' ' + questionNumber
					: I18n.t('routes.question', { locale }) + ' ' + questionNumber;

			case routes.COURSE_INFO:
			case routes.MODULE_COURSE_INFO:
			case routes.FOLDER_LIST:
				course = CourseManager.getCourse(store.getState().content.courses, params.courseHashID);

				return course ? course.title : '';

			case routes.SESSION_LIST:
			case routes.SESSION_LIST_WITHOUT_FOLDER: {
				folder = CourseManager.getFolder(store.getState().content.folders, params.folderHashID);

				return folder ? folder.title : I18n.t('routes.sessions', { locale });
			}

			case routes.SESSION_DETAIL:
			case routes.MODULE_SESSION_DETAIL:
			case routes.SESSION_SEND:
			case routes.MODULE_SESSION_SEND:
			case routes.SESSION_DISCUSSION:
			case routes.MODULE_SESSION_DISCUSSION: {
				session = CourseManager.getSession(store.getState().content.sessions, params.sessionHashID);

				let title = session ? session.title : 'Session';

				if (path === routes.SESSION_DISCUSSION) {
					title = `Discussion - ${title}`;
				}

				return title;
			}

			case routes.BOOKMARKS:
				return I18n.t('routes.bookmarks', { locale });
			case routes.QUIZ_REVIEW:
				return I18n.t('routes.quizReview', { locale });
			case routes.QUIZ_REVIEW_DETAIL:
				const quizResult = QuizManager.getQuizResult(
					store.getState().content.quizResults,
					params.quizHashID
				);

				let title = I18n.t('routes.quizReview', { locale });

				if (quizResult.quiz) {
					title = `${title}: ${quizResult.quiz.title}`;
				}

				return title;

			default:
				return institutionName || APP_NAME;
		}
	}
};
