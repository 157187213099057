import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import {
	COMPOUNDED_MULTIPLE_CHOICE_QUESTION,
	ESSAY_QUESTION,
	NUMERICAL_RANGE_QUESTION,
	RATING_SCALE_QUESTION,
	SEQUENCE_QUESTION,
	SHORT_EXACT_QUESTION
} from '../../config/constants';
import { higherNumber, lowerNumber } from '../../services/quiz/NumericalRange';
import FeedbackContent from './feedback/FeedbackContent';
import FeedbackTitle from './feedback/FeedbackTitle';
import { Locale, Question, QuestionAnswer, UserResponse } from '../../types';

interface Props {
	open: boolean;
	question: Question;
	locale: Locale;
	userResponse?: UserResponse;
	close: () => void;
}

const Feedback = (props: Props) => {
	const {
		open,
		question: { feedback, answers },
		question,
		locale,
		userResponse
	} = props;

	const [isCorrect, setIsCorrect] = useState<boolean | undefined>(false);

	const checkCorrect = (question: Question, userResponse: any, answers: QuestionAnswer[]) => {
		let correct: boolean | undefined = false;
		switch (question.type) {
			case SHORT_EXACT_QUESTION:
				// Check if the user's response exists within the array of answers
				answers.forEach((answer) => {
					if (userResponse && userResponse.toLowerCase() === answer!.title.toLowerCase()) {
						correct = true;
					}
				});
				break;

			case NUMERICAL_RANGE_QUESTION:
				if (
					Number(userResponse) >= lowerNumber(answers[0]!.title, answers[1]!.title) &&
					Number(userResponse) <= higherNumber(answers[1]!.title, answers[0]!.title)
				) {
					correct = true;
				}
				break;

			case COMPOUNDED_MULTIPLE_CHOICE_QUESTION:
				{
					// Sort user responses
					const sortedUserResponses = userResponse.map(Number);

					const sortedCorrectAnswers = Object.keys(answers)
						.filter((key) => answers[key as any]!.correct)
						.map(Number);

					let correctAnswers: Array<number> = [];

					// Get answer ids for correct answers
					sortedCorrectAnswers.forEach((answerIndex) => {
						correctAnswers.push(Number(answers[answerIndex]!.id));
					});

					let count = 0;
					sortedUserResponses.forEach((response: number) => {
						if (correctAnswers.includes(response)) {
							count++;
						}

						if (
							count === correctAnswers.length &&
							sortedUserResponses.length === correctAnswers.length
						) {
							correct = true;
						}
					});
				}

				break;
			case ESSAY_QUESTION:
				correct = undefined;
				break;

			case SEQUENCE_QUESTION:
				let count = 0;

				let sequenceAnswers: Array<number> = [];
				Object.assign(sequenceAnswers, JSON.parse(answers[0]!.title));

				JSON.parse(userResponse).forEach((response: number, index: number) => {
					if (response === sequenceAnswers[index]) {
						count++;
					}
				});

				if (count === sequenceAnswers.length) {
					correct = true;
				}
				break;
			case RATING_SCALE_QUESTION:
				correct = undefined;
				break;
			default:
				// Sort user responses
				const sortedUserResponses = userResponse.map(Number);

				const sortedCorrectAnswers = Object.keys(answers)
					.filter((key) => answers[key as any]!.correct)
					.map(Number);

				let correctAnswers: Array<number> = [];

				// Get answer ids for correct answers
				sortedCorrectAnswers.forEach((answerIndex) => {
					correctAnswers.push(Number(answers[answerIndex]!.id));
				});

				correct = sortedUserResponses.every((answer: number) => correctAnswers.includes(answer));
		}
		return correct;
	};

	useEffect(() => {
		if (open) {
			const correct = checkCorrect(question, userResponse, answers);
			setIsCorrect(correct);
			// Response
			ReactGA.event({
				category: 'Question Response',
				label: question.hashID,
				action: JSON.stringify(userResponse)
			});

			// Feedback
			ReactGA.event({
				category: 'Question Feedback',
				label: question.hashID,
				action: correct ? correct.toString() : 'undefined'
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const handleClose = () => {
		props.close();
	};

	return (
		<Dialog open={open} onClose={handleClose} scroll="body" data-test-id="feedback_dialog">
			<FeedbackTitle
				questionType={question.type}
				isCorrect={isCorrect}
				locale={locale}
				data-test-id="feedback_title"
			/>

			{userResponse && (
				<FeedbackContent question={question} feedback={feedback} userResponse={userResponse} />
			)}

			<DialogActions>
				<Button
					onClick={handleClose}
					color="primary"
					variant="contained"
					fullWidth
					data-test-id="feedback_button"
				>
					{I18n.t('selfTestQuiz.okay', { locale })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Feedback;
