import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import I18n from 'i18n-js';
import React, { useEffect } from 'react';
import { GRADED } from '../../config/constants';
import { QuizNavigation } from '../../services/quiz/QuizNavigation';
import EndIcon from '../icons/EndIcon';
import NextIcon from '../icons/NextIcon';
import PreviousIcon from '../icons/PreviousIcon';
import ToolbarGap from '../utils/ToolbarGap';
import { Theme } from '@material-ui/core';
import { Store, UserResponse } from '../../types';
import { QuizReviewProps } from '../../containers/QuestionScreen';
import { createStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TimeMe from 'timeme.js';
import analyticsClient from '../../services/analytics/analyticsClient';
import { Indexes } from '../../services/analytics/indexes';

const styles = (theme: Theme) =>
	createStyles({
		validateButton: {
			marginBottom: theme.spacing(2),
			padding: '0px 20px'
		},
		footer: {
			backgroundColor: theme.palette.background.default,
			padding: theme.spacing(1),
			textAlign: 'right'
		} as const,
		leftIcon: {
			marginRight: theme.spacing(1)
		},
		rightIcon: {
			marginLeft: theme.spacing(1)
		}
	});

interface Props
	extends Omit<QuizReviewProps, 'questionResponse'>,
		WithStyles<typeof styles>,
		RouteComponentProps {
	userResponse?: UserResponse;
	openFeedback: () => void;
}

export const QuestionFooter = (props: Props) => {
	const { classes, locale, quiz, question, questionIndex, totalQuestions, userResponse } = props;
	const isLastQuestion = parseInt(questionIndex, 10) === totalQuestions - 1;
	const quizNavigation = new QuizNavigation(props);

	const { token, id } = useSelector(
		({
			currentUser: {
				token,
				institution: { id }
			}
		}: Store) => {
			return { token, id };
		}
	);

	const showValidateButton = () => {
		if (quiz.type !== GRADED) {
			return (
				<Grid
					container
					item
					xs={12}
					justifyContent="center"
					className={classes.validateButton}
					data-test-id="validate_button"
				>
					<Button
						className="question_footer__validate_btn"
						data-test-id="question_footer__validate_btn"
						variant={!userResponse ? 'outlined' : 'contained'}
						color="primary"
						fullWidth
						disabled={!userResponse}
						onClick={props.openFeedback}
					>
						{I18n.t('question.checkAnswer', { locale })}
					</Button>
				</Grid>
			);
		}
	};

	useEffect(() => {
		if (quiz && !quiz.submitted && question?.hashID) {
			const key = `${quiz.hashID}_${question.hashID}`;
			const institution_id = typeof id === 'string' ? parseInt(id) : id;

			TimeMe.initialize({
				currentPageName: key,
				idleTimeoutInSeconds: 90
			});

			TimeMe.callWhenUserLeaves(() => {
				const timeOnQuestion = TimeMe.getTimeOnPageInSeconds(key);
				if (typeof timeOnQuestion === 'number' && timeOnQuestion > 0) {
					analyticsClient
						.sendDataToIndex(Indexes.USER_QUIZ_DURATION_V1, {
							question_hash_id: question.hashID,
							quiz_hash_id: quiz?.hashID,
							institution_id,
							user_api_token: token,
							duration_in_ms: Math.round(timeOnQuestion * 1000),
							timestamp: new Date().getTime(),
							source: 'PWA'
						})
						.then(() => TimeMe.resetRecordedPageTime(key));
				}
			});

			return () => {
				const timeOnQuestion = TimeMe.getTimeOnPageInSeconds(`${quiz.hashID}_${question.hashID}`);
				if (typeof timeOnQuestion === 'number' && timeOnQuestion > 0) {
					analyticsClient
						.sendDataToIndex(Indexes.USER_QUIZ_DURATION_V1, {
							question_hash_id: question.hashID,
							quiz_hash_id: quiz?.hashID,
							institution_id,
							user_api_token: token,
							duration_in_ms: Math.round(timeOnQuestion * 1000),
							timestamp: new Date().getTime(),
							source: 'PWA'
						})
						.finally(() => {
							TimeMe.userLeftCallbacks = [];
							TimeMe.stopTimer(key);
							TimeMe.resetRecordedPageTime(key);
						});
				}
			};
		}
	}, [quiz?.hashID, question.hashID]);

	const handleNext = () => {
		// Require at least some answer before proceeding in Self-Assessment questions
		if (quiz.type !== GRADED && (!quiz.userResponses || !quiz.userResponses[question.hashID])) {
			return alert(I18n.t('question.attempt', { locale }));
		}

		return quizNavigation.nextQuestion(quiz);
	};

	const handlePrevious = () => {
		return quizNavigation.previousQuestion(quiz);
	};

	const nextButtonLabel = () => {
		if (isLastQuestion) {
			return 'question.end';
		}

		if (quiz.type === GRADED) {
			return 'question.saveAndNext';
		}

		return 'question.next';
	};

	return (
		<div>
			{showValidateButton()}

			<div className={classes.footer}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						{parseInt(questionIndex) > 0 && (
							<Button
								className="question_footer__previous_btn"
								data-test-id="question_footer__previous_btn"
								variant="outlined"
								color="primary"
								fullWidth
								onClick={handlePrevious}
							>
								<PreviousIcon className={classes.leftIcon} />
								{I18n.t('question.previous', { locale })}
							</Button>
						)}
					</Grid>

					<Grid item xs={6}>
						<Button
							className="question_footer__next_btn"
							data-test-id="question_footer__next_btn"
							variant="contained"
							color="primary"
							fullWidth
							onClick={handleNext}
						>
							{I18n.t(nextButtonLabel(), { locale })}
							{isLastQuestion ? (
								<EndIcon className={classes.rightIcon} />
							) : (
								<NextIcon className={classes.rightIcon} />
							)}
						</Button>
					</Grid>
				</Grid>

				{/* Gap to prevent safari double-click requirement when too close to page bottom */}
				<ToolbarGap />
			</div>
		</div>
	);
};

export default withStyles(styles)(withRouter(QuestionFooter));
