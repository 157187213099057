import { reinitUpdates } from '../redux/actions/actionCreators';
import store from '../redux/store/store';

let currentIsFetchingValue: boolean;

export default function CoursesIsFetchingSubscriber() {
	let previousValue = currentIsFetchingValue;
	const content = store.getState().content;
	currentIsFetchingValue = content.isFetching;
	/**
	 * Re-init updates if there was a previous update (update dialog clicked), is not currently fetching, and there is
	 * no error fetching
	 */
	if (previousValue && !currentIsFetchingValue && !content.isErrorFetching) {
		// if courses isFetching changed from true to false, reinit updates
		store.dispatch(reinitUpdates());
	}
}
