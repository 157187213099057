import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import I18n from 'i18n-js';
import React from 'react';
import { QuizNavigation } from '../../services/quiz/QuizNavigation';
import EndIcon from '../icons/EndIcon';
import ToolbarGap from '../utils/ToolbarGap';
import { Theme } from '@material-ui/core';
import { Locale, Quiz } from '../../types';
import { WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

const styles = (theme: Theme) => ({
	validateButton: {
		marginBottom: theme.spacing(2),
		padding: '0px 20px'
	},
	footer: {
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(1),
		textAlign: 'right'
	} as const,
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	}
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
	isDisabled?: boolean;
	quiz: Quiz;
	locale: Locale;
}

const SurveyFooter = (props: Props) => {
	const { classes, locale, quiz, isDisabled } = props;
	const quizNavigation = new QuizNavigation(props);

	const handleFinish = () => {
		return quizNavigation.nextQuestion(quiz);
	};

	return (
		<div>
			<div className={classes.footer}>
				<Grid container spacing={2}>
					<Grid item xs={6} />

					<Grid item xs={6}>
						<Button
							disabled={isDisabled}
							className="question_footer__next_btn"
							variant="contained"
							color="primary"
							fullWidth
							onClick={handleFinish}
							data-test-id="question_footer__next_btn"
						>
							{I18n.t(isDisabled ? 'survey.submittedButton' : 'survey.submitButton', {
								locale
							})}
							<EndIcon className={classes.rightIcon} />
						</Button>
					</Grid>
				</Grid>

				{/* Gap to prevent safari double-click requirement when too close to page bottom */}
				<ToolbarGap />
			</div>
		</div>
	);
};

export default withStyles(styles)(SurveyFooter);
