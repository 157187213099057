import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, Question } from '../../types';

interface Props {
	openFeedback: (correct: boolean) => void;
	question: Question;
}

const OEQ = (props: Props) => {
	// const [enabled, setEnabled] = useState(true);
	const [response, setResponse] = useState('');

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const handleResponseChange = (response: string) => {
		//Singular answer
		setResponse(response);
	};

	const submitAnswer = () => {
		props.openFeedback(true);
		// setEnabled(false);
	};

	return (
		<div>
			<TextField
				label="Answer"
				fullWidth={true}
				margin="normal"
				value={response || ''}
				onChange={(e) => {
					handleResponseChange(e.target.value);
				}}
			/>

			<div style={{ flex: 1, textAlign: 'center', padding: '1em' }}>
				<Button
					variant="contained"
					color="primary"
					disabled={response.length < 1}
					onClick={submitAnswer}
					data-test-id="oeq_btn"
				>
					{I18n.t('selfTestQuiz.checkAnswer', { locale })}
				</Button>
			</div>
		</div>
	);
};

export default OEQ;
