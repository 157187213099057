import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import IconButton from '@material-ui/core/IconButton/IconButton';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import MoreVert from '@material-ui/icons/MoreVert';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestUserLogout, setLocale } from '../redux/actions/actionCreators';
import UserPanel from '../components/account/UserPanel';
import UserProgressPanel from '../components/account/UserProgressPanel';
import LogoutIcon from '../components/icons/LogoutIcon';
import CheckForUpdatesButton from '../components/updates/CheckForUpdatesButton';
import { availableLocales } from '../config/translations';
import { Store } from '../types';

const AccountScreen = () => {
	const { locale } = useSelector(({ currentUser: {}, settings: { locale } }: Store) => ({
		locale
	}));

	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);

	const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const editLocale = (locale: string) => {
		dispatch(setLocale(locale));

		handleMenuClose();
	};

	const rightIconMenuLocale = () => {
		let items = [];

		for (let availableLocale in availableLocales) {
			if (availableLocales.hasOwnProperty(availableLocale)) {
				items.push(
					<MenuItem
						data-test-id={`locale-menu-${availableLocale}`}
						key={availableLocale}
						selected={availableLocale === locale}
						onClick={() => editLocale(availableLocale)}
					>
						<ListItemText primary={availableLocales[availableLocale]} />
					</MenuItem>
				);
			}
		}

		return (
			<React.Fragment>
				<Tooltip title={I18n.t('update.edit', { locale })}>
					<IconButton data-test-id="menu-open-button" onClick={handleMenuOpen}>
						<MoreVert />
					</IconButton>
				</Tooltip>
				<Menu
					data-test-id="menu-open"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
				>
					{items}
				</Menu>
			</React.Fragment>
		);
	};

	const handleLogout = () => {
		dispatch(requestUserLogout());
	};

	return (
		<div>
			<UserProgressPanel />
			<Card style={{ marginTop: '10px' }}>
				<CardHeader
					title={I18n.t('account.settings.label', { locale })}
					style={{ paddingBottom: 0 }}
				/>

				<CardContent style={{ paddingTop: 0 }}>
					<List>
						<ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
							<ListItemText
								primary={
									I18n.t('account.language', { locale }) + ' : ' + availableLocales[locale]
								}
							/>

							<ListItemSecondaryAction>{rightIconMenuLocale()}</ListItemSecondaryAction>
						</ListItem>
					</List>

					<CheckForUpdatesButton fullWidth />
				</CardContent>
			</Card>

			<UserPanel>
				<Button
					data-test-id="logout-button"
					variant="contained"
					color="primary"
					fullWidth
					onClick={handleLogout}
				>
					<LogoutIcon style={{ marginRight: '0.5em' }} />
					{I18n.t('logout.button', { locale })}
				</Button>
			</UserPanel>
		</div>
	);
};

export default AccountScreen;
