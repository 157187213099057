import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import I18n from 'i18n-js';
import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import NotFound from '../components/NotFound';
import { SURVEY } from '../config/constants';
import { COURSES } from '../config/routes';
import QuizManager from '../services/quiz/QuizManager';
import { QuizNavigation } from '../services/quiz/QuizNavigation';
import { Store } from '../types';
import { WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { ContentParams } from '../services/RouteResolver';
import { ActivityLabel } from '../services/analytics/userActivityClient';
import { sendEventToAnalyticsEngine } from '../redux/actions/actionCreators';

const styles = (theme: Theme) => ({
	root: {
		padding: '1em',
		backgroundColor: theme.palette.secondary.main,
		color: 'white',
		height: '100%',
		textAlign: 'center'
	} as const,
	skipButton: {
		color: 'white',
		marginTop: '5px'
	},
	surveySkipButton: {
		color: theme.palette.primary.main,
		marginTop: '5px'
	}
});

interface Params extends Pick<ContentParams, 'quizHashID'> {
	surveyHashID: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<Params> {}

export const QuizScreen = (props: Props) => {
	const { classes } = props;
	const dispatch = useDispatch();

	const myProps = useSelector((state: Store) => {
		const { quizHashID, surveyHashID } = props.match.params;
		const hashID = surveyHashID ? surveyHashID : quizHashID;
		const quiz = QuizManager.getQuiz(state.content.quizzes, hashID);
		const token = state.currentUser.token;

		if (!quiz) {
			return { locale: state.settings.locale, token, quiz: null };
		}

		return { locale: state.settings.locale, quiz, token };
	});

	const { locale, quiz, token } = myProps;

	const quizProps = { ...props, ...myProps };

	const handleNext = () => {
		if (quiz && quiz.questions) {
			// If quiz has no questions, end quiz
			if (quiz.questions.length === 0) {
				return new QuizNavigation({ ...quizProps, quiz }).quizEnd();
			}

			if (quiz.type === SURVEY) {
				/** Analytics **/
				ReactGA.event({
					category: 'Survey',
					label: quiz.hashID,
					action: 'Begin'
				});
				dispatch(
					sendEventToAnalyticsEngine({
						label: ActivityLabel.COMMENCED_SURVEY,
						description: `${token} commenced survey ${quiz.hashID}`
					})
				);
			} else {
				/** Analytics **/
				ReactGA.event({
					category: 'Quiz',
					label: quiz.hashID,
					action: 'Begin'
				});
				dispatch(
					sendEventToAnalyticsEngine({
						label: ActivityLabel.COMMENCED_QUIZ,
						description: `${token} commenced quiz ${quiz.hashID}`
					})
				);
			}

			return new QuizNavigation({ ...quizProps, quiz }).quizStart();
		}
	};

	const showSkipButton = () => {
		if (quiz && QuizManager.isQuizCompleted(quiz)) {
			return (
				<Grid item style={{ width: '100%' }}>
					<Button
						className={
							quiz && quiz.type === SURVEY ? classes.surveySkipButton : classes.skipButton
						}
						data-test-id="skip-button"
						color="primary"
						variant="outlined"
						onClick={() => {
							new QuizNavigation({ ...quizProps, quiz }).quizEnd();
						}}
					>
						{I18n.t('quiz.button.skip', { locale })}
					</Button>
				</Grid>
			);
		}
	};

	if (!quiz) {
		return <NotFound data-test-id="quiz-screen_not-found" {...props} />;
	}

	if (!QuizManager.isQuizzable(quiz)) {
		return <Redirect data-test-id="quiz-screen_redirect" to={COURSES} />;
	}

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			className={classes.root}
			style={{ backgroundColor: quiz.type === SURVEY ? 'white' : 'none' }}
		>
			<Grid container item className="screen-centered joyride-quiz-details" direction="column">
				<Grid data-test-id="quiz-screen_quiz-title" item>
					<h1
						style={{
							margin: 0,
							color: quiz.type === SURVEY ? 'black' : 'white'
						}}
					>
						{quiz.title}
					</h1>
				</Grid>

				<Grid item>
					<Typography
						data-test-id="quiz-screen_quiz-description"
						variant="subtitle1"
						style={{
							margin: '0.3em 0',
							color: quiz.type === SURVEY ? 'black' : 'white'
						}}
					>
						{quiz.description}
					</Typography>
				</Grid>

				<Grid item style={{ width: '100%' }}>
					<Button
						data-test-id="quiz-screen_begin-btn"
						variant="contained"
						color="primary"
						onClick={handleNext}
					>
						{I18n.t('quiz.button.begin', { locale })}
					</Button>
				</Grid>

				{showSkipButton()}
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(QuizScreen);
