import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid/Grid';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setResponseFiles, setUserResponses } from '../redux/actions/actionCreators';
import RequiredBadge from '../components/badges/RequiredBadge';
import CustomExpansionPanel from '../components/custom/CustomExpansionPanel';
import NotFound from '../components/NotFound';
import HelperText from '../components/quiz/HelperText';
import QuestionAnswers from '../components/quiz/QuestionAnswers';
import SurveyFooter from '../components/quiz/SurveyFooter';
import QuizManager from '../services/quiz/QuizManager';
import { Store, Question, UserResponse } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../services/RouteResolver';
import { getDirection } from '../services/quiz/useTextDirection';
import { ThemeProvider } from '@material-ui/core/styles';
import TimeMe from 'timeme.js';
import analyticsClient from '../services/analytics/analyticsClient';
import { Indexes } from '../services/analytics/indexes';
import FileUploadWidget, { FileData } from '../components/FileUploadWidget';
import { selectQuizResponseFiles } from '../redux/selectors/content';
import { MAXIMUM_FILES_PER_QUESTION } from '../config/constants';
import { TooManyFilesDialog } from './QuestionScreen';

const styles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		marginBottom: '20px'
	},
	preamble: {
		borderStart: `1.5px solid ${theme.palette.primary.main}`,
		paddingStart: '20px',
		color: 'black',
		margin: '20px 0px',
		fontSize: '16px',
		lineHeight: '25px',
		fontWeight: 'bold'
	},
	questionImage: {
		width: '100%',
		height: 'auto',
		maxWidth: '400px'
	}
}));

interface Params extends Pick<ContentParams, 'surveyHashID'> {}
interface Props extends RouteComponentProps<Params> {}

const SurveyQuestionScreen = (props: Props) => {
	const classes = styles();

	const surveyHashID = props.match.params.surveyHashID;

	const [alertDialogOpen, setAlertDialogOpen] = useState(false);

	const dispatch = useDispatch();

	const myProps = useSelector(
		({
			currentUser: {
				token,
				institution: { id }
			},
			content: { quizzes }
		}: Store) => {
			const quiz = QuizManager.getQuiz(quizzes, surveyHashID);

			if (!quiz) {
				return { survey: null, userResponses: null };
			}

			const userResponses = quiz && quiz.userResponses ? quiz.userResponses : null;

			return { survey: quiz, userResponses, token, id };
		}
	);

	const { survey, userResponses } = myProps;

	const quizResponseFiles = useSelector(selectQuizResponseFiles(survey?.hashID)) ?? {};

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const handleResponseChange = (response: UserResponse, question: Question) => {
		const { hashID: questionHashID, type: questionType } = question;
		dispatch(
			setUserResponses({
				quizHashID: surveyHashID,
				questionHashID,
				questionType,
				response
			})
		);
	};

	const closeAlert = () => {
		setAlertDialogOpen(false);
	};

	const handleFilesChange = (questionHashID: Question['hashID']) => (files: FileData[]) => {
		if (files.length > MAXIMUM_FILES_PER_QUESTION) {
			setAlertDialogOpen(true);
			return false;
		}

		if (surveyHashID) {
			dispatch(
				setResponseFiles({
					quizHashID: surveyHashID,
					questionHashID,
					fileData: files
				})
			);
		}
		return true;
	};

	const totalQuestions = survey?.questions ? survey.questions.length : 0;

	useEffect(() => {
		if (survey && !survey.submitted) {
			const { token, id } = myProps;
			const institution_id = typeof id === 'string' ? parseInt(id) : id;

			TimeMe.initialize({
				currentPageName: survey.hashID,
				idleTimeoutInSeconds: 90
			});

			TimeMe.callWhenUserLeaves(() => {
				const timeOnSurvey = TimeMe.getTimeOnPageInSeconds(survey.hashID);

				if (typeof timeOnSurvey === 'number' && timeOnSurvey > 0) {
					analyticsClient
						.sendDataToIndex(Indexes.USER_QUIZ_DURATION_V1, {
							question_hash_id: null,
							quiz_hash_id: survey.hashID,
							institution_id,
							user_api_token: token,
							duration_in_ms: Math.round(timeOnSurvey * 1000),
							timestamp: new Date().getTime(),
							source: 'PWA'
						})
						.then(() => TimeMe.resetRecordedPageTime(survey.hashID));
				}
			});

			return () => {
				const timeOnSurvey = TimeMe.getTimeOnPageInSeconds(survey.hashID);
				if (typeof timeOnSurvey === 'number' && timeOnSurvey > 0) {
					analyticsClient
						.sendDataToIndex(Indexes.USER_QUIZ_DURATION_V1, {
							question_hash_id: null,
							quiz_hash_id: survey.hashID,
							institution_id,
							user_api_token: token,
							duration_in_ms: Math.round(timeOnSurvey * 1000),
							timestamp: new Date().getTime(),
							source: 'PWA'
						})
						.finally(() => {
							TimeMe.userLeftCallbacks = [];
							TimeMe.stopTimer(survey.hashID);
							TimeMe.resetRecordedPageTime(survey.hashID);
						});
				}
			};
		}
	}, [survey?.hashID]);

	if (!survey) {
		return <NotFound data-test-id="survey-question-screen_not-found" {...props} />;
	}

	const footerProps = {
		...props,
		locale,
		quiz: survey,
		isDisabled: survey.submitted
	};

	return (
		<React.Fragment>
			<TooManyFilesDialog onOk={closeAlert} open={alertDialogOpen} />
			{survey.questions &&
				survey.questions.map((question: Question, index: number) => {
					const showFileUpload = !survey.submitted && question.uploadFileAnswer;
					const userResponse = userResponses ? userResponses[question.hashID] : null;
					const direction = getDirection(question.title);
					const theme = createMuiTheme({
						direction
					});
					return (
						<div className="content-layout" key={question.hashID} dir={direction}>
							<ThemeProvider theme={theme}>
								<Grid container direction="column" className={classes.root}>
									<Grid item>
										<Typography
											variant="subtitle2"
											color="textSecondary"
											className="joyride-question-indicator"
											data-test-id="question-index"
										>
											Question {index + 1} {I18n.t('question.of', { locale })}{' '}
											{totalQuestions}
										</Typography>
										{question.preamble && question.preamble.toLowerCase() !== 'null' && (
											<div
												data-test-id="question-preamble"
												className={classes.preamble}
												dangerouslySetInnerHTML={{
													__html: question.preamble
												}}
											/>
										)}

										<Typography
											data-test-id="question-screen_title"
											variant="h6"
											gutterBottom
											style={{ fontSize: '1.75rem' }}
										>
											{question.title}
										</Typography>
										{question.image && (
											<img
												data-test-id="question-image"
												src={question.image.url}
												alt="question"
												className={classes.questionImage}
											/>
										)}
									</Grid>
									{question.hint && question.hint.toLowerCase() !== 'null' && (
										<CustomExpansionPanel>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<Typography>
													{I18n.t('question.hint', {
														locale
													})}
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<span
													dangerouslySetInnerHTML={{
														__html: question.hint
													}}
												/>
											</AccordionDetails>
										</CustomExpansionPanel>
									)}

									<Grid item>
										<HelperText type={question.type} locale={locale} />
									</Grid>
									<Grid item>
										<QuestionAnswers
											isReadOnly={survey.submitted}
											question={question}
											userResponse={userResponse}
											handleResponseChange={(response: UserResponse) =>
												handleResponseChange(response, question)
											}
											locale={locale}
										/>
									</Grid>
									{showFileUpload && (
										<FileUploadWidget
											id={question.hashID}
											files={quizResponseFiles[question.hashID]}
											onFilesChange={handleFilesChange(question.hashID)}
											isRequired={question.uploadFileAnswer?.isRequired}
										/>
									)}
									<Grid item>{question.required && <RequiredBadge locale={locale} />}</Grid>
								</Grid>
							</ThemeProvider>
						</div>
					);
				})}
			<SurveyFooter {...footerProps} />
		</React.Fragment>
	);
};

export default SurveyQuestionScreen;
