import Button from '@material-ui/core/Button/Button';
import I18n from 'i18n-js';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { COURSE_INFO, MODULE_COURSE_INFO, MODULE_SESSION_DETAIL, SESSION_DETAIL } from '../../config/routes';
import generateUrl from '../../services/generateUrl';
import { Locale, Session } from '../../types';
import { ContentParams } from '../../services/RouteResolver';

interface Props extends RouteComponentProps<Pick<ContentParams, 'moduleHashID'>> {
	session: Session;
	nextSession?: Session | null;
	locale: Locale;
}

const ValidatedSession = (props: Props) => {
	const { history, match, session, nextSession, locale } = props;
	const {
		params: { moduleHashID }
	} = match;

	const handleRedirectNextSession = () => {
		if (!nextSession) {
			return;
		}

		return history.push(
			generateUrl(moduleHashID ? MODULE_SESSION_DETAIL : SESSION_DETAIL, {
				':moduleHashID': moduleHashID,
				':courseHashID': nextSession.courseHashID,
				':sessionHashID': nextSession.hashID
			})
		);
	};

	const handleRedirectSessionList = () => {
		return history.push(
			generateUrl(moduleHashID ? MODULE_COURSE_INFO : COURSE_INFO, {
				':moduleHashID': moduleHashID,
				':courseHashID': session.courseHashID
			})
		);
	};

	return (
		<div className="screen-centered">
			<h4 data-test-id="validated-session_success">{I18n.t('send.validation.success', { locale })}</h4>
			<Button
				data-test-id="validated-session_redirect-btn"
				onClick={handleRedirectSessionList}
				style={{ marginRight: '10px', width: '60%' }}
				color="secondary"
			>
				{I18n.t('send.sessionListButton', { locale })}
			</Button>
			{nextSession && (
				<Button
					data-test-id="validated-session_next-btn"
					variant="contained"
					color="primary"
					onClick={handleRedirectNextSession}
					style={{ width: '20%' }}
				>
					{I18n.t('send.nextButton', { locale })}
				</Button>
			)}
		</div>
	);
};

export default withRouter(ValidatedSession);
