export default ({
	course: courseName,
	session: sessionName,
	folder: folderName,
	module: moduleName
}: {
	course: any;
	session: any;
	folder: any;
	module: any;
}) => ({
	course: {
		noContentAvailable: 'Aucun contenu disponible',
		view: 'Accéder'
	},
	session: {
		nextButton: 'Suivant',
		previous: 'Précédent',
		quizButton: 'Continuer vers le quiz',
		surveyButton: 'Continuer vers le sondage',
		end: 'Fin'
	},
	question: {
		next: 'Suivant',
		previous: 'Précédent',
		saveAndNext: 'Enregistrer et passer à la suite',
		answer: 'Réponse',
		checkAnswer: 'Vérifier la réponse',
		numericalRangeFeedback:
			'La bonne réponse est un nombre compris entre <b>%{lowerNumber}</b> et <b>%{higherNumber}</b> inclus',
		answerAll: 'Veuillez répondre à toutes les questions obligatoires avant de continuer.',
		maxFiles: "Vous ne pouvez télécharger qu'un maximum de %{number} vidéos.",
		uploadAll: 'Veuillez télécharger tous les fichiers <b>obligatoires</b> avant de continuer.',
		confirmRemove: 'Êtes-vous sûr de vouloir supprimer le fichier',
		confirm: 'Confirmer',
		backToStart: 'Revenir au Départ',
		attempt: 'Veuillez répondre à la question avant de continue.',
		question: 'Question',
		of: 'sur',
		end: 'Fin',
		hint: 'Cliquez pour afficher un indice',
		helperText: {
			essay: 'Entrez la réponse ci-dessous',
			multipleChoice: 'Sélectionnez une réponse',
			compoundMultipleChoice: 'Sélectionnez toutes les réponses appropriées',
			numericalRange: 'Entrez un nombre dans la plage de réponses appropriées',
			shortExact: 'Entrez la réponse ci-dessous',
			sequence: "Glissez-déposez les éléments pour les organiser dans l'ordre approprié",
			ratingScale: 'Faîtes glisser le curseur pour sélectionner votre réponse'
		}
	},
	feedback: {
		good: 'Bien Joué!',
		bad: 'Mauvaise réponse'
	},
	tokenError: {
		'401':
			'Un problème est survenu avec votre lien - Merci de contacter Chalkboard Education pour assistance.',
		'402':
			'Votre code est utilisé sur un autre appareil. L’accès multi-appareils n’est pas autorisé par votre institution. Merci de contacter Chalkboard Education si vous pensez qu’il s’agit d’une erreur.'
	},
	discussion: {
		title: 'Discussion',
		messagePlaceholder: 'Entrez votre message'
	},
	update: {
		label: 'Télécharger',
		checkForUpdates: 'Vérifier les mises à jour',
		needUpdate: 'Les mises à jour du contenu sont disponibles...',
		updating: 'Récupérer du contenu...',
		fetchingQuizResults: 'Récupération des résultats du quiz...',
		checking: 'Vérification du contenu...',
		downloadSize: '{{size}} {{type}}',
		toDownload: ' à télécharger',
		KB: 'Ko',
		MB: 'Mo',
		upToDate: 'Tout le contenu disponible déjà téléchargé!',
		errorWhileCheckingUpdates: "Un problème de réseau s'est produit lors de la recherche de mises à jour",
		errorWhileUpdating: 'Un problème de réseau est survenu lors du téléchargement de contenu',
		stayOnline:
			'Veuillez rester en ligne et garder la page ouverte. Cela peut prendre plusieurs minutes.',
		offline: 'Pas de connexion internet',
		success: 'Contenu téléchargé avec succès!',
		warning: 'Contenu partiellement téléchargé',
		retry: 'Réessayer',
		retryInFewMinutes: 'Veuillez réessayer dans quelques minutes',
		connectAndRetry: 'Veuillez vous assurer que vous êtes connecté à Internet et réessayez',
		connectAndRetryInMinutes:
			"Veuillez vous assurer d'être connecté à Internet et réessayer dans quelques minutes",
		downloadInProgress: 'Téléchargement en cours',
		contentWillAppear: "Vous verrez le contenu dès qu'il sera terminé",
		edit: 'Modifier',
		downloadingContent: 'Téléchargement de contenu',
		missing: 'Télécharger les médias manquants',
		dismiss: 'Ignorer',
		contentLoading:
			'Téléchargement du contenu en cours. Vous pouvez voir le statut en tapant sur le cercle en bas à droite de votre écran.'
	},
	send: {
		label: {
			quiz: 'Soumettre sa progression avec :',
			survey:
				"Bravo pour avoir terminé l'enquête ! Pour garantir la précision, veuillez vérifier vos réponses. Une fois sûr de vos réponses, veuillez procéder en cliquant sur le bouton 'Soumettre'"
		},
		sessionListButton: `Liste ${sessionName}s`,
		nextButton: 'Suivant',
		medium: {
			internet: 'Internet',
			sms: 'SMS'
		},
		validation: {
			success: 'Merci, votre progression est enregistrée',
			fail: `Erreur durant la validation de la ${sessionName}`
		},
		sms: {
			title: 'Soumettre votre progression par SMS',
			label:
				"Vous allez envoyer un code par SMS et recevoir un lien en retour. Ne modifiez ni l'un ni l'autre. Merci.",
			button: 'Envoyer par SMS',
			notworking: {
				label: 'Envoyer la progression manuellement...',
				button: 'Ne fonctionne pas ?',
				toPhone: 'Au ',
				unblockCode: 'Code à envoyer'
			},
			progressCode: {
				button: 'Entrer le code de progression',
				placeholder: 'Code de Progression',
				title: 'Entrez le nouveau code que vous avez reçu par SMS:',
				cancel: 'Annuler',
				submit: 'Envoyer'
			}
		}
	},
	login: {
		checking: 'Vérification ...',
		start: 'Commencer',
		title: 'Identifiant',
		message:
			'Vous pouvez vous connecter via le lien reçu par SMS. Autrement, vous pouvez écrire votre login ci-dessous.',
		hint:
			"Vous pouvez également utiliser l'identifiant qui vous a été envoyé par SMS pour vous re-connecter à l'application.<br />Votre code à 6 caractères se trouve à la fin du lien qui vous est envoyé par SMS pour vous connecter. Par exemple: https://chalkedu.co/#/login/<b>code</b>",
		tokenPlaceholder: 'Code'
	},
	account: {
		progress: {
			title: `Progression`,
			subtitle: `%{read}/%{remaining} ${sessionName}s lues`
		},
		language: 'Langue',
		settings: {
			label: 'Réglages'
		},
		about: 'À propos',
		consent: 'Consentement',
		support: {
			title: 'Assistance',
			subtitle: 'Vous pouvez nous contacter comme qui suit si vous avez besoin d’aide:'
		}
	},
	error: {
		label: 'Oops.. Il semblerait que quelque chose ne fonctionne pas.',
		report: 'Signaler un problème',
		back: "Retour à l'écran d'accueil",
		dismiss: 'Cacher',
		authenticationError: 'Erreur d’authentification',
		spoolError: {
			title: 'Téléchargement Incomplet',
			description:
				'Certains fichiers n’ont pas pu être chargés. Ne vous inquiétez pas, nous réessayerons lors de la prochaine mise à jour.'
		}
	},
	notFound: {
		title: '404 Page non trouvée',
		description: "Nous sommes désolés mais la page que vous recherchez n'existe pas.",
		back: 'Retourner'
	},
	logout: {
		title: 'Confirmer déconnexion',
		message: 'Êtes-vous sûr(e) de vouloir vous déconnecter?',
		button: 'Déconnexion',
		cancelButton: 'Annuler'
	},
	selfTestQuiz: {
		okay: 'Compris',
		checkAnswer: 'Vérifier la réponse',
		answers: 'Réponses',
		essayResponse: 'Merci pour votre réponse!'
	},
	quiz: {
		label: 'Complétez ce quiz avant de continuer.',
		button: {
			begin: 'Commencer',
			skip: 'Passer'
		},
		upload: 'Sélectionner des fichiers à téléverser',
		uploadedFiles: 'Fichiers téléversés',
		tapToUpload: "Appuyez sur l'icône pour sélectionner les fichiers à téléverser",
		commence: 'Vous êtes sur le point de passer le quiz de cette session',
		submit: {
			submitting: 'En soumission',
			stayOnline:
				"Êtes-vous sûr de vouloir soumettre vos réponses?\nVeuillez vous assurer que vous êtes en ligne et gardez l'écran ouvert",
			inProgress: "Soumission en cours. Cela peut prendre jusqu'à une minute",
			success: 'Quiz envoyé avec succès!',
			error: 'Oops, une erreur est survenue lors de l’envoi du quiz. Veuillez ré-essayer plus tar',
			offline: 'Impossible de soumettre des réponses hors ligne'
		},
		continue: 'Continuez',
		submitButton: 'Soumettre le quiz'
	},
	survey: {
		submitButton: 'Envoyer vos réponses',
		submittedButton: 'Réponses envoyées',
		commence: "Vous êtes sur le point de répondre à l'enquête de cette session",
		submit: {
			success: 'Enquête envoyé avec succès!'
		}
	},
	badge: {
		quiz: 'Quiz',
		survey: 'Sondage',
		required: 'Obligatoire'
	},
	menu: {
		modules: `${moduleName}s`,
		courses: `${courseName}s`,
		account: 'Compte',
		bookmarks: 'Marque-Pages',
		quizReview: 'Examen de quiz',
		help: 'Aide',
		about: 'À propos'
	},
	consent: {
		title: 'Je consens à autoriser Chalkboard Education SAS à collecter et conserver:',
		data: 'Ma progression et les données résultant de cette application',
		analytics:
			'Mes données comportementales et autres informations personelles liées à l’utilisation de cette application'
	},
	custom: {
		moreInfo: 'En Savoir Plus',
		gotIt: 'Compris',
		searchPlaceholder: 'Commencez à taper pour rechercher',
		noSearchResults: 'Aucun résultat trouvé'
	},
	help: {
		title: "Guide pour naviguer dans l'application:",
		menu: {
			title: 'Découvrir le Menu',
			subtitle: `Cliquez pour ouvrir le menu. Le menu contient un lien vers vos ${courseName}s et un lien vers votre Compte.`
		},
		courses: {
			title: `${courseName}s`,
			subtitle: `Cliquez sur Accéder pour ouvrir le ${courseName}s.`
		},
		folders: {
			title: `${folderName}s`,
			subtitle: `Chaque ${courseName}s est découpé en ${folderName}s.`,
			description: `Chaque ${folderName}s est découpé en ${sessionName}s numérotées.`
		},
		sessions: {
			title: `${sessionName}s`,
			subtitle: `Les ${sessionName}s contiennent du contenu que l'utilisateur peut étudier.`
		},
		navigatingSessions: {
			title: `Naviguer dans les ${sessionName}s`,
			back: {
				subtitle: `Une fois dans une ${sessionName}, cliquez sur la flèche retour pour revenir vers la liste principale...`
			},
			next: {
				subtitle: `Ou vers la prochaine ${sessionName} via le bouton en bas de page:`
			}
		},
		bookmarks: {
			title: 'Marque-Pages',
			subtitle:
				'Utilisez le symbole Marque-Page pour accéder rapidement à vos marque-pages dans le menu latéral'
		},
		quizzes: {
			title: 'Quiz/Tests',
			subtitle: `Vous pouvez recevoir un questionnaire au début d'un ${courseName}s ou d'un ${folderName}. Ou à la fin d'une ${sessionName}.`,
			description: `NB: Les ${sessionName}s avec un Quiz sont marquées avec l'icône:`
		},
		account: {
			title: 'Compte',
			subtitle: 'Sur la page du compte, vous pouvez:',
			updates: {
				description: 'Vérifier les mises à jour.'
			},
			language: {
				description: 'Cliquez sur l’îcone pour basculer en Anglais.'
			},
			logout: {
				description: 'Déconnectez-vous de votre compte.'
			},
			contact: {
				description: "Contactez-nous pour tout problème lié à l'utilisation de l'application."
			}
		},
		tutorial: 'Redémarrer le tutoriel interactif:'
	},
	onboarding: {
		'0': {
			title: 'Leçons à la demande',
			subtitle:
				'Tous vos supports d’apprentissage en un seul endroit... Y-compris des quizs pour évaluer vos connaissances.'
		},
		'1': {
			title: 'Accessible hors ligne!',
			subtitle:
				'Une fois vos supports téléchargés, vous y avez accès à tout moment même sans connexion Internet.'
		},
		'2': {
			title: 'Étudiez partout, tout le temps!',
			subtitle:
				'Accédez à vos contenus depuis n’importe quel appareil: utilisez simplement le lien qui vous est fourni par SMS.'
		},
		'3': {
			title: 'Rejoignez la conversation!',
			subtitle: 'Prenez part à l’enseignement collaboratif grace aux discussions.'
		},
		'4': {
			title: "Ajoutez à votre écran d'accueil!",
			subtitle:
				"Installez cette application sur votre écran d'accueil pour y avoir accès facilement et rapidement",
			instructions: {
				menu:
					"Touchez le bouton <strong>menu</strong> dans votre navigateur et cliquez sur <strong> Ajouter à l'écran d'accueil</strong>",
				share:
					"Touchez le bouton <strong>partager</strong> dans votre navigateur et cliquez sur <strong> Ajouter à l'écran d'accueil</strong>\"",
				clickButton: '..ou bien cliquez le bouton ci-dessous'
			}
		},
		addToHomescreen: "Ajouter à l'écran d'accueil",
		getStarted: 'Commencer',
		next: 'Suivant',
		skip: 'Passer'
	},
	'guided-tour': {
		title: 'Tutorial Interactif',
		button: {
			finish: 'Terminer',
			next: 'Suivant',
			previous: 'Précédent',
			're-run': 'Recommencer'
		},
		modules: {
			'1': {
				title: `Page ${moduleName}s`,
				content: `Bienvenue sur votre page <b>${moduleName}s</b>. Les <b>${moduleName}s</b> sont des groupes de <b>${courseName}s</b> auxquels vous avez été assigné`
			},
			'2': {
				content: `Cliquez sur un <b>${moduleName}</b> pour afficher tous les <b>${courseName}s</b> présents dans ce <b>${moduleName}</b>`
			}
		},
		'module-courses': {
			'1': {
				content: `Vous êtes maintenant sur la page des <b>${courseName}s</b> présents dans le <b>${moduleName}</b> que vous avez sélectionnés. Tous les ${courseName}s de ce <b>${moduleName}</b> sont listés ici`
			}
		},
		courses: {
			'1': {
				title: `Page ${courseName}s`,
				content: `Vous êtes sur la page <b>${courseName}s</b>. Tous vos ${courseName}s sont listés ici.`
			},
			'2': {
				content:
					"Lorsque vous voyez ce message, cela signifie qu'il y a de nouveaux contenus à télécharger."
			},
			'3': {
				content: 'Cliquez sur <b>Télécharger</b> pour sauvegarder ce contenu sur votre téléphone'
			},
			'4': {
				content: `Cliquez sur l'image pour afficher la description du ${courseName}s`
			},
			'5': {
				content: `Cliquez sur le ${courseName}s pour aller sur sa page d'informations`
			},
			'6': {
				content:
					'Cliquez sur <b> Vérifier les mises à jour </b> pour vérifier si de nouveaux contenus sont disponibles'
			}
		},
		'course-info': {
			'1': {
				content: `Ceci est la <b>Page ${courseName}s</b>. Ici vous trouverez toutes les informations sur votre ${courseName}s, ainsi que les <b>${sessionName}s</b> de ce ${courseName}s, qui sont groupées en <b>${folderName}s</b>`
			},
			'2': {
				content: `Vous trouverez une courte description du <b>${courseName}s</b> ici`
			},
			'3': {
				content: `Sous la description du ${courseName}se se trouve la liste des <b>${folderName}s</b>`
			},
			'4': {
				content: `Cliquez sur un <b>${folderName}</b> pour révéler la liste des <b>${sessionName}s</b> de ce ${folderName}`
			},
			'5': {
				content: `Voici la liste des <b>${sessionName}s</b>`
			},
			'6': {
				content: `Pour commencer à lire, cliquez sur une <b>${sessionName}</b>`
			}
		},
		'session-details': {
			'1': {
				content: `Bienvenue dans votre première <b>${sessionName}</b>. C'est ici que vous passerez le plus de temps`
			},
			'2': {
				content: `Pour retrouver facilement une <b>${sessionName}</b>, cliquez sur l'icône <b>Bookmark</b>. Cela créera un raccourci vers cette <b>${sessionName}</b>. Les <b>Bookmarks</b> que vous créez sont dans la <b>Page Bookmarks</b>`
			},
			'3': {
				content: 'Cliquez sur la flèche du haut pour retourner à la page précédente'
			},
			'4': {
				content: `Cliquez sur <b>l'icône Discussion</b> pour afficher la <b>Page de Discussion</b> de la <b>${sessionName}</b> sur laquelle vous vous trouvez`
			},
			'5': {
				content: `Pour passer à la <b>${sessionName}</b> suivante dans ce <b>${folderName}</b>, cliquez sur le <b>bouton Suivant</b>`
			},
			'6': {
				content: `Pour retourner à la <b>${sessionName}</b> précédente, cliquez sur le <b>bouton Précédent</b>`
			},
			'7': {
				content:
					"Ceci est le <b>bouton Menu</b>. Cliquez dessus depuis n'importe où pour afficher le <b>Menu latéral</b>"
			}
		},
		bookmarks: {
			'1': {
				content: 'Toutes les pages que vous avez bookmarké sont ici !'
			},
			'2': {
				content: 'Cliquez sur un <b>Bookmark</b> pour vous rendre rapidement à la page correspondante'
			}
		},

		help: {
			'1': {
				content:
					"Bienvenue sur la <b>page d'Aide</b>. Visitez cette page si vous avez des problèmes avec l'application"
			},
			'2': {
				content: "Vous pouvez retrouver ce Tutoriel à tout moment depuis la <b>page d'Aide</b>..."
			},
			'3': {
				content:
					"Cliquez sur n'importe quel élément dans cette liste pour obtenir plus d'information.."
			},
			'4': {
				content: 'Sinon, vous pouvez contacter Chalkboard Education par email'
			},
			'5': {
				content: 'Ou nous parler au téléphone en appelant notre hotline...'
			}
		},

		quiz: {
			'1':
				'Bienvenue sur la <b>Page de Quiz</b>. Vous verrez un écran similaire à celui-ci à chaque fois que vous aurez un Quiz à remplir',
			'2': 'Voici les détails du Quiz. Cliquez sur le bouton <b>Commencer</b>  pour commencer'
		},
		quizQuestion: {
			'1': 'Ceci vous indique à quelle question vous en êtes, et combien de questions il vous reste',
			'2':
				"Répondez ici à la question. Cliquez sur le bouton <b>Suivant</b>, en bas de l'écran, pour passer à la question suivante",
			'3':
				"Vos réponses sont sauvegardées automatiquement sur votre téléphone. Vous pouvez revenir quand vous voulez jusqu'à ce être prêt à Soumettre le Quiz si nécessaire"
		}
	},
	routes: {
		login: 'Login',
		welcome: 'Bienvenue',
		modules: `${moduleName}s`,
		courses: `${courseName}s`,
		help: 'Aide',
		about: 'À propos',
		bookmarks: 'Marque-Pages',
		quizReview: 'Examen de quiz',
		account: 'Compte',
		folders: `${folderName}s`,
		sessions: `${sessionName}s`,
		quiz: 'Quiz',
		question: 'Question'
	},
	bookmarks: {
		none: "Il semblerait que vous n'ayez pas sauvegardé de marque-pages...",
		added: 'Marque-page ajouté',
		removed: 'Marque-page supprimé'
	},
	courses: {
		none: `Les ${courseName}s téléchargés s’afficheront ici`,
		noPurchases: `Vous n'avez encore rien acheté`,
		purchaseMore: `Cliquez ici pour ajouter plus de ${courseName}s`,
		purchaseCourses: `Acheter des ${courseName}s`
	},
	quizReview: {
		questionsTitle: {
			survey: 'Questions et Réponses',
			graded: 'Répartition des réponses'
		},
		none: `Vous n’avez pas encore complété de quizzes ou de questionnaires sauvegardables`,
		correct: 'Correct',
		wrong: 'Incorrect',
		notMarked: 'Pas encore noté',
		resultsIsFetching: 'Récupération des quiz précédemment passés pour révision...',
		errorFetchingResults:
			"Désolé, une erreur s'est produite lors de la récupération des résultats du quiz. Veuillez vérifier les mises à jour et réessayer."
	},
	folder: {
		quizLabel: `Vous devez compléter un Quizz avant d'afficher le contenu de ce ${folderName}:`,
		surveyLabel: `Vous devez compléter un Sondage avant d'afficher le contenu de ce ${folderName}:`
	},
	modules: {
		none: `Aucun ${moduleName} téléchargé...`
	},
	register: {
		signUp: 'Création de compte',
		button: "S'INSCRIRE",
		registering: 'INSCRIPTION EN COURS...',
		loginHint: 'Vous avez déjà un compte? Connectez vous',
		successful: 'Inscription réussie',
		failed: "Échec de l'inscription. Veuillez rééssayer",
		label: {
			firstName: 'Prénoms',
			lastName: 'Nom',
			phoneNumber: 'Numéro de téléphone avec indicatif pays',
			language: 'Langue'
		},
		hint: {
			firstName: 'Le prénom est obligatoire',
			lastName: 'Le nom est obligatoire',
			phoneNumberLength: 'Le numéro de téléphone est trop court',
			phoneNumberFormat: "Le numéro de téléphone doit commencer par '+'",
			verifyHuman: 'Vérifier le Captcha avant de continuer',
			language: "Merci d'indiquer votre langue"
		}
	},
	searchModal: {
		cancel: 'Annuler',
		search: 'Rechercher',
		searchIn: 'Rechercher dans'
	},
	quizType: {
		survey: 'Sondage',
		graded: 'Noté',
		selfAssessment: 'Autoévaluation'
	}
});
