import TextField from '@material-ui/core/TextField';
import I18n from 'i18n-js';
import React from 'react';
import { QuestionTypeProps } from '../QuestionAnswers';

interface Props extends Omit<QuestionTypeProps, 'question' | 'userResponse'> {
	handleOnBlur: () => void | Function;
}

const EssayAnswers = ({ value, handleOnChange, handleOnBlur, locale, isReadOnly }: Props) => (
	<React.Fragment>
		<TextField
			disabled={isReadOnly}
			data-test-id="question-answers_essay-question-text-field"
			label={I18n.t('question.answer', { locale })}
			fullWidth
			margin="normal"
			value={value}
			variant="outlined"
			multiline
			rows={5}
			onChange={(e) => {
				handleOnChange && handleOnChange(e.target.value);
			}}
			onBlur={handleOnBlur}
		/>
	</React.Fragment>
);

export default EssayAnswers;
