import gql from 'graphql-tag';

export default gql`
	{
		bookmarks {
			title
			url
			createdAt
		}
	}
`;
