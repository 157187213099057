import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { WithStyles } from '@material-ui/core/styles';

const styles = () => ({
	root: {
		minHeight: '100vh'
	}
});

interface Props extends WithStyles<typeof styles> {}

const Loader = (props: Props) => (
	<Grid container alignItems="center" justify="center" className={props.classes.root}>
		<Grid item>
			<Typography data-test-id="loader_text" variant="h3">
				Loading...
			</Typography>
			<LinearProgress color="secondary" />
		</Grid>
	</Grid>
);

export default withStyles(styles)(Loader);
