import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { addViewedFolder } from '../redux/actions/actionCreators';
import FolderList from '../components/folder/FolderList';
import { FOLDER_QUIZ, SESSION_LIST } from '../config/routes';
import CourseManager from '../services/CourseManager';
import generateUrl from '../services/generateUrl';
import QuizManager from '../services/quiz/QuizManager';
import { Folder, Store } from '../types';
import { ContentParams } from '../services/RouteResolver';

interface Params extends Pick<ContentParams, 'courseHashID'> {}

interface Props extends RouteComponentProps<Params> {}
export const FolderScreen = (props: Props) => {
	const myProps = useSelector(
		({ content, content: { quizzes, sessions, viewed }, settings: { locale } }: Store) => {
			const folders = CourseManager.getFoldersFromCourse(
				content.folders,
				props.match.params.courseHashID
			);
			const viewedSessions = viewed.sessions;
			const viewedFolders = viewed.folders;

			return {
				folders,
				locale,
				quizzes,
				sessions,
				viewedSessions,
				viewedFolders
			};
		}
	);

	const { folders, sessions, viewedSessions, viewedFolders, quizzes, locale } = myProps;
	const dispatch = useDispatch();

	useEffect(() => {
		let folderHashIDs = Object.keys(folders);

		folderHashIDs.forEach((folderHashID) => {
			let folderSessions = CourseManager.getSessionsFromFolder(sessions, folderHashID);
			let sessionHashIDs = Object.keys(folderSessions);
			let allFolderSessionsRead = true;
			sessionHashIDs.forEach((session) => {
				if (!viewedSessions.includes(session)) {
					allFolderSessionsRead = false;
				}
			});
			if (allFolderSessionsRead) {
				if (!viewedFolders.includes(folderHashID)) {
					dispatch(addViewedFolder(folderHashID));
				}
			}
		});
	}, []); //eslint-disable-line

	const handleNext = (folder: Folder) => {
		const { history } = props;

		let url = generateUrl(SESSION_LIST, {
			':courseHashID': folder.courseHashID,
			':folderHashID': folder.hashID
		});

		const quiz = folder.quizHashID && QuizManager.getQuiz(quizzes, folder.quizHashID);

		if (quiz && !quiz.submitted) {
			url = generateUrl(FOLDER_QUIZ, {
				':courseHashID': folder.courseHashID,
				':folderHashID': folder.hashID,
				':quizHashID': folder.quizHashID
			});
		}

		return history.push(url);
	};

	return (
		<div className="content-layout">
			<FolderList
				data-test-id="folder-screen_folder-list"
				folders={folders}
				locale={locale}
				handleNext={(folder: Folder) => handleNext(folder)}
				viewedFolders={viewedFolders}
			/>
		</div>
	);
};

export default withRouter(FolderScreen);
