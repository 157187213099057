'use strict';
exports.__esModule = true;
var ADRA_INSTUTITION_ID = 3;
exports['default'] = function(id) {
	var keywords = {
		en: {
			course: 'Course',
			session: 'Session',
			folder: 'Folder',
			module: 'Module'
		},
		fr: {
			course: 'Cour',
			session: 'Session',
			folder: 'Dossier',
			module: 'Module'
		},
		es: {
			course: 'Curso',
			session: 'Sesión',
			folder: 'Carpeta',
			module: 'Módulo'
		},
		rw: {
			course: 'Amasomo',
			session: 'Isomo',
			folder: 'Ububiko',
			module: 'Ububiko'
		},
		sw: {
			course: 'Kozi',
			session: 'Kipindi',
			folder: 'Folda',
			module: 'Moduli'
		}
	};
	// ADRA Translations
	if (id === ADRA_INSTUTITION_ID) {
		keywords = {
			en: {
				course: 'Session',
				session: 'Content',
				folder: 'Folder',
				module: 'Module'
			},
			fr: {
				course: 'Session',
				session: 'Contenu',
				folder: 'Dossier',
				module: 'Module'
			},
			es: {
				course: 'Sesión',
				session: 'Contenido',
				folder: 'Carpeta',
				module: 'Módulo'
			},
			rw: {
				course: 'Isomo',
				session: 'Ibirimo',
				folder: 'Ububiko',
				module: 'Ububiko'
			},
			sw: {
				course: 'Kipindi',
				session: 'Yaliyomo',
				folder: 'Folda',
				module: 'Moduli'
			}
		};
	}
	return keywords;
};
