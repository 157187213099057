import {
	RECEIVE_USER_INFORMATIONS,
	SETTINGS_SET_CONSENT,
	SETTINGS_SET_LOCALE
} from '../actions/actionCreators';
import initialState from '../store/initialState';
import { Action } from '../../types';

export default function settings(state = { ...initialState.settings }, action: Action) {
	switch (action.type) {
		/**
		 * Locale
		 */
		case SETTINGS_SET_LOCALE: {
			return {
				...state,
				locale: action.payload.locale
			};
		}

		case RECEIVE_USER_INFORMATIONS:
			return { ...state, locale: action.payload.locale };

		/**
		 * Consent
		 */
		case SETTINGS_SET_CONSENT:
			return { ...state, consent: action.payload.consent };

		default:
			return state;
	}
}
