import addSeconds from 'date-fns/addSeconds';
import isBefore from 'date-fns/isBefore';
import ReactGA from 'react-ga';
import { getUpdates } from '../../redux/actions/actionCreators';
import { LOGIN_STATE_LOGGED_IN } from '../../redux/store/initialState';
import store from '../../redux/store/store';

export default function checkUpdates() {
	const { isFetching, dateLastCheck } = store.getState().updates;
	const { updatedAt } = store.getState().content;

	const isLogged = store.getState().currentUser.loginState === LOGIN_STATE_LOGGED_IN;

	if (!isLogged) {
		return;
	}

	let isOutOfDate;
	if (null === dateLastCheck) {
		isOutOfDate = true;
	} else {
		const intervalInSecondsUpdates: number = parseInt(
			process.env.REACT_APP_UPDATE_INTERVAL_IN_SECONDS || '1000'
		);
		const dateNextCheck = addSeconds(new Date(dateLastCheck), intervalInSecondsUpdates);
		const now = new Date();
		isOutOfDate = isBefore(dateNextCheck, now);
	}

	if (!isFetching && isOutOfDate) {
		ReactGA.event({
			category: 'Update',
			action: 'Automatic Update Check',
			label: String(updatedAt)
		});
		store.dispatch(getUpdates(updatedAt));
	}
}
