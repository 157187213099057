import {
	COMPOUNDED_MULTIPLE_CHOICE_QUESTION,
	GRADED,
	MULTIPLE_CHOICE_QUESTION,
	RATING_SCALE_QUESTION,
	SELF_ASSESSMENT,
	SEQUENCE_QUESTION,
	SHORT_EXACT_QUESTION,
	SURVEY
} from '../../config/constants';
import { Content, Question, QuestionAnswer, QuestionResponse, Quiz } from '../../types';
import { FileData } from '../../components/FileUploadWidget';

export class QuizManager {
	static getQuiz(quizzes: Content['quizzes'], quizHashID?: Quiz['hashID']): Quiz | null {
		if (quizzes && quizHashID) {
			return quizzes[quizHashID];
		}

		return null;
	}

	static getQuestion(quiz: Quiz, questionIndex: number): Question | null {
		if (quiz.questions) {
			return quiz.questions[questionIndex];
		}
		return null;
	}

	static getNextQuestion(quiz: Quiz, questionIndex: number) {
		if (quiz.questions && questionIndex < quiz.questions.length - 1) {
			return quiz.questions[++questionIndex];
		}

		return null;
	}

	static getPreviousQuestion(quiz: Quiz, questionIndex: number) {
		if (quiz.questions && questionIndex > 0) {
			return quiz.questions[--questionIndex];
		}

		return null;
	}

	static isQuizCompleted(quiz: Quiz) {
		switch (quiz.type) {
			case SURVEY:
			case GRADED:
				return quiz.submitted;
			case SELF_ASSESSMENT:
				if (quiz.userResponses && quiz.questions) {
					return Object.keys(quiz.questions).length === Object.keys(quiz.userResponses).length;
				}
				return false;
			default:
				return false;
		}
	}

	static isQuizzable = (quiz?: Quiz | null) => {
		if (quiz) {
			if (quiz.submitted) {
				//If quiz has been submitted but is a survey
				return quiz.type === SURVEY;
			}
			//if quiz hasn't been submitted
			return true;
		}

		return false;
	};

	/**
	 * Quiz Results
	 */
	static getQuizResult(quizResults: Content['quizResults'], quizHashID: Quiz['hashID']) {
		return quizResults[quizHashID];
	}

	static getAnswerById(answers: Array<QuestionAnswer>, answerID: string | number) {
		const answer = answers.filter((answer) => answer.id === answerID);

		if (answer) {
			return answer[0];
		}

		return null;
	}

	static getQuizResultQuestionResponses(
		responses: Array<QuestionResponse>,
		questionHashID: Question['hashID']
	) {
		const questionResponse = responses.filter((response) => response.questionHashID === questionHashID);

		if (questionResponse) {
			return questionResponse;
		}

		return null;
	}

	static formatQuizResultResponse(responses: Array<QuestionResponse> | null, question: Question) {
		if (responses) {
			const questionType = question.type;
			if (
				questionType === MULTIPLE_CHOICE_QUESTION ||
				questionType === COMPOUNDED_MULTIPLE_CHOICE_QUESTION
			) {
				return responses.map((response) => response.answerID);
			}

			if (questionType === SEQUENCE_QUESTION || questionType === SHORT_EXACT_QUESTION) {
				if (responses[0]?.text === null && question.answers) {
					if (!question.answers[0]) {
						return null;
					}
					return question.answers[0].title;
				}
			}

			if (questionType === RATING_SCALE_QUESTION) {
				if (!responses[0]) {
					return null;
				}

				return Number(responses[0].text);
			}

			//dont return anything if response for question is empty
			if (!responses[0]) {
				return null;
			}

			return responses[0].text;
		}
	}

	static isRequiredQuestionsAnswered(questions: Array<Question>, responses: Quiz['userResponses']) {
		for (let i = 0; i < questions.length; i++) {
			const question = questions[i];

			if (question.required) {
				if (
					!responses ||
					responses[question.hashID] === undefined ||
					responses[question.hashID].length === 0
				) {
					return false;
				}
			}
		}

		return true;
	}

	static requiredFilesUploaded(questions: Array<Question>, files: { [p: string]: FileData[] }) {
		return questions
			.filter((question) => question.uploadFileAnswer?.isRequired)
			.every((question) => files[question.hashID]?.length > 0);
	}
}

export default QuizManager;
