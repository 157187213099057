import { GRADED } from '../../config/constants';
import {
	COURSE_INFO,
	COURSE_QUIZ,
	COURSE_QUIZ_QUESTION,
	COURSE_QUIZ_SUBMIT,
	COURSE_SURVEY,
	COURSE_SURVEY_QUESTIONS,
	COURSE_SURVEY_SUBMIT,
	FOLDER_QUIZ,
	FOLDER_QUIZ_QUESTION,
	FOLDER_QUIZ_SUBMIT,
	FOLDER_SURVEY,
	FOLDER_SURVEY_QUESTIONS,
	FOLDER_SURVEY_SUBMIT,
	MODULE_COURSE_INFO,
	MODULE_COURSE_QUIZ,
	MODULE_COURSE_QUIZ_QUESTION,
	MODULE_COURSE_QUIZ_SUBMIT,
	MODULE_COURSE_SURVEY,
	MODULE_COURSE_SURVEY_QUESTIONS,
	MODULE_COURSE_SURVEY_SUBMIT,
	MODULE_FOLDER_QUIZ,
	MODULE_FOLDER_QUIZ_QUESTION,
	MODULE_FOLDER_QUIZ_SUBMIT,
	MODULE_FOLDER_SURVEY,
	MODULE_FOLDER_SURVEY_QUESTIONS,
	MODULE_FOLDER_SURVEY_SUBMIT,
	MODULE_SESSION_QUIZ,
	MODULE_SESSION_QUIZ_QUESTION,
	MODULE_SESSION_QUIZ_SUBMIT,
	MODULE_SESSION_SURVEY,
	MODULE_SESSION_SURVEY_QUESTIONS,
	MODULE_SESSION_SURVEY_SUBMIT,
	SESSION_QUIZ,
	SESSION_QUIZ_QUESTION,
	SESSION_QUIZ_SUBMIT,
	SESSION_SURVEY,
	SESSION_SURVEY_QUESTIONS,
	SESSION_SURVEY_SUBMIT
} from '../../config/routes';
import store from '../../redux/store/store';
import CourseManager from '../CourseManager';
import generateUrl from '../generateUrl';
import RouteResolver, { ContentParams } from '../RouteResolver';
import { SessionNavigation } from '../session/SessionNavigation';
import QuizManager from './QuizManager';
import { Course, Folder, Module, Quiz, Session } from '../../types';
import { RouteComponentProps } from 'react-router-dom';
import { match } from 'react-router';

const coursePaths = [
	COURSE_QUIZ,
	COURSE_QUIZ_QUESTION,
	COURSE_QUIZ_SUBMIT,
	MODULE_COURSE_QUIZ,
	MODULE_COURSE_QUIZ_QUESTION,
	MODULE_COURSE_QUIZ_SUBMIT,
	COURSE_SURVEY,
	COURSE_SURVEY_QUESTIONS,
	COURSE_SURVEY_SUBMIT,
	MODULE_COURSE_SURVEY,
	MODULE_COURSE_SURVEY_QUESTIONS,
	MODULE_COURSE_SURVEY_SUBMIT
];

const folderPaths = [
	FOLDER_QUIZ,
	FOLDER_QUIZ_QUESTION,
	FOLDER_QUIZ_SUBMIT,
	MODULE_FOLDER_QUIZ,
	MODULE_FOLDER_QUIZ_QUESTION,
	MODULE_FOLDER_QUIZ_SUBMIT,
	FOLDER_SURVEY,
	FOLDER_SURVEY_QUESTIONS,
	FOLDER_SURVEY_SUBMIT,
	MODULE_FOLDER_SURVEY,
	MODULE_FOLDER_SURVEY_QUESTIONS,
	MODULE_FOLDER_SURVEY_SUBMIT
];

const sessionPaths = [
	SESSION_QUIZ,
	SESSION_QUIZ_QUESTION,
	SESSION_QUIZ_SUBMIT,
	MODULE_SESSION_QUIZ,
	MODULE_SESSION_QUIZ_QUESTION,
	MODULE_SESSION_QUIZ_SUBMIT,
	SESSION_SURVEY,
	SESSION_SURVEY_QUESTIONS,
	SESSION_SURVEY_SUBMIT,
	MODULE_SESSION_SURVEY,
	MODULE_SESSION_SURVEY_QUESTIONS,
	MODULE_SESSION_SURVEY_SUBMIT
];

const surveyQuestionPaths = [
	COURSE_SURVEY_QUESTIONS,
	FOLDER_SURVEY_QUESTIONS,
	SESSION_SURVEY_QUESTIONS,
	MODULE_COURSE_SURVEY_QUESTIONS,
	MODULE_FOLDER_SURVEY_QUESTIONS,
	MODULE_SESSION_SURVEY_QUESTIONS
];

interface Props extends RouteComponentProps {
	quiz?: Quiz;
}

export class QuizNavigation {
	private props: Props;
	private readonly location: Props['location'];
	private history: Props['history'];
	private quiz?: Quiz;
	private readonly params: ContentParams | null | undefined;
	private matchParams?: match<ContentParams> | null;
	private readonly path?: match<ContentParams>['path'] | null;
	private readonly courseHashID?: Course['hashID'];
	private readonly quizHashID?: Quiz['hashID'];
	private readonly moduleHashID?: Module['hashID'];
	private readonly surveyHashID?: Quiz['hashID'];
	private readonly sessionHashID?: Session['hashID'];
	private readonly folderHashID?: Folder['hashID'];

	constructor(props: Props) {
		this.props = props;
		this.location = this.props.location;
		this.history = this.props.history;
		this.quiz = this.props.quiz;

		this.matchParams = RouteResolver.resolve(this.location);
		this.path = this.matchParams?.path;
		this.params = this.matchParams?.params;

		this.courseHashID = this.params?.courseHashID;
		this.quizHashID = this.params?.quizHashID;
		this.moduleHashID = this.params?.moduleHashID;
		this.surveyHashID = this.params?.surveyHashID;
		this.sessionHashID = this.params?.sessionHashID;
		this.folderHashID = this.params?.folderHashID;
	}

	getSessionRoute() {
		if (this.surveyHashID) {
			if (this.moduleHashID) {
				return MODULE_SESSION_SURVEY_QUESTIONS;
			}
			return SESSION_SURVEY_QUESTIONS;
		} else {
			if (this.moduleHashID) {
				return MODULE_SESSION_QUIZ_QUESTION;
			}
			return SESSION_QUIZ_QUESTION;
		}
	}

	getCourseRoute() {
		if (this.surveyHashID) {
			if (this.moduleHashID) {
				return MODULE_COURSE_SURVEY_QUESTIONS;
			}
			return COURSE_SURVEY_QUESTIONS;
		} else {
			if (this.moduleHashID) {
				return MODULE_COURSE_QUIZ_QUESTION;
			}
			return COURSE_QUIZ_QUESTION;
		}
	}

	getFolderRoute() {
		if (this.surveyHashID) {
			if (this.moduleHashID) {
				return MODULE_FOLDER_SURVEY_QUESTIONS;
			}
			return FOLDER_SURVEY_QUESTIONS;
		} else {
			if (this.moduleHashID) {
				return MODULE_FOLDER_QUIZ_QUESTION;
			}
			return FOLDER_QUIZ_QUESTION;
		}
	}

	/**
	 * Navigate to the start of the quiz
	 */
	quizStart() {
		if (this.path) {
			let url = null;
			if (coursePaths.includes(this.path)) {
				url = generateUrl(this.getCourseRoute(), {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':quizHashID': this.quizHashID,
					':surveyHashID': this.surveyHashID,
					':questionIndex': 0
				});
			} else if (folderPaths.includes(this.path)) {
				url = generateUrl(this.getFolderRoute(), {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':folderHashID': this.params?.folderHashID,
					':quizHashID': this.quizHashID,
					':surveyHashID': this.surveyHashID,
					':questionIndex': 0
				});
			} else if (sessionPaths.includes(this.path)) {
				url = generateUrl(this.getSessionRoute(), {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':sessionHashID': this.params?.sessionHashID,
					':quizHashID': this.quizHashID,
					':surveyHashID': this.surveyHashID,
					':questionIndex': 0
				});
			}

			return typeof url === 'string' && this.history.push(url);
		}
	}

	/**
	 * Navigate to the next Question
	 */
	nextQuestion(quiz: Quiz) {
		const questionIndex = this.params && parseInt(this.params.questionIndex, 10);
		const nextQuestion =
			typeof questionIndex === 'number' && QuizManager.getNextQuestion(quiz, questionIndex);

		let url = null;

		if (this.path) {
			if (surveyQuestionPaths.includes(this.path)) {
				if (coursePaths.includes(this.path)) {
					url = generateUrl(
						this.moduleHashID ? MODULE_COURSE_SURVEY_SUBMIT : COURSE_SURVEY_SUBMIT,
						{
							':moduleHashID': this.moduleHashID,
							':courseHashID': this.courseHashID,
							':folderHashID': this.folderHashID,
							':sessionHashID': this.sessionHashID,
							':surveyHashID': quiz.hashID
						}
					);
				}
				if (folderPaths.includes(this.path)) {
					url = generateUrl(
						this.moduleHashID ? MODULE_FOLDER_SURVEY_SUBMIT : FOLDER_SURVEY_SUBMIT,
						{
							':moduleHashID': this.moduleHashID,
							':courseHashID': this.courseHashID,
							':folderHashID': this.folderHashID,
							':sessionHashID': this.sessionHashID,
							':surveyHashID': quiz.hashID
						}
					);
				}
				if (sessionPaths.includes(this.path)) {
					url = generateUrl(
						this.moduleHashID ? MODULE_SESSION_SURVEY_SUBMIT : SESSION_SURVEY_SUBMIT,
						{
							':moduleHashID': this.moduleHashID,
							':courseHashID': this.courseHashID,
							':folderHashID': this.folderHashID,
							':sessionHashID': this.sessionHashID,
							':surveyHashID': quiz.hashID
						}
					);
				}
			}
		}
		//If the path is a course/folder/Session Question, get the next question if available and push the url
		//If there is no next question and the Quiz is graded, redirect to the Quiz submission page
		if (this.path === COURSE_QUIZ_QUESTION || this.path === MODULE_COURSE_QUIZ_QUESTION) {
			if (nextQuestion && typeof questionIndex === 'number') {
				url = generateUrl(this.moduleHashID ? MODULE_COURSE_QUIZ_QUESTION : COURSE_QUIZ_QUESTION, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':quizHashID': quiz.hashID,
					':questionIndex': questionIndex + 1
				});
			} else if (quiz.type === GRADED) {
				url = generateUrl(this.moduleHashID ? MODULE_COURSE_QUIZ_SUBMIT : COURSE_QUIZ_SUBMIT, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':quizHashID': quiz.hashID
				});
			} else {
				return this.quizEnd();
			}
		} else if (this.path === FOLDER_QUIZ_QUESTION || this.path === MODULE_FOLDER_QUIZ_QUESTION) {
			const folderHashID = this.params?.folderHashID;

			if (nextQuestion && typeof questionIndex === 'number') {
				url = generateUrl(this.moduleHashID ? MODULE_FOLDER_QUIZ_QUESTION : FOLDER_QUIZ_QUESTION, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':folderHashID': folderHashID,
					':quizHashID': quiz.hashID,
					':questionIndex': questionIndex + 1
				});
			} else if (quiz.type === GRADED) {
				url = generateUrl(this.moduleHashID ? MODULE_FOLDER_QUIZ_SUBMIT : FOLDER_QUIZ_SUBMIT, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':folderHashID': folderHashID,
					':quizHashID': quiz.hashID
				});
			} else {
				return this.quizEnd();
			}
		} else if (this.path === SESSION_QUIZ_QUESTION || this.path === MODULE_SESSION_QUIZ_QUESTION) {
			const sessionHashID = this.params?.sessionHashID;

			if (nextQuestion && typeof questionIndex === 'number') {
				url = generateUrl(this.moduleHashID ? MODULE_SESSION_QUIZ_QUESTION : SESSION_QUIZ_QUESTION, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':sessionHashID': sessionHashID,
					':quizHashID': quiz.hashID,
					':questionIndex': questionIndex + 1
				});
			} else if (quiz.type === GRADED) {
				url = generateUrl(this.moduleHashID ? MODULE_SESSION_QUIZ_SUBMIT : SESSION_QUIZ_SUBMIT, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':sessionHashID': sessionHashID,
					':quizHashID': quiz.hashID
				});
			} else {
				return this.quizEnd();
			}
		}

		return typeof url === 'string' && this.history.push(url);
	}

	/**
	 * Navigate to the previous Question
	 */
	previousQuestion(quiz: Quiz) {
		const questionIndex = this.params && parseInt(this.params.questionIndex, 10);
		const previousQuestion =
			typeof questionIndex === 'number' && QuizManager.getPreviousQuestion(quiz, questionIndex);

		let url = null;

		// If there's a previous question, navigate to it
		if (previousQuestion && typeof questionIndex === 'number') {
			if (this.path === COURSE_QUIZ_QUESTION || this.path === MODULE_COURSE_QUIZ_QUESTION) {
				url = generateUrl(this.moduleHashID ? MODULE_COURSE_QUIZ_QUESTION : COURSE_QUIZ_QUESTION, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':quizHashID': quiz.hashID,
					':questionIndex': questionIndex - 1
				});
			} else if (this.path === FOLDER_QUIZ_QUESTION || this.path === MODULE_FOLDER_QUIZ_QUESTION) {
				const folderHashID = this.params?.folderHashID;

				url = generateUrl(this.moduleHashID ? MODULE_FOLDER_QUIZ_QUESTION : FOLDER_QUIZ_QUESTION, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':folderHashID': folderHashID,
					':quizHashID': quiz.hashID,
					':questionIndex': questionIndex - 1
				});
			} else if (this.path === SESSION_QUIZ_QUESTION || this.path === MODULE_SESSION_QUIZ_QUESTION) {
				const sessionHashID = this.params?.sessionHashID;

				url = generateUrl(this.moduleHashID ? MODULE_SESSION_QUIZ_QUESTION : SESSION_QUIZ_QUESTION, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':sessionHashID': sessionHashID,
					':quizHashID': quiz.hashID,
					':questionIndex': questionIndex - 1
				});
			}
		}

		return typeof url === 'string' && this.history.push(url);
	}

	/**
	 * End a Quiz
	 */
	quizEnd() {
		if (this.path) {
			let url = null;

			if (coursePaths.includes(this.path) || folderPaths.includes(this.path)) {
				url = generateUrl(this.moduleHashID ? MODULE_COURSE_INFO : COURSE_INFO, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID
				});
			} else if (sessionPaths.includes(this.path)) {
				const sessions = store.getState().content.sessions;
				const session = this.params && CourseManager.getSession(sessions, this.params.sessionHashID);
				const nextSession = session && CourseManager.getNextSession(sessions, session);

				if (session && nextSession) {
					return new SessionNavigation({ ...this.props, session, nextSession }).next();
				} else {
					//go back to course screen
					return this.history.push(
						generateUrl(COURSE_INFO, {
							':courseHashID': this.courseHashID
						})
					);
				}
			}

			return typeof url === 'string' && this.history.push(url);
		}
	}
}
