import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface Props {
	children: Array<JSX.Element>;
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default
	}
}));

const removeFragments = (children: Array<JSX.Element>) => {
	return children.filter((child) => {
		return String(child.type) !== 'Symbol(react.fragment)';
	});
};

export const CustomExpansionPanel = (props: Props) => {
	const { children, ...otherProps } = props;
	const classes = useStyles();

	return (
		<Accordion {...otherProps} classes={{ root: classes.root }}>
			{removeFragments(children)}
		</Accordion>
	);
};

export default CustomExpansionPanel;
