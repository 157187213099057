import * as Sentry from '@sentry/browser';
import { failGetContent, fileFailed, fileLoaded } from '../../redux/actions/actionCreators';
import store from '../../redux/store/store';
import allSettled from 'promise.allsettled';

const BATCH_SIZE = 10;

export class SpoolFileLoader {
	private increment: number;
	private isLoading: boolean;

	constructor() {
		this.isLoading = false;
		this.increment = 0;
	}

	handle() {
		this.increment++;

		if (this.isLoading) {
			return;
		}

		const fileSpool = store.getState().content.spool.files;
		const filesToLoad =
			fileSpool.length < BATCH_SIZE
				? fileSpool.slice(0, fileSpool.length)
				: fileSpool.slice(0, BATCH_SIZE);

		if (!filesToLoad || filesToLoad?.length === 0) {
			return;
		}

		this.isLoading = true;
		allSettled(filesToLoad.map((filePath: string) => fetch(filePath)))
			.then((response: any) => {
				response.forEach((res: any, index: number) => {
					if (res.status === 'fulfilled') {
						res.value.status === 404 && console.log(`File not found: ${filesToLoad[index]}`);
						store.dispatch(fileLoaded(filesToLoad[index]));
					} else {
						store.dispatch(fileFailed(filesToLoad[index]));
						console.log(
							`There has been a problem when loading: ${filesToLoad[index]}; Error: ${res.reason.message}`
						);
						Sentry.captureMessage('FILE_FAILED: ' + filesToLoad[index]);
					}
				});
			})
			.then(() => {
				this.isLoading = false;
				this.handle();
			})
			.catch((error) => {
				store.dispatch(failGetContent(error.message));
			});
	}
}

export default new SpoolFileLoader();
