import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { Locale } from '../../types';

interface Props {
	locale: Locale;
	percentSpoolCompleted: number;
}

const UpdateDialog = (props: Props) => {
	const { locale, percentSpoolCompleted } = props;

	const [open, setOpen] = useState(true);

	useEffect(() => {
		if (percentSpoolCompleted && percentSpoolCompleted === 100) {
			handleClose();
		}
	}, [percentSpoolCompleted]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			disableBackdropClick={true}
			open={open}
			onClose={handleClose}
			data-test-id="update_dialog_container"
		>
			<DialogTitle data-test-id="update-dialog_title">
				{I18n.t('update.downloadingContent', { locale })}
			</DialogTitle>

			<DialogContent>
				<DialogContentText data-test-id="update-dialog_info" style={{ marginBottom: '1em' }}>
					{I18n.t('update.stayOnline', { locale })}
				</DialogContentText>

				<LinearProgress variant="determinate" value={percentSpoolCompleted} />
			</DialogContent>

			<DialogActions>
				<Button
					data-test-id="update-dialog_dismiss-btn"
					color="primary"
					onClick={handleClose}
					fullWidth
				>
					{I18n.t('update.dismiss', { locale })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UpdateDialog;
