import { Audio, Image, Marker, ParagraphTune, Video, HtmlPaste, FileUpload } from 'chalkboard-plugins';
import ExpandForMore from './expansion-panel/expand-for-more';
import ChangeCase from 'editorjs-change-case';
import Embed from '@editorjs/embed';

const Header = require('@editorjs/header');
const InlineCode = require('@editorjs/inline-code');
const Table = require('chalkboard-test-table');
const Delimiter = require('@editorjs/delimiter');
const Checklist = require('@editorjs/checklist');
const Paragraph = require('@editorjs/paragraph');
const List = require('@editorjs/list');
const CodeTool = require('@editorjs/code');
const Underline = require('@editorjs/underline');
const Strikethrough = require('editorjs-strikethrough');
const Superscript = require('editorjs-superscript');
const Subscript = require('editorjs2-subscript');

export const createParagraphBlockData = (text: string) => {
	return {
		blocks: [
			{
				type: 'paragraph',
				data: {
					text
				}
			}
		]
	};
};

export const blockTools = {
	htmlPaste: HtmlPaste,
	audio: Audio,
	header: {
		class: Header,
		inlineToolbar: true,
		config: {
			placeholder: 'Enter a header',
			levels: [1, 2, 3, 4],
			defaultLevel: 3
		}
	},
	alignment: {
		class: ParagraphTune
	},
	fileUpload: FileUpload,
	expandMore: ExpandForMore,
	paragraph: {
		class: Paragraph,
		inlineToolbar: true,
		tunes: ['alignment']
	},
	embed: Embed,
	inlineCode: InlineCode,
	list: {
		class: List,
		inlineToolbar: true,
		tunes: ['alignment']
	},
	delimiter: Delimiter,
	table: {
		class: Table,
		inlineToolbar: true,
		tunes: ['alignment']
	},
	checklist: {
		class: Checklist,
		inlineToolbar: true
	},
	img: {
		class: Image,
		inlineToolbar: true
	},
	video: Video,
	code: CodeTool,
	underline: Underline,
	changeCase: {
		class: ChangeCase
	},
	Marker: Marker,
	strikethrough: Strikethrough,
	superscript: Superscript,
	subscript: Subscript
};

export const { expandMore, ...expansionPanelTools } = blockTools;
