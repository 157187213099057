import gql from 'graphql-tag';

export default gql`
	mutation SubmitQuiz($hashID: String!, $responses: String!, $files: [FileEntry!]) {
		submitQuiz(hashID: $hashID, responses: $responses, files: $files) {
			id
			quiz {
				hashID
				title
				type
				description
				questions {
					hashID
					title
					type
					answers {
						id
						title
						correct
					}
				}
			}
			grade
			totalCorrect
			responses {
				questionHashID
				text
				correct
				answerID
			}
		}
	}
`;
