import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import React from 'react';
import { useSelector } from 'react-redux';
import { ESSAY_QUESTION, MULTIPLE_CHOICE_QUESTION, RATING_SCALE_QUESTION } from '../../../config/constants';
import QuizManager from '../../../services/quiz/QuizManager';
import Responses from './Responses';
import { Store, Question, UserResponse } from '../../../types';

interface Props {
	question: Question;
	feedback?: Question['feedback'];
	userResponse: UserResponse;
}

const FeedbackContent = (props: Props) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const noContentQuestions = [ESSAY_QUESTION, RATING_SCALE_QUESTION];

	const {
		question,
		question: { answers },
		feedback: questionFeedback,
		userResponse
	} = props;

	const showFeedbackText = () => {
		if (question.type === MULTIPLE_CHOICE_QUESTION) {
			const answer = QuizManager.getAnswerById(answers, userResponse[0]);

			if (answer && answer.feedback) {
				return answer.feedback;
			}
		}

		if (questionFeedback) {
			return questionFeedback;
		}

		return '';
	};

	// Hide feedback text if essay/rating scale has no feedback
	if (noContentQuestions.includes(question.type) && !questionFeedback) {
		return null;
	}

	return (
		<DialogContent>
			<DialogContentText
				data-test-id="alert-dialog-description"
				id="alert-dialog-description"
				dangerouslySetInnerHTML={{ __html: showFeedbackText() }}
			/>
			{answers && <Responses question={question} locale={locale} data-test-id="responses_comp" />}
		</DialogContent>
	);
};

export default FeedbackContent;
