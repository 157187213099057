import Avatar from '@material-ui/core/Avatar/Avatar';
import amber from '@material-ui/core/colors/amber';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import I18n from 'i18n-js';
import React from 'react';
import { SURVEY } from '../../config/constants';
import QuizManager from '../../services/quiz/QuizManager';
import QuizBadge from '../badges/QuizBadge';
import SurveyBadge from '../badges/SurveyBadge';
import { Content, Locale, Session } from '../../types';

const styles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4)
	},
	smallAvatar: {
		width: 25,
		height: 25
	},
	smallAvatarFont: {
		fontSize: 14
	},
	lastToReadAvatar: {
		color: '#fff',
		backgroundColor: amber[400]
	},
	quizAvatar: {
		color: '#fff',
		backgroundColor: theme.palette.secondary.main
	},
	progressedAvatar: {
		color: '#fff',
		backgroundColor: theme.palette.primary.main
	},
	readListItem: {
		backgroundColor: theme.palette.background.default
	}
}));

interface Props {
	locale: Locale;
	sessions: Content['sessions'];
	quizzes: Content['quizzes'];
	viewedSessions: string[];
	handleNext: (session: Session) => void;
}

const SessionList = (props: Props) => {
	const { locale, sessions, quizzes, viewedSessions } = props;
	const classes = styles();

	const sessionAvatar = (index: number, session: Session, isLastToRead = false, enabled = false) => {
		let icon = index + 1;
		let iconClass;

		if (isLastToRead) {
			iconClass = classes.lastToReadAvatar;
		} else if (enabled) {
			iconClass = classes.progressedAvatar;
		}

		return (
			<Avatar
				className={classnames(classes.smallAvatar, classes.smallAvatarFont, {
					[iconClass as string]: iconClass
				})}
			>
				{icon}
			</Avatar>
		);
	};

	const sessionIsEmpty = (session: Session) => {
		if (session.content && session.content.includes('&lt;quiz&gt;')) {
			return true;
		}
		return false;
	};

	const sessionIsQuizzed = (session: Session) => {
		//TODO: Fix implementation. variable quiz should be of type Quiz
		const quiz = session.quizHashID && QuizManager.getQuiz(quizzes, session.quizHashID);
		return quiz && QuizManager.isQuizzable(quiz);
	};

	const sessionIsQuiz = (session: Session) => {
		return sessionIsEmpty(session) && sessionIsQuizzed(session);
	};

	const sessionQuizTitle = (session: Session) => {
		const quiz = session.quizHashID && QuizManager.getQuiz(quizzes, session.quizHashID);
		if (quiz) {
			return 'Quiz: ' + quiz.title;
		}
		return session.title;
	};

	const setBadge = (session: Session) => {
		const quiz = session.quizHashID && QuizManager.getQuiz(quizzes, session.quizHashID);
		if (quiz) {
			switch (quiz.type) {
				case SURVEY:
					return <SurveyBadge locale={locale} />;
				default:
					return <QuizBadge locale={locale} />;
			}
		}
	};

	const totalSessions = Object.keys(sessions).length;
	const sessionsKeys = Object.keys(sessions);
	const lastToReadSessionKey = sessionsKeys.find((key) => {
		const session = sessions[key];
		return session.progressionLock && !session.progressed;
	});

	if (!sessions || totalSessions === 0) {
		return <p>{I18n.t('course.noContentAvailable', { locale })}</p>;
	}

	return (
		<List component="div" disablePadding className="joyride-sessions-items">
			{sessionsKeys.map((key, index) => {
				let session = sessions[key];
				const isLastToRead = lastToReadSessionKey === key;
				const enabled = session.progressed || !session.progressionLock || isLastToRead;

				const { nested: nestedClass, readListItem } = classes;

				return (
					<React.Fragment key={index}>
						<ListItem
							data-test-id="session-list_list-item"
							className={classnames('joyride-sessions-item', nestedClass, {
								[readListItem]: viewedSessions.includes(session.hashID)
							})}
							button={enabled as any}
							disabled={!enabled}
							onClick={() => {
								enabled && props.handleNext(session);
							}}
						>
							<ListItemIcon>
								{sessionAvatar(index, session, isLastToRead, enabled)}
							</ListItemIcon>
							<ListItemText
								data-test-id="session_list_item_text"
								primary={sessionIsQuiz(session) ? sessionQuizTitle(session) : session.title}
							/>
							{sessionIsQuizzed(session) && setBadge(session)}
						</ListItem>
					</React.Fragment>
				);
			})}
		</List>
	);
};

export default SessionList;
