import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import QuestionItem from '../components/quiz/quizReview/QuestionItem';
import { SURVEY } from '../config/constants';
import QuizManager from '../services/quiz/QuizManager';
import { Store, Question } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../services/RouteResolver';

const useStyles = makeStyles(() => ({
	infoWrapper: {
		padding: '20px 10px',
		backgroundColor: 'white'
	},
	quizTitle: {
		textTransform: 'capitalize'
	},
	quizDescription: {
		fontStyle: 'italic'
	},
	quizGrade: {
		marginTop: '20px',
		fontWeight: 'bold'
	},
	titleWrapper: { padding: '15px', paddingBottom: '5px' },
	title: {
		textTransform: 'capitalize'
	}
}));

interface Params extends Pick<ContentParams, 'quizHashID'> {}
interface Props extends RouteComponentProps<Params> {}

const QuizReviewDetailScreen = (props: Props) => {
	const classes = useStyles();

	const myProps = useSelector(({ content: { quizResults }, settings: { locale } }: Store) => {
		let quizHashID = props.match.params.quizHashID;
		let quizResult = QuizManager.getQuizResult(quizResults, quizHashID);
		let questions = quizResult?.quiz.questions;

		return {
			quizResult,
			questions,
			locale
		};
	});

	const { quizResult, questions, locale } = myProps;

	const quiz = quizResult?.quiz;

	return (
		<div data-test-id="quiz-review-detail-screen_container">
			<Grid item container direction="column" className={classes.infoWrapper}>
				<Typography variant="h6" className={classes.quizTitle}>
					{quiz.title}
				</Typography>

				<Typography variant="body1" color="textSecondary" className={classes.quizDescription}>
					<span dangerouslySetInnerHTML={{ __html: quiz.description ?? '' }} />
				</Typography>
			</Grid>
			<Grid item container direction="column" className={classes.titleWrapper}>
				<Typography
					data-test-id="quiz-review-detail-screen_question-title"
					variant="h6"
					className={classes.title}
				>
					{I18n.t(
						quiz.type === SURVEY
							? 'quizReview.questionsTitle.survey'
							: 'quizReview.questionsTitle.graded',
						{ locale }
					)}
				</Typography>
			</Grid>
			{questions &&
				questions.map((question: Question, questionIndex: number) => {
					const questionResponses =
						quizResult && quizResult.responses ? quizResult.responses : null;

					const questionResponse = questionResponses
						? QuizManager.getQuizResultQuestionResponses(questionResponses, question.hashID)
						: null;
					return (
						<QuestionItem
							data-test-id="quiz-review-detail-screen_question-item"
							quiz={quiz}
							locale={locale ?? 'en'}
							key={question.hashID}
							questionResponse={questionResponse}
							question={question}
							questionIndex={questionIndex.toString()}
							totalQuestions={questions.length}
						/>
					);
				})}
		</div>
	);
};

export default QuizReviewDetailScreen;
