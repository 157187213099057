import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import I18n from 'i18n-js';
import React from 'react';
import { GRADED, SELF_ASSESSMENT, SURVEY } from '../../config/constants';
import SessionFragment from '../../containers/SessionFragment';
import CourseManager from '../../services/CourseManager';
import QuizManager from '../../services/quiz/QuizManager';
import FolderIcon from '../icons/FolderIcon';
import { Content, Folder, Locale, Quiz, Store } from '../../types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';

const styles = makeStyles((theme) => ({
	padded: {
		padding: theme.spacing(3)
	},
	folderName: {
		padding: '0px 16px'
	},
	folderListItem: {
		padding: '11px 16px'
	},
	progress: {
		width: '100%',
		top: '0px',
		position: 'absolute'
	},
	readListItem: {
		backgroundColor: theme.palette.background.default + ' !important'
	}
}));

interface Props {
	expanded?: (folderHashID: Folder['hashID']) => boolean;
	folders: Content['folders'];
	viewedFolders: Array<Folder['hashID']>;
	sessions?: Content['sessions'];
	quizzes?: Content['quizzes'];
	locale: Locale;
	handleQuiz?: (folder: Folder) => void;
	handleToggle?: (folderHashID: Folder['hashID']) => void;
	className?: string;
	handleNext?: (folder: Folder) => void;
}

const FolderList = (props: Props) => {
	const { folders, viewedFolders, sessions, quizzes, locale } = props;
	const classes = styles();

	const totalFolders = Object.keys(folders).length;

	const allSessions: any = useSelector(({ content: { sessions } }: Store) => sessions);

	const viewedSessions: string[] = useSelector(
		({
			content: {
				viewed: { sessions }
			}
		}: Store) => sessions
	);

	if (!folders || totalFolders === 0) {
		return <p data-test-id="alert">{I18n.t('course.noContentAvailable', { locale })}</p>;
	}

	const showExpandIcon = (folderHashID: string) => {
		return props.expanded && props.expanded(folderHashID) ? <ExpandLess /> : <ExpandMore />;
	};

	const showQuizButton = (quiz: Quiz | null, folder: Folder) => {
		if (!quiz) {
			return null;
		}

		if (
			quiz.type === SELF_ASSESSMENT ||
			(quiz.type === GRADED && !QuizManager.isQuizCompleted(quiz)) ||
			quiz.type === SURVEY
		) {
			return (
				<Grid container className={classes.padded} direction="column" data-test-id="quiz_container">
					<Grid item>
						{!QuizManager.isQuizCompleted(quiz) && (
							<Typography color="textSecondary" data-test-id="quiz_label">
								{I18n.t(quiz.type === SURVEY ? 'folder.surveyLabel' : 'folder.quizLabel', {
									locale
								})}
							</Typography>
						)}
					</Grid>

					<Grid item>
						<Button
							variant="contained"
							fullWidth
							color="secondary"
							onClick={() => props.handleQuiz && props.handleQuiz(folder)}
							data-test-id="quiz_button"
						>
							{I18n.t(quiz.type === SURVEY ? 'session.surveyButton' : 'session.quizButton', {
								locale
							})}
						</Button>
					</Grid>
				</Grid>
			);
		}
	};

	/**
	 * If folder has a quiz, only show the session list if the quiz is completed
	 * Else if there's no quiz in the folder, show the session list for the folder
	 */
	const showSessionList = (quiz: Quiz | null, folderSessions: any) => {
		if (!quiz || (quiz && QuizManager.isQuizCompleted(quiz))) {
			return <SessionFragment data-test-id="session_fragment" sessions={folderSessions} />;
		}
	};

	const showCollapsible = (folder: Folder) => {
		const quiz: Quiz | null =
			folder.quizHashID && quizzes ? QuizManager.getQuiz(quizzes, folder.quizHashID) : null;
		const folderSessions = sessions && CourseManager.getSessionsFromFolder(sessions, folder.hashID);

		if (folderSessions) {
			return (
				<React.Fragment>
					{showQuizButton(quiz, folder)}
					{showSessionList(quiz, folderSessions)}
				</React.Fragment>
			);
		}
	};

	const progressPercentage = (folderHashID: string) => {
		let progress: number = 0;
		const folderSessions: string[] = Object.keys(allSessions).filter(
			(session: string) => allSessions[session].folderHashID == folderHashID
		);

		const viewedFolderSessions: string[] = folderSessions.filter((folder) =>
			viewedSessions.includes(folder)
		);

		if (viewedFolderSessions && folderSessions) {
			const percent = (viewedFolderSessions.length / folderSessions.length) * 100;
			progress = parseInt(percent.toFixed(0));
		}

		return progress;
	};

	return (
		<List style={{ paddingTop: 0, paddingBottom: 0 }} className="joyride-folder-items">
			{Object.keys(folders).map((key, index) => {
				let folder = folders[key];

				let listItemClass;
				if (viewedFolders.includes(folder.hashID)) {
					listItemClass = classes.readListItem;
				}

				return (
					<React.Fragment key={index}>
						<ListItem
							data-test-id="folder-list_folder-list-item"
							className={classnames(
								'joyride-folder-item',
								classes.folderListItem,
								listItemClass ? listItemClass : ''
							)}
							button
							onClick={() => {
								props.handleToggle && props.handleToggle(folder.hashID);
							}}
						>
							<Avatar>
								<FolderIcon />
								<div className={classes.progress}>
									<CircularProgress variant="determinate" value={progressPercentage(key)} />
								</div>
							</Avatar>
							<ListItemText className={classes.folderName} primary={folder.title} />

							{/*Expand Icon*/}
							{showExpandIcon(folder.hashID)}
						</ListItem>

						{/* Session List (Collapsible) */}
						<Collapse
							in={props.expanded && props.expanded(folder.hashID)}
							timeout="auto"
							unmountOnExit
						>
							{showCollapsible(folder)}
						</Collapse>

						{index < totalFolders - 1 && <Divider />}
					</React.Fragment>
				);
			})}
		</List>
	);
};

export default FolderList;
