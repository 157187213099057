import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	tile: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'black',
		aspectRatio: '1/1',
		overflow: 'hidden',
		width: '60px',
		[theme.breakpoints.up('md')]: {
			width: '80px'
		}
	},
	text: {
		bottom: '6px',
		[theme.breakpoints.up('md')]: {
			width: '10px'
		}
	},
	button: {
		position: 'absolute',
		height: '100%',
		width: '100%'
	}
}));

const SquareTile = (props: {
	text?: string;
	onTap: () => void;
	icon: JSX.Element;
	backgroundColor?: string | null;
}) => {
	const classes = useStyles();

	return (
		<Paper style={{ backgroundColor: props.backgroundColor ?? 'white' }} className={classes.tile}>
			{props.text ? (
				<Typography
					style={{
						fontSize: '8px',
						textAlign: 'center',
						position: 'absolute',
						bottom: '10px'
					}}
				>
					{props.text}
				</Typography>
			) : null}
			<Button onClick={props.onTap} className={classes.button} title={props.text || ''}>
				{props.icon}
			</Button>
		</Paper>
	);
};

export default SquareTile;
