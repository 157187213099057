import Typography from '@material-ui/core/Typography';
import I18n from 'i18n-js';
import React from 'react';
import {
	COMPOUNDED_MULTIPLE_CHOICE_QUESTION,
	ESSAY_QUESTION,
	MULTIPLE_CHOICE_QUESTION,
	NUMERICAL_RANGE_QUESTION,
	RATING_SCALE_QUESTION,
	SEQUENCE_QUESTION,
	SHORT_EXACT_QUESTION
} from '../../config/constants';
import { Locale, QuestionTypeEnum } from '../../types';

const setHelperText = (type: string): string => {
	switch (type) {
		case COMPOUNDED_MULTIPLE_CHOICE_QUESTION:
			return 'question.helperText.compoundMultipleChoice';
		case MULTIPLE_CHOICE_QUESTION:
			return 'question.helperText.multipleChoice';
		case SHORT_EXACT_QUESTION:
			return 'question.helperText.shortExact';
		case NUMERICAL_RANGE_QUESTION:
			return 'question.helperText.numericalRange';
		case SEQUENCE_QUESTION:
			return 'question.helperText.sequence';
		case ESSAY_QUESTION:
			return 'question.helperText.essay';
		case RATING_SCALE_QUESTION:
			return 'question.helperText.ratingScale';
		default:
			return '';
	}
};

interface Props {
	type: QuestionTypeEnum;
	locale: Locale;
}

const HelperText = ({ type, locale }: Props) => (
	<Typography style={{ color: 'grey', marginTop: '20px' }} variant="caption">
		{I18n.t(setHelperText(type), { locale })}
	</Typography>
);

export default HelperText;
