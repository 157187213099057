import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import CardActions from '@material-ui/core/CardActions/CardActions';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Typography from '@material-ui/core/Typography/Typography';
import format from 'date-fns/format';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdates, getUserInformation, resetTutorial, setConsent } from '../redux/actions/actionCreators';
import AboutPanel from '../components/account/AboutPanel';
import UserPanel from '../components/account/UserPanel';
import { HOME, MODULES, WELCOME } from '../config/routes';
import { LOGIN_STATE_LOGGED_IN } from '../redux/store/initialState';
import store from '../redux/store/store';
import { Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';

interface Params {
	token: string;
}

interface MyProps extends Pick<RouteComponentProps<Params>, 'history' | 'match'> {}

const ConsentScreen = (props: MyProps) => {
	const myProps = useSelector(({ currentUser, settings: { locale, consent } }: Store) => ({
		currentUser,
		locale,
		consent
	}));

	const message = useSelector(({ currentUser: { message } }: Store) => message);

	const {
		currentUser,
		currentUser: { firstLogin, token, isLoggingIn },
		locale,
		consent
	} = myProps;
	const { history } = props;

	const [dataConsent, setDataConsent] = useState(false);
	const [analyticsConsent, setAnalyticsConsent] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		const token = props.match.params.token;
		const tokenIssuedAt = format(new Date(), 't');

		if (!token) {
			history.push(HOME);
		}

		const isTokenChanged = currentUser.token !== null && currentUser.token !== token;

		if (isTokenChanged || currentUser.loginState !== LOGIN_STATE_LOGGED_IN) {
			dispatch(getUserInformation({ token, tokenIssuedAt }));

			return;
		}

		//If no consent, do not progress
		if (!consent) return;

		// user already logged and consent given, redirect to courses list
		return history.push(MODULES);
	}, []); //eslint-disable-line

	useEffect(() => {
		if (token === null && !isLoggingIn && store.getState().currentUser.message) {
			history.push(HOME);
		}
	}, [isLoggingIn, message]); //eslint-disable-line

	const handleRedirectOnboarding = (): void => {
		//Set consent to true
		dispatch(setConsent(true));

		// Get updates
		dispatch(getUpdates(null));

		if (!firstLogin) {
			// Skip onboarding and go to courses list
			history.push(MODULES);
		} else {
			//enable tutorials
			dispatch(resetTutorial());

			// Go to onboarding
			history.push(WELCOME);
		}
	};

	if (isLoggingIn) {
		return <div className="flash-container">{I18n.t('login.checking', { locale })}</div>;
	}

	if (currentUser.loginState === LOGIN_STATE_LOGGED_IN) {
		return (
			<div data-test-id="logged-in-screen_wrapper">
				<AboutPanel data-test-id="logged-in-screen_about-panel" />

				<UserPanel data-test-id="logged-in-screen_user-panel" />

				<Card>
					<CardHeader
						data-test-id="logged-in-screen_card-header"
						title={I18n.t('account.consent', { locale })}
						style={{ paddingBottom: 0 }}
					/>

					<CardContent style={{ paddingTop: 0 }}>
						<Typography
							data-test-id="logged-in-screen_card-desc"
							variant="subtitle2"
							component="div"
						>
							{I18n.t('consent.title', { locale })}
						</Typography>

						<FormControlLabel
							data-test-id="logged-in-screen_data-consent-fcl"
							control={
								<Checkbox
									color="primary"
									checked={dataConsent}
									onChange={() => setDataConsent(!dataConsent)}
								/>
							}
							label={I18n.t('consent.data', { locale })}
						/>

						<br />

						<FormControlLabel
							data-test-id="logged-in-screen_analytics-consent-fcl"
							control={
								<Checkbox
									color="primary"
									checked={analyticsConsent}
									onChange={() => setAnalyticsConsent(!analyticsConsent)}
								/>
							}
							label={I18n.t('consent.analytics', { locale })}
						/>
					</CardContent>

					<CardActions>
						<Button
							data-test-id="logged-in-screen_btn"
							variant="contained"
							color="primary"
							fullWidth
							disabled={!dataConsent || !analyticsConsent}
							style={{ margin: '10px' }}
							onClick={handleRedirectOnboarding}
						>
							{I18n.t('login.start', { locale })}
						</Button>
					</CardActions>
				</Card>
			</div>
		);
	}

	return <div />;
};

export default ConsentScreen;
