import Button from '@material-ui/core/Button/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Question, Store } from '../../types';

interface Props {
	question: Question;
	openFeedback: Function;
	correct: boolean;
}
const MCQ = (props: Props) => {
	const { question } = props;

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const [enabled, setEnabled] = useState(true);
	const [userResponses, setUserResponses] = useState<number[]>([]);

	const handleResponseChange = (answerIndex: any) => {
		answerIndex = parseInt(answerIndex, 10);

		if (question.isMultiple && userResponses !== undefined) {
			if (userResponses.indexOf(answerIndex) > -1) {
				//Answer already exists in state so remove it
				setUserResponses([...userResponses.filter((answer) => answer !== answerIndex)]);
			} else {
				setUserResponses([...userResponses, answerIndex]);
			}

			return;
		}

		//Singular answer
		setUserResponses([answerIndex]);
	};

	const submitAnswer = () => {
		let correct = false;

		if (question.correctAnswers) {
			if (question.isMultiple) {
				//Check the answers in the userResponses array if they're the same length and contain the same answers when sorted
				if (
					userResponses.length === question.correctAnswers.length &&
					JSON.stringify(userResponses.sort()) === JSON.stringify(question.correctAnswers.sort())
				) {
					correct = true;
				}
			} else {
				if (userResponses[0] === question.correctAnswers[0]) {
					correct = true;
				}
			}
		}
		props.openFeedback(correct);
		setEnabled(false);
	};

	const renderAnswers = () => {
		if (question.isMultiple && question.answers) {
			return (
				<FormControl component="fieldset">
					{question.answers &&
						Object.keys(question.answers).map((key, index) => {
							const answer = question.answers[index];

							return (
								<FormControlLabel
									key={index}
									data-test-id="mcq_multiple_form_label"
									control={
										<Checkbox
											color="primary"
											checked={userResponses.includes(index)}
											onChange={() => {
												handleResponseChange(index);
											}}
											disabled={enabled === false}
										/>
									}
									label={answer.title}
								/>
							);
						})}
				</FormControl>
			);
		}

		return (
			<FormControl component="fieldset">
				<RadioGroup
					data-test-id="mcq_form_control"
					aria-label={question.title}
					name={question.title}
					value={userResponses.length > 0 ? userResponses[0].toString() : ''}
					onChange={(e, value) => {
						handleResponseChange(value);
					}}
				>
					{question.answers &&
						Object.keys(question.answers).map((key, index) => {
							const answer = question.answers[index];

							return (
								<FormControlLabel
									data-test-id="mcq_form_label"
									key={index}
									label={answer.title}
									value={index.toString()}
									checked={index === userResponses[0]}
									control={<Radio color="primary" />}
									disabled={enabled === false}
								/>
							);
						})}
				</RadioGroup>
			</FormControl>
		);
	};

	return (
		<div>
			{renderAnswers()}

			<div style={{ flex: 1, textAlign: 'center', padding: '1em' }}>
				<Button
					variant="contained"
					color="primary"
					disabled={userResponses.length < 1}
					onClick={submitAnswer}
					data-test-id="mcq_check_answer_button"
				>
					{I18n.t('selfTestQuiz.checkAnswer', { locale })}
				</Button>
			</div>
		</div>
	);
};

export default MCQ;
