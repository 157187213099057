import Button from '@material-ui/core/Button';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';

interface Props {
	history: Partial<RouteComponentProps['history']>;
}

const NotFound = (props: Props) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	return (
		<div className="screen-centered" data-test-id="not_found_comp">
			<h3>{I18n.t('notFound.title', { locale })}</h3>
			<p>{I18n.t('notFound.description', { locale })}</p>

			<Button
				data-test-id="not_found_btn"
				variant="contained"
				color="secondary"
				onClick={() => props.history!.goBack!()}
			>
				{I18n.t('notFound.back', { locale })}
			</Button>
		</div>
	);
};

export default NotFound;
