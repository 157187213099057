import {
	CANCEL_USER_LOGOUT,
	REQUEST_FORCED_USER_LOGOUT,
	REQUEST_USER_LOGOUT
} from '../actions/actionCreators';
import initialState from '../store/initialState';
import { Action } from '../../types';

export default function logout(state = { ...initialState.logout }, action: Action) {
	switch (action.type) {
		case REQUEST_USER_LOGOUT: {
			return {
				...state,
				loggingOut: true
			};
		}

		case REQUEST_FORCED_USER_LOGOUT: {
			return {
				...state,
				loggingOut: true,
				isForced: true
			};
		}

		case CANCEL_USER_LOGOUT: {
			return {
				...state,
				loggingOut: false,
				isForced: false
			};
		}

		default:
			return state;
	}
}
