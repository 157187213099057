import {
	ADD_BOOKMARK,
	ADD_VIEWED_FOLDER,
	ADD_VIEWED_SESSION,
	DONE_SUBMIT_QUIZ,
	DONE_VALIDATE_SESSION,
	FAIL_GET_CONTENT,
	FAIL_GET_QUIZ_RESULTS,
	FAIL_GET_SESSION_CONTENT,
	FAIL_SUBMIT_QUIZ_INTERNET,
	FAIL_VALIDATE_SESSION_INTERNET,
	FILE_FAILED,
	FILE_LOADED,
	RECEIVE_BOOKMARKS,
	RECEIVE_CONTENT,
	RECEIVE_MODULES_INFORMATION,
	RECEIVE_PROGRESSIONS,
	RECEIVE_QUIZ_RESULT,
	RECEIVE_QUIZ_RESULTS,
	RECEIVE_SESSION_CONTENT,
	RECEIVE_SUBMIT_QUIZ_INTERNET,
	RECEIVE_VALIDATE_SESSION_INTERNET,
	RECEIVE_VALIDATE_SESSION_SMS,
	REINIT_CONTENT_STATES,
	REMOVE_BOOKMARK,
	REMOVE_SESSION_FROM_SPOOL,
	REQUEST_CONTENT,
	REQUEST_QUIZ_RESULTS,
	REQUEST_SESSION_CONTENT,
	REQUEST_SUBMIT_QUIZ_INTERNET,
	REQUEST_VALIDATE_SESSION_INTERNET,
	SESSION_CONTENT_RECEIVED,
	SET_RESPONSE_FILES,
	SET_USER_RESPONSES,
	SPOOL_TERMINATED,
	SUBMIT_BOOKMARKS_SUCCESS,
	TOGGLE_FOLDER
} from '../actions/actionCreators';
import { COMPOUNDED_MULTIPLE_CHOICE_QUESTION, MULTIPLE_CHOICE_QUESTION } from '../../config/constants';
import initialState from '../store/initialState';
import receiveContentHandler, { receiveQuizResultsHandler } from './handler/receiveContentHandler';
import { Action, Content, ModuleModel } from '../../types';

interface Data {
	hashID: string;
	name: string;
	description: string;
	courseHashIDs: any;
}

export default function content(state: Content = { ...initialState.content }, action: Action) {
	switch (action.type) {
		/**
		 * MODULES
		 */
		case RECEIVE_MODULES_INFORMATION: {
			const { modules: modulesData } = action.payload as { modules: ModuleModel[] };
			const modules: Content['modules'] = {};

			modulesData.map((data: Data) => {
				const module = {
					hashID: data.hashID,
					name: data.name,
					description: data.description,
					courses: data.courseHashIDs
				};

				// Store module in list of modules
				return (modules[data.hashID] = module);
			});

			return {
				...state,
				modules
			};
		}

		/**
		 * COURSES
		 */
		case REQUEST_CONTENT: {
			return {
				...state,
				isFetching: true,
				isErrorFetching: false
			};
		}

		case RECEIVE_CONTENT: {
			return receiveContentHandler(state, action);
		}

		case FAIL_GET_CONTENT: {
			console.error(action);
			alert(action.payload.message);

			return {
				...state,
				isFetching: false,
				isErrorFetching: true
			};
		}

		case REQUEST_QUIZ_RESULTS: {
			return {
				...state,
				isQuizResultFetching: true,
				isQuizResultErrorFetching: false
			};
		}

		case RECEIVE_QUIZ_RESULTS: {
			return {
				...state,
				quizResults: receiveQuizResultsHandler(action.payload),
				isQuizResultFetching: false,
				isQuizResultErrorFetching: false
			};
		}

		case FAIL_GET_QUIZ_RESULTS: {
			console.error(action);
			alert(action.payload.message);

			return {
				...state,
				isQuizResultFetching: false,
				isQuizResultErrorFetching: true
			};
		}

		/**
		 * FILES
		 */
		case FILE_LOADED: {
			const files = state.spool.files.filter((file) => file !== action.payload.file);

			return {
				...state,
				spool: {
					...state.spool,
					files
				}
			};
		}

		case FILE_FAILED: {
			const file = action.payload.file;

			const errorFiles: Array<any> = [...state.spoolErrors.files];
			const files = state.spool.files.filter((file) => file !== action.payload.file);

			// If the errored file isn't already listed in the list of error files...
			if (!errorFiles.includes(file)) {
				errorFiles.push(file);
			}

			return {
				...state,
				spool: {
					...state.spool,
					files
				},
				spoolErrors: {
					...state.spoolErrors,
					files: errorFiles,
					total: errorFiles.length
				}
			};
		}

		case SPOOL_TERMINATED: {
			// Reset spool total to zero
			return {
				...state,
				spool: {
					...state.spool,
					total: 0
				}
			};
		}

		/**
		 * SESSIONS
		 */

		case REQUEST_SESSION_CONTENT: {
			return {
				...state,
				spool: {
					...state.spool,
					isFetching: true
				}
			};
		}

		case RECEIVE_SESSION_CONTENT: {
			const {
				hashID: sessionHashID,
				contentUpdatedAt: sessionContentUpdatedAt,
				content: sessionContent
			} = action.payload.sessionContent;

			// Set loaded session content
			const loadedSession = {
				...state.sessions[sessionHashID],
				content: sessionContent,
				contentUpdatedAt: sessionContentUpdatedAt,
				spoolComplete: true
			};
			const currentSessions = { ...state.sessions };
			currentSessions[sessionHashID] = loadedSession;

			// Recreate texts spool without loaded session
			const texts = state.spool.texts.filter((hashID) => hashID !== sessionHashID);

			return {
				...state,
				sessions: currentSessions,
				spool: {
					...state.spool,
					texts
				}
			};
		}

		case REMOVE_SESSION_FROM_SPOOL: {
			const sessionIDToRemove = action.payload;

			const oldSpool = state.spool.texts;

			const newTextSpool = oldSpool.filter((sessionHashID) => {
				return sessionHashID != sessionIDToRemove;
			});

			return {
				...state,
				spool: {
					...state.spool,
					texts: newTextSpool
				}
			};
		}

		case FAIL_GET_SESSION_CONTENT:
		case SESSION_CONTENT_RECEIVED:
			return {
				...state,
				spool: {
					...state.spool,
					isFetching: false
				}
			};

		case REQUEST_VALIDATE_SESSION_INTERNET: {
			return {
				...state,
				isSessionValidating: true,
				isSessionFailValidating: false
			};
		}

		case RECEIVE_VALIDATE_SESSION_INTERNET: {
			const validatedSession = {
				...state.sessions[action.payload.sessionHashID],
				progressed: true
			};
			const currentSessions = { ...state.sessions };
			currentSessions[validatedSession.hashID] = validatedSession;

			if (state.sessions[action.payload.sessionHashID] !== undefined) {
				return {
					...state,
					sessions: { ...currentSessions },
					isSessionValidating: false,
					isSessionFailValidating: false
				};
			}

			return state;
		}

		case RECEIVE_VALIDATE_SESSION_SMS: {
			const validatedSession = {
				...state.sessions[action.payload.sessionHashID],
				progressed: true
			};

			const currentSessions = { ...state.sessions };
			currentSessions[validatedSession.hashID] = validatedSession;

			return {
				...state,
				sessions: { ...currentSessions },
				isSessionValidating: false,
				isSessionFailValidating: false,
				isSessionValidated: true
			};
		}

		case FAIL_VALIDATE_SESSION_INTERNET: {
			return {
				...state,
				isSessionFailValidating: true,
				isSessionValidating: false
			};
		}

		case DONE_VALIDATE_SESSION: {
			return {
				...state,
				isSessionValidated: false,
				isSessionValidating: false,
				isSessionFailValidating: false
			};
		}

		case REINIT_CONTENT_STATES: {
			return {
				...state,
				isFetching: false,
				isErrorFetching: false,
				isSessionValidating: false,
				isSessionFailValidating: false,
				isSessionValidated: false
			};
		}

		/**
		 * USER RESPONSES
		 */
		case SET_USER_RESPONSES: {
			const { quizHashID, questionHashID, questionType, response } = action.payload;

			const currentQuizzes = { ...state.quizzes };
			const currentQuiz = currentQuizzes[quizHashID];

			//User Responses stored where Quiz data is not Question
			let currentUserResponses: { [index: string]: any } = currentQuiz.userResponses || {};
			let currentQuestionResponse = currentUserResponses[questionHashID] || [];

			if (questionType === MULTIPLE_CHOICE_QUESTION) {
				currentQuestionResponse = [response];
			} else if (questionType === COMPOUNDED_MULTIPLE_CHOICE_QUESTION) {
				currentQuestionResponse =
					currentQuestionResponse.indexOf(response) > -1
						? [
								...currentQuestionResponse.filter((answerIndex: string | number) => {
									return answerIndex !== response;
								})
						  ]
						: [...currentQuestionResponse, response];
			} else {
				//Short exact responses can be set directly as default
				currentQuestionResponse = response;
			}

			currentUserResponses[questionHashID] = currentQuestionResponse;
			currentQuiz.userResponses = currentUserResponses;
			currentQuizzes[quizHashID] = currentQuiz;

			return {
				...state,
				quizzes: { ...currentQuizzes }
			};
		}

		case SET_RESPONSE_FILES: {
			const { quizHashID, questionHashID, fileData } = action.payload;
			const currentQuizzes = { ...state.quizzes };
			const currentQuiz = currentQuizzes[quizHashID];
			if (!currentQuiz.files) {
				currentQuiz.files = {};
			}
			currentQuiz.files[questionHashID] = fileData;
			currentQuizzes[quizHashID] = currentQuiz;

			return {
				...state,
				quizzes: { ...currentQuizzes }
			};
		}

		/**
		 * QUIZ SUBMISSION
		 */
		case REQUEST_SUBMIT_QUIZ_INTERNET: {
			return {
				...state,
				isQuizSubmitting: true,
				isQuizFailSubmitting: false
			};
		}

		case RECEIVE_SUBMIT_QUIZ_INTERNET: {
			const currentQuizzes = { ...state.quizzes };

			const submittedQuiz = {
				...state.quizzes[action.payload.quizHashID],
				submitted: true
			};

			currentQuizzes[submittedQuiz.hashID] = submittedQuiz;

			if (state.quizzes[action.payload.quizHashID] !== undefined) {
				return {
					...state,
					quizzes: { ...currentQuizzes },
					isQuizSubmitting: false,
					isQuizFailSubmitting: false
				};
			}

			return {
				...state,
				isQuizSubmitting: false,
				isQuizFailSubmitting: false
			};
		}

		case FAIL_SUBMIT_QUIZ_INTERNET: {
			return {
				...state,
				isQuizFailSubmitting: true,
				isQuizSubmitting: false
			};
		}

		case DONE_SUBMIT_QUIZ: {
			return {
				...state,
				isQuizSubmitted: false,
				isQuizSubmitting: false,
				isQuizFailSubmitting: false
			};
		}

		/**
		 * BOOKMARKS
		 */
		case ADD_BOOKMARK:
			return {
				...state,
				bookmarks: {
					...state.bookmarks,
					[action.payload.url]: {
						title: action.payload.title,
						createdAt: action.payload.createdAt
					}
				}
			};

		case REMOVE_BOOKMARK:
			const { [action.payload.url]: _, ...newBookmarks } = state.bookmarks;

			return {
				...state,
				bookmarks: {
					...newBookmarks
				},
				spool: {
					...state.spool,
					removedBookmarks: [...state.spool.removedBookmarks, action.payload.url]
				}
			};

		case RECEIVE_BOOKMARKS: {
			let bookmarks = {
				...state.bookmarks,
				...action.payload.bookmarks
			};

			let removedBookmarks = state.spool.removedBookmarks;

			removedBookmarks.forEach((bookmark) => {
				delete bookmarks[bookmark];
			});

			return {
				...state,
				isFetchingBookmarks: false,
				bookmarks
			};
		}

		case SUBMIT_BOOKMARKS_SUCCESS: {
			return {
				...state,
				spool: {
					...state.spool,
					removedBookmarks: []
				}
			};
		}

		/**
		 * TOGGLED [FOLDERS
		 */
		case TOGGLE_FOLDER: {
			const folderHashID = action.payload;
			const oldToggledFolders = state.toggled.folders;
			const newToggledFolders =
				oldToggledFolders.indexOf(folderHashID) > -1
					? [...oldToggledFolders.filter((hashID) => hashID !== folderHashID)]
					: [...oldToggledFolders, folderHashID];

			return {
				...state,
				toggled: {
					...state.toggled,
					folders: [...newToggledFolders]
				}
			};
		}

		/**
		 * VIEWED
		 */
		case ADD_VIEWED_SESSION: {
			return {
				...state,
				viewed: {
					...state.viewed,
					sessions: [...state.viewed.sessions, action.payload]
				}
			};
		}

		case ADD_VIEWED_FOLDER: {
			return {
				...state,
				viewed: {
					...state.viewed,
					folders: [...state.viewed.folders, action.payload]
				}
			};
		}

		/**
		 * QUIZ_RESULTS
		 */
		case RECEIVE_QUIZ_RESULT:
			const quizResult = action.payload;
			const id = quizResult.quiz ? quizResult.quiz.hashID : `qR${quizResult.id}`;

			return {
				...state,
				quizResults: {
					...state.quizResults,
					[id]: {
						...quizResult
					}
				}
			};

		case RECEIVE_PROGRESSIONS:
			return {
				...state,
				viewed: action.payload
			};

		default:
			return state;
	}
}
