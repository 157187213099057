import { Content, Question, QuizResult } from '../../../types';
import { fileShouldNotDownload } from '../../../services/fileHandling';

export default function receiveContentHandler(state: any, action: any) {
	const {
		courses: coursesData,
		currentDate,
		submittedQuizHashIds,
		progressedSessionHashIds
	} = action.payload;

	const submittedQuizHashIdMap = submittedQuizHashIds.reduce(
		(
			acc: {
				[idx: string]: boolean;
			},
			key: string
		) => {
			acc[key] = true; // or any other value you need
			return acc;
		},
		{}
	);

	const progressedSessionHashIdsMap = progressedSessionHashIds.reduce(
		(
			acc: {
				[idx: string]: boolean;
			},
			key: string
		) => {
			acc[key] = true; // or any other value you need
			return acc;
		},
		{}
	);

	const previousCourses = { ...state.courses };
	const lastUpdatedAt = state.updatedAt;
	const spoolTexts = [...state.spool.texts];
	const removedBookmarks = [...state.spool.removedBookmarks];
	const errorFiles = [...state.spoolErrors.files];
	const defaultFolder = process.env.REACT_APP_DEFAULT_FOLDER;

	const courses: Content['courses'] = {};
	const folders: Content['folders'] = {};
	const sessions: Content['sessions'] = {};
	const quizzes: Content['quizzes'] = {};

	// Respool errors
	const spoolFiles = [...state.spool.files, ...errorFiles];

	// For each course, set new data if there is any
	coursesData.forEach((course: any) => {
		const previousCourse = previousCourses[course.hashID];
		const isNewCourse = previousCourse === undefined;

		const courseQuiz: any = course.quiz
			? submittedQuizHashIdMap[course.quiz.hashID]
				? null
				: { ...course.quiz }
			: null;

		// Set the quiz data
		if (courseQuiz && courseQuiz.hashID) {
			if (courseQuiz.questions && courseQuiz.questions.length > 0) {
				courseQuiz.questions = courseQuiz.questions.map((question: any) => {
					let hint = question.hint === 'null' ? null : question.hint;
					let preamble = question.preamble === 'null' ? null : question.preamble;

					return {
						...question,
						hint,
						preamble
					};
				});
			}

			quizzes[courseQuiz.hashID] = {
				...courseQuiz,
				userResponses: {}
			};

			courseQuiz.questions.forEach((question: Question) => {
				let questionImage = question.image;
				if (questionImage && !spoolFiles.includes(questionImage.url)) {
					spoolFiles.push(questionImage.url);
				}
			});
		}

		const courseImage = course.image;

		// Add image url to spool
		if (
			courseImage &&
			(isNewCourse || !isUpToDate(lastUpdatedAt, courseImage.updatedAt)) &&
			!spoolFiles.includes(courseImage.url)
		) {
			spoolFiles.push(courseImage.url);
		}

		// Set course details in courses array
		courses[course.hashID] = {
			hashID: course.hashID,
			image: courseImage,
			title: course.title,
			teacher: course.teacher,
			description: course.description,
			updatedAt: course.updatedAt,
			quizHashID: courseQuiz ? courseQuiz.hashID : undefined
		};

		// Loop through the course's folders and set the data
		course.folders.forEach((folder: any) => {
			const isDefault = folder.hashID === defaultFolder;
			const folderHashID = isDefault ? `${course.hashID}_${folder.hashID}` : folder.hashID;
			// const previousFolder = previousFolders[folderHashID];
			// const isNewFolder = previousFolder === undefined;

			// Get the previous quiz to keep the user progression
			let folderQuiz = null;

			// Default folders have no quiz
			if (!isDefault) {
				folderQuiz = folder.quiz
					? submittedQuizHashIdMap[folder.quiz.hashID]
						? null
						: { ...folder.quiz }
					: null;

				// Set the quiz data
				if (folderQuiz && folderQuiz.hashID) {
					if (folderQuiz.questions && folderQuiz.questions.length > 0) {
						folderQuiz.questions = folderQuiz.questions.map((question: any) => {
							let hint = question.hint === 'null' ? null : question.hint;
							let preamble = question.preamble === 'null' ? null : question.preamble;

							return {
								...question,
								hint,
								preamble
							};
						});
					}
					quizzes[folderQuiz.hashID] = {
						...folderQuiz,
						userResponses: {}
					};

					folderQuiz.questions.forEach((question: any) => {
						let questionImage = question.image;
						if (questionImage && !spoolFiles.includes(questionImage.url)) {
							spoolFiles.push(questionImage.url);
						}
					});
				}
			}

			// Set folder details in folders array
			folders[folderHashID] = {
				hashID: folderHashID,
				isDefault,
				title: folder.title,
				updatedAt: folder.updatedAt,
				courseHashID: course.hashID,
				quizHashID: folderQuiz ? folderQuiz.hashID : undefined
			};

			// Loop through the folder's sessions, check with previous session data and set new data if any
			folder.sessions.forEach((session: any, index: any) => {
				//Copy previous session content to new session content if it exists

				let sessionQuiz: any = session.quiz
					? submittedQuizHashIdMap[session.quiz.hashID]
						? null
						: { ...session.quiz }
					: null;

				// Set the quiz data
				if (sessionQuiz && sessionQuiz.hashID) {
					if (sessionQuiz.questions && sessionQuiz.questions.length > 0) {
						sessionQuiz.questions = sessionQuiz.questions.map((question: any) => {
							let hint = question.hint === 'null' ? null : question.hint;
							let preamble = question.preamble === 'null' ? null : question.preamble;

							return {
								...question,
								hint,
								preamble
							};
						});
					}
					quizzes[sessionQuiz.hashID] = {
						...sessionQuiz,
						userResponses: {}
					};

					sessionQuiz.questions.forEach((question: Question) => {
						let questionImage = question.image;
						if (questionImage && !spoolFiles.includes(questionImage.url)) {
							spoolFiles.push(questionImage.url);
						}
					});
				}

				// Set session details in session array
				sessions[session.hashID] = {
					hashID: session.hashID,
					index,
					title: session.title,
					contentUpdatedAt: session.contentUpdatedAt,
					progressionLock: session.progressionLock,
					progressed: progressedSessionHashIdsMap[session.hashID] ?? false,
					discussable: session.discussable,
					updatedAt: session.updatedAt,
					unblockCode: session.unblockCode,
					progressCode: session.progressCode,
					files: { ...session.files },
					courseHashID: course.hashID,
					folderHashID,
					content: session.content,
					contentType: session.contentType,
					spoolComplete: session.spoolComplete ?? true,
					quizHashID: sessionQuiz ? sessionQuiz.hashID : undefined
				};

				// Add file urls to spool
				session.files.forEach((file: any) => {
					if (
						(isNewCourse || !isUpToDate(lastUpdatedAt, file.updatedAt)) &&
						!spoolFiles.includes(file.url) &&
						!fileShouldNotDownload(file.url)
					) {
						spoolFiles.push(file.url);
					}
				});
			});
		});
	});

	return {
		...state,
		// Set new updatedAt date provided by the backend
		updatedAt: currentDate,
		isFetching: false,
		isErrorFetching: false,
		courses,
		folders,
		sessions,
		quizzes,
		spool: {
			texts: spoolTexts,
			files: spoolFiles,
			removedBookmarks: removedBookmarks,
			total: spoolTexts.length + spoolFiles.length
		},
		spoolErrors: {
			files: [],
			total: 0
		}
	};
}

// Quiz Results Handler
export const receiveQuizResultsHandler = (quizResultsData: Array<QuizResult>) => {
	const quizResults: { [index: string]: any } = {};

	// Loop through the results and use the quizzes as the ID
	quizResultsData.forEach((result) => {
		const id = result.quiz ? result.quiz.hashID : `qR${result.id}`;

		quizResults[id] = {
			...result
		};
	});

	return quizResults;
};

function isUpToDate(lastUpdatedAt: Date | string | number, contentUpdatedAt: Date | string | number) {
	return lastUpdatedAt !== null && new Date(contentUpdatedAt) < new Date(lastUpdatedAt);
}
