import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import { detect } from 'detect-browser';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '@material-ui/core';
import { Store } from '../../types';
import { WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
	instruction: {
		marginTop: '0.5em',
		marginBottom: '0.5em',
		backgroundColor: theme.palette.background.default,
		padding: '1em 1em',
		borderRadius: '4px'
	},
	image: {
		marginTop: '1em',
		borderRadius: '4px'
	}
});

interface Props extends WithStyles<typeof styles> {}

export const AddToHomescreenInstruction = ({ classes }: Props) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);
	const browser = detect();

	if (browser) {
		// Add To Homescreen instruction for ios and safari
		if (browser.os === 'iOS' && browser.name === 'ios') {
			return (
				<Card className={classes.instruction} data-test-id="iOS_ios">
					<span
						dangerouslySetInnerHTML={{
							__html: I18n.t('onboarding.4.instructions.share', {
								locale
							})
						}}
					/>
					<br />
					<img
						className={classes.image}
						src={require('../../assets/safari.jpg')}
						alt="safari share icon"
					/>
				</Card>
			);
		}

		if (browser.os === 'Android OS') {
			switch (browser.name) {
				case 'chrome':
					return (
						<Card className={classes.instruction} data-test-id="android_chrome">
							<span
								dangerouslySetInnerHTML={{
									__html: I18n.t('onboarding.4.instructions.menu', { locale })
								}}
							/>
							<br />
							<img
								className={classes.image}
								src={require('../../assets/chrome.jpg')}
								alt="chrome menu icon"
							/>
						</Card>
					);
				case 'opera':
					return (
						<Card className={classes.instruction} data-test-id="android_opera">
							<span
								dangerouslySetInnerHTML={{
									__html: I18n.t('onboarding.4.instructions.menu', { locale })
								}}
							/>
							<br />
							<img
								className={classes.image}
								src={require('../../assets/opera.jpg')}
								alt="opera menu icon"
							/>
						</Card>
					);
				default:
					return null;
			}
		}
	}

	return null;
};

export default withStyles(styles)(AddToHomescreenInstruction);
