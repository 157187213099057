import { SET_NETWORK_STATUS } from '../actions/actionCreators';
import initialState from '../store/initialState';
import { Action } from '../../types';

export default function currentUser(state = { ...initialState.network }, action: Action) {
	switch (action.type) {
		case SET_NETWORK_STATUS: {
			return { isOnline: action.payload };
		}

		default:
			return state;
	}
}
