import Typography from '@material-ui/core/Typography/Typography';
import React, { useState } from 'react';
import Feedback from './Feedback';
import MCQ from './MCQ';
import OEQ from './OEQ';
import { Question } from '../../types';

interface Props {
	question: Question;
}

const SelfTestQuiz = (props: Props) => {
	const { question } = props;

	const [feedback, setFeedback] = useState(false);
	const [correct, setCorrect] = useState(false);

	const openFeedback = (correct: boolean) => {
		if (question.feedback) {
			setFeedback(true);
			setCorrect(correct);
		}
	};

	const closeFeedback = () => {
		setFeedback(false);
	};

	const renderQuizType = () => {
		if (/mcq/i.test(question.type)) {
			return (
				<MCQ
					question={question}
					openFeedback={openFeedback}
					correct={correct}
					data-test-id="mcq_question"
				/>
			);
		} else if (/oeq/i.test(question.type)) {
			return <OEQ question={question} openFeedback={openFeedback} data-test-id="oeq_question" />;
		}

		return <div />;
	};

	return (
		<div>
			<Typography variant="h5">{question.title}</Typography>

			{question.subtitle && <Typography variant="subtitle1">{question.subtitle}</Typography>}

			{renderQuizType()}

			<Feedback
				open={feedback}
				close={closeFeedback}
				correct={correct}
				question={question}
				data-test-id="self_test_feedback"
			/>
		</div>
	);
};

export default SelfTestQuiz;
