import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../components/Loader';

const DiscussionScreen = Loadable({
	loader: () => import('./LoadableDiscussionScreen'),
	loading() {
		return <Loader />;
	}
});

export default DiscussionScreen;
