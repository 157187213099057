import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Typography from '@material-ui/core/Typography/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { Locale, Question, QuestionAnswer } from '../../../types';

interface Props {
	locale: Locale;
	isCorrect: QuestionAnswer['correct'] | undefined;
	questionType: Question['type'];
}

const FeedbackTitle = ({ isCorrect, locale }: Props) => {
	return (
		<DialogTitle id="alert-dialog-title" disableTypography={true} data-test-id="alert-dialog-title">
			<Typography
				data-test-id="dialog-title_text"
				variant="h5"
				align="center"
				style={{
					color: typeof isCorrect === 'undefined' ? 'black' : isCorrect ? lightGreen[500] : red[500]
				}}
			>
				{typeof isCorrect === 'undefined'
					? I18n.t('selfTestQuiz.essayResponse', { locale })
					: I18n.t(isCorrect ? 'feedback.good' : 'feedback.bad', {
							locale
					  })}
			</Typography>
		</DialogTitle>
	);
};

export default FeedbackTitle;
