import { init } from '@sentry/react';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import Loader from './components/Loader';
import ErrorBoundary from './components/utils/ErrorBoundary';
import './index.css';
import * as serviceWorker from './serviceWorker';
import persistor from './redux/store/persistor';
import store from './redux/store/store';

// Sentry DSN
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryRelease = process.env.REACT_APP_BITBUCKET_COMMIT;
if (sentryDsn) {
	init({
		dsn: sentryDsn,
		release: sentryRelease
	});
}

// Render the App
ReactDOM.render(
	<ErrorBoundary>
		<Provider store={store}>
			<PersistGate loading={<Loader />} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</ErrorBoundary>,
	document.getElementById('root')
);

serviceWorker.register({
	onUpdate: function() {
		console.log('New app version detected. Reloading...');
		window.location.reload();
	}
});
