import gql from 'graphql-tag';

export default gql`
	query session($hashID: String!) {
		session(hashID: $hashID) {
			hashID
			content
			contentUpdatedAt
		}
	}
`;
