import * as Sentry from '@sentry/browser';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { onError } from '@apollo/client/link/error';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createUploadLink } from 'apollo-upload-client';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { requestForcedUserLogout } from '../actions/actionCreators';
import rootReducer from '../reducers/rootReducer';
import coursesIsFetchingSubscriber from '../../subscriber/CoursesIsFetchingSubscriber';
import coursesSpoolSubscriber from '../../subscriber/CoursesSpoolSubscriber';
import initialState from './initialState';

// Store
const store: any = createStore(
	rootReducer,
	initialState as any,
	composeWithDevTools(applyMiddleware(thunkMiddleware))
);

// Init subscribers
store.subscribe(coursesIsFetchingSubscriber);
store.subscribe(coursesSpoolSubscriber);

const uploadLink = createUploadLink({
	uri: process.env.REACT_APP_API_ENDPOINT
});

// networkInterface need the store
const authMiddleware = new ApolloLink((operation, forward) => {
	const userToken = store.getState().currentUser.token;
	const userTokenIssuedAt = store.getState().currentUser.tokenIssuedAt;
	operation.setContext({
		headers: {
			authorization: userToken ? `Bearer ${userToken + '~' + userTokenIssuedAt}` : null
		}
	});

	return forward(operation);
});

//Afterware to handle errors
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
	if (graphQLErrors) {
		Sentry.configureScope((scope) => {
			graphQLErrors.forEach(({ message, locations, path }) => {
				scope.setExtra('Message:', message);
				scope.setExtra('Location:', JSON.stringify(locations));
				scope.setExtra('Path:', path);
			});
		});

		Sentry.captureMessage('GraphQL Error', graphQLErrors as any);
	}

	if (networkError) {
		if ((networkError as any).statusCode === 402) {
			store.dispatch(requestForcedUserLogout());
		} else {
			Sentry.configureScope((scope) => {
				scope.setExtra('operation', operation.operationName);
				scope.setExtra('variables', operation.variables);
				Sentry.captureException(networkError);
			});
		}
	}
});

//set time out
const timeoutLink = new ApolloLinkTimeout(300000); // 5 min timeout

//@ts-ignore
const link = ApolloLink.from([authMiddleware, errorLink, timeoutLink.concat(uploadLink)]);

export const GraphqlClient = new ApolloClient({
	link,
	cache: new InMemoryCache()
});

if ((module as any).hot) {
	(module as any).hot.accept('../reducers/rootReducer', () => {
		const nextGTR = require('../reducers/rootReducer');
		store.replaceReducer(nextGTR);
	});
}

export default store;
