import { getSessionContent } from '../../redux/actions/actionCreators';
import store from '../../redux/store/store';
import { Session } from '../../types';
import { LOGIN_STATE_LOGGED_OUT } from '../../redux/store/initialState';

export class SpoolContentLoader {
	readonly batchSize: number;

	constructor() {
		this.batchSize = 10;
	}

	handle() {
		const loginState = store.getState().currentUser.loginState;
		const isLoggingOut = store.getState().logout.loggingOut;
		const contentSpool = store.getState().content.spool.texts;
		const sessionHashIDs: Session['hashID'][] =
			contentSpool?.length < this.batchSize
				? contentSpool?.slice(0, contentSpool.length)
				: contentSpool?.slice(0, this.batchSize);

		if (
			sessionHashIDs?.length === 0 ||
			typeof sessionHashIDs === 'undefined' ||
			loginState === LOGIN_STATE_LOGGED_OUT ||
			isLoggingOut
		) {
			return;
		}

		store.dispatch(getSessionContent(sessionHashIDs));
	}
}

export default new SpoolContentLoader();
