import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import InternetIcon from '@material-ui/icons/Language';
import I18n from 'i18n-js';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { doneSubmitQuiz, submitQuiz } from '../redux/actions/actionCreators';
import SubmittedQuiz from '../components/quiz/SubmittedQuiz';
import MySnackbar from '../components/utils/MySnackbar';
import QuizManager from '../services/quiz/QuizManager';
import { QuizNavigation } from '../services/quiz/QuizNavigation';
import store from '../redux/store/store';
import { Content, Locale, Quiz, QuizTypeEnum, Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../services/RouteResolver';
import { ColorFormat, CountdownCircleTimer } from 'react-countdown-circle-timer';
import {
	QUIZ_SUBMISSION_DEFAULT_TIMEOUT_IN_SECONDS,
	QUIZ_SUBMISSION_WITH_FILE_UPLOAD_TIMEOUT_IN_SECONDS
} from '../config/constants';

interface Params extends Pick<ContentParams, 'quizHashID' | 'surveyHashID'> {}

interface Props extends RouteComponentProps<Params> {
	submitted: boolean;
	quiz: Quiz;
	responses: Quiz['userResponses'];
	locale: Locale;
	isSubmitting: Content['isQuizSubmitting'];
	isFailSubmitting: Content['isQuizFailSubmitting'];
	dispatch: Function;
	institutionColor: string;
	files: Quiz['files'];
}

export class SubmitQuizScreen extends Component<
	Props,
	{ isSubmitted: boolean; submissionInProgress: boolean }
> {
	constructor(props: Props) {
		super(props);
		this.state = { isSubmitted: props.submitted ? props.submitted : false, submissionInProgress: false };
	}

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.submitted) {
			this.setState({ isSubmitted: true });
		}
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<{ isSubmitted: boolean }>,
		snapshot?: any
	) {
		if (!prevProps.isSubmitting && this.props.isSubmitting) {
			this.setState({
				submissionInProgress: true
			});
		}
	}

	componentWillUnmount() {
		this.setState({
			submissionInProgress: false
		});
	}

	handleSubmitByInternet = () => {
		const { quiz, responses } = this.props;

		ReactGA.event({
			category: 'Quiz',
			action: 'Submitted via Internet',
			label: 'Quiz: ' + quiz.hashID
		});

		store.dispatch(
			submitQuiz({
				quizHashID: quiz.hashID,
				responses: JSON.stringify(responses)
			})
		);
	};

	handleBackToQuizStart = () => {
		const { history, location, match } = this.props;

		return new QuizNavigation({ history, location, match }).quizStart();
	};

	checkResponses = () => {
		const { responses, quiz } = this.props;
		const questions = quiz.questions;

		return questions && QuizManager.isRequiredQuestionsAnswered(questions, responses);
	};

	checkFileUpload = () => {
		const { files, quiz } = this.props;
		const questions = quiz.questions;

		return questions && QuizManager.requiredFilesUploaded(questions, files ?? {});
	};

	submissionIncludesFiles = () => {
		const { quiz } = this.props;
		return quiz.questions?.some((question) => question.uploadFileAnswer);
	};

	render() {
		const { locale, isSubmitting, isFailSubmitting } = this.props;
		const iconStyle = { marginRight: '0.3em' };

		if (this.state.isSubmitted) {
			return <SubmittedQuiz {...this.props} />;
		}

		if (!this.checkResponses() || !this.checkFileUpload()) {
			return (
				<div className="screen-centered" data-test-id="submit_quiz_screen_check">
					<Typography
						className="submit_quiz_screen__answer_all_text"
						data-test-id="submit_quiz_screen__answer_all_text"
						variant="h5"
						component="h1"
					>
						<span
							dangerouslySetInnerHTML={{
								__html: I18n.t(
									!this.checkResponses() ? 'question.answerAll' : 'question.uploadAll',
									{ locale }
								)
							}}
						/>
					</Typography>

					<Button
						className="submit_quiz_screen__back_to_start_btn"
						data-test-id="submit_quiz_screen__back_to_start_btn"
						variant="contained"
						color="primary"
						onClick={this.handleBackToQuizStart}
						style={{ margin: '2em' }}
					>
						{I18n.t('question.backToStart', { locale })}
					</Button>
				</div>
			);
		}

		return (
			<div data-test-id="submit_quiz_screen_uncheck">
				<div className="screen-centered">
					<div className="content">
						<p>
							{this.props.quiz.type === QuizTypeEnum.survey
								? I18n.t('send.label.survey', { locale })
								: I18n.t('send.label.quiz', { locale })}
						</p>
						<Button
							className="submit_quiz_screen__submit_internet_btn"
							data-test-id="submit_quiz_screen__submit_internet_btn"
							variant="contained"
							color="primary"
							disabled={isSubmitting}
							onClick={this.handleSubmitByInternet}
							style={{ marginRight: '10px', width: '40%' }}
						>
							{this.state.submissionInProgress ? (
								<div style={{ paddingRight: '10px' }}>
									<CountdownCircleTimer
										isPlaying
										duration={
											this.submissionIncludesFiles()
												? QUIZ_SUBMISSION_WITH_FILE_UPLOAD_TIMEOUT_IN_SECONDS
												: QUIZ_SUBMISSION_DEFAULT_TIMEOUT_IN_SECONDS
										}
										size={30}
										strokeWidth={5}
										colors={this.props.institutionColor as ColorFormat}
									/>
								</div>
							) : (
								<InternetIcon style={iconStyle} />
							)}
							{I18n.t('send.sms.progressCode.submit', { locale })}
						</Button>
					</div>
				</div>

				{isFailSubmitting && (
					<MySnackbar
						open
						variant="error"
						message={I18n.t('quiz.submit.error', { locale })}
						onClose={() => {
							this.props.dispatch(doneSubmitQuiz);
						}}
					/>
				)}
			</div>
		);
	}
}

function mapStateToProps(state: Store, props: Props) {
	const {
		content: { sessions, quizzes },
		settings: { locale },
		currentUser: {
			institution: { color }
		}
	} = state;

	const { quizHashID, surveyHashID } = props.match.params;
	const hashID = quizHashID ? quizHashID : surveyHashID;

	const quiz = QuizManager.getQuiz(quizzes, hashID);
	const responses = quiz?.userResponses;
	const files = quiz?.files;

	return {
		institutionColor: color,
		sessions,
		quizzes,
		quiz,
		files,
		locale,
		responses,
		isSubmitting: state.content.isQuizSubmitting,
		isFailSubmitting: state.content.isQuizFailSubmitting,
		submitted: quiz?.submitted
	};
}

export default connect(mapStateToProps)(SubmitQuizScreen);
