import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as routes from '../../config/routes';
import CourseManager from '../../services/CourseManager';
import generateUrl from '../../services/generateUrl';
import RouteResolver from '../../services/RouteResolver';
import { SessionNavigation } from '../../services/session/SessionNavigation';
import PreviousIcon from '../icons/PreviousIcon';
import SidebarIcon from '../icons/SidebarIcon';
import UpIcon from '../icons/UpIcon';
import SearchIcon from '../icons/SearchIcon';
import Bookmark from './Bookmark';
import Discussion from './Discussion';
import Sidebar from './Sidebar';
import { Store } from '../../types';
import SearchModal from '../search/SearchModal';

const styles: Function = makeStyles(() => ({
	appBar: {
		zIndex: 1201
	}
}));

const disabledRoutes: Array<string> = [
	routes.HOME,
	routes.MODULES,
	routes.COURSES,
	routes.QUIZ_REVIEW,
	routes.BOOKMARKS,
	routes.ACCOUNT
];
const upRoutes: Array<string> = [
	routes.MODULE_COURSE_LIST,
	routes.COURSE_INFO,
	routes.MODULE_COURSE_INFO,
	routes.SESSION_DETAIL,
	routes.MODULE_SESSION_DETAIL,
	routes.SESSION_LIST,
	routes.SESSION_LIST_WITHOUT_FOLDER,
	routes.FOLDER_LIST,
	routes.QUIZ_REVIEW_DETAIL
];

const searchRoutes: Array<string> = [
	routes.COURSES,
	routes.MODULES,
	routes.SESSION_LIST_WITHOUT_FOLDER,
	routes.MODULE_COURSE_INFO,
	routes.MODULE_COURSE_LIST,
	routes.COURSE_INFO,
	routes.FOLDER_LIST,
	routes.SESSION_LIST
];

const Header = (props: RouteComponentProps) => {
	const myProps = useSelector((state: Store) => {
		const {
			content: { sessions, folders, quizzes }
		} = state;

		const { locale } = state.settings;
		let discussable: boolean = false;

		const route = RouteResolver.resolve(props.location);

		if (!route) {
			return {};
		}

		// Get the route title
		const title = RouteResolver.resolveTitle(route, locale);

		// Check to see if discussions are enable for this route
		if ([routes.SESSION_DETAIL, routes.MODULE_SESSION_DETAIL].includes(route.path)) {
			const session = CourseManager.getSession(sessions, route.params.sessionHashID);

			if (session) {
				discussable = session.discussable;
			}
		}

		return {
			sessions,
			folders,
			quizzes,
			route,
			title,
			locale,
			discussable
		};
	});

	const [sidebar, setSidebar] = useState(false);
	const [showSearch, setShowSearch] = useState(false);

	const { history } = props;

	const { sessions, folders, route, title, locale, discussable } = myProps;

	const classes = styles();

	/**
	 * Open the Sidebar
	 */
	const handleOpenSidebar = (): void => {
		setSidebar(true);
	};

	/**
	 * Close the Sidebar
	 */
	const handleCloseSidebar = (): void => {
		setSidebar(false);
	};

	/**
	 * Redirect to parent list
	 * @returns {*}
	 */
	const handleRedirectToList = (): void => {
		if (route) {
			const { sessionHashID, moduleHashID } = route.params;

			if ([routes.SESSION_DETAIL, routes.MODULE_SESSION_DETAIL].includes(route.path)) {
				const session = sessions && CourseManager.getSession(sessions, sessionHashID);
				if (session) {
					return history.push(
						generateUrl(moduleHashID ? routes.MODULE_COURSE_INFO : routes.COURSE_INFO, {
							':moduleHashID': moduleHashID,
							':courseHashID': session.courseHashID
						})
					);
				}
			} else if (route.path === routes.SESSION_LIST) {
				if (folders) {
					const courseHashID = folders[route.params.folderHashID].courseHashID;

					const courseFolders = CourseManager.getFoldersFromCourse(folders, courseHashID);

					const totalFolders: number = Object.keys(courseFolders).length;
					const firstFolder = folders[Object.keys(courseFolders)[0]];

					if (!(totalFolders === 1 && firstFolder.isDefault)) {
						return history.push(
							generateUrl(routes.COURSE_INFO, {
								':courseHashID': courseHashID
							})
						);
					}

					return history.push(routes.COURSES);
				}
			} else if (
				[
					routes.COURSE_INFO,
					routes.MODULE_COURSE_INFO,
					routes.FOLDER_LIST,
					routes.SESSION_LIST_WITHOUT_FOLDER
				].includes(route.path)
			) {
				return history.push(
					generateUrl(moduleHashID ? routes.MODULE_COURSE_LIST : routes.COURSES, {
						':moduleHashID': moduleHashID
					})
				);
			} else if (route.path === routes.MODULE_COURSE_LIST) {
				return history.push(routes.MODULES);
			}

			return handleGoBack();
		}
	};

	/**
	 * Go Back
	 */
	const handleGoBack = (): void => {
		history.goBack();
	};

	/**
	 * Toggle Sidebar
	 * @param route
	 */
	const handleSidebarClick = (route: string): void => {
		setSidebar(false);

		ReactGA.event({
			category: 'Sidebar',
			action: 'Clicked Menu Item',
			label: route
		});

		history.push(route);
	};

	const handleDiscussion = (): void => {
		const session = sessions && route && CourseManager.getSession(sessions, route.params.sessionHashID);

		return session && new SessionNavigation({ ...props, session }).discussion();
	};

	/**
	 * Is the route a logged in route
	 * @returns {boolean}
	 */
	const loggedIn = (): boolean => {
		return route?.path !== routes.HOME && true && route?.path !== routes.LOGIN;
	};

	/**
	 * Sidebar Icon
	 * @returns {*}
	 */
	const sidebarIcon = () => {
		if (!loggedIn()) {
			return <div />;
		}

		return (
			<IconButton
				className="joyride-menu-button"
				color="inherit"
				aria-label="Menu"
				onClick={sidebar ? handleCloseSidebar : handleOpenSidebar}
			>
				{sidebar ? <CloseIcon /> : <SidebarIcon />}
			</IconButton>
		);
	};

	const leftIcon = () => {
		if (!loggedIn() || !route || disabledRoutes.includes(route.path)) {
			return null;
		}

		return (
			<IconButton className="joyride-up-button" color="inherit" onClick={handleRedirectToList}>
				{upRoutes.includes(route.path) ? <UpIcon /> : <PreviousIcon />}
			</IconButton>
		);
	};

	/**
	 * Sidebar component
	 * @returns {*}
	 */
	const showSidebar = (): JSX.Element => {
		if (!loggedIn()) {
			return <div />;
		}

		return <Sidebar open={sidebar} handleClick={handleSidebarClick} />;
	};

	const showSearchButton = (): JSX.Element => {
		if (route && !searchRoutes.includes(route.path)) {
			return <div />;
		}

		return (
			<IconButton
				className="joyride-search-button"
				color="inherit"
				onClick={() => {
					setShowSearch(true);
				}}
			>
				<SearchIcon />
			</IconButton>
		);
	};

	if (!route || route.path === routes.WELCOME) {
		return <div />;
	}

	return (
		<React.Fragment>
			<AppBar data-test-id="header" position="fixed" className={classes.appBar}>
				<Toolbar>
					{sidebarIcon()}

					{leftIcon()}

					<Typography variant="subtitle1" color="inherit" style={{ flex: 1 }} noWrap>
						{title}
					</Typography>

					{/*user  has to be logged in, title and locale present to access bookmark*/}
					{loggedIn() && title && locale && (
						<Bookmark route={route} title={title} locale={locale} />
					)}

					{showSearchButton()}

					{discussable && <Discussion handleDiscussion={handleDiscussion} />}
				</Toolbar>
			</AppBar>

			{showSidebar()}
			{showSearch && (
				<SearchModal open={showSearch} handleModalClose={() => setShowSearch(false)} {...props} />
			)}
		</React.Fragment>
	);
};

export default withRouter(Header);
