import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

class ScrollToTop extends Component<RouteComponentProps> {
	componentDidUpdate(prevProps: Readonly<RouteComponentProps>): void {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render(): null {
		return null;
	}
}

export default withRouter(ScrollToTop);
