import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import DeleteIcon from '@material-ui/icons/Close';
import distanceInWordsToNow from 'date-fns/formatDistanceToNow';
import I18n from 'i18n-js';
import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { removeBookmark } from '../redux/actions/actionCreators';
import BookmarksIcon from '../components/icons/BookmarksIcon';
import { Store } from '../types';
import { WithStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { getBookmarkLocale } from '../services/locale/getBookmarkLocale';

const styles = (theme: Theme) => ({
	root: {
		padding: '1em',
		backgroundColor: 'white',
		height: '100%',
		position: 'fixed',
		textAlign: 'center',
		zIndex: -1
	} as const,
	icon: {
		color: theme.palette.background.default,
		fontSize: '60vmin'
	}
});

interface Props extends WithStyles<typeof styles>, Pick<RouteComponentProps, 'history'> {}

const BookmarksScreen = (props: Props) => {
	const myProps = useSelector(({ content: { bookmarks }, settings: { locale } }: Store) => ({
		bookmarks,
		locale
	}));

	const dispatch = useDispatch();

	const { history, classes } = props;
	const { bookmarks, locale } = myProps;
	const remove = (url: string) => {
		return dispatch(removeBookmark(url));
	};

	const visitBookmark = (url: string) => {
		ReactGA.event({
			category: 'Bookmark',
			action: 'Visit Bookmark',
			label: url
		});
		history.push(url);
	};

	/**
	 * No bookmarks available
	 */
	if (!bookmarks || Object.keys(bookmarks).length === 0) {
		return (
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				data-test-id="bookmarks-screen_empty-screen"
				className={classes.root}
			>
				<Grid item xs={12} className="screen-centered">
					<div style={{ margin: 0 }}>
						<BookmarksIcon className={classes.icon} />
					</div>
					<Typography variant="subtitle1" color="textSecondary" style={{ margin: '0.3em 0' }}>
						{I18n.t('bookmarks.none', { locale })}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	/**
	 * List all bookmarks
	 */
	return (
		<div className="content-layout">
			<List className="joyride-bookmarks-items">
				{Object.keys(bookmarks).map((url) => {
					const createdAt = bookmarks[url].createdAt;
					return (
						<ListItem
							className="joyride-bookmarks-item"
							data-test-id="bookmarks-screen_bookmark-list-item"
							key={url}
							button
							onClick={() => visitBookmark(url)}
						>
							<ListItemText
								primary={bookmarks[url].title}
								secondary={distanceInWordsToNow(
									typeof createdAt === 'string' ? parseISO(createdAt) : createdAt,
									{
										locale: getBookmarkLocale(locale)
									}
								)}
							/>
							<ListItemSecondaryAction>
								<IconButton onClick={() => remove(url)}>
									<DeleteIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					);
				})}
			</List>
		</div>
	);
};

export default withStyles(styles)(BookmarksScreen);
