import grey from '@material-ui/core/colors/grey';
import Paper from '@material-ui/core/Paper';
import { Theme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Reorder from 'react-reorder';
import { Question } from '../../../types';
import { QuestionTypeProps } from '../QuestionAnswers';
import { WithStyles } from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) => ({
	dragDropItem: {
		padding: '15px 20px',
		marginBottom: '5px'
	},
	disabledDragDropItem: {
		padding: '15px 20px',
		marginBottom: '5px',
		boxShadow: 'none',
		border: `1px solid ${grey[400]}`
	},
	draggedItem: {
		border: `2px solid ${theme.palette.primary.main}`
	},
	placeholderItem: {
		visibility: 'hidden'
	} as const,
	disabledText: {
		color: grey[400]
	}
});

interface Props extends Omit<QuestionTypeProps, 'question' | 'userResponse'>, WithStyles<typeof styles> {
	reorderId: string;
	answers: Question['answers'];
	handleOnReorder: (event: any, previousIndex: number, nextIndex: number) => void;
}

const SequenceQuestionAnswers = ({ reorderId, answers, classes, handleOnReorder, isReadOnly }: Props) => (
	<div style={{ marginTop: '10px' }}>
		<Reorder
			reorderId={reorderId}
			data-test-id="question-answers_sequence-question"
			placeholderClassName={classes.placeholderItem}
			draggedClassName={classes.draggedItem}
			lock="horizontal"
			onReorder={handleOnReorder}
			disabled={isReadOnly}
		>
			{answers.map((answer, index) => {
				return (
					<Paper
						key={index}
						className={isReadOnly ? classes.disabledDragDropItem : classes.dragDropItem}
						data-test-id="sequence_question_answers"
					>
						<Typography component="p" className={isReadOnly ? classes.disabledText : ''}>
							{answer}
						</Typography>
					</Paper>
				);
			})}
		</Reorder>
	</div>
);
export default withStyles(styles)(SequenceQuestionAnswers);
