import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import SquareTile from './SquareTile';
import { useSelector } from 'react-redux';
import { selectInstitutionColor } from '../redux/selectors/currentUser';

interface Props {
	handleFileChange: React.ChangeEventHandler;
	id: string | number;
}

const UploadButton = ({ id, handleFileChange }: Props) => {
	const institutionColor = useSelector(selectInstitutionColor);
	return (
		<>
			<input
				style={{ display: 'none' }}
				id={`response-files_${id}`}
				type="file"
				multiple
				onChange={handleFileChange}
			/>
			<label htmlFor={`response-files_${id}`}>
				<SquareTile
					backgroundColor={institutionColor}
					onTap={() => document.getElementById(`response-files_${id}`)?.click()}
					icon={<PublishIcon style={{ color: 'white' }} />}
				/>
			</label>
		</>
	);
};

export default UploadButton;
