import Grid from '@material-ui/core/Grid/Grid';
import List from '@material-ui/core/List/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import QuizReviewIcon from '../components/icons/QuizReviewIcon';
import QuizReviewListItem from '../components/quiz/quizReview/QuizReviewListItem';
import CheckForUpdatesButton from '../components/updates/CheckForUpdatesButton';
import { QUIZ_REVIEW_DETAIL } from '../config/routes';
import generateUrl from '../services/generateUrl';
import { Locale, Quiz, Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import WarningIcon from '../components/icons/WarningIcon';
import Loader from '../components/Loader';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '1em',
		backgroundColor: 'white',
		height: '100%',
		position: 'absolute',
		top: '0px',
		textAlign: 'center'
	},
	icon: {
		color: theme.palette.background.default,
		fontSize: '60vmin'
	}
}));

interface Props extends Pick<RouteComponentProps, 'history'> {
	locale: Locale;
}

const QuizReviewScreen = (props: Props) => {
	const classes = useStyles();

	const quizResultsAreFetching = useSelector(
		({ content: { isQuizResultFetching } }: Store) => isQuizResultFetching
	);
	const errorFetchingQuizResults = useSelector(
		({ content: { isQuizResultErrorFetching } }: Store) => isQuizResultErrorFetching
	);
	const quizResults = useSelector(({ content: { quizResults } }: Store) => quizResults);

	const handleNext = (quizHashID: Quiz['hashID']) => {
		let url = generateUrl(QUIZ_REVIEW_DETAIL, {
			':quizHashID': quizHashID
		});

		props.history.push(url);
	};

	const { locale } = props;

	if (quizResultsAreFetching) {
		return (
			<Grid
				data-test-id="quiz-review-screen_empty-state"
				container
				direction="column"
				alignItems="center"
				className={classes.root}
			>
				<Grid item className="screen-centered">
					<div style={{ margin: 0 }}>
						<Loader />
					</div>
					<Typography
						variant="subtitle1"
						color="textSecondary"
						style={{ margin: '0.3em 0' }}
						dangerouslySetInnerHTML={{
							__html: I18n.t('quizReview.resultsIsFetching', { locale })
						}}
					/>
				</Grid>
			</Grid>
		);
	}

	if (errorFetchingQuizResults) {
		return (
			<Grid
				data-test-id="quiz-review-screen_empty-state"
				container
				direction="column"
				alignItems="center"
				className={classes.root}
			>
				<Grid item className="screen-centered">
					<div style={{ margin: 0 }}>
						<WarningIcon className={classes.icon} />
					</div>
					<Typography
						variant="subtitle1"
						color="textSecondary"
						style={{ margin: '0.3em 0' }}
						dangerouslySetInnerHTML={{
							__html: I18n.t('quizReview.errorFetchingResults', { locale })
						}}
					/>

					<CheckForUpdatesButton data-test-id="course-screen_check-for-updates-button" />
				</Grid>
			</Grid>
		);
	}

	if (quizResults && Object.keys(quizResults).length > 0) {
		return (
			<div className="content-layout" style={{ minHeight: '100vh' }}>
				<List data-test-id="quiz-review-screen_quiz-review-list">
					{Object.keys(quizResults).map((key) => {
						const quizResult = quizResults[key];

						return (
							<QuizReviewListItem
								data-test-id="quiz-review-screen_quiz-review-list-item"
								key={key}
								quizResult={quizResult}
								handleNext={handleNext}
							/>
						);
					})}
				</List>
			</div>
		);
	}

	return (
		<Grid
			data-test-id="quiz-review-screen_empty-state"
			container
			direction="column"
			alignItems="center"
			className={classes.root}
		>
			<Grid item className="screen-centered">
				<div style={{ margin: 0 }}>
					<QuizReviewIcon className={classes.icon} />
				</div>

				<Typography
					variant="subtitle1"
					color="textSecondary"
					style={{ margin: '0.3em 0' }}
					dangerouslySetInnerHTML={{
						__html: I18n.t('quizReview.none', { locale })
					}}
				/>

				<CheckForUpdatesButton data-test-id="course-screen_check-for-updates-button" />
			</Grid>
		</Grid>
	);
};

export default QuizReviewScreen;
