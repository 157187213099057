import { spoolTerminated } from '../redux/actions/actionCreators';
import spoolContentLoader from '../services/updates/spoolContentLoader';
import spoolFileLoader from '../services/updates/spoolFileLoader';
import store from '../redux/store/store';

let currentSpoolSessionFilesCount = 0;
let currentSpoolSessionTextCount = 0;

export default function CoursesSpoolSubscriber() {
	const previousSpoolSessionFilesCount = currentSpoolSessionFilesCount;
	const previousSpoolSessionTextCount = currentSpoolSessionTextCount;

	const spool = store.getState().content.spool;
	const sessionIsFetching = store.getState().content.spool.isFetching;
	currentSpoolSessionTextCount = spool.texts.length;
	currentSpoolSessionFilesCount = spool.files.length;
	if (
		previousSpoolSessionFilesCount + previousSpoolSessionTextCount > 0 &&
		currentSpoolSessionFilesCount + currentSpoolSessionTextCount === 0
	) {
		store.dispatch(spoolTerminated());
		return;
	}

	if (
		currentSpoolSessionFilesCount !== previousSpoolSessionFilesCount &&
		currentSpoolSessionFilesCount > 0
	) {
		spoolFileLoader.handle();
	}

	if (currentSpoolSessionTextCount > 0 && !sessionIsFetching) {
		spoolContentLoader.handle();
	}
}
