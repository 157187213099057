import React from 'react';
import AboutPanel from '../components/account/AboutPanel';
import SupportPanel from '../components/account/SupportPanel';

export const AboutScreen = () => (
	<div>
		<AboutPanel />
		<SupportPanel />
	</div>
);

export default AboutScreen;
