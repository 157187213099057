import React, { Component } from 'react';
import { reorder } from 'react-reorder';
import {
	COMPOUNDED_MULTIPLE_CHOICE_QUESTION,
	ESSAY_QUESTION,
	MULTIPLE_CHOICE_QUESTION,
	NUMERICAL_RANGE_QUESTION,
	RATING_SCALE_QUESTION,
	SEQUENCE_QUESTION,
	SHORT_EXACT_QUESTION
} from '../../config/constants';
import { shuffleAnswers } from '../../services/quiz/Sequence';
import CompoundedMultipleChoiceAnswers from './QuestionAnswers/CompoundedMultipleChoiceAnswers';
import EssayAnswers from './QuestionAnswers/EssayAnswers';
import MultipleChoiceAnswers from './QuestionAnswers/MultipleChoiceAnswers';
import NumericalRangeAnswers from './QuestionAnswers/NumericalRangeAnswers';
import RatingScaleAnswers from './QuestionAnswers/RatingScaleAnswers';
import SequenceQuestionAnswers from './QuestionAnswers/SequenceQuestionAnswers';
import ShortExactAnswers from './QuestionAnswers/ShortExactAnswers';
import { Locale, Question, QuestionAnswer, QuestionResponse, UserResponse } from '../../types';

export interface QuestionTypeProps {
	isReadOnly?: boolean;
	handleOnChange?: (response: UserResponse) => void;
	question: Question;
	userResponse: any;
	locale?: Locale;
	value?: any;
}

interface Props extends Pick<QuestionTypeProps, 'question' | 'userResponse' | 'locale' | 'isReadOnly'> {
	handleResponseChange?: QuestionTypeProps['handleOnChange'];
}

interface State {
	answers: QuestionAnswer[];
	input: UserResponse;
}

class QuestionAnswers extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			input: props.userResponse,
			answers: []
		};
	}

	componentDidMount() {
		let {
			question: { answers, type },
			userResponse
		} = this.props;

		// Set answers to user response if user has already responded to the sequence question
		if (answers) {
			this.setAnswers(answers, type, userResponse);
		}
	}

	setAnswers = (
		answers: QuestionAnswer[],
		type: string,
		userResponse: QuestionResponse['text'] | QuestionResponse['text'][]
	) => {
		if (type === SEQUENCE_QUESTION) {
			if (userResponse) {
				answers = JSON.parse(userResponse as string);
			} else {
				answers = shuffleAnswers(JSON.parse(answers[0].title));
			}
		}

		this.setState({
			answers
		});
	};

	componentWillReceiveProps(nextProps: Props) {
		if (this.props !== nextProps) {
			this.setState({
				input: nextProps.userResponse
			});

			let {
				question: { answers, type },
				userResponse
			} = nextProps;

			if (answers) {
				this.setAnswers(answers, type, userResponse);
			}
		}
	}

	handleOnChange = (value: string) => {
		this.setState({
			input: value
		});
	};

	handleOnSliderChange = (value: string) => {
		this.props.handleResponseChange && this.props.handleResponseChange(value);
	};

	handleOnBlur = () => {
		this.props.handleResponseChange && this.props.handleResponseChange(this.state.input);
	};

	onReorder = (event: any, previousIndex: number, nextIndex: number) => {
		this.setState(
			{
				answers: reorder(this.state.answers, previousIndex, nextIndex)
			},
			() => {
				this.props.handleResponseChange &&
					this.props.handleResponseChange(JSON.stringify(this.state.answers));
			}
		);
	};

	render() {
		const { question, locale, userResponse, isReadOnly, handleResponseChange } = this.props;

		switch (question.type) {
			case MULTIPLE_CHOICE_QUESTION: {
				return (
					<MultipleChoiceAnswers
						isReadOnly={isReadOnly}
						question={question}
						locale={locale}
						userResponse={userResponse || []}
						handleOnChange={handleResponseChange}
						data-test-id="question-answers_multiple-choice-answers"
					/>
				);
			}
			case COMPOUNDED_MULTIPLE_CHOICE_QUESTION: {
				return (
					<CompoundedMultipleChoiceAnswers
						isReadOnly={isReadOnly}
						question={question}
						locale={locale}
						userResponse={userResponse || []}
						handleOnChange={handleResponseChange}
						data-test-id="question-answers_compounded-multiple-choice-answers"
					/>
				);
			}
			case SHORT_EXACT_QUESTION: {
				return (
					<ShortExactAnswers
						isReadOnly={isReadOnly}
						value={(this.state.input as string) || ''}
						handleOnChange={this.handleOnChange}
						handleOnBlur={this.handleOnBlur}
						locale={locale}
						data-test-id="question-answers_short-exact-question-text-field"
					/>
				);
			}
			case NUMERICAL_RANGE_QUESTION: {
				return (
					<NumericalRangeAnswers
						isReadOnly={isReadOnly}
						value={(this.state.input as string) || ''}
						handleOnChange={this.handleOnChange}
						handleOnBlur={this.handleOnBlur}
						locale={locale}
						data-test-id="question-answers_numerical-range-text-field"
					/>
				);
			}
			case ESSAY_QUESTION: {
				return (
					<EssayAnswers
						isReadOnly={isReadOnly}
						value={(this.state.input as string) || ''}
						handleOnChange={this.handleOnChange}
						handleOnBlur={this.handleOnBlur}
						locale={locale}
						data-test-id="question-answers_essay-question-text-field"
					/>
				);
			}
			case SEQUENCE_QUESTION: {
				let { answers } = this.state;

				return (
					<SequenceQuestionAnswers
						isReadOnly={isReadOnly}
						handleOnReorder={this.onReorder.bind(this)}
						answers={answers}
						locale={locale}
						data-test-id="question-answers_sequence-question"
						reorderId={question.hashID}
					/>
				);
			}
			case RATING_SCALE_QUESTION: {
				const ratingScaleParams = JSON.parse(question.answers[0].title);

				return (
					<RatingScaleAnswers
						isReadOnly={isReadOnly}
						value={userResponse || ratingScaleParams.min}
						handleOnChange={this.handleOnSliderChange}
						answers={ratingScaleParams}
						locale={locale}
						data-test-id="question-answers_rating-scale-question"
					/>
				);
			}
			default:
				return <div data-test-id="question-answers_empty-state" />;
		}
	}
}

export default QuestionAnswers;
