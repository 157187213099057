import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { SearchResult, Store } from '../../types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '../icons/SearchIcon';
import I18n from 'i18n-js';
import { useSelector } from 'react-redux';

interface Props {
	searchResults: SearchResult[] | null;
	isFilled: boolean;
	routeToResult: (url: string) => void;
	isSearching: boolean;
}

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 130,
		opacity: 0.5
	},
	resultsWrapper: {
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	result: {
		borderBottom: '1px solid rgb(0 0 0 / 12%);'
	},
	icon: {
		fontSize: 70
	}
}));

const noSearchResult = (
	text: string,
	classes: Record<'root' | 'icon' | 'resultsWrapper' | 'result', string>
) => (
	<Grid direction="column" alignItems="center" justify="center" className={classes.root} container>
		<Grid item xs={12}>
			<SearchIcon className={classes.icon} />
		</Grid>
		<Grid item xs={12}>
			<Typography>{text}</Typography>
		</Grid>
	</Grid>
);

const SearchResultsList = (props: Props) => {
	const { searchResults, isFilled, routeToResult, isSearching } = props;

	const classes = useStyles();

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const searchResultIcon = (searchResult: SearchResult) => {
		return searchResult.icon != null ? (
			<ListItemAvatar data-test-id="search-results-list_search-results-list-item_avatar">
				<Avatar>{searchResult.icon}</Avatar>
			</ListItemAvatar>
		) : (
			<div />
		);
	};

	const showSearchResults = () => {
		if (isFilled && searchResults && searchResults.length === 0) {
			return noSearchResult(I18n.t('custom.noSearchResults', { locale }), classes);
		}
		if (isFilled && isSearching) {
			return (
				<Grid container alignItems="center" justify="center">
					<CircularProgress />
				</Grid>
			);
		}
		if (searchResults && isFilled) {
			return searchResults.map((searchResult: SearchResult) => (
				<ListItem
					key={searchResult.hashID}
					data-test-id="search-results-list_search-results-list-item"
					className={classes.result}
					button
					onClick={() => routeToResult(searchResult.url)}
				>
					{searchResultIcon(searchResult)}

					<ListItemText
						data-test-id="search-results-list_search-results-list-item_title"
						primary={searchResult.title}
					/>
				</ListItem>
			));
		} else {
			return noSearchResult(I18n.t('custom.searchPlaceholder', { locale }), classes);
		}
	};

	return <List className={classes.resultsWrapper}>{showSearchResults()}</List>;
};

export default memo(SearchResultsList);
