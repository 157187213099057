import IconButton from '@material-ui/core/IconButton/IconButton';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBookmark, sendEventToAnalyticsEngine, removeBookmark } from '../../redux/actions/actionCreators';
import * as routes from '../../config/routes';
import AddBookmarkIcon from '../icons/AddBookmarkIcon';
import RemoveBookmarkIcon from '../icons/RemoveBookmarkIcon';
import MySnackbar from '../utils/MySnackbar';
import { Bookmark as BookmarkType, Locale, Store } from '../../types';
import { ActivityLabel } from '../../services/analytics/userActivityClient';

const bookmarkablePaths = [
	routes.MODULE_COURSE_LIST,
	routes.COURSE_INFO,
	routes.MODULE_COURSE_INFO,
	routes.FOLDER_LIST,
	routes.SESSION_LIST,
	routes.SESSION_LIST_WITHOUT_FOLDER,
	routes.SESSION_DETAIL,
	routes.MODULE_SESSION_DETAIL,
	routes.COURSE_QUIZ,
	routes.MODULE_COURSE_QUIZ,
	routes.FOLDER_QUIZ,
	routes.MODULE_FOLDER_QUIZ,
	routes.SESSION_QUIZ,
	routes.MODULE_SESSION_QUIZ,
	routes.COURSE_QUIZ_QUESTION,
	routes.MODULE_COURSE_QUIZ_QUESTION,
	routes.FOLDER_QUIZ_QUESTION,
	routes.MODULE_FOLDER_QUIZ_QUESTION,
	routes.SESSION_QUIZ_QUESTION,
	routes.MODULE_SESSION_QUIZ_QUESTION,
	routes.SESSION_DISCUSSION,
	routes.MODULE_SESSION_DISCUSSION
];

type Route = {
	url: string;
	path: string;
};

interface Props {
	route: Route;
	title: BookmarkType['title'];
	locale: Locale;
}

const Bookmark = (props: Props) => {
	const [bookmarked, setBookmarked] = useState<null | boolean>(null);
	const dispatch = useDispatch();

	const {
		route: { url, path },
		title,
		locale
	} = props;

	const { isBookmarked, token } = useSelector(
		({ currentUser: { token }, content: { bookmarks } }: Store) => {
			let isBookmarked = false;

			if (bookmarks && bookmarks[url]) {
				isBookmarked = true;
			}

			return { isBookmarked, token };
		}
	);

	/**
	 * Toggle Bookmark
	 */
	const handleBookmark = () => {
		if (isBookmarked) {
			setBookmarked(false);
			return dispatch(removeBookmark(url));
		}

		setBookmarked(true);

		dispatch(
			sendEventToAnalyticsEngine({
				label: ActivityLabel.BOOKMARKED_PAGE,
				description: `${token} bookmarked page title: ${title} url: ${url}`
			})
		);
		return dispatch(addBookmark({ url, title }));
	};

	/**
	 * Show bookmark icons
	 * @returns {*}
	 */
	const showIcon = () => {
		if (!bookmarkablePaths.includes(path)) {
			return null;
		}

		return (
			<IconButton
				className="joyride-bookmark-button"
				color="inherit"
				onClick={handleBookmark}
				data-test-id="joyride-bookmark-button"
			>
				{isBookmarked ? <RemoveBookmarkIcon /> : <AddBookmarkIcon />}
			</IconButton>
		);
	};

	return (
		<React.Fragment>
			{showIcon()}

			<MySnackbar
				open={bookmarked === true}
				message={I18n.t('bookmarks.added', { locale })}
				variant="success"
				onClose={() => {
					setBookmarked(null);
				}}
				data-test-id="bookmark_added_snackbar"
			/>
			<MySnackbar
				open={bookmarked === false}
				message={I18n.t('bookmarks.removed', { locale })}
				variant="error"
				onClose={() => setBookmarked(null)}
				data-test-id="bookmark_removed_snackbar"
			/>
		</React.Fragment>
	);
};

export default Bookmark;
