export const HOME = '/';
export const LOGIN = '/login/:token';
export const REGISTER = '/register';
export const WELCOME = '/welcome';
export const MODULES = '/modules';
export const COURSES = '/courses';
export const BOOKMARKS = '/bookmarks';
export const QUIZ_REVIEW = '/quiz-review';
export const HELP = '/help';
export const ABOUT = '/about';
export const ACCOUNT = '/account';

/**
 * Course Routes
 * @type {string}
 */
export const COURSE_INFO = '/courses/:courseHashID';
export const FOLDER_LIST = '/courses/:courseHashID/folders';
export const SESSION_LIST = '/courses/:courseHashID/folders/:folderHashID/sessions';
export const SESSION_LIST_WITHOUT_FOLDER = '/courses/:courseHashID/sessions';
export const SESSION_DETAIL = '/courses/:courseHashID/sessions/:sessionHashID';
export const SESSION_SEND = SESSION_DETAIL + '/send';
export const SESSION_VALIDATE_SMS = '/session/validate/sms/:unblockCode';
export const SESSION_SEND_SMS = SESSION_DETAIL + '/sms/send';

export const COURSE_QUIZ = '/courses/:courseHashID/quiz/:quizHashID';
export const FOLDER_QUIZ = '/courses/:courseHashID/folders/:folderHashID/quiz/:quizHashID';
export const SESSION_QUIZ = SESSION_DETAIL + '/quiz/:quizHashID';
export const COURSE_QUIZ_QUESTION = COURSE_QUIZ + '/question/:questionIndex';
export const FOLDER_QUIZ_QUESTION = FOLDER_QUIZ + '/question/:questionIndex';
export const SESSION_QUIZ_QUESTION = SESSION_QUIZ + '/question/:questionIndex';
export const COURSE_QUIZ_SUBMIT = COURSE_QUIZ + '/submit';
export const FOLDER_QUIZ_SUBMIT = FOLDER_QUIZ + '/submit';
export const SESSION_QUIZ_SUBMIT = SESSION_QUIZ + '/submit';

export const COURSE_SURVEY = '/courses/:courseHashID/survey/:surveyHashID';
export const FOLDER_SURVEY = '/courses/:courseHashID/folders/:folderHashID/survey/:surveyHashID';
export const SESSION_SURVEY = SESSION_DETAIL + '/survey/:surveyHashID';

export const COURSE_SURVEY_QUESTIONS = COURSE_SURVEY + '/questions';
export const FOLDER_SURVEY_QUESTIONS = FOLDER_SURVEY + '/questions';
export const SESSION_SURVEY_QUESTIONS = SESSION_SURVEY + '/questions';

export const COURSE_SURVEY_SUBMIT = COURSE_SURVEY + '/submit';
export const FOLDER_SURVEY_SUBMIT = FOLDER_SURVEY + '/submit';
export const SESSION_SURVEY_SUBMIT = SESSION_SURVEY + '/submit';

export const SESSION_DISCUSSION = SESSION_DETAIL + '/discussion';

export const QUIZ_REVIEW_DETAIL = '/quiz-review/:quizHashID';

/**
 * Generate Module routes
 */
const generateModuleRoute = (route: string) => {
	return `${MODULE_COURSE_LIST}${route}`;
};

/**
 * Module Routes
 * @type {string}
 */
export const MODULE_COURSE_LIST = '/modules/:moduleHashID';
export const MODULE_COURSE_INFO = generateModuleRoute(COURSE_INFO);
export const MODULE_SESSION_DETAIL = generateModuleRoute(SESSION_DETAIL);
export const MODULE_SESSION_SEND = generateModuleRoute(SESSION_SEND);
export const MODULE_SESSION_VALIDATE_SMS = generateModuleRoute(SESSION_VALIDATE_SMS);
export const MODULE_SESSION_SEND_SMS = generateModuleRoute(SESSION_SEND_SMS);

export const MODULE_COURSE_QUIZ = generateModuleRoute(COURSE_QUIZ);
export const MODULE_FOLDER_QUIZ = generateModuleRoute(FOLDER_QUIZ);
export const MODULE_SESSION_QUIZ = generateModuleRoute(SESSION_QUIZ);
export const MODULE_COURSE_QUIZ_QUESTION = generateModuleRoute(COURSE_QUIZ_QUESTION);
export const MODULE_FOLDER_QUIZ_QUESTION = generateModuleRoute(FOLDER_QUIZ_QUESTION);
export const MODULE_SESSION_QUIZ_QUESTION = generateModuleRoute(SESSION_QUIZ_QUESTION);
export const MODULE_COURSE_QUIZ_SUBMIT = generateModuleRoute(COURSE_QUIZ_SUBMIT);
export const MODULE_FOLDER_QUIZ_SUBMIT = generateModuleRoute(FOLDER_QUIZ_SUBMIT);
export const MODULE_SESSION_QUIZ_SUBMIT = generateModuleRoute(SESSION_QUIZ_SUBMIT);

export const MODULE_COURSE_SURVEY = generateModuleRoute(COURSE_SURVEY);
export const MODULE_FOLDER_SURVEY = generateModuleRoute(FOLDER_SURVEY);
export const MODULE_SESSION_SURVEY = generateModuleRoute(SESSION_SURVEY);

export const MODULE_COURSE_SURVEY_QUESTIONS = generateModuleRoute(COURSE_SURVEY_QUESTIONS);
export const MODULE_FOLDER_SURVEY_QUESTIONS = generateModuleRoute(FOLDER_SURVEY_QUESTIONS);
export const MODULE_SESSION_SURVEY_QUESTIONS = generateModuleRoute(SESSION_SURVEY_QUESTIONS);

export const MODULE_COURSE_SURVEY_SUBMIT = generateModuleRoute(COURSE_SURVEY_SUBMIT);
export const MODULE_FOLDER_SURVEY_SUBMIT = generateModuleRoute(FOLDER_SURVEY_SUBMIT);
export const MODULE_SESSION_SURVEY_SUBMIT = generateModuleRoute(SESSION_SURVEY_SUBMIT);

export const MODULE_SESSION_DISCUSSION = generateModuleRoute(SESSION_DISCUSSION);
