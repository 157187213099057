import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import EditorJS, { API, BlockAPI, OutputData } from '@editorjs/editorjs';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import DragDrop from 'editorjs-drag-drop';
import { createParagraphBlockData, expansionPanelTools } from '../utils';

const ACCORDION_TITLE_ID = 'accordion-title';
const ACCORDION_CONTENT_ID = 'accordion-content';

interface ExpansionData {
	title: string;
	details: string;
	placeholder?: boolean;
}

interface ExpansionProps extends ExpansionData {
	onSave: (content: string) => void;
	id?: string;
}

const ExpansionInput = (props: ExpansionProps) => {
	const [fields, setFields] = useState({
		title: props.title || '',
		details: props.details || ''
	});

	useEffect(() => {
		document.getElementById(props.id || '')?.addEventListener('keydown', (event) => {
			if (event.key === 'Enter') {
				event.stopImmediatePropagation();
			}
		});

		return () => {
			const contentEditor = document.getElementById(props.id || '');
			if (contentEditor) {
				contentEditor.onkeydown = null;
			}
		};
	}, []);

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value, name } = event.target;
		setFields((prevState) => ({ ...prevState, [name]: value }));
	};

	return (
		<Grid container item xs={11} style={{ paddingTop: '15px', paddingBottom: '15px' }}>
			<TextField
				id={ACCORDION_TITLE_ID}
				name={'title'}
				fullWidth
				onChange={handleInputChange}
				type={'text'}
				required
				value={fields.title}
				variant={'outlined'}
				label={'Title'}
			/>
			<br />

			<div style={{ width: '100%' }}>
				<div style={{ paddingTop: '13px' }} id={'editorjs'} />;
			</div>
		</Grid>
	);
};

type BlockProps = {
	readOnly: true;
	content: string;
	editorID: string;
	style: {
		[attributes: string]: string;
	};
	blockTools?: {
		[idx: string]: any;
	};
};

const SessionBlockEditor = ({ style, editorID, content }: BlockProps) => {
	const [editor, setEditor] = useState<EditorJS | null>(null);

	let data: OutputData;
	try {
		const savedContent = JSON.parse(content || '[]');
		if (Array.isArray(savedContent)) {
			data = {
				blocks: savedContent
			};
		} else {
			//is object
			data = { ...savedContent };
		}
	} catch (e) {
		//data happens to be just a string so stick it in a paragraph
		data =
			typeof content === 'string'
				? createParagraphBlockData(content)
				: {
						blocks: []
				  };
	}

	useEffect(() => {
		if (!editor) {
			const editor = new EditorJS({
				holder: editorID,
				autofocus: true,
				onReady() {
					new DragDrop(editor);
					setEditor(editor);
				},
				readOnly: true,
				data: data,
				//@ts-ignore
				tools: expansionPanelTools
			});
		}

		return () => {
			setEditor(null);
		};
	}, []);

	return <div style={style} id={editorID} />;
};

class ExpandForMore {
	private readonly data: ExpansionData;
	private readonly api: API;
	private readonly readOnly: boolean = false;
	private readonly wrapper: HTMLDivElement | undefined;
	private contentData: string;
	private readonly id: string;
	private block: BlockAPI;

	static get toolbox() {
		return {
			title: 'Expansion Panel',
			icon: `<svg width="19" height="17" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 119.72" xml:space="preserve"><g><path d="M22.72,0h77.45c6.25,0,11.93,2.56,16.05,6.67c4.11,4.11,6.67,9.79,6.67,16.05v74.29c0,6.25-2.56,11.93-6.67,16.05 l-0.32,0.29c-4.09,3.94-9.64,6.38-15.73,6.38H22.72c-6.25,0-11.93-2.56-16.05-6.67l-0.3-0.32C2.43,108.64,0,103.09,0,97.01V22.71 c0-6.25,2.55-11.93,6.67-16.05C10.78,2.55,16.46,0,22.72,0L22.72,0z M75.83,73.05L75.83,73.05c-3.28-3.26-5.54-5.99-7.79-8.72 c-1.95-2.35-3.9-4.72-6.52-7.41L46.03,73.77c-0.1,0.11-0.22,0.2-0.36,0.27c-1.06,0.54-2.25,0.73-3.4,0.55 c-1.12-0.17-2.2-0.68-3.07-1.54c-1.09-1.07-1.65-2.5-1.66-3.92c-0.01-1.44,0.52-2.87,1.6-3.97c5.83-6.43,12.07-13.94,18.11-19.99 c1.24-1.23,2.69-1.84,4.15-1.83c1.44,0.01,2.85,0.6,4.05,1.76c5.34,4.94,13.32,14.33,18.28,20.06c1.06,1.08,1.6,2.49,1.6,3.9 c0,1.39-0.51,2.78-1.53,3.86l-0.1,0.1c-1.08,1.08-2.51,1.63-3.94,1.63c-1.39,0-2.78-0.51-3.86-1.54L75.83,73.05L75.83,73.05z M100.16,10.24H22.72c-3.43,0-6.54,1.41-8.81,3.67c-2.26,2.26-3.67,5.38-3.67,8.81v74.29c0,3.33,1.31,6.35,3.43,8.59l0.24,0.22 c2.26,2.26,5.38,3.67,8.81,3.67h77.45c3.32,0,6.35-1.31,8.59-3.44l0.21-0.23c2.26-2.26,3.67-5.38,3.67-8.81V22.71 c0-3.42-1.41-6.54-3.67-8.81C106.71,11.65,103.59,10.24,100.16,10.24L100.16,10.24z"/></g></svg>`
		};
	}

	static get isReadOnlySupported() {
		return true;
	}

	constructor(props: { data: ExpansionData; readOnly: boolean; api: API; block: BlockAPI }) {
		const { data, readOnly, api, block } = props;
		this.id = String(block.id);
		this.api = api;
		this.block = block;
		this.data = data;
		this.readOnly = readOnly;
		this.updateContent = this.updateContent.bind(this);
		this.wrapper = document.createElement('div');
		this.contentData = this.data.details;
	}

	updateContent(newContent: string) {
		this.contentData = newContent;
		this.block.dispatchChange();
	}

	render() {
		const { title } = this.data;
		if (this.data.placeholder) {
			ReactDOM.render(
				<>
					<Accordion style={{ marginBottom: '20px' }}>
						<AccordionSummary
							expandIcon={<ExpandIcon />}
							className={ACCORDION_TITLE_ID}
							style={{ backgroundColor: '#d1d1d1' }}
						>
							{title}
						</AccordionSummary>
						<AccordionDetails className={ACCORDION_CONTENT_ID} style={{ display: 'inherit' }}>
							<Typography variant={'caption'}>Live Preview not available</Typography>
							<Skeleton variant={'rect'} height={100} />
							<Skeleton />
						</AccordionDetails>
					</Accordion>
				</>,
				this.wrapper!
			);

			return this.wrapper;
		}

		if (this.readOnly) {
			ReactDOM.render(
				<>
					<Accordion style={{ marginBottom: '20px' }}>
						<AccordionSummary
							expandIcon={<ExpandIcon />}
							className={ACCORDION_TITLE_ID}
							style={{ backgroundColor: '#d1d1d1' }}
						>
							{title}
						</AccordionSummary>
						<AccordionDetails className={ACCORDION_CONTENT_ID} style={{ display: 'inherit' }}>
							<SessionBlockEditor
								key={1}
								readOnly={true}
								content={this.contentData}
								editorID={this.id}
								style={{ paddingTop: '13px' }}
							/>
						</AccordionDetails>
					</Accordion>
				</>,
				this.wrapper!
			);
		} else {
			ReactDOM.render(
				<ExpansionInput
					id={this.id}
					title={title}
					details={this.contentData}
					onSave={this.updateContent}
				/>,
				this.wrapper!
			);
		}

		return this.wrapper;
	}

	save(blockContent: HTMLDivElement) {
		const title = blockContent.querySelector(`#${ACCORDION_TITLE_ID}`) as HTMLInputElement;
		const details = this.contentData;

		if (title?.value) {
			return {
				title: title.value,
				details: details || ''
			};
		}
	}

	validate(savedData: { title: string }) {
		return Boolean(savedData?.title);
	}
}

export default ExpandForMore;
