import {
	COMPLETE_TOUR,
	FAIL_GET_USER_INFORMATIONS,
	RECEIVE_USER_INFORMATIONS,
	REQUEST_USER_INFORMATIONS,
	RESET_TUTORIAL
} from '../actions/actionCreators';
import initialState, { LOGIN_STATE_LOGGED_IN, LOGIN_STATE_LOGGED_OUT } from '../store/initialState';
import { Action, User } from '../../types';

export default function currentUser(state: User = { ...initialState.currentUser }, action: Action) {
	switch (action.type) {
		case REQUEST_USER_INFORMATIONS: {
			return {
				...state,
				token: action.payload.token,
				tokenIssuedAt: action.payload.tokenIssuedAt,
				loginState: LOGIN_STATE_LOGGED_OUT,
				message: null,
				isLoggingIn: true
			};
		}

		case RECEIVE_USER_INFORMATIONS: {
			const {
				firstName,
				lastName,
				country,
				phoneNumber,
				email,
				locale,
				institution,
				firstLogin,
				analyticsToken
			} = action.payload;

			return {
				...state,
				firstName,
				lastName,
				country,
				phoneNumber,
				email,
				locale,
				firstLogin,
				analyticsToken,
				message: null,
				isLoggingIn: false,
				institution: {
					id: institution.id,
					name: institution.name,
					image: institution.image,
					color: institution.color,
					about: institution.about,
					analyticsCode: institution.analyticsCode,
					isMerchant: institution.isMerchant
				},
				loginState: LOGIN_STATE_LOGGED_IN
			};
		}

		case FAIL_GET_USER_INFORMATIONS: {
			const { message } = action.payload;

			return {
				...state,
				token: null,
				tokenIssuedAt: null,
				isLoggingIn: false,
				loginState: LOGIN_STATE_LOGGED_OUT,
				message
			};
		}

		case COMPLETE_TOUR: {
			return {
				...state,
				viewedTutorials: {
					...state.viewedTutorials,
					[action.payload]: true
				}
			};
		}

		case RESET_TUTORIAL: {
			return {
				...state,
				viewedTutorials: {
					modules: false,
					moduleCourses: false,
					courses: false,
					courseInfo: false,
					sessions: false,
					sessionDetails: false,
					bookmarks: false,
					help: false
				}
			};
		}

		default:
			return state;
	}
}
