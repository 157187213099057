import React from 'react';
import { Category, Store } from '../../types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import I18n from 'i18n-js';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	searchCategoryWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		padding: '10px 0px'
	},
	searchCategory: {
		marginRight: '5px',
		marginBottom: '5px'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	categoriesLabel: {
		fontSize: '13px !important',
		marginRight: '5px !important',
		position: 'relative',
		top: '2px'
	}
}));

const SearchCategories = (props: any) => {
	const { handleCategoryChange, category } = props;

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const classes = useStyles();

	const categories = [
		{
			label: I18n.t('routes.modules', { locale }),
			type: Category.modules
		},
		{
			label: I18n.t('routes.courses', { locale }),
			type: Category.courses
		},
		{
			label: I18n.t('routes.folders', { locale }),
			type: Category.folders
		},
		{
			label: I18n.t('routes.sessions', { locale }),
			type: Category.sessions
		}
	];

	return (
		<div className={classes.searchCategoryWrapper}>
			<Typography className={classes.categoriesLabel}>
				{I18n.t('searchModal.searchIn', { locale })}:
			</Typography>
			{categories.map((categoryItem, index) => (
				<Chip
					data-test-id="search-category_chip"
					key={index}
					className={classes.searchCategory}
					size="small"
					label={categoryItem.label}
					clickable
					color={category === categoryItem.type ? 'primary' : 'default'}
					onClick={() => handleCategoryChange(categoryItem.type)}
				/>
			))}
		</div>
	);
};

export default SearchCategories;
