import debounce from 'lodash/debounce';
import { Session } from '../../types';

interface DebouncedFunction {
	(...args: any): void;
	flush: () => void;
	cancel: () => void;
}

interface Debouncer {
	createDebouncedFunction: (pureFunction: (...args: any) => any, wait: number) => DebouncedFunction;
}

interface SessionProgressionSenderService {
	send: (session: Session) => void;
}

const LodashDebouncer: Debouncer = {
	createDebouncedFunction: (pureFunction: (...args: any) => any, wait: number) =>
		debounce(pureFunction, wait)
};

export const PROGRESSION_TRANSMISSION_DELAY_DURATION: number = 10000;

class SessionProgressionSender {
	private TRANSMISSION_DELAY_DURATION: number = PROGRESSION_TRANSMISSION_DELAY_DURATION;
	private debouncedFunction: DebouncedFunction;
	private senderService: SessionProgressionSenderService;

	constructor(senderService: SessionProgressionSenderService) {
		this.senderService = senderService;
	}

	delaySendingProgressionForSession = (session: Session) => {
		this.debounceSendingSessionProgression(LodashDebouncer);
		if (this.debouncedFunctionIsPresent()) {
			this.debouncedFunction(session);
		}
	};

	private debounceSendingSessionProgression = (debouncer: Debouncer) => {
		this.debouncedFunction = debouncer.createDebouncedFunction(
			this.sendSessionProgression,
			this.TRANSMISSION_DELAY_DURATION
		);
	};

	private sendSessionProgression = (session: Session) => {
		this.senderService.send(session);
	};

	sendProgressionImmediatelyIfStillPending = () => {
		if (this.debouncedFunctionIsPresent()) {
			this.debouncedFunction.flush();
		}
	};

	cancelSendingOutPendingProgression = () => {
		if (this.debouncedFunctionIsPresent()) {
			this.debouncedFunction.cancel();
		}
	};

	private debouncedFunctionIsPresent = () => typeof this.debouncedFunction !== 'undefined';
}

export default SessionProgressionSender;
