import withStyles from '@material-ui/core/styles/withStyles';
import I18n from 'i18n-js';
import React from 'react';
import { Theme } from '@material-ui/core';
import { Locale } from '../../types';
import { WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
	badge: {
		backgroundColor: 'trasparent',
		border: `1px solid ${theme.palette.secondary.main}`,
		color: theme.palette.secondary.main,
		padding: '5px 13px',
		borderRadius: '4px',
		fontSize: '13px',
		lineHeight: '10px'
	}
});

interface Props extends WithStyles<typeof styles> {
	locale: Locale;
}
const SurveyBadge = ({ classes, locale }: Props) => (
	<div className={classes.badge}>{I18n.t('badge.survey', { locale })}</div>
);

export default withStyles(styles)(SurveyBadge);
