import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/Store';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { Locale, Store } from '../../types';

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(1)
	}
}));

interface Props {
	locale: Locale;
}

const PurchaseCoursesButton = ({ locale }: Props) => {
	const classes = useStyles();

	const token = useSelector(({ currentUser: { token } }: Store) => token);

	const handleOnClick = () => {
		window.open('http://store.chalkboard.education/#/login/' + token, '_blank');
	};

	return (
		<Button data-test-id="purchase-button" variant="contained" color="primary" onClick={handleOnClick}>
			<Icon className={classes.icon} />
			{I18n.t('courses.purchaseCourses', { locale })}
		</Button>
	);
};

export default PurchaseCoursesButton;
