export default () => ({
	course: {
		noContentAvailable: 'Không có sẵn nội dung',
		view: 'Xem'
	},
	session: {
		nextButton: 'Tiếp theo',
		previous: 'Trước',
		quizButton: 'Tiến hành câu hỏi',
		surveyButton: 'Tiến hành khảo sát',
		end: 'Hoàn thành'
	},
	question: {
		next: 'Tiếp theo',
		previous: 'Trước',
		saveAndNext: 'Lưu & Tiếp theo',
		answer: 'Câu trả lời',
		checkAnswer: 'Kiểm tra câu trả lời',
		numericalRangeFeedback:
			'Câu trả lời đúng là một số từ <b>%{lowerNumber}</b> đến <b>%{higherNumber}</b> bao gồm',
		answerAll: 'Vui lòng trả lời tất cả các câu hỏi <b>bắt buộc</b> trước khi tiếp tục.',
		maxFiles: 'Bạn chỉ có thể tải lên tối đa {number} video',
		uploadAll: 'Vui lòng tải lên tất cả các tệp <b>yêu cầu</b> trước khi tiếp tục',
		confirmRemove: 'Bạn có chắc chắn muốn xóa tệp không',
		confirm: 'Xác nhận',
		backToStart: 'Trở về Đầu',
		attempt: 'Vui lòng thử trả lời câu hỏi trước khi tiếp tục.',
		question: 'Câu hỏi',
		of: 'của',
		end: 'Hoàn tất',
		hint: 'Nhấp để bật gợi ý',
		helperText: {
			essay: 'Nhập câu trả lời bên dưới',
			multipleChoice: 'Chọn một câu trả lời',
			compoundMultipleChoice: 'Chọn tất cả các câu trả lời thích hợp',
			numericalRange: 'Nhập một số trong phạm vi câu trả lời thích hợp',
			shortExact: 'Nhập câu trả lời bên dưới',
			sequence: 'Kéo và thả các mục để sắp xếp chúng theo thứ tự thích hợp',
			ratingScale: 'Kéo thanh trượt để chọn và trả lời'
		}
	},
	feedback: {
		good: 'Làm tốt lắm!',
		bad: 'Không đúng'
	},
	discussion: {
		title: 'Thảo luận',
		messagePlaceholder: 'Nhập tin nhắn của bạn'
	},
	tokenError: {
		'401': 'Liên kết của bạn có vấn đề - vui lòng liên hệ với Chalkboard Education để được hỗ trợ.',
		'402':
			'Mã thông báo của bạn đang được sử dụng trên một thiết bị khác. Tổ chức của bạn cấm đăng nhập trên nhiều thiết bị. Vui lòng liên hệ với Chalkboard Education nếu bạn cho rằng đã xảy ra lỗi.'
	},
	update: {
		label: 'Tải xuống',
		checkForUpdates: 'Kiểm tra cập nhật',
		needUpdate: 'Có sẵn cập nhật nội dung...',
		updating: 'Đang tìm nạp nội dung...',
		checking: 'Đang kiểm tra nội dung...',
		fetchingQuizResults: 'Đang lấy kết quả kiểm tra...',
		downloadSize: '{{size}} {{type}}',
		toDownload: 'để tải về',
		KB: 'KB',
		MB: 'MB',
		upToDate: 'Tất cả nội dung có sẵn đã được tải xuống!',
		errorWhileCheckingUpdates: 'Đã xảy ra sự cố mạng trong khi kiểm tra cập nhật',
		errorWhileUpdating: 'Đã xảy ra sự cố mạng khi tải nội dung xuống',
		stayOnline:
			'Vui lòng duy trì trạng thái trực tuyến và giữ cho trang luôn mở. Việc này có thể sẽ mất vài phút.',
		offline: 'Bạn đang ngoại tuyến.',
		success: 'Nội dung đã tải xuống thành công!',
		warning: 'Nội dung được tải xuống một phần',
		retry: 'Thử lại',
		retryInFewMinutes: 'กรุณาลองอีกครั้งในไม่กี่นาที',
		connectAndRetry: 'Vui lòng thử lại sau vài phút',
		connectAndRetryInMinutes: 'Vui lòng đảm bảo bạn đã kết nối với Internet và thử lại sau vài phút',
		downloadInProgress: 'Đang tải xuống',
		contentWillAppear: 'Bạn sẽ thấy nội dung ngay khi nó hoàn tất',
		edit: 'Chỉnh sửa',
		downloadingContent: 'Tải xuống nội dung',
		missing: 'Tải xuống phương tiện thiếu',
		dismiss: 'Bỏ qua',
		contentLoading:
			'Đang tải nội dung. Bạn có thể xem trạng thái bằng cách chạm vào vòng tròn ở góc dưới bên phải màn hình của bạn'
	},
	send: {
		label: {
			quiz: 'Gửi tiến trình của bạn với:',
			survey:
				"Bạn đã hoàn thành cuộc khảo sát tốt lắm! Để đảm bảo tính chính xác, vui lòng xem xét lại các câu trả lời của bạn. Khi bạn chắc chắn về câu trả lời của mình, hãy tiếp tục bằng cách nhấn vào nút 'Gửi'"
		},
		sessionListButton: `Danh sách phiên`,
		nextButton: 'Tiếp theo',
		medium: {
			internet: 'Internet',
			sms: 'Tin nhắn SMS'
		},
		validation: {
			success: 'Cảm ơn bạn. Tiến trình của bạn đã được lưu.',
			fail: `Lỗi khi xác thực Phiên`
		},
		sms: {
			title: 'Gửi tiến trình của bạn qua tin nhắn SMS',
			label:
				'Bạn sẽ gửi mã qua SMS và nhận lại mã tiến trình. Đừng sửa đổi một trong hai mã. Xin cảm ơn bạn.',
			button: 'Gửi bằng SMS',
			notworking: {
				label: 'Tự gửi tin nhắn SMS...',
				button: 'Không được?',
				toPhone: 'Số Điện thoại',
				unblockCode: 'Mã để gửi'
			},
			progressCode: {
				button: 'Nhập Mã Tiến trình',
				placeholder: 'Mã Tiến trình',
				title: 'Nhập mã bạn nhận lại được từ SMS bên dưới:',
				cancel: 'Hủy',
				submit: 'Gửi'
			}
		}
	},
	login: {
		checking: 'Đang kiểm tra ...',
		start: 'Bắt đầu',
		title: 'Đăng nhập',
		message:
			'Bạn có thể đăng nhập bằng liên kết SMS được cung cấp. Ngoài ra, bạn có thể nhập mã thông báo đăng nhập của mình bên dưới.',
		hint:
			'Bạn có thể sử dụng liên kết đăng nhập được gửi cho bạn qua SMS để đăng nhập lại.<br/>Ngoài ra, có thể tìm thấy mã thông báo 6 ký tự cùng URL đăng nhập được gửi cho bạn qua SMS. Ví dụ: https://chalkedu.co/#/login/<b>token</b>',
		tokenPlaceholder: 'Mã thông báo'
	},
	account: {
		progress: {
			title: `tiến triển`,
			subtitle: `%{read}/%{remaining} Phiên đã đọc`
		},
		language: 'Ngôn ngữ',
		settings: {
			label: 'Cài đặt'
		},
		about: 'Giới thiệu',
		consent: 'Bằng lòng',
		support: {
			title: 'Đường dây Hỗ trợ',
			subtitle: 'Bạn có thể liên hệ với chúng tôi thông qua các phương pháp sau để được trợ giúp thêm:'
		}
	},
	error: {
		label: 'Hình như có lỗi mất rồi.',
		report: 'Báo Lỗi',
		back: 'Trở về Trang chủ',
		dismiss: 'Bỏ qua',
		authenticationError: 'Lỗi Xác thực',
		spoolError: {
			title: 'Tải xuống Chưa Đầy đủ',
			description:
				'Không thể tải xuống một số tệp. Đừng lo, chúng tôi sẽ cố gắng tải lại chúng trong bản cập nhật tiếp theo.'
		}
	},
	'404': {
		title: 'Lỗi 404 - không tìm được trang',
		description: 'Rất tiếc, trang bạn đang tìm kiếm không tồn tại.',
		back: 'Quay lại'
	},
	logout: {
		title: 'Xác nhận Đăng xuất',
		message: 'Bạn có chắc chắn muốn thoát?',
		button: 'Đăng xuất',
		cancelButton: 'Hủy'
	},
	selfTestQuiz: {
		okay: 'OK',
		checkAnswer: 'Kiểm tra câu trả lời',
		answers: 'Câu trả lời',
		essayResponse: 'Cảm ơn bạn đã phản hồi!'
	},
	quiz: {
		label: 'Bạn phải hoàn thành Bài kiểm tra sau đây trước khi tiếp tục!',
		button: {
			begin: 'Bắt đầu',
			skip: 'Bỏ qua'
		},
		upload: 'Chọn tệp để tải lên',
		uploadedFiles: 'Tệp đã tải lên',
		tapToUpload: 'Chạm vào biểu tượng để chọn tệp để tải lênd',
		commence: 'Bạn sắp thực hiện bài kiểm tra của phiên này',
		submit: {
			submitting: 'Đang nộp',
			stayOnline:
				'Bạn có chắc chắn muốn gửi câu trả lời của mình không?\nVui lòng đảm bảo bạn đang trực tuyến và giữ màn hình mở',
			inProgress: 'Đang gửi. Quá trình này có thể mất tối đa một phút',
			success: 'Đã gửi câu hỏi thành công!',
			error: 'Lỗi khi gửi câu hỏi! Vui lòng thử lại sau.',
			offline: 'Không thể gửi phản hồi khi không kết nối'
		},
		continue: 'Tiếp tục',
		submitButton: 'Nộp bài kiểm tra'
	},
	survey: {
		submitButton: 'Gửi Khảo sát',
		submittedButton: 'Đã Gửi Khảo sát',
		commence: 'Bạn sắp thực hiện bản khảo sát của phiên này',
		submit: {
			success: 'Khảo sát đã được gửi thành công!'
		}
	},
	badge: {
		quiz: 'Đố',
		survey: 'Khảo sát',
		required: 'Bắt buộc'
	},
	menu: {
		modules: `Các Học phần`,
		courses: `Các khóa học`,
		account: 'Tài khoản',
		bookmarks: 'Dấu trang',
		quizReview: 'Đánh giá Câu hỏi',
		help: 'Trợ giúp',
		about: 'Giới thiệu'
	},
	consent: {
		title: 'Tôi đồng ý để Chalkboard Education SAS thu thập và lưu giữ:',
		data: 'Dữ liệu tiến trình và kết quả của tôi từ ứng dụng này',
		analytics: 'Dữ liệu hành vi của tôi và thông tin cá nhân khác liên quan đến việc sử dụng ứng dụng này'
	},
	custom: {
		moreInfo: 'Thêm thông tin',
		gotIt: 'Đã hiểu',
		searchPlaceholder: 'Bắt đầu gõ để tìm kiếm',
		noSearchResults: 'Không tìm thấy kết quả nào'
	},
	help: {
		title: 'Cách điều hướng ứng dụng:',
		menu: {
			title: 'Khám phá Menu',
			subtitle: `Nhấp vào biểu tượng để mở menu. Menu chứa liên kết đến các Khóa học và Tài khoản của bạn.`
		},
		courses: {
			title: `Các khóa học`,
			subtitle: `Trên trang Khóa học, nhấp vào <b>Xem</b> để mở Khóa học.`
		},
		folders: {
			title: `Thư mục`,
			subtitle: `Mỗi Khóa học được chia thành các Thư mục.`,
			description: `Mỗi Thư mục được chia thành các Phiên. Các phiên chứa nội dung mà người dùng có thể nghiên cứu.`
		},
		sessions: {
			title: `Phiên`,
			subtitle: `Các phiên chứa nội dung mà người dùng có thể nghiên cứu.`
		},
		navigatingSessions: {
			title: `Điều hướng Phiên`,
			back: {
				subtitle: `Khi ở trong một Phiên, bạn có thể điều hướng trở lại danh sách chính bằng mũi tên quay lại ...`
			},
			next: {
				subtitle: `Hoặc đến Phiên tiếp theo bằng nút ở cuối trang:`
			}
		},
		bookmarks: {
			title: 'Dấu trang',
			subtitle:
				'Sử dụng biểu tượng dấu trang để lưu các trang để xem nhanh từ phần Dấu trang trong menu bên'
		},
		quizzes: {
			title: 'Câu hỏi/Kiểm tra',
			subtitle: `Bạn có thể sẽ gặp một bài Câu hỏi khi bắt đầu một Khóa học hoặc Thư mục. Hoặc vào cuối Phiên.`,
			description: `NB: Các phiên có Câu hỏi được đánh dấu bằng biểu tượng:`
		},
		addToHomescreen: {
			title: 'Thêm vào Màn hình Chính',
			subtitle:
				'Bạn có thể cài đặt ứng dụng này trên màn hình chính của mình để truy cập nhanh chóng và dễ dàng bất cứ đâu.'
		},
		account: {
			title: 'Tài khoản',
			subtitle: 'Trên trang Tài khoản, bạn có thể:',
			updates: {
				description: 'Kiểm tra tìm bản cập nhật cho nội dung.'
			},
			language: {
				description: 'Thay đổi cài đặt ngôn ngữ của bạn.'
			},
			logout: {
				description: 'Đăng xuất khỏi tài khoản của bạn.'
			},
			contact: {
				description: 'Liên hệ với chúng tôi nếu bạn gặp bất kỳ vấn đề nào khi sử dụng ứng dụng.'
			}
		},
		tutorial: 'Chạy lại hướng dẫn tương tác:'
	},
	onboarding: {
		'0': {
			title: 'Bài học theo yêu cầu',
			subtitle:
				'Tất cả tài liệu học tập của bạn ở một nơi... Bao gồm các câu hỏi để kiểm tra kiến ​​thức của bạn.'
		},
		'1': {
			title: 'Có khả năng dùng ngoại tuyến!',
			subtitle:
				'Sau khi nội dung của bạn được tải xuống, bạn có thể dùng nội dung bất cứ lúc nào kể cả khi ngoại tuyến.'
		},
		'2': {
			title: 'Học mọi lúc, mọi nơi!',
			subtitle:
				'Truy cập nội dung của bạn trên mọi thiết bị. Chỉ cần sử dụng liên kết SMS được cung cấp.'
		},
		'3': {
			title: 'Tham gia thảo luận!',
			subtitle: `Tham gia học với người khác trên các Phiên hỗ trợ thảo luận.`
		},
		'4': {
			title: 'Thêm vào Màn hình chính của bạn!',
			subtitle:
				'Cài đặt ứng dụng này trên màn hình chính của bạn để truy cập nhanh chóng và dễ dàng ở bất kỳ đâu.',
			instructions: {
				menu:
					'Chỉ cần nhấn vào nút <strong>menu</strong> trong trình duyệt của bạn<br />và chọn <strong>Thêm vào Màn hình chính</strong>',
				share:
					'Chỉ cần nhấn vào nút <strong>chia sẻ</strong> trong trình duyệt của bạn<br />và chọn <strong>Thêm vào Màn hình chính</strong>',
				clickButton: '...hoặc bằng cách nhấp vào nút bên dưới'
			}
		},
		addToHomescreen: 'Thêm vào Màn hình Chính',
		getStarted: 'Bắt đầu',
		next: 'Tiếp theo',
		skip: 'Bỏ qua'
	},
	'guided-tour': {
		title: 'Hướng dẫn Tương tác',
		button: {
			finish: 'Kết thúc',
			next: 'Tiếp theo',
			previous: 'Trước',
			're-run': 'Chạy lại'
		},
		modules: {
			'1': {
				title: `Trang Học phần`,
				content: `Chào mừng đến với trang <b>Học phần</b>. <b>Học phần</b> là các nhóm <b>Khóa học</b> mà bạn đã được chỉ định`
			},
			'2': {
				content: `Nhấp vào <b>Học phần</b> sẽ đưa bạn đến danh sách tất cả các <b>Khóa học</b> trong <b>Học phần</b>`
			}
		},
		'module-courses': {
			'1': {
				content: `Bạn đang ở trang <b>Các Khóa học</b> của <b>Học phần</b> mà bạn vừa chọn. Các Khóa học trong <b>Học phần</b> được liệt kê ở đây`
			}
		},
		courses: {
			'1': {
				title: `Trang Khóa học`,
				content: `Bạn đang ở trang <b>Khóa học</b>. Tất cả các <b>Khóa học</b> của bạn đều được liệt kê ở đây`
			},
			'2': {
				content: 'Bất cứ khi nào bạn nhìn thấy cái này thì nghĩa là có nội dung mới để tải xuống'
			},
			'3': {
				content: 'Nhấp vào <b>Tải xuống</b> để lưu nội dung mới vào thiết bị của bạn'
			},
			'4': {
				content: `Nhấp vào hình ảnh để hiển thị một mô tả ngắn về <b>Khóa học</b>`
			},
			'5': {
				content: `Nhấp vào <b>Khóa học</b> sẽ đưa bạn đến trang thông tin của <b>Khóa học</b> đó`
			},
			'6': {
				content: 'Nhấp vào <b>Kiểm tra Cập nhật</b> để kiểm tra nội dung mới'
			}
		},
		'course-info': {
			'1': {
				content: `Đây là <b>Trang Khóa học</b>. Tại đây, bạn sẽ tìm thấy thêm thông tin về Khóa học, cũng như các <b>Phiên</b> của bạn được nhóm thành <b>Thư mục</b>`
			},
			'2': {
				content: `Bạn có thể tìm thấy mô tả ngắn gọn về <b>Khóa học</b> tại đây`
			},
			'3': {
				content: `Bên dưới mô tả Khóa học là danh sách <b>Thư mục</b>`
			},
			'4': {
				content: `Nhấp vào <b>Thư mục</b> sẽ hiển thị danh sách <b>Phiên</b> trong Thư mục đó`
			},
			'5': {
				content: `Đây là danh sách <b>Phiên</b>`
			},
			'6': {
				content: `Để bắt đầu đọc, hãy nhấp vào <b>Phiên</b>`
			}
		},
		'session-details': {
			'1': {
				content: `Chào mừng bạn đến với <b>Phiên</b> đầu tiên của mình. Đây là nơi bạn sẽ dành phần lớn thời gian của mình`
			},
			'2': {
				content: `Để quay lại <b>Phiên</b> dễ dàng hơn, hãy nhấp vào biểu tượng <b>Dấu trang</b> để tạo liên kết nhanh quay lại <b>Phiên</b> này. Bạn có thể tìm thấy <b>Dấu trang</b> mà bạn tạo trong <b>Trang Dấu trang</b>`
			},
			'3': {
				content: 'Nhấp vào mũi tên lên sẽ đưa bạn đến trang trước.'
			},
			'4': {
				content: `Nhấp vào <b>Biểu tượng Thảo luận</b> để xem <b>Trang Thảo luận</b> của <b>Phiên</b> bạn hiện đang xem`
			},
			'5': {
				content: `Để chuyển đến <b>Phiên</b> tiếp theo trong <b>Thư mục</b>, hãy nhấp vào <b>Nút Tiếp theo</b>`
			},
			'6': {
				content: `Để chuyển sang <b>Phiên</b> trước, hãy nhấp vào <b>Nút Trước</b>`
			},
			'7': {
				content:
					'Đây là <b>Nút Menu</b>. Nhấp vào nút này trên bất kỳ trang nào để hiển thị <b>Menu bên</b>'
			}
		},
		bookmarks: {
			'1': {
				content: 'Tất cả các trang bạn đã đánh dấu đều hiện ở đây!'
			},
			'2': {
				content: 'Nhấp vào <b>Dấu trang</b> sẽ nhanh chóng đưa bạn đến trang đã đánh dấu'
			}
		},

		help: {
			'1': {
				content:
					'Chào mừng bạn đến với <b>Trang Trợ giúp</b>. Hãy đến trang này bất cứ khi nào bạn có thắc mắc về cách sử dụng ứng dụng'
			},
			'2': {
				content:
					'Bạn có thể truy cập <b>Trang Trợ giúp</b> để chạy lại hướng dẫn này bất kỳ lúc nào...'
			},
			'3': {
				content: 'Nhấp vào bất kỳ mục nào trong danh sách để biết thêm thông tin...'
			},
			'4': {
				content: 'Ngoài ra, bạn có thể liên hệ với Chalkboard Education qua email'
			},
			'5': {
				content: 'Hoặc trò chuyện với chúng tôi bằng cách gọi đường dây nóng của chúng tôi...'
			}
		},

		quiz: {
			'1':
				'Chào mừng bạn đến với một <b>Trang Câu hỏi</b>. Bạn sẽ thấy một màn hình tương tự bất cứ khi nào bạn chuẩn bị làm một bài Câu hỏi',
			'2': 'Đây là chi tiết về bài Câu hỏi. Nhấp vào nút <b>Bắt đầu</b> để bắt đầu...'
		},
		quizQuestion: {
			'1': 'Điều này cho biết bạn đang ở câu hỏi nào và bạn còn lại bao nhiêu câu hỏi...',
			'2':
				'Tương tác và trả lời câu hỏi. Nhấp vào nút <b>Tiếp theo</b> ở cuối màn hình để chuyển sang câu hỏi tiếp theo',
			'3':
				'Câu trả lời của bạn sẽ tự động được lưu trên thiết bị của bạn sau khi bạn nhập chúng. Bạn có thể quay lại bất kỳ câu hỏi nào cho đến khi bạn gửi bài kiểm tra!'
		}
	},
	routes: {
		login: 'Đăng nhập',
		welcome: 'Chào mừng',
		modules: `Các Học phần`,
		courses: `Các khóa học`,
		help: 'Trợ giúp',
		about: 'Giới thiệu',
		bookmarks: 'Dấu trang',
		quizReview: 'Đánh giá Câu hỏi',
		account: 'Tài khoản',
		folders: `Thư mục`,
		sessions: `Phiên`,
		quiz: 'Đố',
		question: 'Câu hỏi'
	},
	bookmarks: {
		none: 'Có vẻ như bạn chưa lưu dấu trang nào...',
		added: 'Đã thêm dấu trang',
		removed: 'Đã xóa dấu trang'
	},
	courses: {
		none: `Các khóa học đã tải xuống sẽ hiện tại đây...`,
		noPurchases: `Bạn chưa thực hiện bất kỳ giao dịch mua nào`,
		purchaseMore: `Bấm vào đây để thêm các khóa học`,
		purchaseCourses: `Mua khóa học`
	},
	quizReview: {
		questionsTitle: {
			survey: 'Câu hỏi và câu trả lời',
			graded: 'Xổ ra'
		},
		none: `Bạn chưa hoàn thành bất kỳ bài Câu hỏi <b>Chấm điểm</b> hoặc <b>Khảo sát</b> nào`,
		correct: 'Đúng',
		wrong: 'Sai',
		notMarked: 'Chưa đánh dấu',
		resultsIsFetching: 'Đang lấy lại các bài kiểm tra đã làm trước đây để xem xét...',
		errorFetchingResults:
			'Xin lỗi, đã xảy ra lỗi khi lấy kết quả kiểm tra. Vui lòng kiểm tra các bản cập nhật để thử lại'
	},
	folder: {
		quizLabel: `Bạn phải hoàn thành một bài Câu hỏi trước khi xem nội dung của Thư mục này:`,
		surveyLabel: `Bạn phải hoàn thành Khảo sát trước khi xem nội dung của Thư mục này:`
	},
	modules: {
		none: `Chưa tải xuống Học phần nào...`
	},
	register: {
		signUp: 'Đăng ký',
		button: 'ĐĂNG KÝ',
		registering: 'ĐANG ĐĂNG KÝ...',
		loginHint: 'Bạn đã có sẵn Tài khoản? Đăng nhập',
		successful: 'Đăng ký thành công',
		failed: 'Đăng ký không thành công. Vui lòng thử lại',
		label: {
			firstName: 'Họ',
			lastName: 'Họ',
			phoneNumber: 'Số Điện thoại',
			language: 'Ngôn ngữ'
		},
		hint: {
			firstName: 'Bắt buộc phải điền Tên',
			lastName: 'Bắt buộc phải điền Họ',
			phoneNumberLength: 'Số điện thoại phải dài hơn 9 chữ số',
			phoneNumberFormat: "Số điện thoại phải bắt đầu bằng dấu '+'",
			verifyHuman: 'Vui lòng xác minh recaptcha để tiếp tục',
			language: 'Bắt buộc phải chọn Ngôn ngữ'
		}
	},
	searchModal: {
		cancel: 'Hủy bỏ',
		search: 'Tìm kiếm',
		searchIn: 'Tìm kiếm trong'
	},
	quizType: {
		survey: 'Khảo sát',
		graded: 'Đã chấm điểm',
		selfAssessment: 'Tự đánh giá'
	}
});
