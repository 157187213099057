import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../types';

interface Props {
	children?: Array<JSX.Element> | JSX.Element;
}

export default ({ children }: Props) => {
	const { firstName, lastName, phoneNumber, email, institution, country, token } = useSelector(
		({ currentUser }: Store) => currentUser
	);

	const subtitle = () => {
		return (
			<React.Fragment>
				{email && <span data-id="email">{email}</span>}
				<br />
				{phoneNumber && <span data-id="phone-number">{phoneNumber}</span>}
				<br />
				{institution.name && <span data-id="institution">{institution.name}</span>}
				<br />
				{country && <span data-id="country">{country}</span>}
				<br />
			</React.Fragment>
		);
	};

	if (token !== undefined && token !== null) {
		return (
			<Card data-id="user-panel">
				<CardHeader
					disableTypography
					title={
						<Typography variant="h4" data-id="title">
							{`${firstName} ${lastName}`}
						</Typography>
					}
					subheader={
						<Typography variant="body2" color="textSecondary" data-id="subtitle">
							{subtitle()}
						</Typography>
					}
				/>

				{children && <CardContent data-test-id="content">{children}</CardContent>}
			</Card>
		);
	}

	return null;
};
