import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Typography from '@material-ui/core/Typography/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { Course, Locale } from '../../types';

interface Props {
	course: Course;
	locale: Locale;
	handleNext: (course: Course) => void;
	handleClose: () => void;
}

const CourseDialog = ({ course, locale, handleNext, handleClose }: Props) => {
	return (
		<Dialog open onClose={handleClose} scroll="body">
			<DialogTitle disableTypography>
				<Typography data-test-id="course-info_course-title" variant="h6">
					{course.title}
				</Typography>

				<Typography data-test-id="course-info_course-teacher" variant="subtitle1">
					{course.teacher}
				</Typography>
			</DialogTitle>

			<DialogContent>
				{course.image && (
					<img
						data-test-id="course-info_course-image"
						src={course.image.url}
						alt=""
						style={{ width: '100%' }}
					/>
				)}

				<DialogContentText data-test-id="course-info_course-description">
					{course.description && (
						<span
							dangerouslySetInnerHTML={{
								__html: course.description
							}}
						/>
					)}
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button
					data-test-id="course-info_next-btn"
					color="secondary"
					variant="contained"
					fullWidth
					onClick={() => {
						handleNext(course);
					}}
				>
					{I18n.t('course.view', { locale })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CourseDialog;
