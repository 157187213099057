interface Params {
	[index: number]: string;
	':moduleHashID'?: string;
	':courseHashID'?: string;
	':quizHashID'?: string;
	':surveyHashID'?: string;
	':questionIndex'?: number;
	':folderHashID'?: string;
	':sessionHashID'?: string;
	':token'?: string;
}

export default function generateUrl(path: string, params: Params = {}) {
	let url = path;

	for (let key in params) {
		url = url.replace(key, params[key]);
	}

	return url;
}
