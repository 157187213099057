import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import ModuleListItem from './ModuleListItem';
import { Theme } from '@material-ui/core';
import { Content, Module } from '../../types';
import { WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
	background: {
		backgroundColor: theme.palette.background.default
	}
});

export interface ModuleListProps extends WithStyles<typeof styles> {
	handleNext: (module: Module) => void;
	modules: Content['modules'];
}

export const ModuleList = ({ modules, classes, handleNext }: ModuleListProps) => {
	return (
		<div className={classes.background}>
			{Object.keys(modules).map((key, index) => {
				let module = modules[key];
				return (
					<ModuleListItem
						data-test-id="module-list_module-list-item"
						key={index}
						module={module}
						handleNext={handleNext}
					/>
				);
			})}
		</div>
	);
};

export default withStyles(styles)(ModuleList);
