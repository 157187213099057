import ReactGA from 'react-ga';
import translations from '../../config/translations';
import store, { GraphqlClient } from '../store/store';
import bookmarkMutation from '../../graphql/mutation/bookmarkMutation';
import progressionMutation from '../../graphql/mutation/progressionMutation';
import bulkProgressionMutation from '../../graphql/mutation/bulkProgressionMutation';
import submitQuizMutation from '../../graphql/mutation/submitQuizMutation';
import BookmarksQuery from '../../graphql/query/BookmarksQuery';
import ProgressionsQuery from '../../graphql/query/ProgressionsQuery';
import HasUpdatesQuery from '../../graphql/query/HasUpdatesQuery';
import SessionContentQuery from '../../graphql/query/SessionContentQuery';
import UserQuery from '../../graphql/query/UserQuery';
import I18n from 'i18n-js';
import { formatBookmarks } from '../../services/bookmarks/formatBookmarks';
import { formatProgressions } from '../../services/progressions/formatProgressions';
import { ModuleModel, Progression, QuizResultModel, Session, Store } from '../../types';
import allSettled from 'promise.allsettled';
import * as Sentry from '@sentry/browser';
import TimeoutExceededError from '../../services/exceptions/timeoutExceededError';
import { ActivityLabel, sendActivityData } from '../../services/analytics/userActivityClient';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AxiosRequestConfig } from 'axios';
import { FileData } from '../../components/FileUploadWidget';
import { dataURLtoFile } from '../../services/fileHandling';
import {
	QUIZ_SUBMISSION_DEFAULT_TIMEOUT_IN_SECONDS,
	QUIZ_SUBMISSION_WITH_FILE_UPLOAD_TIMEOUT_IN_SECONDS
} from '../../config/constants';
import axiosClient from '../../services/rest/axiosClient';
import QuizResultQuery from '../../graphql/query/QuizResultQuery';
import updateLocaleMutation from '../../graphql/mutation/updateLocaleMutation';

interface userInfo {
	token: string;
	tokenIssuedAt: any;
}
// NETWORK STATUS
export const SET_NETWORK_STATUS = '@@CHALKBOARDEDUCATION/SET_NETWORK_STATUS';

export function setNetworkStatus(isOnline: boolean) {
	return { type: SET_NETWORK_STATUS, payload: isOnline };
}

// USER
export const REQUEST_USER_INFORMATIONS = '@@CHALKBOARDEDUCATION/REQUEST_USER_INFORMATIONS';

export const RECEIVE_USER_INFORMATIONS = '@@CHALKBOARDEDUCATION/RECEIVE_USER_INFORMATIONS';

export const FAIL_GET_USER_INFORMATIONS = '@@CHALKBOARDEDUCATION/FAIL_GET_USER_INFORMATIONS';

export function requestUserInformations({ token, tokenIssuedAt }: userInfo) {
	return {
		type: REQUEST_USER_INFORMATIONS,
		payload: { token, tokenIssuedAt }
	};
}

export function receiveUserInformations(user: any) {
	return (dispatch: Function) => {
		return new Promise((resolve, _) => {
			resolve(dispatch({ type: RECEIVE_USER_INFORMATIONS, payload: user }));
		});
	};
}

export function failGetUserInformations(message: any) {
	return { type: FAIL_GET_USER_INFORMATIONS, payload: { message } };
}

export function getUserInformation({ token, tokenIssuedAt }: userInfo) {
	return function(dispatch: Function, _: Function) {
		dispatch(requestUserInformations({ token, tokenIssuedAt }));
		GraphqlClient.query({
			query: UserQuery,
			fetchPolicy: 'network-only',
			variables: { tokenIssuedAt }
		})
			.then((response) => {
				dispatch(receiveUserInformations(response.data.user))
					.then(() => {
						// Reset the translations
						translations(response.data.user.institution.id);
					})
					.then(() => {
						dispatch(
							sendEventToAnalyticsEngine({
								label: ActivityLabel.LOGGED_IN,
								description: `${token} logged in`
							})
						);
					});
			})
			.catch((_) => {
				dispatch(failGetUserInformations(`Token invalid please try again`));
			});
	};
}

/**
 * MODULES
 */
export const RECEIVE_MODULES_INFORMATION = '@@CHALKBOARDEDUCATION/RECEIVE_MODULES_INFORMATION';

export function receiveModulesInformation(modules: ModuleModel[]) {
	return {
		type: RECEIVE_MODULES_INFORMATION,
		payload: { modules }
	};
}

/**
 * CONTENT
 */
export const REQUEST_CONTENT = '@@CHALKBOARDEDUCATION/REQUEST_CONTENT';

export const RECEIVE_CONTENT = '@@CHALKBOARDEDUCATION/RECEIVE_CONTENT';

export const FAIL_GET_CONTENT = '@@CHALKBOARDEDUCATION/FAIL_GET_CONTENT';

export const REINIT_CONTENT_STATES = '@@CHALKBOARDEDUCATION/REINIT_CONTENT_STATES';

export const REQUEST_QUIZ_RESULTS = '@@CHALKBOARDEDUCATION/REQUEST_QUIZ_RESULTS';

export const RECEIVE_QUIZ_RESULTS = '@@CHALKBOARDEDUCATION/RECEIVE_QUIZ_RESULTS';

export const FAIL_GET_QUIZ_RESULTS = '@@CHALKBOARDEDUCATION/FAIL_GET_QUIZ_RESULTS';

export function reInitContentStates() {
	return { type: REINIT_CONTENT_STATES };
}

export function requestContent() {
	return { type: REQUEST_CONTENT };
}

export function receiveContent({
	courses,
	currentDate,
	progressedSessionHashIds,
	submittedQuizHashIds
}: {
	courses: any;
	currentDate: any;
	progressedSessionHashIds: string[];
	submittedQuizHashIds: string[];
}) {
	return {
		type: RECEIVE_CONTENT,
		payload: { courses, currentDate, progressedSessionHashIds, submittedQuizHashIds }
	};
}

export function failGetContent(message: any) {
	return { type: FAIL_GET_CONTENT, payload: { message } };
}

export function requestQuizResults() {
	return { type: REQUEST_QUIZ_RESULTS };
}

export function receiveQuizResults(quizResults: QuizResultModel[]) {
	return {
		type: RECEIVE_QUIZ_RESULTS,
		payload: quizResults
	};
}

export function failGetQuizResults(message: string) {
	return {
		type: FAIL_GET_QUIZ_RESULTS,
		payload: { message }
	};
}

export function getQuizResults() {
	return async function(dispatch: Function, getState: Function) {
		dispatch(requestQuizResults());

		try {
			const response = await GraphqlClient.query({
				query: QuizResultQuery,
				fetchPolicy: 'network-only'
			});
			dispatch(receiveQuizResults(response.data.quizResults));
		} catch (err) {
			console.error(err);
			const locale = getState().settings.locale;
			dispatch(
				failGetQuizResults(
					I18n.t('quizReview.errorFetchingResults', {
						locale
					})
				)
			);
		}
	};
}

export function getContent() {
	return async function(dispatch: Function, getState: Function) {
		const timeOut = setTimeout(() => {
			Sentry.configureScope((scope) => {
				scope.setExtra('operation', 'GetContent');
				Sentry.captureException(
					new TimeoutExceededError(`Request for getting content exceeded timeout of 2 minutes`)
				);
			});
		}, 120000);
		dispatch(requestContent());

		try {
			const userToken = store.getState().currentUser.token;
			const userTokenIssuedAt = store.getState().currentUser.tokenIssuedAt;
			const response = await axiosClient.get('learner/user-courses', {
				headers: {
					Authorization: userToken ? `Bearer ${userToken + '~' + userTokenIssuedAt}` : null
				}
			});

			const {
				modules,
				courses,
				submittedQuizHashIds,
				progressedSessionHashIds,
				currentDate
			} = response.data;

			dispatch(receiveModulesInformation(modules));

			dispatch(
				receiveContent({ courses, currentDate, progressedSessionHashIds, submittedQuizHashIds })
			);

			dispatch(getQuizResults());
		} catch (err) {
			console.log(err);
			const locale = getState().settings.locale;
			dispatch(
				failGetContent(
					I18n.t('update.errorWhileUpdating', {
						locale
					}) +
						'\n' +
						I18n.t('update.connectAndRetryInMinutes', {
							locale
						})
				)
			);
		} finally {
			clearTimeout(timeOut);
		}
	};
}

// FILE
export const FILE_LOADED = '@@CHALKBOARDEDUCATION/FILE_LOADED';
export const FILE_FAILED = '@@CHALKBOARDEDUCATION/FILE_FAILED';

export const SPOOL_TERMINATED = '@@CHALKBOARDEDUCATION/SPOOL_TERMINATED';

export function fileLoaded(file: any) {
	return { type: FILE_LOADED, payload: { file } };
}

export function fileFailed(file: any) {
	return { type: FILE_FAILED, payload: { file } };
}

export function spoolTerminated() {
	return { type: SPOOL_TERMINATED };
}

// GET UPDATES
export const REQUEST_UPDATES = '@@CHALKBOARDEDUCATION/REQUEST_UPDATES';

export const RECEIVE_UPDATES = '@@CHALKBOARDEDUCATION/RECEIVE_UPDATES';

export const FAIL_GET_UPDATES = '@@CHALKBOARDEDUCATION/FAIL_GET_UPDATES';

export const REINIT_UPDATES = '@@CHALKBOARDEDUCATION/REINIT_UPDATES';

export function requestUpdates() {
	return (dispatch: Function) => {
		dispatch({ type: REQUEST_UPDATES });
	};
	// return { type: REQUEST_UPDATES };
}

export function receiveUpdates(updates: any) {
	return { type: RECEIVE_UPDATES, payload: { updates } };
}

export function failGetUpdates(message: any) {
	return { type: FAIL_GET_UPDATES, payload: { message } };
}

export function reinitUpdates() {
	return { type: REINIT_UPDATES };
}

export function getUpdates(updatedAt: any) {
	return (dispatch: Function, getState: Function) => {
		dispatch(requestUpdates());
		dispatch(syncBookmarks());
		dispatch(syncProgressions());

		const { courses } = getState().content;
		const courseHashIDs = courses && Object.keys(courses).toString();

		GraphqlClient.query({
			query: HasUpdatesQuery,
			fetchPolicy: 'network-only',
			variables: {
				dateLastUpdated: updatedAt,
				courseHashIDs: courseHashIDs
			}
		})
			.then((response) => {
				dispatch(receiveUpdates(response.data.hasUpdates));
			})
			.catch((error) => {
				dispatch(failGetUpdates(`Bad response from server: ${error}`));
			});
	};
}

// VALIDATE SESSION
export const REQUEST_VALIDATE_SESSION_INTERNET = '@@CHALKBOARDEDUCATION/REQUEST_VALIDATE_SESSION_INTERNET';
export const RECEIVE_VALIDATE_SESSION_INTERNET = '@@CHALKBOARDEDUCATION/RECEIVE_VALIDATE_SESSION_INTERNET';
export const FAIL_VALIDATE_SESSION_INTERNET = '@@CHALKBOARDEDUCATION/FAIL_VALIDATE_SESSION_INTERNET';
export const RECEIVE_VALIDATE_SESSION_SMS = '@@CHALKBOARDEDUCATION/RECEIVE_VALIDATE_SESSION_SMS';
export const DONE_VALIDATE_SESSION = '@@CHALKBOARDEDUCATION/DONE_VALIDATE_SESSION';

export function requestValidateSessionInternet(sessionHashID: string) {
	return {
		type: REQUEST_VALIDATE_SESSION_INTERNET,
		payload: { sessionHashID }
	};
}

export function failValidateSessionInternet() {
	return { type: FAIL_VALIDATE_SESSION_INTERNET };
}

export function doneValidateSession() {
	return { type: DONE_VALIDATE_SESSION };
}

export function receiveValidateSessionInternet({
	sessionHashID,
	response
}: {
	sessionHashID: string;
	response: any;
}) {
	return {
		type: RECEIVE_VALIDATE_SESSION_INTERNET,
		payload: { sessionHashID, response }
	};
}

export function receiveValidateSessionSMS(sessionHashID: string) {
	return {
		type: RECEIVE_VALIDATE_SESSION_SMS,
		payload: { sessionHashID }
	};
}

export function validateSession(sessionHashID: string) {
	return (dispatch: Function) => {
		dispatch(requestValidateSessionInternet(sessionHashID));

		GraphqlClient.mutate({
			mutation: progressionMutation,
			variables: { sessionHashID }
		})
			.then((data) => {
				dispatch(
					receiveValidateSessionInternet({
						sessionHashID,
						response: data
					})
				);
			})
			.catch(() => {
				dispatch(failValidateSessionInternet());
			});
	};
}

// USER SETTINGS
//LOCALE
export const SETTINGS_SET_LOCALE = '@@CHALKBOARDEDUCATION/SETTINGS/SET_LOCALE';

export function setLocale(locale: string) {
	return (dispatch: Function) => {
		dispatch({ type: SETTINGS_SET_LOCALE, payload: { locale } });
		dispatch(submitLocale(locale));
	};
}

export function submitLocale(locale: string) {
	return (_: Function) => {
		GraphqlClient.mutate({
			mutation: updateLocaleMutation,
			variables: { input: { locale } }
		}).then((_) => {
			ReactGA.event({
				category: 'Settings',
				action: 'Change Language',
				label: locale
			});
		});
	};
}

export const SETTINGS_SET_CONSENT = '@@CHALKBOARDEDUCATION/SETTINGS/SET_CONSENT';

export function setConsent(consent: any) {
	return { type: SETTINGS_SET_CONSENT, payload: { consent } };
}

// SESSION CONTENT
export const REQUEST_SESSION_CONTENT = '@@CHALKBOARDEDUCATION/REQUEST_SESSION_CONTENT';

export const RECEIVE_SESSION_CONTENT = '@@CHALKBOARDEDUCATION/RECEIVE_SESSION_CONTENT';

export const FAIL_GET_SESSION_CONTENT = '@@CHALKBOARDEDUCATION/FAIL_GET_SESSION_CONTENT';

export const SESSION_CONTENT_RECEIVED = '@@CHALKBOARDEDUCATION/SESSION_CONTENT_RECEIVED';

export function requestSessionContent() {
	return { type: REQUEST_SESSION_CONTENT };
}

export function receiveSessionContent(sessionContent: any) {
	return { type: RECEIVE_SESSION_CONTENT, payload: { sessionContent } };
}

export function failGetSessionContent(message: any) {
	return { type: FAIL_GET_SESSION_CONTENT, payload: { message } };
}

function sessionContentReceived() {
	return { type: SESSION_CONTENT_RECEIVED };
}

export const REMOVE_SESSION_FROM_SPOOL = '@@CHALKBOARDEDUCATION/REMOVE_SESSION_FROM_SPOOL';

export function removeSessionFromSpool(sessionHashID: string) {
	return {
		type: REMOVE_SESSION_FROM_SPOOL,
		payload: sessionHashID
	};
}

export function getSessionContent(sessionHashIDs: Session['hashID'][]) {
	return function(dispatch: Function) {
		dispatch(requestSessionContent());

		allSettled(
			sessionHashIDs.map((sessionID) => {
				return GraphqlClient.query({
					query: SessionContentQuery,
					fetchPolicy: 'network-only',
					variables: { hashID: sessionID }
				});
			})
		)
			.then((response: any) => {
				response.forEach((res: any, index: number) => {
					if (res.status === 'fulfilled') {
						dispatch(
							receiveSessionContent({
								hashID: res.value.data.session.hashID,
								content: res.value.data.session.content,
								contentUpdatedAt: res.value.data.session.contentUpdatedAt
							})
						);
					}

					if (res.status == 'rejected' && res.reason?.graphQLErrors.length > 0) {
						res.reason.graphQLErrors.map((err: any) => {
							if (err.extensions?.category == '404') {
								dispatch(removeSessionFromSpool(sessionHashIDs[index]));
							}
						});
					}
				});
			})
			.catch((error: any) => {
				dispatch(failGetSessionContent(`Bad response from server: ${error}`));
			})
			.then(() => dispatch(sessionContentReceived()));
	};
}

// QUIZ
export const SET_USER_RESPONSES = '@@CHALKBOARDEDUCATION/SET_USER_RESPONSES';
export const REQUEST_SUBMIT_QUIZ_INTERNET = '@@CHALKBOARDEDUCATION/REQUEST_SUBMIT_QUIZ_INTERNET';
export const RECEIVE_SUBMIT_QUIZ_INTERNET = '@@CHALKBOARDEDUCATION/RECEIVE_SUBMIT_QUIZ_INTERNET';
export const FAIL_SUBMIT_QUIZ_INTERNET = '@@CHALKBOARDEDUCATION/FAIL_SUBMIT_QUIZ_INTERNET';
export const DONE_SUBMIT_QUIZ = '@@CHALKBOARDEDUCATION/DONE_SUBMIT_QUIZ';
export const RECEIVE_QUIZ_RESULT = '@@CHALKBOARDEDUCATION/RECEIVE_QUIZ_RESULT';

export const SET_RESPONSE_FILES = '@@CHALKBOARDEDUCATION/SET_RESPONSE_FILES';

export const setResponseFiles = ({
	quizHashID,
	questionHashID,
	fileData
}: {
	quizHashID: string;
	questionHashID: string;
	fileData: FileData[];
}) => {
	return {
		type: SET_RESPONSE_FILES,
		payload: {
			quizHashID,
			questionHashID,
			fileData
		}
	};
};

export function setUserResponses({
	quizHashID,
	questionHashID,
	questionType,
	response
}: {
	quizHashID: string;
	questionHashID: string;
	questionType: string;
	response: any;
}) {
	return {
		type: SET_USER_RESPONSES,
		payload: { quizHashID, questionHashID, questionType, response }
	};
}

export function requestSubmitQuizInternet(quizHashID: string) {
	return {
		type: REQUEST_SUBMIT_QUIZ_INTERNET,
		payload: { quizHashID }
	};
}

export function failSubmitQuizInternet() {
	return { type: FAIL_SUBMIT_QUIZ_INTERNET };
}

export function doneSubmitQuiz() {
	return { type: DONE_SUBMIT_QUIZ };
}

export function receiveSubmitQuizInternet({ quizHashID }: { quizHashID: string }) {
	return {
		type: RECEIVE_SUBMIT_QUIZ_INTERNET,
		payload: { quizHashID }
	};
}

export function submitQuiz({
	quizHashID,
	responses
}: {
	quizHashID: string;
	responses: any;
}): ThunkAction<void, Store, unknown, Action> {
	return (dispatch, getState) => {
		const timeOut = setTimeout(() => {
			Sentry.configureScope((scope) => {
				scope.setExtra('operation', 'SubmitQuiz');
				scope.setExtra('variables', {
					quizHashID,
					responses
				});
				Sentry.captureException(
					new TimeoutExceededError(`Request for submitting quiz ${quizHashID} exceeded timeout`)
				);
			});
		}, 65000);
		dispatch(requestSubmitQuizInternet(quizHashID));

		const files = getState().content.quizzes[quizHashID].files;
		const entries = Object.entries(files ?? {});
		const filesToSubmit = entries.map((entry) => ({
			key: entry[0],
			value: entry[1].map((fileData) => dataURLtoFile(fileData.dataAsString, fileData.name))
		}));

		GraphqlClient.mutate({
			context: {
				timeout:
					entries.length > 0
						? QUIZ_SUBMISSION_WITH_FILE_UPLOAD_TIMEOUT_IN_SECONDS * 1000
						: QUIZ_SUBMISSION_DEFAULT_TIMEOUT_IN_SECONDS * 1000
			}, //4 minute timeout for quiz submission with files 60 seconds for regular submission
			mutation: submitQuizMutation,
			variables: {
				hashID: quizHashID,
				responses: responses,
				files: filesToSubmit
			}
		})
			.then((response) => {
				dispatch(receiveSubmitQuizInternet({ quizHashID }));

				// Store Quiz Result in store
				if (response.data && response.data.submitQuiz) {
					const quizResult = response.data.submitQuiz;

					dispatch({
						type: RECEIVE_QUIZ_RESULT,
						payload: quizResult
					});
				}
				dispatch(
					sendEventToAnalyticsEngine({
						label: ActivityLabel.QUIZ_SUBMITTED,
						description: `User ${getState().currentUser.token} submitted quiz ${quizHashID}`
					})
				);
			})
			.catch((error) => {
				console.error(error);
				dispatch(failSubmitQuizInternet());
			})
			.finally(() => {
				clearTimeout(timeOut);
			});
	};
}

//LOGOUT
export const USER_LOGOUT = '@@CHALKBOARDEDUCATION/LOGOUT';

export const REQUEST_USER_LOGOUT = '@@CHALKBOARDEDUCATION/REQUEST_USER_LOGOUT';

export const REQUEST_FORCED_USER_LOGOUT = '@@CHALKBOARDEDUCATION/REQUEST_FORCED_USER_LOGOUT';

export const CANCEL_USER_LOGOUT = '@@CHALKBOARDEDUCATION/CANCEL_USER_LOGOUT';

export function requestUserLogout() {
	return { type: REQUEST_USER_LOGOUT };
}

export function requestForcedUserLogout() {
	return { type: REQUEST_FORCED_USER_LOGOUT };
}

export const userLogout: ThunkAction<void, Store, unknown, Action> = async (dispatch, getState) => {
	dispatch(
		sendEventToAnalyticsEngine(
			{
				label: ActivityLabel.LOGGED_OUT,
				description: `${getState().currentUser.token} logged out`
			},
			{
				headers: {
					Authorization: 'Bearer ' + getState().currentUser.analyticsToken
				} // We have to pass this in because during the logout state gets emptied
			}
		)
	);
	dispatch({
		type: USER_LOGOUT
	});
};

export function cancelUserLogout() {
	return { type: CANCEL_USER_LOGOUT };
}

// BOOKMARKS
export const ADD_BOOKMARK = '@@CHALKBOARDEDUCATION/ADD_BOOKMARK';
export const REMOVE_BOOKMARK = '@@CHALKBOARDEDUCATION/REMOVE_BOOKMARK';
export const RECEIVE_BOOKMARKS = '@@CHALKBOARDEDUCATION/RECEIVE_BOOKMARKS';
export const SUBMIT_BOOKMARKS_SUCCESS = '@@CHALKBOARDEDUCATION/SUBMIT_BOOKMARKS_SUCCESS';

export function addBookmark(bookmark: any) {
	return (dispatch: Function) => {
		dispatch(storeBookmark(bookmark));
		dispatch(syncBookmarks());
	};
}

export function storeBookmark({ url, title }: { url: string; title: any }) {
	ReactGA.event({
		category: 'Bookmark',
		action: 'Added Bookmark',
		label: url
	});

	return {
		type: ADD_BOOKMARK,
		payload: { url, title, createdAt: new Date() }
	};
}

export function removeBookmark(url: string) {
	ReactGA.event({
		category: 'Bookmark',
		action: 'Removed Bookmark',
		label: url
	});

	return (dispatch: Function) => {
		dispatch(deleteBookmark(url));
		dispatch(syncBookmarks());
	};
}

export function deleteBookmark(url: string) {
	return {
		type: REMOVE_BOOKMARK,
		payload: { url }
	};
}

export function receiveBookmarks(bookmarks: any) {
	return {
		type: RECEIVE_BOOKMARKS,
		payload: { bookmarks }
	};
}

export function getBookmarks() {
	return (dispatch: Function) => {
		return GraphqlClient.query({
			query: BookmarksQuery,
			fetchPolicy: 'network-only'
		})
			.then((response) => {
				let bookmarks = formatBookmarks(response.data.bookmarks);

				dispatch(receiveBookmarks(bookmarks));
			})
			.catch((_) => {
				// console.log('failed to get bookmarks', error);
			});
	};
}

export function submitBookmarks(bookmarks: any) {
	return (dispatch: Function) => {
		GraphqlClient.mutate({
			mutation: bookmarkMutation,
			variables: { bookmarks: JSON.stringify(bookmarks) }
		})
			.then((_) => {
				dispatch({
					type: SUBMIT_BOOKMARKS_SUCCESS
				});
			})
			.catch((_) => {
				// console.log('failed to submit bookmarks');
			});
	};
}

//USER PROGRESSIONS
export const RECEIVE_PROGRESSIONS = '@@CHALKBOARDEDUCATION/RECEIVE_PROGRESSIONS';
export const SUBMIT_PROGRESSIONS_SUCCESS = '@@CHALKBOARDEDUCATION/SUBMIT_PROGRESSIONS_SUCCESS';
export const SUBMIT_PROGRESSIONS_FAILED = '@@CHALKBOARDEDUCATION/SUBMIT_PROGRESSIONS_FAILED';

export function getProgressions() {
	return (dispatch: Function) => {
		return GraphqlClient.query({
			query: ProgressionsQuery,
			fetchPolicy: 'network-only'
		})
			.then((response) => {
				let progressions = formatProgressions(response.data.progressions);

				dispatch(receiveProgressions(progressions));
			})
			.catch((_) => {
				// console.log('failed to get progressions', error);
			});
	};
}

export function receiveProgressions(progressions: Progression) {
	return {
		type: RECEIVE_PROGRESSIONS,
		payload: { ...progressions }
	};
}
/*
 * Get progressions from server.
 * Progressions from server are added to local progressions and posted to the server
 */

export const syncProgressions = () => (dispatch: Function, getState: Function) => {
	dispatch(getProgressions()).then(() => {
		let progressions = getState().content.viewed ? getState().content.viewed.sessions : [];

		//prevent submitting empty progressions to backend
		if (progressions.length > 0) {
			dispatch(submitProgressions(progressions));
		}
	});
};

export function submitProgressions(progressions: any) {
	return (dispatch: Function) => {
		GraphqlClient.mutate({
			mutation: bulkProgressionMutation,
			variables: { progressions: JSON.stringify(progressions) }
		})
			.then((_) => {
				dispatch({
					type: SUBMIT_PROGRESSIONS_SUCCESS
				});
			})
			.catch((error) => {
				dispatch({
					type: SUBMIT_PROGRESSIONS_FAILED,
					payload: { message: error }
				});
				// console.log('failed to submit progressions');
			});
	};
}

/*
 * Get bookmarks from server.
 * Bookmarks from server are added to local bookmarks and posted to the server
 */
export const syncBookmarks = () => (dispatch: Function, getState: Function) => {
	if (getState().content.bookmarks.length < 1) {
		dispatch(getBookmarks());
	} else {
		dispatch(getBookmarks()).then(() => {
			let bookmarks = getState().content.bookmarks;

			dispatch(submitBookmarks(bookmarks));
		});
	}
};

// TOGGLED [FOLDERS]
export const TOGGLE_FOLDER = '@@CHALKBOARDEDUCATION/TOGGLE_FOLDER';

export const toggleFolder = (folderHashID: string) => {
	return {
		type: TOGGLE_FOLDER,
		payload: folderHashID
	};
};

// VIEWED
export const ADD_VIEWED_SESSION = '@@CHALKBOARDEDUCATION/ADD_VIEWED_SESSION';
export const ADD_VIEWED_FOLDER = '@@CHALKBOARDEDUCATION/ADD_VIEWED_FOLDER';

export const addViewedSession = (sessionHashID: string) => {
	return {
		type: ADD_VIEWED_SESSION,
		payload: sessionHashID
	};
};

export const addViewedFolder = (folderHashID: string) => {
	return {
		type: ADD_VIEWED_FOLDER,
		payload: folderHashID
	};
};

export const COMPLETE_TOUR = '@@CHALKBOARDEDUCATION/COMPLETE_TOUR';
export const RESET_TUTORIAL = '@@CHALKBOARDEDUCATION/RESET_TUTORIAL';

export const completeTutorial = (page: any) => {
	return {
		type: COMPLETE_TOUR,
		payload: page
	};
};

export const resetTutorial = () => {
	return {
		type: RESET_TUTORIAL
	};
};

export const DISPATCH_ANALYTICS_EVENT = '@@CHALKBOARDEDUCATION/DISPATCH_ANALYTICS_EVENT';

export type UserActivityPayload = {
	label: ActivityLabel;
	description: string;
};

export const sendEventToAnalyticsEngine = (
	payload: UserActivityPayload,
	config?: AxiosRequestConfig
): ThunkAction<void, Store, unknown, Action> => async (dispatch: Dispatch, getState) => {
	await sendActivityData(
		{
			label: payload.label,
			description: payload.description,
			timestamp: new Date().getTime(),
			user_api_token: getState().currentUser.token,
			institution_id: getState().currentUser.institution.id
		},
		config
	);
	dispatch({
		type: DISPATCH_ANALYTICS_EVENT,
		payload
	});
};
