import { Content, Course, Folder, Session } from '../types';

export class CourseManager {
	static getCourse(courseItems: Content['courses'], courseHashID: Course['hashID']) {
		return courseItems[courseHashID];
	}

	static getFolder(folderItems: Content['folders'], folderHashID: Folder['hashID']) {
		return folderItems[folderHashID];
	}

	static getSession(sessionsItems: Content['sessions'], sessionHashID: Session['hashID']) {
		return sessionsItems[sessionHashID];
	}

	static getFoldersFromCourse(
		foldersItems: Content['folders'],
		courseHashID: Course['hashID'] | undefined
	) {
		let folders: Content['folders'] = {};
		for (let key in foldersItems) {
			if (foldersItems[key].courseHashID === courseHashID) {
				folders[key] = foldersItems[key];
			}
		}

		return folders;
	}

	static getSessionsFromFolder(sessionsItems: Content['sessions'], folderHashID: Folder['hashID']) {
		let sessions: Content['sessions'] = {};
		for (let key in sessionsItems) {
			if (sessionsItems[key].folderHashID === folderHashID) {
				sessions[key] = sessionsItems[key];
			}
		}

		return sessions;
	}

	static getNextSession(sessionsItems: Content['sessions'], session: Session) {
		const courseHashID = session.courseHashID;
		const folderHashID = session.folderHashID;
		const currentIndex = session.index;

		for (let key in sessionsItems) {
			if (
				sessionsItems[key].courseHashID === courseHashID &&
				sessionsItems[key].folderHashID === folderHashID &&
				sessionsItems[key].index === currentIndex + 1
			) {
				return sessionsItems[key];
			}
		}

		return null;
	}

	static getPreviousSession(sessionsItems: Content['sessions'], session: Session) {
		const courseHashID = session.courseHashID;
		const folderHashID = session.folderHashID;
		const currentIndex = session.index;

		for (let key in sessionsItems) {
			if (
				sessionsItems[key].courseHashID === courseHashID &&
				sessionsItems[key].folderHashID === folderHashID &&
				sessionsItems[key].index === currentIndex - 1
			) {
				return sessionsItems[key];
			}
		}

		return null;
	}
}

export default CourseManager;
