import { Common, Session } from '../../types';

export class UnBlockSession {
	static getHexString() {
		return '0123456789abcdef';
	}

	static getUnlockCodeForSession(userHashID: string, sessionHashID: Session['hashID'], privateKey: string) {
		return (
			this.getCodeFromHashID(userHashID, privateKey) + this.getCodeFromHashID(sessionHashID, privateKey)
		);
	}

	static getCodeFromHashID(hashID: Common['hashID'], privateKey: string) {
		const hexString = this.getHexString();

		const remainingLength = privateKey.length - hexString.length - 1;
		const begin = Math.floor(Math.random() * remainingLength);
		const firstChar = privateKey.charAt(begin);

		let chars: Array<string | number> = hashID.split('');

		// remove '-'
		chars = chars.filter((char) => char !== '-');

		// replace hex by equivalent int
		chars = chars.map((char) => hexString.indexOf(char as string));

		return firstChar + chars.map((char) => privateKey.charAt(begin + (char as number) + 1)).join('');
	}

	static getHashIDFromCode(code: string, privateKey: string) {
		const hexString = this.getHexString();

		let chars: Array<string | number> = code.split('');
		const firstChar = chars[0];
		const begin = privateKey.indexOf(firstChar as string);

		chars.shift();
		chars = chars.map((char) => privateKey.indexOf(char as string) - begin - 1);

		const result = chars.map((char) => hexString.charAt(char as number)).join('');

		// set '-' separator
		return result.substr(0, 10) + '-' + result.substr(10, 5) + '-' + result.substr(15, 5);
	}

	/**
	 * @param {string} code
	 * @param {string} privateKey
	 * @returns {string}
	 */
	static getSessionHashIDFromCode(code: string, privateKey: string) {
		return this.getHashIDFromCode(code.substr(code.length / 2, code.length), privateKey);
	}

	/**
	 * @param {string} code
	 * @param {string} privateKey
	 * @returns {string}
	 */
	static getUserHashIDFromCode(code: string, privateKey: string) {
		return this.getHashIDFromCode(code.substr(0, code.length / 2), privateKey);
	}
}

export default UnBlockSession;
