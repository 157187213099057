import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
	createStyles({
		fixed: {
			position: 'fixed',
			top: 0,
			width: '100%'
		},
		downloading: {
			display: 'inherit',
			padding: '1em',
			borderRadius: 0,
			maxWidth: '100%'
		},
		content: {
			zIndex: 1,
			position: 'relative',
			borderRadius: 0,
			maxWidth: '100%'
		},
		downloadIcon: {
			marginRight: theme.spacing(1)
		}
	});
