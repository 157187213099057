import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { Link, RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Recaptcha from 'react-recaptcha';
import { HOME, LOGIN } from '../config/routes';
import I18n from 'i18n-js';
import countries from '../config/countries';
import generateUrl from '../services/generateUrl';
import axiosClient from '../services/rest/axiosClient';
import { Store } from '../types';

const linkProps: any = {
	variant: 'body2'
};

const useStyles = makeStyles((theme) => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}));

interface Props extends Pick<RouteComponentProps, 'history'> {}

const RegisterScreen = (props: Props) => {
	const classes = useStyles();

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const [user, setUser] = useState({
		first_name: '',
		last_name: '',
		phone_number: '+',
		country: 'CI',
		locale: '',
		subdomain: ''
	});

	const [error, setError] = useState('');
	const [validated, setValidated] = useState(false);
	const [verifiedHuman, setVerifiedHuman] = useState(false);
	const [isRegistering, setIsRegistering] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setUser({
			...user,
			[name]: value
		});
	};

	const getSubdomain = (url: string) => {
		let subdomain = '';
		const urlParts = url.split('.');

		if (urlParts.length >= 3) {
			subdomain = urlParts[0];
		}

		return subdomain;
	};

	const handleSubmit = () => {
		setIsRegistering(true);

		axiosClient
			.post('api/users/self-register', user)
			.then((result) => {
				setIsRegistering(false);
				props.history.push(
					generateUrl(LOGIN, {
						':token': result.data.api_token
					})
				);
			})
			.catch((error) => {
				const errors = error.response.data.errors;
				let errorString = '';

				if (errors) {
					Object.keys(errors).forEach((errorKey) => {
						errorString += `${errors[errorKey]}<br />`;
					});
					setError(errorString);
				}
				setIsRegistering(false);
			});
	};

	const recaptchaLoadedCallback = () => {
		// console.log('recaptcha loaded successfully!');
	};

	const recaptchaVerifyCallback = (response: string) => {
		if (response) {
			setVerifiedHuman(true);
		}
	};

	const recaptchaExpiredCallback = () => {
		setVerifiedHuman(false);
	};

	const isFormValidated = () => {
		if (user.first_name.length < 1) {
			setError(I18n.t('register.hint.firstName', { locale }));
			return false;
		}

		if (user.last_name.length < 1) {
			setError(I18n.t('register.hint.lastName', { locale }));
			return false;
		}

		if (user.phone_number.length < 10) {
			setError(I18n.t('register.hint.phoneNumberLength', { locale }));
			return false;
		}

		if (!user.phone_number.includes('+')) {
			setError(I18n.t('register.hint.phoneNumberFormat', { locale }));
			return false;
		}

		if (user.locale === '') {
			setError(I18n.t('register.hint.language', { locale }));
			return false;
		}

		if (verifiedHuman === false) {
			setError(I18n.t('register.hint.verifyHuman', { locale }));
			return false;
		}

		setError('');
		return true;
	};

	useEffect(() => {
		setValidated(isFormValidated());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, verifiedHuman]);

	useEffect(() => {
		const subdomain = getSubdomain(window.location.host);

		setUser({
			...user,
			subdomain
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container
			style={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column'
			}}
			component="main"
			maxWidth="sm"
		>
			<CssBaseline />
			<div style={{ padding: '40px 20px' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						{I18n.t('register.signUp', { locale })}
					</Typography>
				</div>
				<div className={classes.form}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								onChange={handleChange}
								autoComplete="fname"
								name="first_name"
								value={user.first_name}
								variant="outlined"
								required
								fullWidth
								label={I18n.t('register.label.firstName', {
									locale
								})}
								autoFocus
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
								label={I18n.t('register.label.lastName', {
									locale
								})}
								name="last_name"
								value={user.last_name}
								autoComplete="lname"
							/>
						</Grid>
						<Grid item xs={12}>
							{/* <MuiPhoneNumber defaultCountry={'us'} onChange={handlePhoneChange}/> */}
							<TextField
								onChange={handleChange}
								variant="outlined"
								required
								fullWidth
								label={I18n.t('register.label.phoneNumber', {
									locale
								})}
								name="phone_number"
								type="tel"
								value={user.phone_number}
								// autoComplete="phone"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								select
								variant="outlined"
								label="Country"
								name="country"
								margin="normal"
								fullWidth
								// disabled={ isSaving }
								value={user.country}
								onChange={handleChange}
							>
								{countries.map((country, index) => (
									<MenuItem key={index} value={country.id}>
										{country.label}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={12}>
							<TextField
								select
								variant="outlined"
								label={I18n.t('register.label.language', {
									locale
								})}
								margin="normal"
								fullWidth
								name="locale"
								// disabled={isRegistering}
								value={user.locale}
								onChange={handleChange}
								style={{
									marginTop: '0px',
									marginBottom: '0px'
								}}
							>
								<MenuItem value="en">English</MenuItem>
								<MenuItem value="fr">Français</MenuItem>
							</TextField>
						</Grid>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '20px' }}>
						<Recaptcha
							sitekey="6LcfS-cUAAAAADFc7GpmZ5TLGZMR7ahKkomZYQEp"
							render="explicit"
							onloadCallback={recaptchaLoadedCallback}
							verifyCallback={recaptchaVerifyCallback}
							expiredCallback={recaptchaExpiredCallback}
						/>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={!validated || isRegistering}
						onClick={() => handleSubmit()}
					>
						{isRegistering
							? I18n.t('register.registering', { locale })
							: I18n.t('register.button', { locale })}
					</Button>
					{error.length > 1 && (
						<div style={{ margin: '0px' }}>
							<span
								style={{
									position: 'relative',
									top: '2px',
									fontSize: '16px',
									color: '#f50057'
								}}
							>
								*
							</span>
							<span
								dangerouslySetInnerHTML={{ __html: error }}
								style={{
									marginTop: '0px',
									fontSize: '13px',
									color: '#f50057'
								}}
							/>
						</div>
					)}
					<Grid container justify="center" style={{ marginTop: '10px' }}>
						<Grid item>
							<Link to={HOME} {...linkProps} style={{ cursor: 'pointer', color: '#0061f5' }}>
								{I18n.t('register.loginHint', { locale })}
							</Link>
						</Grid>
					</Grid>
				</div>
			</div>
		</Container>
	);
};

export default RegisterScreen;
