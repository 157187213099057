import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import I18n from 'i18n-js';
import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SURVEY } from '../../config/constants';
import CourseManager from '../../services/CourseManager';
import QuizManager from '../../services/quiz/QuizManager';
import { SessionNavigation } from '../../services/session/SessionNavigation';
import EndIcon from '../icons/EndIcon';
import NextIcon from '../icons/NextIcon';
import PreviousIcon from '../icons/PreviousIcon';
import ToolbarGap from '../utils/ToolbarGap';
import { Theme } from '@material-ui/core';
import { Session, Store } from '../../types';
import { WithStyles } from '@material-ui/core/styles';
import SessionProgressionSender from '../../services/session/SessionProgressionSender';
import { addViewedSession, validateSession } from '../../redux/actions/actionCreators';
import analyticsClient from '../../services/analytics/analyticsClient';
import { Indexes } from '../../services/analytics/indexes';
import TimeMe from 'timeme.js';

const styles = (theme: Theme) => ({
	footer: {
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(1)
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	}
});

interface Props extends RouteComponentProps, WithStyles<typeof styles>, Pick<Session, 'courseHashID'> {
	session: Session;
	readingStartTime: Date;
}

const SessionFooter = (props: Props) => {
	const { classes, readingStartTime, session } = props;

	const dispatch = useDispatch();

	const myProps = useSelector(
		({
			currentUser: {
				token,
				institution: { id }
			},
			content: {
				quizzes,
				sessions,
				viewed: { sessions: viewedSessions }
			},
			settings: { locale }
		}: Store) => {
			const quiz = QuizManager.getQuiz(quizzes, session.quizHashID);
			const previousSession = CourseManager.getPreviousSession(sessions, session);
			const nextSession = CourseManager.getNextSession(sessions, session);

			return { quiz, previousSession, nextSession, locale, viewedSessions, token, id };
		}
	);

	const { viewedSessions } = myProps;

	const sendSessionProgressionToAPI = (sessionHashID: Session['hashID']) => {
		dispatch(validateSession(sessionHashID));
	};

	const addUnviewedToViewedSessions = (sessionHashID: Session['hashID']) => {
		if (!viewedSessions.includes(sessionHashID)) {
			dispatch(addViewedSession(sessionHashID));
		}
	};

	const progressionSenderService = {
		send: (session: Session) => {
			sendSessionProgressionToAPI(session.hashID);
			addUnviewedToViewedSessions(session.hashID);
		}
	};

	const trackingRef = useRef(new SessionProgressionSender(progressionSenderService));
	let sessionProgressionTracker = trackingRef.current;

	useEffect(() => {
		const { token, id } = myProps;
		const institution_id = typeof id === 'string' ? parseInt(id) : id;

		TimeMe.initialize({
			currentPageName: session.hashID,
			idleTimeoutInSeconds: 90
		});

		TimeMe.callWhenUserLeaves(() => {
			const timeOnPage = TimeMe.getTimeOnPageInSeconds(session.hashID);
			if (typeof timeOnPage === 'number' && timeOnPage > 0) {
				analyticsClient
					.sendDataToIndex(Indexes.USER_SESSION_DURATION_V1, {
						course_hash_id: session.courseHashID,
						duration_in_ms: Math.round(timeOnPage * 1000),
						folder_hash_id: session.folderHashID,
						institution_id: institution_id,
						session_hash_id: session.hashID,
						user_api_token: token,
						timestamp: new Date().getTime()
					})
					.then(() => TimeMe.resetRecordedPageTime(session.hashID));
			}
		});

		return () => {
			const timeOnPage = TimeMe.getTimeOnPageInSeconds(session.hashID);
			if (typeof timeOnPage === 'number' && timeOnPage > 0) {
				analyticsClient
					.sendDataToIndex(Indexes.USER_SESSION_DURATION_V1, {
						course_hash_id: session.courseHashID,
						duration_in_ms: Math.round(timeOnPage * 1000),
						folder_hash_id: session.folderHashID,
						institution_id: institution_id,
						session_hash_id: session.hashID,
						user_api_token: token,
						timestamp: new Date().getTime()
					})
					.finally(() => {
						TimeMe.userLeftCallbacks = [];
						TimeMe.stopTimer(session.hashID);
						TimeMe.resetRecordedPageTime(session.hashID);
					});
			}
		};
	}, [session.hashID]);

	useEffect(() => {
		sessionProgressionTracker.delaySendingProgressionForSession(session);

		return () => {
			sessionProgressionTracker.cancelSendingOutPendingProgression();
		};
	}, []);

	const { quiz, previousSession, nextSession, locale } = myProps;

	const isQuizNotSubmitted = quiz && QuizManager.isQuizzable(quiz);

	const sessionNavigation = new SessionNavigation({ ...props, ...myProps });

	const handleNext = () => {
		let currentTime = new Date();
		let timeRead = currentTime.getTime() - readingStartTime.getTime();

		ReactGA.event({
			category: 'Session Read',
			action: 'Next Session Click',
			label: String(session.hashID),
			value: timeRead
		});

		sessionProgressionTracker.sendProgressionImmediatelyIfStillPending();
		nextSession && sessionProgressionTracker.delaySendingProgressionForSession(nextSession);

		return sessionNavigation.next();
	};

	const handlePrevious = () => {
		return sessionNavigation.previous();
	};

	const nextButtonLabel = () => {
		if (isQuizNotSubmitted && quiz) {
			if (quiz.type === SURVEY) {
				return 'session.surveyButton';
			}
			return 'session.quizButton';
		}

		if (!nextSession) {
			return 'session.end';
		}

		return 'session.nextButton';
	};

	return (
		<div className={classes.footer}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					{previousSession && (
						<Button
							className="session_footer__previous_btn"
							data-test-id="session_footer__previous_btn"
							variant="outlined"
							color="primary"
							fullWidth
							onClick={handlePrevious}
						>
							<PreviousIcon className={classes.leftIcon} />
							{I18n.t('session.previous', { locale })}
						</Button>
					)}
				</Grid>

				<Grid item xs={6}>
					<Button
						className="session_footer__next_btn"
						data-test-id="session_footer__next_btn"
						variant="contained"
						disabled={!session.spoolComplete}
						fullWidth
						color={isQuizNotSubmitted ? 'secondary' : 'primary'}
						onClick={handleNext}
					>
						{I18n.t(nextButtonLabel(), { locale })}
						{nextSession ? (
							<NextIcon className={classes.rightIcon} data-test-id="session_footer_next_icon" />
						) : (
							<EndIcon className={classes.rightIcon} data-test-id="session_footer_end_icon" />
						)}
					</Button>
				</Grid>
			</Grid>

			{/* Gap to prevent safari double-click requirement when too close to page bottom */}
			<ToolbarGap />
		</div>
	);
};

export default withRouter(withStyles(styles)(SessionFooter));
