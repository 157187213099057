import React, { ChangeEvent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toBase64 } from '../services/fileHandling';
import Grid from '@material-ui/core/Grid';
import I18n from 'i18n-js';
import Typography from '@material-ui/core/Typography';
import SquareTile from './SquareTile';
import { useSelector } from 'react-redux';
import { Store } from '../types';
import UploadButton from './UploadButton';
import DeleteIcon from '@material-ui/icons/Delete';
import unionBy from 'lodash/unionBy';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';

export type FileData = {
	dataAsString: string;
	name: File['name'];
	size: File['size'];
	type: File['type'];
	lastModified: File['lastModified'];
};

interface Props extends RouteComponentProps {
	onFilesChange: (files: FileData[]) => boolean; // boolean to indicate where files should be stored in local file widget state
	files: FileData[];
	id: string | number;
	isRequired?: boolean;
}

const FileTile = (props: { file: FileData; onRemove: () => void }) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	return (
		<>
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
				<DialogTitle>
					{I18n.t('question.confirmRemove', { locale })} {props.file.name}?
				</DialogTitle>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)}>
						{I18n.t('logout.cancelButton', { locale })}
					</Button>
					<Button onClick={props.onRemove}>{I18n.t('question.confirm', { locale })}</Button>
				</DialogActions>
			</Dialog>
			<SquareTile
				icon={<DeleteIcon style={{ opacity: 0.6 }} />}
				text={props.file.name}
				onTap={() => setDialogOpen(true)}
			/>
		</>
	);
};

const FileUploadWidget = (props: Props) => {
	const [files, setFiles] = useState<FileData[]>(props.files ?? []);

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const convertFilesToFileData = async (files: File[]) => {
		const filesData: FileData[] = [];
		for (const file of files) {
			filesData.push({
				name: file.name,
				size: file.size,
				type: file.type,
				lastModified: file.lastModified,
				dataAsString: (await toBase64(file)) as string
			});
		}
		return filesData;
	};

	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const incomingFiles = Array.prototype.slice.call(event.target.files);
			event.target.value = '';
			const filesData = await convertFilesToFileData(incomingFiles);
			const shouldUpdate = props.onFilesChange(unionBy(filesData, files, 'name'));
			shouldUpdate && setFiles(unionBy(filesData, files, 'name'));
		}
	};

	const onRemove = (filename: string) => {
		const newFiles = files.filter((file) => file.name !== filename);
		const shouldUpdate = props.onFilesChange(newFiles);
		shouldUpdate && setFiles(newFiles);
	};

	const renderEmptyFileUpload = () => (
		<Grid container alignItems="center" justifyContent="center" direction="row" spacing={2} wrap="nowrap">
			<div style={{ padding: '0 0.75rem' }}>
				<UploadButton handleFileChange={handleFileChange} id={props.id} />
			</div>
			<div style={{ textAlign: 'center' }}>
				<Typography color={'textSecondary'}>{I18n.t('quiz.tapToUpload', { locale })}</Typography>
				{props.isRequired ? (
					<Typography style={{ color: 'red' }} variant={'caption'}>
						{I18n.t('badge.required', { locale })}
					</Typography>
				) : null}
			</div>
		</Grid>
	);

	const renderSelectedFileUpload = () => (
		<Grid container alignItems="center" justifyContent="center" spacing={2}>
			<Grid item>
				<UploadButton handleFileChange={handleFileChange} id={props.id} />
			</Grid>
			{files.map((file) => (
				<Grid item key={`${file.name}_${file.lastModified}`}>
					<FileTile file={file} onRemove={() => onRemove(file.name)} />
				</Grid>
			))}
		</Grid>
	);

	return files.length > 0 ? renderSelectedFileUpload() : renderEmptyFileUpload();
};

export default withRouter(FileUploadWidget);
