import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';

import { cancelUserLogout, userLogout } from '../redux/actions/actionCreators';
import { Store } from '../types';

export const Logout = () => {
	const myProps = useSelector(({ logout, settings: { locale } }: Store) => ({
		logout,
		locale
	}));

	const {
		logout: { loggingOut, isForced },
		locale
	} = myProps;

	const [open, setOpen] = useState(loggingOut);
	const dispatch = useDispatch();

	useEffect(() => {
		setOpen(loggingOut);
	}, [loggingOut]);

	const handleLogout = () => {
		ReactGA.event({
			category: 'Logout',
			action: 'Logged out',
			label: isForced ? 'Forced' : 'Voluntary'
		});

		dispatch(userLogout);
	};

	const handleClose = () => {
		dispatch(cancelUserLogout());
	};

	return (
		<Dialog disableBackdropClick={isForced} open={open} onClose={handleClose}>
			<DialogTitle data-test-id="logout_title">
				{I18n.t(isForced ? 'error.authenticationError' : 'logout.title', {
					locale
				})}
			</DialogTitle>

			<DialogContent>
				<DialogContentText data-test-id="logout_content-text">
					{I18n.t(isForced ? 'tokenError.402' : 'logout.message', {
						locale
					})}
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				{!isForced && (
					<Button data-test-id="logout_cancel-button" color="secondary" onClick={handleClose}>
						{I18n.t('logout.cancelButton', { locale })}
					</Button>
				)}

				<Button
					data-test-id="logout_proceed-button"
					variant="contained"
					color="secondary"
					onClick={handleLogout}
					fullWidth={isForced}
				>
					{I18n.t('logout.button', { locale })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Logout;
