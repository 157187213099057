import { persistStore } from 'redux-persist';
import { reInitContentStates, reinitUpdates } from '../actions/actionCreators';
import networkStatusEventListener from '../../services/network/networkStatusEventListener';
import clock from '../../services/updates/clock';
import store from './store';

export default persistStore(store, null, () => {
	store.dispatch(reinitUpdates());
	store.dispatch(reInitContentStates());
	networkStatusEventListener();
	clock();
});
