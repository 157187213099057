import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import I18n from 'i18n-js';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ABOUT, ACCOUNT, BOOKMARKS, COURSES, HELP, MODULES, QUIZ_REVIEW } from '../../config/routes';
import ModuleManager from '../../services/module/ModuleManager';
import AboutIcon from '../icons/AboutIcon';
import AccountIcon from '../icons/AccountIcon';
import BookmarksIcon from '../icons/BookmarksIcon';
import CourseIcon from '../icons/CourseIcon';
import HelpIcon from '../icons/HelpIcon';
import ModuleIcon from '../icons/ModuleIcon';
import QuizReviewIcon from '../icons/QuizReviewIcon';
import { Store } from '../../types';
import { ActivityLabel } from '../../services/analytics/userActivityClient';
import { sendEventToAnalyticsEngine } from '../../redux/actions/actionCreators';

interface Props {
	open: boolean;
	handleClick: (route: string) => void;
}

type MenuItem = { text: string; route: string; icon: ReactElement; activityLabel: ActivityLabel };

const drawerWidth = 240;

const styles: Function = makeStyles((theme) => ({
	root: {
		zIndex: '1200 !important' as any
	},
	drawerPaper: {
		position: 'fixed',
		width: drawerWidth
	},
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(3),
		minWidth: 0 // So the Typography noWrap works
	},
	toolbar: theme.mixins.toolbar
}));

const Sidebar = (props: Props) => {
	const dispatch = useDispatch();
	const myProps = useSelector(
		({ currentUser: { token }, content: { modules, courses }, settings: { locale } }: Store) => ({
			modules,
			courses,
			locale,
			token
		})
	);

	const { open } = props;
	const { token, modules, courses, locale } = myProps;
	const classes = styles();

	const showMenuList = () => {
		const menuItems: Array<MenuItem> = [
			{
				text: 'menu.modules',
				route: MODULES,
				icon: <ModuleIcon />,
				activityLabel: ActivityLabel.MODULE_PAGE_VIEW
			},
			{
				text: 'menu.courses',
				route: COURSES,
				icon: <CourseIcon />,
				activityLabel: ActivityLabel.COURSE_PAGE_VIEW
			},
			{
				text: 'menu.bookmarks',
				route: BOOKMARKS,
				icon: <BookmarksIcon />,
				activityLabel: ActivityLabel.BOOKMARK_PAGE_VIEW
			},
			{
				text: 'menu.quizReview',
				route: QUIZ_REVIEW,
				icon: <QuizReviewIcon />,
				activityLabel: ActivityLabel.QUIZ_RESULTS_PAGE_VIEW
			},
			{
				text: 'menu.help',
				route: HELP,
				icon: <HelpIcon />,
				activityLabel: ActivityLabel.HELP_PAGE_VIEW
			},
			{
				text: 'menu.about',
				route: ABOUT,
				icon: <AboutIcon />,
				activityLabel: ActivityLabel.ABOUT_PAGE_VIEW
			},
			{
				text: 'menu.account',
				route: ACCOUNT,
				icon: <AccountIcon />,
				activityLabel: ActivityLabel.ACCOUNT_PAGE_VIEW
			}
		];

		// Remove the Modules menu item if the user has no Modules but some Courses.
		if ((!modules || Object.keys(modules).length === 0) && courses && Object.keys(courses).length >= 1) {
			menuItems.shift();
		}

		// Remove the Courses menu item if the user has all their Courses in Modules
		else if (
			modules &&
			Object.keys(modules).length > 0 &&
			ModuleManager.isAllCoursesInModules(modules, courses)
		) {
			let courseIndex = menuItems.findIndex((menuItem) => menuItem.route === COURSES);

			if (courseIndex !== -1) {
				menuItems.splice(courseIndex, 1);
			}
		}

		return menuItems.map((item, index) => {
			return (
				<React.Fragment key={index}>
					<ListItem
						button
						onClick={async () => {
							dispatch(
								sendEventToAnalyticsEngine({
									label: item.activityLabel,
									description: `User ${token} viewed page`
								})
							);
							props.handleClick(item.route);
						}}
					>
						<ListItemIcon>{item.icon}</ListItemIcon>

						<ListItemText primary={I18n.t(item.text, { locale })} />
					</ListItem>

					{item.text === 'menu.quizReview' && <Divider light />}
				</React.Fragment>
			);
		});
	};

	return (
		<Drawer
			data-test-id="sidebar_drawer"
			variant="temporary"
			anchor="left"
			open={open}
			classes={{ root: classes.root, paper: classes.drawerPaper }}
		>
			<Divider />

			<div className={classes.toolbar} />
			<List>{showMenuList()}</List>
		</Drawer>
	);
};

export default React.memo(Sidebar);
