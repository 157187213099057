import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import PurchaseCoursesButton from '../components/course/PurchaseCoursesButton';
import ModuleIcon from '../components/icons/ModuleIcon';
import ModuleList from '../components/module/ModuleList';
import CheckForUpdatesButton from '../components/updates/CheckForUpdatesButton';
import { COURSES, MODULE_COURSE_LIST } from '../config/routes';
import generateUrl from '../services/generateUrl';
import { Module, Store } from '../types';
import { Theme, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
	root: {
		padding: '1em',
		backgroundColor: 'white',
		height: '100%',
		position: 'absolute',
		top: '0px',
		textAlign: 'center'
	} as const,
	icon: {
		color: theme.palette.background.default,
		fontSize: '60vmin'
	}
});

interface Props extends WithStyles<typeof styles>, Pick<RouteComponentProps, 'history'> {}

const ModuleScreen = ({ classes, history }: Props) => {
	const myProps = useSelector(
		({
			content: { modules, courses },
			currentUser: {
				institution: { isMerchant }
			},
			updates: { hasUpdates },
			settings: { locale }
		}: Store) => ({
			modules,
			courses,
			locale,
			isMerchant,
			hasUpdates
		})
	);

	const { modules, courses, locale, isMerchant, hasUpdates } = myProps;

	/**
	 * Open Module
	 * @param module
	 * @returns {*}
	 */
	const handleNext = (module: Module) => {
		return history.push(generateUrl(MODULE_COURSE_LIST, { ':moduleHashID': module.hashID }));
	};

	/**
	 * No modules available
	 */
	if (!modules || Object.keys(modules).length === 0) {
		// Redirect to Courses List if there are 0 modules but >0 courses
		if (courses && Object.keys(courses).length > 0) {
			return <Redirect to={COURSES} />;
		}

		if ((!courses || Object.keys(courses).length === 0) && !hasUpdates && isMerchant) {
			return (
				<Grid container direction="column" alignItems="center" className={classes.root}>
					<Grid item data-test-id="course-screen_empty-state" className="screen-centered">
						<div style={{ margin: 0 }}>
							<ModuleIcon className={classes.icon} />
						</div>

						<Typography variant="subtitle1" color="textSecondary" style={{ margin: '0.3em 0' }}>
							{I18n.t('courses.noPurchases', { locale })}
						</Typography>

						<PurchaseCoursesButton locale={locale} />
					</Grid>
				</Grid>
			);
		}
		return (
			<Grid container direction="column" alignItems="center" className={classes.root}>
				<Grid item data-test-id="module-screen_empty-state" className="screen-centered">
					<div style={{ margin: 0 }}>
						<ModuleIcon className={classes.icon} />
					</div>

					<Typography variant="subtitle1" color="textSecondary" style={{ margin: '0.3em 0' }}>
						{I18n.t('modules.none', { locale })}
					</Typography>

					<CheckForUpdatesButton data-test-id="module-screen_check-for-updates-button" />
				</Grid>
			</Grid>
		);
	}

	/**
	 * Modules available
	 */
	return <ModuleList data-test-id="module-screen_modules-list" modules={modules} handleNext={handleNext} />;
};

export default withStyles(styles)(ModuleScreen);
