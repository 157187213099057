import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';
import th from 'date-fns/locale/th';
import zh from 'date-fns/locale/zh-CN';
import vi from 'date-fns/locale/vi';

export const getBookmarkLocale = (locale: string) => {
	switch (locale) {
		case 'fr':
			return fr;
		case 'es':
			return es;
		case 'pt':
			return pt;
		case 'th':
			return th;
		case 'zh':
			return zh;
		case 'vi':
			return vi;
		default:
			return en;
	}
};
