import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import I18n from 'i18n-js';
import React from 'react';
import { SURVEY } from '../../../config/constants';
import QuizManager from '../../../services/quiz/QuizManager';
import QuestionAnswers from '../QuestionAnswers';
import QuestionSolution from './QuestionSolution';
import { QuizReviewProps } from '../../../containers/QuestionScreen';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		marginBottom: '15px'
	},
	questionImage: {
		width: '100%',
		height: 'auto',
		maxWidth: '400px'
	}
}));

const QuestionItem = (props: QuizReviewProps) => {
	const { question, quiz, questionIndex, totalQuestions, locale, questionResponse } = props;

	const userResponse = QuizManager.formatQuizResultResponse(questionResponse, question);

	const classes = useStyles();

	return (
		<div className="content-layout">
			<Grid container direction="column" className={classes.root}>
				<Grid item xs={12}>
					<Typography
						variant="subtitle2"
						color="textSecondary"
						className="joyride-question-indicator"
						data-test-id="question-screen_subtitle"
					>
						{I18n.t('question.question', { locale })} {parseInt(questionIndex, 10) + 1}{' '}
						{I18n.t('question.of', { locale })} {totalQuestions}
					</Typography>

					<Typography
						data-test-id="question-screen_title"
						variant="h6"
						gutterBottom
						style={{
							fontSize: '1.30rem',
							textTransform: 'capitalize',
							margin: '0px'
						}}
					>
						{question.title}
					</Typography>
					{question.image && (
						<img
							src={question.image.url}
							alt="question"
							className={classes.questionImage}
							data-test-id="question-screen_image"
						/>
					)}
				</Grid>

				<Grid item xs={12}>
					<QuestionAnswers
						isReadOnly
						question={question}
						userResponse={userResponse}
						locale={locale}
						data-test-id="question-screen_question_answers"
					/>
				</Grid>
				{quiz.type !== SURVEY && (
					<Grid item style={{ marginTop: '20px' }} xs={12}>
						<QuestionSolution
							question={question}
							questionResponse={questionResponse}
							userResponse={userResponse}
							locale={locale}
						/>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default QuestionItem;
