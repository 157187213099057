import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import I18n from 'i18n-js';
import React, { ChangeEvent, useState } from 'react';
import ReactGA from 'react-ga';
import { Dispatch } from 'redux';
import { useSelector } from 'react-redux';
import { resetTutorial } from '../redux/actions/actionCreators';
import SupportPanel from '../components/account/SupportPanel';
import QuizBadge from '../components/badges/QuizBadge';
import AddBookmarkIcon from '../components/icons/AddBookmarkIcon';
import CheckForUpdatesIcon from '../components/icons/CheckForUpdatesIcon';
import FolderIcon from '../components/icons/FolderIcon';
import LogoutIcon from '../components/icons/LogoutIcon';
import NextIcon from '../components/icons/NextIcon';
import PreviousIcon from '../components/icons/PreviousIcon';
import QuickMenuIcon from '../components/icons/QuickMenuIcon';
import SidebarIcon from '../components/icons/SidebarIcon';
import AddToHomescreenInstruction from '../components/onboarding/AddToHomescreenInstruction';
import { MODULES } from '../config/routes';
import { isAddToHomescreenCompatible } from '../services/addToHomescreenService';
import { Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';

const styles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		backgroundColor: '#ffffff'
	},
	tutorialCard: {
		padding: '16px !important'
	},
	title: {
		fontWeight: 'bold'
	},
	sectionHeader: {
		marginBottom: '10px'
	},
	primaryAvatar: {
		backgroundColor: theme.palette.primary.main
	},
	secondaryAvatar: {
		backgroundColor: theme.palette.secondary.main
	}
}));

interface Props extends RouteComponentProps {
	dispatch: Dispatch;
}

export const HelpScreen = (props: Props) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const [expanded, setExpanded] = useState<string | boolean | undefined>(undefined);

	const startTutorial = () => {
		let { dispatch, history } = props;

		dispatch(resetTutorial());
		history.push(MODULES);
	};

	const handleChange = (panel: string) => (event: ChangeEvent<{}>, expanded: boolean) => {
		if (expanded) {
			ReactGA.event({
				category: 'Help',
				action: `${panel} panel expanded`
			});
		}
		setExpanded(expanded ? panel : false);
	};

	const classes = styles();

	return (
		<Grid container direction="column" className={classes.root}>
			{/* Interactive Tutorial */}
			<Grid item>
				<Typography className={classes.sectionHeader} variant="h5">
					{I18n.t('help.tutorial', { locale })}
				</Typography>

				<Card className="joyride-tutorial-item">
					<CardContent className={classes.tutorialCard}>
						<Grid container direction="row" justifyContent="space-between" alignItems="center">
							<Typography variant="subtitle1" className={classes.title}>
								{I18n.t('guided-tour.title', { locale })}
							</Typography>
							<Button
								data-test-id="start-tutorial"
								variant="contained"
								color="primary"
								onClick={() => startTutorial()}
							>
								{I18n.t('guided-tour.button.re-run')}
							</Button>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			{/* Help Items */}
			<Grid item style={{ paddingTop: '30px' }}>
				<Typography className={classes.sectionHeader} data-test-id="help-screen_title" variant="h5">
					{I18n.t('help.title', { locale })}
				</Typography>

				{/* Menu */}
				<Accordion
					className="joyride-help-item"
					expanded={expanded === 'menu'}
					onChange={handleChange('menu')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							data-test-id="help-screen_menu-title"
							variant="subtitle1"
							className={classes.title}
						>
							{I18n.t('help.menu.title', { locale })}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container alignItems="center">
							<Grid item xs={10}>
								<Typography data-test-id="help-screen_menu-subtitle">
									{I18n.t('help.menu.subtitle', { locale })}
								</Typography>
							</Grid>

							<Grid item xs={2} container justifyContent="flex-end">
								<Avatar className={classes.primaryAvatar}>
									<SidebarIcon />
								</Avatar>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>

				{/* Courses */}
				<Accordion expanded={expanded === 'courses'} onChange={handleChange('courses')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							data-test-id="help-screen_courses-title"
							variant="subtitle1"
							className={classes.title}
						>
							{I18n.t('help.courses.title', { locale })}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container alignItems="center">
							<Grid item xs={12}>
								<Typography data-test-id="help-screen_courses-subtitle">
									{I18n.t('help.courses.subtitle', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Button color="secondary" variant="contained" fullWidth>
									{I18n.t('course.view', { locale })}
								</Button>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>

				{/* Folders */}
				<Accordion expanded={expanded === 'folders'} onChange={handleChange('folders')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							data-test-id="help-screen_folders-title"
							variant="subtitle1"
							className={classes.title}
						>
							{I18n.t('help.folders.title', { locale })}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container alignItems="center">
							<Grid item xs={10}>
								<Typography data-test-id="help-screen_folders-subtitle">
									{I18n.t('help.folders.subtitle', {
										locale
									})}
								</Typography>
								<Typography data-test-id="help-screen_folders-description">
									{I18n.t('help.folders.description', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={2} container justifyContent="flex-end">
								<Avatar>
									<FolderIcon />
								</Avatar>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>

				{/* Sessions */}
				<Accordion expanded={expanded === 'sessions'} onChange={handleChange('sessions')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							data-test-id="help-screen_sessions-title"
							variant="subtitle1"
							className={classes.title}
						>
							{I18n.t('help.sessions.title', { locale })}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container alignItems="center">
							<Grid item xs={10}>
								<Typography data-test-id="help-screen_sessions-subtitle">
									{I18n.t('help.sessions.subtitle', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={2} container justifyContent="flex-end">
								<Grid item md={4} container justifyContent="flex-end">
									<Avatar className={classes.primaryAvatar}>1</Avatar>
								</Grid>
								<Grid item md={4} container justifyContent="flex-end">
									<Avatar className={classes.primaryAvatar}>2</Avatar>
								</Grid>
								<Grid item md={4} container justifyContent="flex-end">
									<Avatar className={classes.primaryAvatar}>3</Avatar>
								</Grid>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>

				{/* Navigating Sessions */}
				<Accordion
					expanded={expanded === 'navigatingSessions'}
					onChange={handleChange('navigatingSessions')}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							data-test-id="help-screen_navigating-sessions-title"
							variant="subtitle1"
							className={classes.title}
						>
							{I18n.t('help.navigatingSessions.title', {
								locale
							})}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container spacing={1} alignItems="center">
							<Grid item xs={9}>
								<Typography data-test-id="help-screen_navigating-sessions-back-subtitle">
									{I18n.t('help.navigatingSessions.back.subtitle', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={3} container justifyContent="flex-end">
								<Grid item md={12} container justifyContent="flex-end">
									<Avatar className={classes.primaryAvatar}>
										<PreviousIcon />
									</Avatar>
								</Grid>
							</Grid>

							<Grid item xs={6}>
								<Typography data-test-id="help-screen_navigating-sessions-next-subtitle">
									{I18n.t('help.navigatingSessions.next.subtitle', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={6} container justifyContent="flex-end">
								<Grid item md={12} container justifyContent="flex-end">
									<Button color="primary" variant="contained" fullWidth>
										{I18n.t('session.nextButton', {
											locale
										})}
										<NextIcon />
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>

				{/* Bookmarks */}
				<Accordion expanded={expanded === 'bookmarks'} onChange={handleChange('bookmarks')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							data-test-id="help-screen_bookmarks-title"
							variant="subtitle1"
							className={classes.title}
						>
							{I18n.t('help.bookmarks.title', { locale })}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container alignItems="center">
							<Grid item xs={10}>
								<Typography data-test-id="help-screen_bookmarks-subtitle">
									{I18n.t('help.bookmarks.subtitle', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={2} container justifyContent="flex-end">
								<Grid item md={12} container justifyContent="flex-end">
									<Avatar className={classes.primaryAvatar}>
										<AddBookmarkIcon />
									</Avatar>
								</Grid>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>

				{/* Quizzes */}
				<Accordion expanded={expanded === 'quizzes'} onChange={handleChange('quizzes')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							variant="subtitle1"
							data-test-id="help-screen_quizzes-title"
							className={classes.title}
						>
							{I18n.t('help.quizzes.title', { locale })}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container alignItems="center">
							<Grid item xs={12}>
								<Typography data-test-id="help-screen_quizzes-subtitle">
									{I18n.t('help.quizzes.subtitle', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Button color="primary" variant="contained" fullWidth>
									{I18n.t('quiz.button.begin', { locale })}
								</Button>
							</Grid>

							<Grid item xs={12} style={{ padding: '0.5em 0' }}>
								<Divider />
							</Grid>

							<Grid item xs={9}>
								<Typography
									data-test-id="help-screen_quizzes-description"
									className={classes.title}
								>
									{I18n.t('help.quizzes.description', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={3} container justifyContent="flex-end">
								<QuizBadge locale={locale} />
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>

				{/* Add To Homescreen */}
				{isAddToHomescreenCompatible() ? (
					<Accordion expanded={expanded === 'a2hs'} onChange={handleChange('a2hs')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography
								variant="subtitle1"
								data-test-id="help-screen_add-to-homescreen-title"
								className={classes.title}
							>
								{I18n.t('help.addToHomescreen.title', {
									locale
								})}
							</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<Grid container alignItems="center">
								<Typography data-test-id="help-screen_add-to-homescreen-subtitle">
									{I18n.t('help.addToHomescreen.subtitle', {
										locale
									})}
								</Typography>
								<Grid item xs={12}>
									<AddToHomescreenInstruction />
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				) : null}

				{/* Account */}
				<Accordion expanded={expanded === 'account'} onChange={handleChange('account')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography
							variant="subtitle1"
							data-test-id="help-screen_account-title"
							className={classes.title}
						>
							{I18n.t('help.account.title', { locale })}
						</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container alignItems="center">
							<Grid item xs={12}>
								<Typography data-test-id="help-screen_account-subtitle">
									{I18n.t('help.account.subtitle', {
										locale
									})}
								</Typography>
							</Grid>

							<Grid item xs={12} style={{ margin: '0.5em' }} />

							{/* Updates */}
							<Grid item xs={12} style={{ padding: '0.5em 0' }}>
								<Typography data-test-id="help-screen_updates-description">
									<li>{I18n.t('help.account.updates.description', { locale })}</li>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Button color="primary" variant="contained" fullWidth>
									<CheckForUpdatesIcon style={{ marginRight: '0.5em' }} />
									{I18n.t('update.checkForUpdates', {
										locale
									})}
								</Button>
							</Grid>

							<Grid item xs={12} style={{ padding: '0.5em 0' }}>
								<Divider />
							</Grid>

							{/* Language */}
							<Grid item xs={10} style={{ padding: '0.5em 0' }}>
								<Typography data-test-id="help-screen_language-description">
									<li>
										{I18n.t('help.account.language.description', {
											locale
										})}
									</li>
								</Typography>
							</Grid>
							<Grid
								item
								xs={2}
								container
								justifyContent="flex-end"
								style={{ padding: '0.5em 0' }}
							>
								<Avatar>
									<QuickMenuIcon />
								</Avatar>
							</Grid>

							<Grid item xs={12} style={{ padding: '0.5em 0' }}>
								<Divider />
							</Grid>

							{/* Logout */}
							<Grid item xs={12} style={{ padding: '0.5em 0' }}>
								<Typography data-test-id="help-screen_logout-description">
									<li>{I18n.t('help.account.logout.description', { locale })}</li>
								</Typography>
							</Grid>
							<Grid item xs={12} style={{ padding: '0.5em 0' }}>
								<Button color="primary" variant="contained" fullWidth>
									<LogoutIcon style={{ marginRight: '0.5em' }} />
									{I18n.t('logout.button', { locale })}
								</Button>
							</Grid>

							<Grid item xs={12} style={{ padding: '0.5em 0' }}>
								<Divider />
							</Grid>

							{/* Contact */}
							<Grid item xs={12}>
								<Typography data-test-id="help-screen_contact-description">
									<li>{I18n.t('help.account.contact.description', { locale })}</li>
								</Typography>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Grid>

			{/* Support */}
			<Grid item style={{ paddingTop: '0.75em' }}>
				<SupportPanel data-test-id="help-screen_support-panel" />
			</Grid>
		</Grid>
	);
};

export default HelpScreen;
