import checkUpdates from './checkUpdates';
interface Module extends NodeModule {
	hot: {
		accept: Function;
		dispose: Function;
	};
}
export default function() {
	// Loop every 5 minutes
	const loopCheckUpdates = setInterval(checkUpdates, 300000);

	// Check updates when app start
	const firstCheckUpdates = setTimeout(checkUpdates, 5000);

	if ((module as Module).hot) {
		(module as Module).hot.accept();
		(module as Module).hot.dispose(() => {
			clearInterval(loopCheckUpdates);
			clearTimeout(firstCheckUpdates);
		});
	}
}
