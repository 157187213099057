import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../types';

const AboutPanel = () => {
	const { image, about } = useSelector(({ currentUser: { institution } }: Store) => institution);

	const subheader = () => {
		return (
			<React.Fragment>
				{image && (
					<React.Fragment>
						<img className="about-image" crossOrigin="anonymous" src={image.url} alt="Logo" />
						<br />
					</React.Fragment>
				)}
				{about && (
					<Typography variant="body2" color="textSecondary">
						<span dangerouslySetInnerHTML={{ __html: about }} />
					</Typography>
				)}
			</React.Fragment>
		);
	};

	if (image || about) {
		return (
			<Card>
				<CardHeader subheader={subheader()} />
			</Card>
		);
	}

	return null;
};

export default AboutPanel;
