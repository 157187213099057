import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import React from 'react';
import { Theme } from '@material-ui/core';
import { Module } from '../../types';
import { WithStyles } from '@material-ui/core/styles';
import { ModuleListProps } from './ModuleList';

const styles = (theme: Theme) => ({
	background: {
		backgroundColor: theme.palette.background.default
	},
	card: {
		margin: theme.spacing(1),
		display: 'flex',
		cursor: 'pointer'
	},
	cardContent: {
		display: 'flex',
		flex: '1',
		flexDirection: 'column',
		wordBreak: 'break-word'
	} as const
});

interface Props extends WithStyles<typeof styles> {
	handleNext: ModuleListProps['handleNext'];
	module: Module;
}

export const ModuleListItem = ({ module, handleNext, classes }: Props) => {
	return (
		<Card
			className={classnames(classes.card, 'joyride-module-list-item')}
			onClick={() => handleNext(module)}
			data-test-id="module_list_item_card"
		>
			<CardActionArea>
				{/* Title */}
				<CardHeader
					title={module.name}
					subheader={
						module.description && (
							<Typography color="textSecondary">
								<span
									dangerouslySetInnerHTML={{
										__html: module.description
									}}
								/>
							</Typography>
						)
					}
				/>
			</CardActionArea>
		</Card>
	);
};

export default withStyles(styles)(ModuleListItem);
