import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { completeTutorial } from '../../redux/actions/actionCreators';
import * as routes from '../../config/routes';
import { QuizNavigation } from '../../services/quiz/QuizNavigation';
import RouteResolver from '../../services/RouteResolver';
import Tooltip from './Tooltip';
import { Store } from '../../types';

const Tutorial = (props: RouteComponentProps) => {
	const { location } = props;

	const myProps = useSelector(({ settings: { locale }, currentUser: { viewedTutorials } }: Store) => {
		const route = RouteResolver.resolve(location);
		return {
			viewedTutorials,
			locale,
			route
		};
	});

	let { route, locale, viewedTutorials }: { route: any; locale: any; viewedTutorials: any } = myProps;

	const [stepIndex, setStepIndex]: [number, Function] = useState(0);
	const [run, setRun]: [boolean, Function] = useState(true);
	const dispatch = useDispatch();

	const quizPaths: Array<string> = [
		routes.COURSE_QUIZ,
		routes.FOLDER_QUIZ,
		routes.SESSION_QUIZ,
		routes.MODULE_COURSE_QUIZ,
		routes.MODULE_FOLDER_QUIZ,
		routes.MODULE_SESSION_QUIZ,
		routes.COURSE_SURVEY,
		routes.FOLDER_SURVEY,
		routes.SESSION_SURVEY,
		routes.MODULE_COURSE_SURVEY,
		routes.MODULE_FOLDER_SURVEY,
		routes.MODULE_SESSION_SURVEY
	];

	useEffect(() => {
		setStepIndex(0);
		setRun(true);
	}, [route?.path]);

	const getRouteSteps: () => Array<Step> = () => {
		if (route) {
			switch (route.path) {
				case routes.MODULES:
					return [
						{
							target: 'body',
							content: I18n.t('guided-tour.modules.1.content', {
								locale
							}),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: '.joyride-check-for-updates-button',
							content: I18n.t('guided-tour.courses.6.content', {
								locale
							}),
							spotlightPadding: 2,
							disableBeacon: true
						},
						{
							target: '.joyride-update-snackbar',
							content: I18n.t('guided-tour.courses.2.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-update-snackbar-button',
							content: I18n.t('guided-tour.courses.3.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-module-list-item',
							spotlightPadding: 0,
							content: I18n.t('guided-tour.modules.2.content', {
								locale
							}),
							disableBeacon: true
						}
					];
				case routes.MODULE_COURSE_LIST:
					return [
						{
							target: 'body',
							// title: I18n.t('guided-tour.courses.1.title', { locale }),
							content: I18n.t('guided-tour.module-courses.1.content', { locale }),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: '.joyride-check-for-updates-button',
							content: I18n.t('guided-tour.courses.6.content', {
								locale
							}),
							spotlightPadding: 2,
							disableBeacon: true
						},
						{
							target: '.joyride-update-snackbar',
							content: I18n.t('guided-tour.courses.2.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-update-snackbar-button',
							content: I18n.t('guided-tour.courses.3.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-course-item-image',
							content: I18n.t('guided-tour.courses.4.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-course-item-content',
							content: I18n.t('guided-tour.courses.5.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						}
					];
				case routes.COURSES:
					return [
						{
							target: 'body',
							// title: I18n.t('guided-tour.courses.1.title', { locale }),
							content: I18n.t('guided-tour.courses.1.content', {
								locale
							}),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: '.joyride-check-for-updates-button',
							content: I18n.t('guided-tour.courses.6.content', {
								locale
							}),
							spotlightPadding: 2,
							disableBeacon: true
						},
						{
							target: '.joyride-update-snackbar',
							content: I18n.t('guided-tour.courses.2.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-update-snackbar-button',
							content: I18n.t('guided-tour.courses.3.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-course-item-image',
							content: I18n.t('guided-tour.courses.4.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-course-item-content',
							content: I18n.t('guided-tour.courses.5.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						}
					];
				case routes.MODULE_COURSE_INFO:
				case routes.COURSE_INFO:
					return [
						{
							target: 'body',
							content: I18n.t('guided-tour.course-info.1.content', { locale }),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: '.joyride-course-info',
							spotlightPadding: 0,
							content: I18n.t('guided-tour.course-info.2.content', { locale }),
							disableBeacon: true
						},
						{
							target: '.joyride-folder-items',
							spotlightPadding: 0,
							content: I18n.t('guided-tour.course-info.3.content', { locale }),
							disableBeacon: true
						},
						{
							target: '.joyride-folder-item',
							spotlightPadding: 0,
							content: I18n.t('guided-tour.course-info.4.content', { locale }),
							disableBeacon: true
						},
						{
							target: '.joyride-sessions-items',
							spotlightPadding: 0,
							content: I18n.t('guided-tour.course-info.5.content', { locale }),
							disableBeacon: true
						},
						{
							target: '.joyride-sessions-item',
							spotlightPadding: 0,
							content: I18n.t('guided-tour.course-info.6.content', { locale }),
							disableBeacon: true
						}
					];
				case routes.MODULE_SESSION_DETAIL:
				case routes.SESSION_DETAIL:
					return [
						{
							target: 'body',
							content: I18n.t('guided-tour.session-details.1.content', { locale }),
							disableBeacon: true,
							// disableOverlay: true,
							placement: 'center'
						},
						{
							target: '.joyride-discuss-button',
							content: I18n.t('guided-tour.session-details.4.content', { locale }),
							disableBeacon: true,
							spotlightPadding: 0,
							styles: {
								options: {
									zIndex: 1202
								}
							}
						},
						{
							target: '.joyride-bookmark-button',
							content: I18n.t('guided-tour.session-details.2.content', { locale }),
							disableBeacon: true,
							spotlightPadding: 0,
							styles: {
								options: {
									zIndex: 1202
								}
							}
						},
						{
							target: '.joyride-up-button',
							content: I18n.t('guided-tour.session-details.3.content', { locale }),
							disableBeacon: true,
							spotlightPadding: 0,
							styles: {
								options: {
									zIndex: 1202
								}
							}
						},
						{
							target: '.joyride-menu-button',
							content: I18n.t('guided-tour.session-details.7.content', { locale }),
							spotlightPadding: 0,
							disableBeacon: true,
							styles: {
								options: {
									zIndex: 1202
								}
							}
						}
					];
				case routes.BOOKMARKS:
					return [
						{
							target: 'body',
							content: I18n.t('guided-tour.bookmarks.1.content', {
								locale
							}),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: '.joyride-bookmarks-item',
							content: I18n.t('guided-tour.bookmarks.2.content', {
								locale
							}),
							disableBeacon: true
						}
					];
				case routes.HELP:
					return [
						{
							target: 'body',
							content: I18n.t('guided-tour.help.1.content', {
								locale
							}),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: '.joyride-tutorial-item',
							content: I18n.t('guided-tour.help.2.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-help-item',
							content: I18n.t('guided-tour.help.3.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-support-panel-email',
							content: I18n.t('guided-tour.help.4.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: '.joyride-support-panel-phone',
							content: I18n.t('guided-tour.help.5.content', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						}
					];
				case routes.COURSE_QUIZ:
				case routes.FOLDER_QUIZ:
				case routes.SESSION_QUIZ:
				case routes.MODULE_COURSE_QUIZ:
				case routes.MODULE_FOLDER_QUIZ:
				case routes.MODULE_SESSION_QUIZ:
					return [
						{
							target: 'body',
							content: I18n.t('guided-tour.quiz.1', { locale }),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: '.joyride-quiz-details',
							content: I18n.t('guided-tour.quiz.2', { locale }),
							spotlightPadding: 0,
							disableBeacon: true
						}
					];
				case routes.COURSE_QUIZ_QUESTION:
				case routes.FOLDER_QUIZ_QUESTION:
				case routes.SESSION_QUIZ_QUESTION:
				case routes.MODULE_COURSE_QUIZ_QUESTION:
				case routes.MODULE_FOLDER_QUIZ_QUESTION:
				case routes.MODULE_SESSION_QUIZ_QUESTION:
					return [
						{
							target: '.joyride-question-indicator',
							content: I18n.t('guided-tour.quizQuestion.1', {
								locale
							}),
							spotlightPadding: 0,
							disableBeacon: true
						},
						{
							target: 'body',
							content: I18n.t('guided-tour.quizQuestion.2', {
								locale
							}),
							disableBeacon: true,
							placement: 'center'
						},
						{
							target: 'body',
							content: I18n.t('guided-tour.quizQuestion.3', {
								locale
							}),
							disableBeacon: true,
							placement: 'center'
						}
					];
				default:
					return [];
			}
		} else {
			return [];
		}
	};

	const handleJoyrideCallback = (data: any): void => {
		const { action, index, status, type } = data;

		if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
			// Update state to advance the tour
			setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
		} else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			// Need to set our running state to false, so we can restart if we click start again.
			setRun(false);

			endRouteTutorial();
			if (route && quizPaths.includes(route.path)) {
				new QuizNavigation({ ...props }).quizEnd();
			}
		}
	};

	const shouldTutorialRunInRoute = (): boolean | undefined => {
		if (route) {
			switch (route.path) {
				case routes.MODULES:
					return !viewedTutorials.modules;
				case routes.MODULE_COURSE_LIST:
					return !viewedTutorials.moduleCourses;
				case routes.COURSES:
					return !viewedTutorials.courses;
				case routes.MODULE_COURSE_INFO:
				case routes.COURSE_INFO:
					return !viewedTutorials.courseInfo;
				case routes.MODULE_SESSION_DETAIL:
				case routes.SESSION_DETAIL:
					return !viewedTutorials.sessionDetails;
				case routes.BOOKMARKS:
					return !viewedTutorials.bookmarks;
				case routes.HELP:
					return !viewedTutorials.help;
				case routes.COURSE_QUIZ:
				case routes.FOLDER_QUIZ:
				case routes.SESSION_QUIZ:
				case routes.MODULE_COURSE_QUIZ:
				case routes.MODULE_FOLDER_QUIZ:
				case routes.MODULE_SESSION_QUIZ:
					return !viewedTutorials.quiz;
				case routes.COURSE_QUIZ_QUESTION:
				case routes.FOLDER_QUIZ_QUESTION:
				case routes.SESSION_QUIZ_QUESTION:
				case routes.MODULE_COURSE_QUIZ_QUESTION:
				case routes.MODULE_FOLDER_QUIZ_QUESTION:
				case routes.MODULE_SESSION_QUIZ_QUESTION:
					return !viewedTutorials.quizQuestion;
				default:
					return true;
			}
		}
	};

	const endRouteTutorial = () => {
		if (route) {
			switch (route.path) {
				case routes.MODULES:
					return dispatch(completeTutorial('modules'));
				case routes.MODULE_COURSE_LIST:
					return dispatch(completeTutorial('moduleCourses'));
				case routes.COURSES:
					return dispatch(completeTutorial('courses'));
				case routes.MODULE_COURSE_INFO:
				case routes.COURSE_INFO:
					return dispatch(completeTutorial('courseInfo'));
				case routes.MODULE_SESSION_DETAIL:
				case routes.SESSION_DETAIL:
					return dispatch(completeTutorial('sessionDetails'));
				case routes.BOOKMARKS:
					return dispatch(completeTutorial('bookmarks'));
				case routes.HELP:
					return dispatch(completeTutorial('help'));
				case routes.COURSE_QUIZ:
				case routes.FOLDER_QUIZ:
				case routes.SESSION_QUIZ:
				case routes.MODULE_COURSE_QUIZ:
				case routes.MODULE_FOLDER_QUIZ:
				case routes.MODULE_SESSION_QUIZ:
					return dispatch(completeTutorial('quiz'));
				case routes.COURSE_QUIZ_QUESTION:
				case routes.FOLDER_QUIZ_QUESTION:
				case routes.SESSION_QUIZ_QUESTION:
				case routes.MODULE_COURSE_QUIZ_QUESTION:
				case routes.MODULE_FOLDER_QUIZ_QUESTION:
				case routes.MODULE_SESSION_QUIZ_QUESTION:
					return dispatch(completeTutorial('quizQuestion'));
				default:
					return true;
			}
		}
	};

	return (
		<Joyride
			data-test-id="joyride_tutorial"
			stepIndex={stepIndex}
			steps={getRouteSteps()}
			run={run && shouldTutorialRunInRoute()}
			disableOverlayClose
			tooltipComponent={Tooltip}
			continuous
			callback={handleJoyrideCallback}
		/>
	);
};

export default withRouter(Tutorial);
