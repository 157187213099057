import React from 'react';
import { useSelector } from 'react-redux';
import ModuleManager from '../services/module/ModuleManager';
import CourseScreen from './CourseScreen';
import { Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../services/RouteResolver';

type Params = Pick<ContentParams, 'moduleHashID'>;

interface Props extends RouteComponentProps<Params> {}

export const ModuleCourseScreen = (props: Props) => {
	const courses = useSelector(({ content }: Store) => {
		const { moduleHashID } = props.match.params;

		const module = ModuleManager.getModule(content.modules, moduleHashID);
		const courses = ModuleManager.getModuleCourses(module, content.courses);
		return courses;
	});
	const courseScreenProps = {
		courses: courses
	};

	if (courses) {
		return <CourseScreen {...courseScreenProps} />;
	}

	return null;
};

export default ModuleCourseScreen;
