import makeStyles from '@material-ui/core/styles/makeStyles';
import I18n from 'i18n-js';
import React from 'react';
import { Theme } from '@material-ui/core';
import { Locale } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
	badge: {
		// backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.main,
		borderRadius: '4px',
		fontSize: '13px',
		lineHeight: '10px',
		marginTop: '15px'
	}
}));

interface Props {
	locale: Locale;
}
const RequiredBadge = ({ locale }: Props) => {
	const classes = useStyles();

	return <div className={classes.badge}>*{I18n.t('badge.required', { locale })}</div>;
};

export default RequiredBadge;
