import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { QuestionTypeProps } from '../QuestionAnswers';

const MultipleChoiceAnswers = ({ question, userResponse, handleOnChange, isReadOnly }: QuestionTypeProps) => (
	<React.Fragment>
		<FormControl component="fieldset">
			<RadioGroup
				aria-label={question.title}
				name={question.title}
				data-test-id="question-answers_radio"
				value={userResponse && userResponse.length > 0 ? userResponse[0].toString() : ''}
				onChange={(e, value) => {
					handleOnChange && handleOnChange(value);
				}}
			>
				{Object.keys(question.answers).map((key, index) => {
					const answer = question.answers[index];

					const checked =
						userResponse && userResponse[0]
							? answer.id.toString() === userResponse[0].toString()
							: false;

					return (
						<FormControlLabel
							data-test-id="question-answers_multiple-choice-answers"
							key={answer.id}
							label={answer.title}
							value={answer.id.toString()}
							disabled={isReadOnly}
							checked={checked}
							control={<Radio color="primary" />}
						/>
					);
				})}
			</RadioGroup>
		</FormControl>
	</React.Fragment>
);

export default MultipleChoiceAnswers;
