import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { QuestionTypeProps } from '../QuestionAnswers';

const styles = makeStyles({
	slider: {
		paddingTop: '60px'
	}
});

interface Props extends Omit<QuestionTypeProps, 'question' | 'userResponse'> {
	answers: {
		step: string;
		start: string;
		end: string;
	};
}

const RatingScaleAnswers = ({ value, answers, handleOnChange, isReadOnly }: Props) => {
	const { step, start, end } = answers;
	const [currentValue, setCurrentValue] = useState(value ? Number(value) : Number(start));
	const classes = styles();

	useEffect(() => {
		if (typeof value !== 'number') {
			value = parseInt(value);
		}
		setCurrentValue(value);
	}, [value]);

	return (
		<React.Fragment>
			<Slider
				data-test-id="question-answers_rating-scale-question"
				className={classes.slider}
				value={currentValue ? currentValue : parseInt(start)}
				aria-labelledby="discrete-slider-always"
				step={Number(step)}
				marks
				min={Number(start)}
				max={Number(end)}
				onChange={(e, value) => handleOnChange && handleOnChange(value.toString())}
				valueLabelDisplay="on"
				disabled={isReadOnly}
			/>
		</React.Fragment>
	);
};

export default RatingScaleAnswers;
