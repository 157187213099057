import * as Sentry from '@sentry/react';
import React, { Component, ErrorInfo } from 'react';
import ErrorScreen from '../../containers/ErrorScreen';

interface State {
	error: any;
}

interface Info extends ErrorInfo {
	[index: string]: any;
}

class ErrorBoundary extends Component<{}, State> {
	constructor(props: {}) {
		super(props);

		this.state = {
			error: null
		};
	}

	componentDidCatch(error: Error, info: Info) {
		this.setState({ error });

		Sentry.configureScope((scope) => {
			Object.keys(info).forEach((key) => {
				scope.setExtra(key, info[key]);
			});
		});
		Sentry.captureException(error);
	}

	render() {
		if (this.state.error) {
			return (
				<ErrorScreen
					data-test-id="error_screen"
					showReport={() => Sentry.showReportDialog()}
					closeError={() => this.setState({ error: null })}
				/>
			);
		}

		// No errors caught
		return this.props.children;
	}
}

export default ErrorBoundary;
