import { detect } from 'detect-browser';

/* Function to determine
 * whether to show or hide
 * Add To Homescreen slide
 */
export const isAddToHomescreenCompatible = () => {
	const browser = detect();

	if (browser!.os === 'iOS' && browser!.name === 'ios') {
		return true;
	}

	if (browser!.os === 'Android OS' && ['opera', 'chrome'].includes(browser!.name)) {
		return true;
	}

	return false;
};
