import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import InternetIcon from '@material-ui/icons/Language';
import SMSIcon from '@material-ui/icons/Sms';
import I18n from 'i18n-js';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { validateSession } from '../redux/actions/actionCreators';
import ValidatedSession from '../components/session/ValidatedSession';
import MySnackbar from '../components/utils/MySnackbar';
import { SESSION_SEND_SMS } from '../config/routes';
import CourseManager from '../services/CourseManager';
import generateUrl from '../services/generateUrl';
import store from '../redux/store/store';
import { Store, Session, Locale, Content } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../services/RouteResolver';

const DEFAULT_STATE = {
	nextSession: null,
	isSessionValidated: false
};

interface Props extends Pick<RouteComponentProps, 'history'> {
	session: Session;
	sessions: Content['sessions'];
	locale: Locale;
	isValidating: Content['isSessionValidating'];
	isFailValidating: Content['isSessionFailValidating'];
}

interface State {
	nextSession: Session | null;
	isSessionValidated: boolean;
}

export class ProgressionScreen extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = DEFAULT_STATE;
	}

	componentWillReceiveProps(nextProps: Readonly<Props>) {
		if (!this.props.session.progressed && nextProps.session.progressed) {
			const nextSession = CourseManager.getNextSession(nextProps.sessions, nextProps.session);

			this.setState({
				...this.state,
				nextSession,
				isSessionValidated: true
			});
		}
	}

	handleSendByInternet = () => {
		this.setState({ ...this.state });

		const { session } = this.props;

		ReactGA.event({
			category: 'Session',
			action: 'Progressed via Internet',
			label: session.hashID
		});

		store.dispatch(validateSession(session.hashID));
	};

	handleSendBySms = () => {
		return this.props.history.push(
			generateUrl(SESSION_SEND_SMS, {
				':courseHashID': this.props.session.courseHashID,
				':sessionHashID': this.props.session.hashID
			})
		);
	};

	render() {
		const { session, locale, isValidating, isFailValidating } = this.props;
		const iconStyle = { marginRight: '0.3em' };

		if (this.state.isSessionValidated) {
			return (
				<ValidatedSession session={session} nextSession={this.state.nextSession} locale={locale} />
			);
		}

		return (
			<React.Fragment>
				<Grid
					container
					alignItems="center"
					justify="center"
					direction="column"
					spacing={8}
					className="screen-centered"
				>
					<Grid item>
						<Typography variant="h5">{I18n.t('send.label', { locale })}</Typography>
					</Grid>

					<Grid item container spacing={4}>
						<Grid item xs={6}>
							<Button
								variant="contained"
								color="primary"
								disabled={isValidating}
								onClick={this.handleSendByInternet}
								fullWidth
							>
								<InternetIcon style={iconStyle} />
								{I18n.t('send.medium.internet', { locale })}
							</Button>
						</Grid>

						<Grid item xs={6}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleSendBySms}
								fullWidth
							>
								<SMSIcon style={iconStyle} />
								{I18n.t('send.medium.sms', { locale })}
							</Button>
						</Grid>
					</Grid>
				</Grid>

				{isFailValidating && (
					<MySnackbar open variant="error" message={I18n.t('send.validation.fail', { locale })} />
				)}
			</React.Fragment>
		);
	}
}

function mapStateToProps(
	{ content: { sessions, isSessionValidating, isSessionFailValidating }, settings: { locale } }: Store,
	props: Pick<RouteComponentProps<Pick<ContentParams, 'sessionHashID'>>, 'match'>
) {
	const session = CourseManager.getSession(sessions, props.match.params.sessionHashID);

	return {
		sessions,
		session,
		isValidating: isSessionValidating,
		isFailValidating: isSessionFailValidating,
		locale
	};
}

export default connect(mapStateToProps)(ProgressionScreen);
