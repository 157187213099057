import {
	FAIL_GET_UPDATES,
	RECEIVE_UPDATES,
	REINIT_UPDATES,
	REQUEST_UPDATES
} from '../actions/actionCreators';
import initialState from '../store/initialState';
import { Action, Updates } from '../../types';

export default function updates(state: Updates = { ...initialState.updates }, action: Action) {
	switch (action.type) {
		case REINIT_UPDATES: {
			return {
				...state,
				isFetching: false,
				isErrorFetching: false,
				hasUpdates: false,
				size: 0
			};
		}

		case REQUEST_UPDATES: {
			return {
				...state,
				isFetching: true,
				isErrorFetching: false
			};
		}

		case RECEIVE_UPDATES: {
			const { hasUpdates, size } = action.payload.updates;

			return {
				...state,
				isFetching: false,
				isErrorFetching: false,
				dateLastCheck: new Date(),
				hasUpdates: hasUpdates,
				size: size
			};
		}

		case FAIL_GET_UPDATES: {
			return {
				...state,
				isFetching: false,
				isErrorFetching: true
			};
		}

		default:
			return state;
	}
}
