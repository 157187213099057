interface FetchedBookmark {
	title: string;
	createdAt: any;
	url: string;
	[index: string]: any;
}

export const formatBookmarks = (bookmarks: Array<FetchedBookmark>) => {
	let formattedBookmarks = {};

	bookmarks.forEach((bookmark: FetchedBookmark) => {
		let temp: any = {};

		temp[`${bookmark.url}`] = {
			title: bookmark.title,
			createdAt: bookmark.createdAt
		};

		formattedBookmarks = { ...formattedBookmarks, ...temp };
	});

	return formattedBookmarks;
};
