import axios, { AxiosRequestConfig } from 'axios';
import { Service } from 'axios-middleware';
import store, { GraphqlClient } from '../../redux/store/store';
import * as Sentry from '@sentry/react';
import UserQuery from '../../graphql/query/UserQuery';
import { receiveUserInformations } from '../../redux/actions/actionCreators';
import { Indexes } from './indexes';
import { PWA_ANALYTICS_SOURCE } from '../../config/constants';

const instance = axios.create({
	baseURL: process.env.REACT_APP_ANALYTICS_INGEST_ENDPOINT
});

const service = new Service(instance);

service.register({
	onRequest(config: any) {
		if (!config.headers['Authorization']) {
			config.headers['Authorization'] = `Bearer ${store.getState().currentUser.analyticsToken}`;
		}
		return config;
	},
	async onResponseError(err: any) {
		if (err.response?.status === 401 && !err.config.hasRetriedRequest) {
			//Seek new access token when expired
			const userTokenIssuedAt = store.getState().currentUser.tokenIssuedAt;
			return new Promise(async (res, rej) => {
				try {
					const userDataResponse = await GraphqlClient.query({
						query: UserQuery,
						fetchPolicy: 'network-only',
						variables: { tokenIssuedAt: userTokenIssuedAt }
					});
					//Reset user information with new analytics token
					store.dispatch(receiveUserInformations(userDataResponse.data.user));
					const response = await instance.request({
						...err.config,
						hasRetriedRequest: true,
						headers: {
							...err.config.headers,
							Authorization: `Bearer ${userDataResponse.data.user.analyticsToken}`
						}
					});
					res(response);
				} catch (e) {
					rej(e);
				}
			});
		}
		throw err;
	}
});

const analyticsClient = {
	async sendDataToIndex(
		index: Indexes,
		data: {
			[idx: string]: any;
		},
		config?: AxiosRequestConfig
	) {
		try {
			await instance.post(
				'/ingest',
				{
					metric: index,
					data: { ...data, source: PWA_ANALYTICS_SOURCE }
				},
				{ ...config }
			);
		} catch (e) {
			console.error(e);
			Sentry.captureException(e);
		}
	}
};

export default analyticsClient;
