import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SessionList from '../components/session/SessionList';
import { MODULE_SESSION_DETAIL, MODULE_SESSION_QUIZ, SESSION_DETAIL, SESSION_QUIZ } from '../config/routes';
import generateUrl from '../services/generateUrl';
import { Content, Session, Store } from '../types';
import { ContentParams } from '../services/RouteResolver';

type Params = Pick<ContentParams, 'moduleHashID'>;

interface Props extends RouteComponentProps<Params> {
	sessions: Content['sessions'];
}
export const SessionFragment = (props: Props) => {
	const myProps = useSelector(
		({
			content: {
				quizzes,
				viewed: { sessions: viewedSessions }
			},
			settings: { locale }
		}: Store) => ({
			locale,
			quizzes,
			viewedSessions
		})
	);

	const { locale, viewedSessions, quizzes } = myProps;

	const { history, sessions } = props;

	const handleNext = (session: Session) => {
		const { moduleHashID } = props.match.params;

		if (session.content && session.content.includes('&lt;quiz&gt;')) {
			return history.push(
				generateUrl(moduleHashID ? MODULE_SESSION_QUIZ : SESSION_QUIZ, {
					':moduleHashID': moduleHashID,
					':courseHashID': session.courseHashID,
					':sessionHashID': session.hashID,
					':quizHashID': session.quizHashID
				})
			);
		}

		return history.push(
			generateUrl(moduleHashID ? MODULE_SESSION_DETAIL : SESSION_DETAIL, {
				':moduleHashID': moduleHashID,
				':courseHashID': session.courseHashID,
				':sessionHashID': session.hashID
			})
		);
	};

	return (
		<div className="content-layout">
			<SessionList
				data-test-id="session-screen_session-list"
				sessions={sessions}
				quizzes={quizzes}
				locale={locale}
				handleNext={handleNext}
				viewedSessions={viewedSessions}
			/>
		</div>
	);
};

export default withRouter(SessionFragment);
