import I18n from 'i18n-js';
import institutionTranslations from '../services/institutionTranslations';
import enTranslations from './translate/en';
import frTranslations from './translate/fr';
import esTranslations from './translate/es';
import rwTranslations from './translate/rw';
import swTranslations from './translate/sw';
import viTranslations from './translate/vi';
import thTranslations from './translate/th';
import zhTranslations from './translate/zh';
import ptTranslations from './translate/pt';

interface availableLocales {
	[index: string]: string;
	en: string;
	fr: string;
	es: string;
	rw: string;
	sw: string;
	vi: string;
	th: string;
	zh: string;
	pt: string;
}
export const availableLocales: availableLocales = {
	en: 'English',
	fr: 'Français',
	es: 'Español',
	rw: 'Kinyarwanda',
	sw: 'Kiswahili',
	vi: 'Tiếng Việt',
	th: 'ไทย',
	zh: '简体中文',
	pt: 'Português'
};
export const defaultLocale = 'en';

export default (institution_id: number | null) => {
	const keywords = institutionTranslations(institution_id);

	const en = enTranslations(keywords.en);
	const fr = frTranslations(keywords.fr);
	const es = esTranslations(keywords.es);
	const rw = rwTranslations(keywords.rw);
	const sw = swTranslations(keywords.sw);
	const vi = viTranslations();
	const th = thTranslations();
	const zh = zhTranslations();
	const pt = ptTranslations();

	I18n.fallbacks = true;
	I18n.defaultLocale = defaultLocale;
	I18n.translations = { en, fr, es, rw, sw, vi, th, zh, pt };
};
