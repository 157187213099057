import React from 'react';
import { useSelector } from 'react-redux';
import SessionList from '../components/session/SessionList';
import { SESSION_DETAIL } from '../config/routes';
import CourseManager from '../services/CourseManager';
import generateUrl from '../services/generateUrl';
import { Course, Session, Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../services/RouteResolver';

interface Params extends Pick<ContentParams, 'courseHashID' | 'folderHashID'> {}

interface Props extends Pick<RouteComponentProps<Params>, 'match' | 'history'> {}

const SessionScreen = (props: Props) => {
	const myProps = useSelector((state: Store) => {
		const {
			content,
			settings: { locale }
		} = state;

		const params = props.match.params;
		const viewedSessions = content.viewed.sessions;

		let course: Course | null = CourseManager.getCourse(content.courses, params.courseHashID);
		let folderHashID = params.folderHashID;

		if (course === undefined) {
			course = null;
		}

		if (folderHashID === undefined) {
			const defaultFolder = process.env.REACT_APP_DEFAULT_FOLDER;
			folderHashID = `${course?.hashID}_${defaultFolder}`;
		}

		const sessions = CourseManager.getSessionsFromFolder(content.sessions, folderHashID);

		return {
			locale,
			course,
			sessions,
			quizzes: content.quizzes,
			viewedSessions
		};
	});

	const { locale, viewedSessions, quizzes, sessions, course } = myProps;

	const { history } = props;

	const handleNext = (session: Session) => {
		return history.push(
			generateUrl(SESSION_DETAIL, {
				':courseHashID': course?.hashID,
				':sessionHashID': session.hashID
			})
		);
	};

	return (
		<div className="content-layout">
			<SessionList
				data-test-id="session-screen_session-list"
				sessions={sessions}
				quizzes={quizzes}
				locale={locale}
				handleNext={handleNext}
				viewedSessions={viewedSessions}
			/>
		</div>
	);
};

export default SessionScreen;
