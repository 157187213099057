import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import React from 'react';
import SnackbarErrorIcon from '../icons/snackbar/SnackbarErrorIcon';
import SnackbarSuccessIcon from '../icons/snackbar/SnackbarSuccessIcon';
import SnackbarWarningIcon from '../icons/snackbar/SnackbarWarningIcon';
import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';

const variants = {
	success: SnackbarSuccessIcon,
	warning: SnackbarWarningIcon,
	error: SnackbarErrorIcon
};

const iconVariants: { [index: string]: (props: any) => JSX.Element } = variants;

const styles = (theme: Theme) => ({
	success: {
		color: green[600]
	},
	warning: {
		color: amber[700]
	},
	error: {
		color: theme.palette.error.dark
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	}
});

interface Props extends WithStyles<typeof styles> {
	open: boolean;
	onClose?: () => void;
	variant?: keyof typeof variants;
	autoHideDuration?: number | null;
	message: string;
	action?: React.ReactNode;
}

interface State {
	open: boolean;
}

class MySnackbar extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			open: props.open
		};
	}

	componentWillReceiveProps(nextProps: Readonly<Props>) {
		if (this.props.open !== nextProps.open) {
			this.setState({
				open: nextProps.open
			});
		}
	}

	handleClose: any = (event: any, reason: string) => {
		if (reason === 'clickaway') {
			return;
		}

		return this.setState({ open: false }, () => {
			this.props.onClose && this.props.onClose();
		});
	};

	render() {
		const { classes, variant, autoHideDuration, message, action } = this.props;
		const Icon = variant ? iconVariants[variant] : null;

		return (
			<Snackbar
				open={this.state.open}
				autoHideDuration={autoHideDuration !== undefined ? autoHideDuration : 5000}
				onClose={this.handleClose}
				message={
					<span className={classes.message}>
						{Icon && variant && (
							<Icon className={classNames(classes.iconVariant, classes[variant])} />
						)}
						{message}
					</span>
				}
				action={
					action || (
						<IconButton color="inherit" onClick={this.handleClose}>
							<CloseIcon />
						</IconButton>
					)
				}
			/>
		);
	}
}

export default withStyles(styles)(MySnackbar);
