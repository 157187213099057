export default () => ({
	course: {
		noContentAvailable: '没有可用的内容',
		view: '看法'
	},
	session: {
		nextButton: '下一个',
		previous: '以前的',
		quizButton: '进行测验',
		surveyButton: '进行调查',
		end: '完全的'
	},
	question: {
		next: '下一个',
		previous: '以前的',
		saveAndNext: '保存并下一步',
		answer: '回答',
		checkAnswer: '检查答案',
		numericalRangeFeedback: '正确答案是介于 <b>%{lowerNumber}</b> 和 <b>%{higherNumber}</b> 之间的数字',
		answerAll: '在继续之前，请回答所有<b>必填</b>问题。',
		uploadAll: '请在继续之前上传所有<b>必需</b>文件。',
		maxFiles: '您只能上传最多 {number} 个视频',
		confirmRemove: '您确定要删除文件吗',
		confirm: '确认',
		backToStart: '回到起点',
		attempt: '在继续之前，请尝试这个问题。',
		question: '问题',
		of: '的',
		end: '结束',
		hint: '单击以切换提示',
		helperText: {
			essay: '请在下面输入答案',
			multipleChoice: '选择一个答案',
			compoundMultipleChoice: '选择所有适当的答案',
			numericalRange: '输入适当答案范围内的数字',
			shortExact: '请在下面输入答案',
			sequence: '拖放项目以按适当的顺序排列',
			ratingScale: '拖动滑块选择并回答'
		}
	},
	feedback: {
		good: '做得好！',
		bad: '不正确'
	},
	tokenError: {
		'401': '你的链接有问题 - 请联系 Chalkboard Education 寻求支持。',
		'402':
			'你的令牌正在另一台设备上使用。你的机构禁止多设备登录。如果你认为这是一个错误，请联系 Chalkboard Education。'
	},
	discussion: {
		title: '讨论',
		messagePlaceholder: '输入您的消息'
	},
	update: {
		label: '下载',
		checkForUpdates: '检查更新',
		needUpdate: '内容更新可用...',
		updating: '正在获取内容...',
		checking: '正在检查内容...',
		fetchingQuizResults: '正在获取测验结果...',
		downloadSize: '{{size}} {{type}}',
		toDownload: ' 并下载',
		KB: 'KB',
		MB: 'MB',
		upToDate: '所有可用内容已下载！',
		errorWhileCheckingUpdates: '检查更新时出现网络问题',
		errorWhileUpdating: '下载内容时出现网络问题',
		stayOnline: '请保持在线并保持页面打开。这可能需要几分钟。',
		offline: '你离线了。',
		success: '内容已成功下载！',
		warning: '内容已部分下载',
		connectAndRetry: '请确保您已连接到互联网并重试',
		connectAndRetryInMinutes: '请确保您已连接到互联网，然后在几分钟后重试',
		downloadInProgress: '正在下载中',
		contentWillAppear: '下载完成后您将看到内容',
		retry: '重试',
		retryInFewMinutes: '请在几分钟后再试一次',
		edit: '编辑',
		downloadingContent: '下载内容',
		missing: '下载缺失的媒体',
		dismiss: '消除',
		contentLoading: '内容正在下载中。您可以通过点击屏幕右下角的圆圈查看状态'
	},
	send: {
		label: {
			quiz: '通过以下方式提交你的进度：',
			survey:
				"完成调查的表现非常出色！为了确保准确性，请仔细查看您的回答。一旦您确定了您的回答，请点击 '提交' 按钮继续"
		},
		sessionListButton: `课程列表`,
		nextButton: '下一个',
		medium: {
			internet: '互联网',
			sms: '短信'
		},
		validation: {
			success: '谢谢。你的进度已保存。',
			fail: `验证课程时出错`
		},
		sms: {
			title: '通过短信提交你的进度',
			label: '你将通过短信发送代码并收到进度代码。请勿修改任一代码。谢谢。',
			button: '通过短信发送',
			notworking: {
				label: '手动发送短信...',
				button: '不工作？',
				toPhone: '电话号码',
				unblockCode: '发送代码'
			},
			progressCode: {
				button: '输入进度代码',
				placeholder: '进度代码',
				title: '输入你从下方短信中收到的代码：',
				cancel: '取消',
				submit: '提交'
			}
		}
	},
	login: {
		checking: '正在检查……',
		start: '开始',
		title: '登录',
		message: '你可以使用提供的短信链接登录。或者，你也可以在下方输入你的登录令牌。',
		hint:
			'你可以使用通过短信发送给你的登录链接重新登录。<br/>或者，你可以在通过短信发送给你的登录 URL 中找到你的 6 字符令牌。例如：https://chalkedu.co/#/login/<b>token</b>',
		tokenPlaceholder: '令牌'
	},
	account: {
		progress: {
			title: `进步`,
			subtitle: `%{read}/%{remaining} 会话阅读`
		},
		language: '语言',
		settings: {
			label: '设置'
		},
		about: '关于',
		consent: '同意',
		support: {
			title: '支持线',
			subtitle: '你可以通过以下方式联系我们以获取更多帮助：'
		}
	},
	error: {
		label: '哎呀……好像出了点问题。',
		report: '报告错误',
		back: '验证错误',
		dismiss: '消除',
		authenticationError: '下载不完整',
		spoolError: {
			title: '部分文件下载失败。不用担心，我们会在下次更新时尝试重新下载',
			description: '部分文件下载失败。不用担心，我们会在下次更新时尝试重新下载。'
		}
	},
	'404': {
		title: '404页面不存在',
		description: '很抱歉，你要查找的页面不存在。',
		back: '回去'
	},
	logout: {
		title: '确认退出',
		message: '你确定要退出吗？',
		button: '退出',
		cancelButton: '取消'
	},
	selfTestQuiz: {
		okay: '好的',
		checkAnswer: '检查答案',
		answers: '答案',
		essayResponse: '谢谢你的回复！'
	},
	quiz: {
		label: '在继续之前，你必须完成以下测验！',
		button: {
			begin: '开始',
			skip: '跳过'
		},
		upload: '选择要上传的文件',
		uploadedFiles: '已上传的文件',
		tapToUpload: '点击图标以选择要上传的文件',
		commence: '您即将参加这个会议的测验',
		submit: {
			submitting: '提交中',
			stayOnline: '您确定要提交您的答案吗？\n请确保您在线并保持屏幕开启',
			inProgress: '提交进行中。这可能需要一分钟的时间',
			success: '测验已成功提交！',
			error: '提交测验时出错！ 请稍后再试。',
			offline: '离线时不能提交回复'
		},
		continue: '继续',
		submitButton: '提交测验'
	},
	survey: {
		submitButton: '提交调查',
		submittedButton: '已提交调查',
		commence: '您即将参加这个会议的调查',
		submit: {
			success: '调查成功提交'
		}
	},
	badge: {
		quiz: '测验',
		survey: '问卷调查',
		required: '必须'
	},
	menu: {
		modules: `模块`,
		courses: `课目`,
		account: '帐户',
		bookmarks: '书签',
		quizReview: '测验复习',
		help: '帮助',
		about: '关于'
	},
	consent: {
		title: '我同意 Chalkboard Education SAS 收集并保存：',
		data: '我的进度和结果数据来自这个应用程序',
		analytics: '我与使用此应用程序有关的行为数据和其他个人信息'
	},
	custom: {
		moreInfo: '更多信息',
		gotIt: '知道了',
		searchPlaceholder: '开始输入以进行搜索',
		noSearchResults: '未找到结果'
	},
	help: {
		title: '如何浏览应用程序：',
		menu: {
			title: '探索菜单',
			subtitle: `单击图标打开菜单。该菜单包含一个指向你的课目和帐户的链接。`
		},
		courses: {
			title: `课目`,
			subtitle: `在“课目”页面上，单击“查看”打开课目。`
		},
		folders: {
			title: `文件夹`,
			subtitle: `每课目都分为不同的文件夹。`,
			description: `每个文件夹被进一步划分为课程。课程包含用户可以学习的内容。`
		},
		sessions: {
			title: `课程`,
			subtitle: `课程包含用户可以学习的内容。`
		},
		navigatingSessions: {
			title: `浏览课程`,
			back: {
				subtitle: `进入课程后，你可以使用后退箭头导航回父列表...`
			},
			next: {
				subtitle: `或使用页面底部的按钮进入下一个课程：`
			}
		},
		bookmarks: {
			title: '书签',
			subtitle: '使用书签图标保存页面，以便从侧面菜单中的“书签”部分快速访问'
		},
		quizzes: {
			title: '小测验/测试',
			subtitle: `你可能会在课程或文件夹开始， 或在课程结束时看到一个测验。`,
			description: `注意：带有测验的课程标有图标：`
		},
		addToHomescreen: {
			title: '添加到主屏幕',
			subtitle: '你可以在主屏幕上安装此应用程序，以便在旅途中快速轻松地访问。'
		},
		account: {
			title: '帐户',
			subtitle: '在“帐户”页面上，你可以：',
			updates: {
				description: '检查内容的更新。'
			},
			language: {
				description: '更改你的语言设置。'
			},
			logout: {
				description: '退出你的帐户。'
			},
			contact: {
				description: '如果你在使用该应用程序时遇到任何问题，请联系我们。'
			}
		},
		tutorial: '重新运行交互式教程：'
	},
	onboarding: {
		'0': {
			title: '按需上课',
			subtitle: '你所有的学习资料都集中在一个地方...包括知识测试。'
		},
		'1': {
			title: '可离线使用！',
			subtitle: '下载内容后，即可离线使用，以便你随时学习。'
		},
		'2': {
			title: '随时随地学习！',
			subtitle: '兼容所有设备。只需使用提供的短信链接。'
		},
		'3': {
			title: '加入讨论！',
			subtitle: `在支持讨论的课程中参与对等学习。`
		},
		'4': {
			title: '添加到主屏幕！',
			subtitle: '将此应用程序安装在你的主屏幕上，以便在旅途中快速轻松地学习。',
			instructions: {
				menu: '只需点击浏览器中的<strong>菜单</strong>按钮<br />并选择<strong>添加到主屏幕</strong>',
				share: '只需点击浏览器中的<strong>分享</strong>按钮<br />并选择<strong>添加到主屏幕</strong>',
				clickButton: '...或单击下方按钮'
			}
		},
		addToHomescreen: '添加到主屏幕',
		getStarted: '入门',
		next: '下一个',
		skip: '跳过'
	},
	'guided-tour': {
		title: '交互式教程',
		button: {
			finish: '结束',
			next: '下一个',
			previous: '以前的',
			're-run': '重新运行'
		},
		modules: {
			'1': {
				title: `模块页面`,
				content: `欢迎来到<b>模块</b>页面。<b>模块</b>是分配给你的<b>课目</b>组`
			},
			'2': {
				content: `点击一个<b>模块</b>便会带你进入<b>模块</b>下所有<b>课目</b>的列表`
			}
		},
		'module-courses': {
			'1': {
				content: `你位于刚选择的<b>模块</b>的<b>课目</b>页面中。<b>模块</b>下的课程在此处列出`
			}
		},
		courses: {
			'1': {
				title: `课目页面`,
				content: `你在<b>课目</b>页面中。你的所有<b>课目</b>都会在此处列出`
			},
			'2': {
				content: '每当你看到这个，这意味着有新的内容可供下载'
			},
			'3': {
				content: '点击<b>下载</b>将新内容保存到你的设备上'
			},
			'4': {
				content: `点击图片可以看到对<b>课程</b>的简短描述`
			},
			'5': {
				content: `点击一个<b>课程</b>便会带你转至该<b>课程</b>的信息页面`
			},
			'6': {
				content: '点击<b>检查更新</b>以检查新内容'
			}
		},
		'course-info': {
			'1': {
				content: `这是<b>课目页面</b>。在这里，你可以找到有关课目的更多信息，以及分组到 <b>文件夹</b> 中的 <b>课程</b>`
			},
			'2': {
				content: `你可以在此处找到关于<b>课程</b>的简要说明`
			},
			'3': {
				content: `课程描述下方是<b>文件夹</b>的列表`
			},
			'4': {
				content: `单击<b>文件夹</b>会显示该文件夹下的<b>课程</b>列表`
			},
			'5': {
				content: `以下是<b>课程列表</b>`
			},
			'6': {
				content: `若要开始阅读，请单击<b>课程</b>`
			}
		},
		'session-details': {
			'1': {
				content: `欢迎来到你的首节<b>课程</b>。你将在这里度过大部分时间`
			},
			'2': {
				content: `若要更轻松地返回到 <b>课程</b>，请单击 <b>书签</b> 图标以创建返回此 <b>课程</b> 的快速链接。你创建的<b>书签</b>可以在<b>书签页面</b>中找到。`
			},
			'3': {
				content: '单击向上箭头将带你到上一页面。'
			},
			'4': {
				content: `点击<b>讨论图标</b>查看你当前正在查看的<b>课程</b>的<b>讨论页面</b>`
			},
			'5': {
				content: `若要转至<b>文件夹</b>中的下一节<b>课程</b>，请单击<b>“下一节”按钮</b>`
			},
			'6': {
				content: `若要转至上一节<b>课程</b>，请单击<b>“上一节“按钮</b>`
			},
			'7': {
				content: '这是<b>菜单按钮</b>。在任何页面上单击，将显示 <b>侧边菜单</b>'
			}
		},
		bookmarks: {
			'1': {
				content: '你收藏的所有页面都会出现在这里！'
			},
			'2': {
				content: '单击<b>书签</b>可快速将你转至书签页面'
			}
		},

		help: {
			'1': {
				content: '欢迎来到<b>帮助页面</b>。每当你无法理解应用程序的工作原理时，请访问此页面'
			},
			'2': {
				content: '你可以随时访问<b>帮助页面</b>重新运行本指南…'
			},
			'3': {
				content: '单击列表中的任何项目以获取更多信息…'
			},
			'4': {
				content: '或者，你可以通过电子邮件联系 Chalkboard Education'
			},
			'5': {
				content: '或拨打我们的热线电话与我们交谈...'
			}
		},

		quiz: {
			'1': '欢迎来到<b>测验页面</b>。每当进行测验时，你都会看到类似的屏幕',
			'2': '以下是测验的详细信息。单击<b>开始</b>按钮并开始...'
		},
		quizQuestion: {
			'1': '这会显示了你目前正在做的问题，以及你还剩下多少问题......',
			'2': '互动并回答问题。点击屏幕底部的<b>下一步</b>按钮继续下一个问题',
			'3':
				'输入后，你的答案将自动保存在你的设备上。你可以随时暂停和继续测试。直至你完成并提交整份测试！'
		}
	},
	routes: {
		login: '登录',
		welcome: '欢迎',
		modules: `模块`,
		courses: `课目`,
		help: '帮助',
		about: '关于',
		bookmarks: '书签',
		quizReview: '测验复习',
		account: '帐户',
		folders: `文件夹`,
		sessions: `课程`,
		quiz: '测验',
		question: '问题'
	},
	bookmarks: {
		none: '你似乎没有保存的书签...',
		added: '书签已添加',
		removed: '书签已移除'
	},
	courses: {
		none: `下载的课目将出现在此处...`,
		noPurchases: `你还没有购买任何商品`,
		purchaseMore: `点击此处添加课目`,
		purchaseCourses: `购买课目`
	},
	quizReview: {
		questionsTitle: {
			survey: '问题与回答',
			graded: '细分'
		},
		none: `你尚未完成任何<b>评分</b>测验或<b>调查</b>`,
		correct: '正确',
		wrong: '错误',
		notMarked: '尚未标记',
		resultsIsFetching: '正在获取之前的测验以供审阅...',
		errorFetchingResults: '抱歉，获取测验结果时出错。请检查更新后重试。'
	},
	folder: {
		quizLabel: `在查看此文件夹的内容之前，你必须完成一个测验：`,
		surveyLabel: `在查看此文件夹的内容之前，你必须完成调查：`
	},
	modules: {
		none: `没有已下载模块...`
	},
	register: {
		signUp: '报名',
		button: '注册',
		registering: '正在注册...',
		loginHint: '已经有一个帐户？ 登录',
		successful: '注册成功',
		failed: '注册失败。请再试一次',
		label: {
			firstName: '名',
			lastName: '姓',
			phoneNumber: '电话号码',
			language: '语言'
		},
		hint: {
			firstName: '名字是必填项',
			lastName: '姓氏是必填项',
			phoneNumberLength: '电话号码长度应超过 9 位',
			phoneNumberFormat: '电话号码应以“+”开头',
			verifyHuman: '请验证 recaptcha 以继续',
			language: '语言是必填项'
		}
	},
	searchModal: {
		cancel: '取消',
		search: '搜索',
		searchIn: '搜索于'
	},
	quizType: {
		survey: '调查',
		graded: '已评分',
		selfAssessment: '自我评估'
	}
});
