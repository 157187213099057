import { SURVEY } from '../../config/constants';
import { MODULE_SESSION_DISCUSSION } from '../../config/routes';
import { SESSION_DISCUSSION } from '../../config/routes';
import {
	COURSE_INFO,
	COURSES,
	MODULE_COURSE_INFO,
	MODULE_COURSE_LIST,
	MODULE_SESSION_DETAIL,
	MODULE_SESSION_QUIZ,
	MODULE_SESSION_QUIZ_QUESTION,
	MODULE_SESSION_QUIZ_SUBMIT,
	MODULE_SESSION_SURVEY,
	SESSION_DETAIL,
	SESSION_QUIZ,
	SESSION_QUIZ_QUESTION,
	SESSION_QUIZ_SUBMIT,
	SESSION_SURVEY
} from '../../config/routes';
import store from '../../redux/store/store';
import CourseManager from '../CourseManager';
import generateUrl from '../generateUrl';
import QuizManager from '../quiz/QuizManager';
import { Course, Module, Quiz, Session } from '../../types';
import { RouteComponentProps } from 'react-router-dom';
import { ContentParams } from '../RouteResolver';

const quizPaths = [
	SESSION_QUIZ,
	SESSION_QUIZ_QUESTION,
	SESSION_QUIZ_SUBMIT,
	MODULE_SESSION_QUIZ,
	MODULE_SESSION_QUIZ_QUESTION,
	MODULE_SESSION_QUIZ_SUBMIT,
	MODULE_SESSION_SURVEY,
	SESSION_SURVEY
];

interface Props extends RouteComponentProps<Partial<Pick<ContentParams, 'moduleHashID'>>> {
	quiz?: Quiz | null;
	session: Session;
	nextSession?: Session | null;
	previousSession?: Session | null;
	dispatch?: Function;
}
export class SessionNavigation {
	private props: Props;
	private location: Props['location'];
	private history: Props['history'];
	private readonly quiz?: Quiz | null;
	private session: Props['session'];
	private readonly nextSession?: Props['session'] | null;
	private readonly previousSession?: Props['session'] | null;
	private match: Props['match'];
	private readonly path: Props['match']['path'];
	private readonly sessionHashID: Session['hashID'];
	private readonly courseHashID?: Course['hashID'];
	private readonly moduleHashID?: Module['hashID'];

	constructor(props: Props) {
		this.props = props;
		this.location = this.props.location;
		this.history = this.props.history;

		this.quiz = this.props.quiz;
		this.session = this.props.session;
		this.nextSession = this.props.nextSession;
		this.previousSession = this.props.previousSession;

		this.match = this.props.match;
		this.path = this.match.path;

		// Key Params
		this.sessionHashID = this.session.hashID;
		this.courseHashID = this.session.courseHashID;
		this.moduleHashID = this.match.params.moduleHashID;
	}

	/**
	 * if surveyHashID? (if moduleHashId then module_session_survey else session survey) else if moduleHashID (module_session_quiz else session_quiz)
	 */
	getUrl() {
		if (this.quiz && this.quiz.type === SURVEY) {
			if (this.moduleHashID) {
				return MODULE_SESSION_SURVEY;
			}
			return SESSION_SURVEY;
		} else {
			if (this.moduleHashID) {
				return MODULE_SESSION_QUIZ;
			}
			return SESSION_QUIZ;
		}
	}

	/**
	 * Navigate to the next Session
	 */
	next() {
		const {
			content: { folders }
		} = store.getState();

		/**
		 * If there's a quiz, navigate to it
		 */
		if (!quizPaths.includes(this.path) && QuizManager.isQuizzable(this.quiz)) {
			return this.history.push(
				generateUrl(this.getUrl(), {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.courseHashID,
					':sessionHashID': this.sessionHashID,
					':quizHashID': this.session.quizHashID,
					':surveyHashID': this.session.quizHashID
				})
			);
		}

		// Get the next session
		/**
		 * Navigate to the next session if it exists
		 */
		if (this.nextSession) {
			return this.history.push(
				generateUrl(this.moduleHashID ? MODULE_SESSION_DETAIL : SESSION_DETAIL, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.nextSession.courseHashID,
					':sessionHashID': this.nextSession.hashID
				})
			);
		} else {
			/**
			 * Navigate back to the list of courses otherwise
			 */
			const courseFolders = CourseManager.getFoldersFromCourse(folders, this.courseHashID);
			const totalFolders = Object.keys(courseFolders).length;

			const lastFolder = courseFolders[Object.keys(courseFolders)[totalFolders - 1]];
			const isLastFolder = lastFolder === courseFolders[this.session.folderHashID];

			if (totalFolders === 1 || isLastFolder) {
				return this.history.push(
					generateUrl(this.moduleHashID ? MODULE_COURSE_LIST : COURSES, {
						':moduleHashID': this.moduleHashID
					})
				);
			} else {
				return this.history.push(
					generateUrl(this.moduleHashID ? MODULE_COURSE_INFO : COURSE_INFO, {
						':moduleHashID': this.moduleHashID,
						':courseHashID': this.courseHashID
					})
				);
			}
		}
	}

	/**
	 * Navigate to the previous Session
	 */
	previous() {
		if (this.previousSession) {
			return this.history.push(
				generateUrl(this.moduleHashID ? MODULE_SESSION_DETAIL : SESSION_DETAIL, {
					':moduleHashID': this.moduleHashID,
					':courseHashID': this.previousSession.courseHashID,
					':sessionHashID': this.previousSession.hashID
				})
			);
		}
	}

	/**
	 * Discussion
	 */
	discussion() {
		return this.history.push(
			generateUrl(this.moduleHashID ? MODULE_SESSION_DISCUSSION : SESSION_DISCUSSION, {
				':moduleHashID': this.moduleHashID,
				':courseHashID': this.courseHashID,
				':sessionHashID': this.sessionHashID
			})
		);
	}
}
