import Avatar from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import JsxParser from 'react-jsx-parser';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import CustomDialog from '../components/custom/CustomDialog';
import CustomExpansionPanel from '../components/custom/CustomExpansionPanel';
import NotFound from '../components/NotFound';
import SelfTestQuestion from '../components/selfTestQuiz/SelfTestQuiz';
import SessionFooter from '../components/session/SessionFooter';
import { MODULE_SESSION_QUIZ, SESSION_QUIZ } from '../config/routes';
import CourseManager from '../services/CourseManager';
import generateUrl from '../services/generateUrl';
import { Institution, Session, Store, User } from '../types';
import { Theme, WithStyles } from '@material-ui/core/styles';
import { ContentParams } from '../services/RouteResolver';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { BLOCK_SESSION_CONTENT } from '../config/constants';
import DragDrop from 'editorjs-drag-drop';
import '../components/session/block/blocks.css';

import { blockTools, createParagraphBlockData } from '../components/session/block/utils';
import { Action } from 'redux';
import { sendEventToAnalyticsEngine, UserActivityPayload } from '../redux/actions/actionCreators';
import { ActivityLabel } from '../services/analytics/userActivityClient';
import { ThunkDispatch } from 'redux-thunk';
import I18n from 'i18n-js';
import WarningIcon from '../components/icons/WarningIcon';
import CheckForUpdatesButton from '../components/updates/CheckForUpdatesButton';

const jsxComponents = Object.assign(
	{},
	{
		Button,
		Card,
		CardHeader,
		CardContent,
		CardActionArea,
		CardActions,
		CardMedia,
		Paper,
		ExpansionPanel: CustomExpansionPanel,
		Accordion: CustomExpansionPanel,
		AccordionActions,
		AccordionDetails,
		AccordionSummary,
		ExpandMore,
		Chip,
		Divider,
		CircularProgress,
		LinearProgress,
		Typography,
		SelfTestQuestion: SelfTestQuestion,
		CustomDialog: CustomDialog
	}
);

const styles = (theme: Theme) => ({
	smallAvatar: {
		width: 26,
		height: 26,
		fontSize: 14,
		backgroundColor: theme.palette.primary.main
	},
	sessionInfo: {
		borderBottom: '1px solid #eee'
	},
	sessionIndexWrapper: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		borderRight: '1px solid #eee'
	},
	sessionTitle: {
		padding: '15px 20px'
	}
});

interface Params extends Pick<ContentParams, 'moduleHashID' | 'sessionHashID' | 'courseHashID'> {}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<Params> {
	session: Session;
	courseHashID: Session['courseHashID'];
	sendEventToAnalytics: (payload: UserActivityPayload) => void;
	token: User['token'];
	locale: User['locale'];
	sessionContentIsFetching: boolean;
	color: Institution['color'];
}

export class SessionDetailScreen extends Component<Props, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			editor: null,
			test: 'test'
		};
	}
	readingStartTime = new Date();

	initBlockEditor() {
		if (this.state.editor && 'destroy' in this.state.editor) {
			this.state.editor.destroy();
		}

		const { content } = this.props.session;
		let data: OutputData;

		try {
			//We do this for backward compatibility as we previously saved only the blocks as an array, but now we save it as an object
			const savedContent = JSON.parse(content || '[]');
			if (Array.isArray(savedContent)) {
				data = {
					blocks: savedContent
				};
			} else {
				//is object
				data = { ...savedContent };
			}
		} catch (e) {
			//data happens to be just a string so stick it in a paragraph
			data =
				typeof content === 'string'
					? createParagraphBlockData(content)
					: {
							blocks: []
					  };
		}

		const editor = new EditorJS({
			readOnly: true,
			//@ts-ignore
			tools: blockTools,
			data
		});
		editor.isReady.then(() => {
			new DragDrop(editor);
			this.setState({ editor });
		});
	}

	componentDidMount() {
		const { contentType } = this.props.session;
		if (contentType === BLOCK_SESSION_CONTENT && !this.state.editor) {
			this.initBlockEditor();
		}
		if (this.props.session) {
			this.props.sendEventToAnalytics({
				label: ActivityLabel.SESSION_VIEWED,
				description: `${this.props.token} viewed session ${this.props.session.hashID}`
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>) {
		const { content: previousContent } = prevProps.session;
		const { contentType: currentContentType, content: currentContent } = this.props.session;
		if (previousContent !== currentContent && currentContentType === BLOCK_SESSION_CONTENT) {
			this.initBlockEditor();
		}
	}

	renderContent() {
		return { __html: this.props.session.content ?? '' };
	}

	block() {
		return <div style={{ paddingTop: '13px' }} id={'editorjs'} />;
	}

	renderer() {
		const content = this.props.session.content
			? this.props.session.content
					.replace(new RegExp('<ExpansionPanel', 'g'), '<Accordion')
					.replace(new RegExp('</ExpansionPanel', 'g'), '</Accordion')
			: null;

		const jsxRegEx = /(?:<([A-Z][\w]+)\s?[\w\s={}:"]*>(.|\r|\n)*?<\/\1>|<[A-Z]([^>])+\/>)/g;
		const isJSX = content && jsxRegEx.test(content);

		if (isJSX) {
			return <JsxParser jsx={content} components={jsxComponents} />;
		}

		return <div dangerouslySetInnerHTML={this.renderContent()} />;
	}

	showDownloadOngoingPlaceholder() {
		return (
			<Grid container justifyContent={'center'} alignItems={'center'}>
				<Grid item xs={12} style={{ textAlign: 'center', paddingTop: 20 }}>
					<CircularProgress />
				</Grid>
				<Grid item style={{ textAlign: 'center' }}>
					<p style={{ color: 'grey' }}>
						{I18n.t('update.downloadInProgress', { locale: this.props.locale })}.<br />
						{I18n.t('update.contentWillAppear', { locale: this.props.locale })}.
					</p>
				</Grid>
			</Grid>
		);
	}

	showErrorWhileUpdatingPlaceholder() {
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} style={{ paddingBottom: 25 }}>
				<Grid item xs={12} style={{ textAlign: 'center', paddingTop: 20 }}>
					<WarningIcon style={{ color: this.props.color, width: 70, height: 70 }} />
				</Grid>
				<Grid item style={{ textAlign: 'center' }}>
					<p style={{ color: 'grey' }}>
						{I18n.t('update.errorWhileUpdating', { locale: this.props.locale })}.<br />
						{I18n.t('update.connectAndRetry', { locale: this.props.locale })}.
					</p>
				</Grid>
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<CheckForUpdatesButton
						customText={I18n.t('update.retry', { locale: this.props.locale })}
					/>
				</Grid>
			</Grid>
		);
	}

	displayContent() {
		if (this.props.sessionContentIsFetching && !this.props.session.spoolComplete) {
			return this.showDownloadOngoingPlaceholder();
		}

		if (!this.props.session.spoolComplete) {
			return this.showErrorWhileUpdatingPlaceholder();
		}

		return this.props.session.contentType === BLOCK_SESSION_CONTENT ? this.block() : this.renderer();
	}

	render() {
		const { session, courseHashID, classes } = this.props;

		if (session !== undefined) {
			if (session.content && session.content.includes('&lt;quiz&gt;') && session.quizHashID) {
				const moduleHashID = this.props.match.params.moduleHashID;

				const quizUrl = generateUrl(moduleHashID ? MODULE_SESSION_QUIZ : SESSION_QUIZ, {
					':moduleHashID': moduleHashID,
					':courseHashID': session.courseHashID,
					':sessionHashID': session.hashID,
					':quizHashID': session.quizHashID
				});

				return <Redirect to={quizUrl} />;
			}

			return (
				<div className="content-layout">
					<Grid container className={classes.sessionInfo}>
						<Grid item xs={2} className={classes.sessionIndexWrapper}>
							<Avatar className={classes.smallAvatar}>{session.index + 1}</Avatar>
						</Grid>
						<Grid item xs={10}>
							<Typography variant="subtitle2" className={classes.sessionTitle}>
								{session.title}
							</Typography>
						</Grid>
					</Grid>
					<div className="content">
						<div className="session-content">{this.displayContent()}</div>
					</div>

					<SessionFooter
						courseHashID={courseHashID}
						session={session}
						readingStartTime={this.readingStartTime}
					/>
				</div>
			);
		}

		return <NotFound {...this.props} />;
	}
}

function mapStateToProps(state: Store, props: Props) {
	const {
		currentUser: {
			token,
			institution: { color }
		},
		settings: { locale },
		content: {
			spool: { isFetching }
		}
	} = state;

	const session = CourseManager.getSession(state.content.sessions, props.match.params.sessionHashID);

	return {
		session,
		locale,
		token,
		courseHashID: props.match.params.courseHashID,
		sessionContentIsFetching: isFetching,
		color
	};
}

const mapDispatchToProps = (dispatch: ThunkDispatch<Store, any, Action>) => {
	return {
		sendEventToAnalytics: (payload: UserActivityPayload) => dispatch(sendEventToAnalyticsEngine(payload))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SessionDetailScreen));
