import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import I18n from 'i18n-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { Quiz, QuizResult, QuizTypeEnum, Store } from '../../../types';

const useStyles = makeStyles(() => ({
	item: {
		textTransform: 'capitalize'
	}
}));

interface Props {
	handleNext: (quizHashID: Quiz['hashID']) => void;
	quizResult: QuizResult;
}

const QuizReviewListItem = ({ quizResult, handleNext }: Props) => {
	const quiz = quizResult.quiz;
	const classes = useStyles();

	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const getQuizTypeTranslations = (type: QuizTypeEnum) => {
		switch (type) {
			case QuizTypeEnum.graded:
				return I18n.t('quizType.graded', { locale });
			case QuizTypeEnum.survey:
				return I18n.t('quizType.survey', { locale });
			case QuizTypeEnum.self_assessment:
				return I18n.t('quizType.selfAssessment', { locale });
			default:
				return type;
		}
	};

	return (
		<ListItem
			className={classes.item}
			divider
			button
			onClick={() => quiz && handleNext(quizResult.quiz.hashID)}
			data-test-id="quiz_review_item_list_item"
		>
			<ListItemText
				primary={quiz ? quiz.title : ''}
				secondary={quiz ? getQuizTypeTranslations(quiz.type) : ''}
				data-test-id="quiz_review_item_list_item_list_item"
				// secondary={`Grade: ${quizResult.grade ? `${quizResult.grade}%` : 'Not yet graded'}`}
			/>
		</ListItem>
	);
};

export default QuizReviewListItem;
