import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import CourseIcon from '../icons/CourseIcon';
import { Course, Store } from '../../types';
import { CourseListProps } from './CourseList';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';

const cardHeight = '20vh';
const imageWidth = '40%';

const styles = makeStyles((theme) => ({
	background: {
		backgroundColor: theme.palette.background.default
	},
	card: {
		margin: theme.spacing(1),
		display: 'flex',
		cursor: 'pointer',
		height: cardHeight
	},
	sessionTitle: {
		lineHeight: '23px',
		fontSize: '1.4rem'
	},
	progress: {
		width: '100%',
		padding: '10px',
		position: 'absolute',
		bottom: '0px'
	},
	cardMedia: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.palette.primary.light,
		width: imageWidth,
		maxWidth: '300px',
		boxSizing: 'border-box',
		overflow: 'hidden'
	},
	cardImage: {
		height: '100%',
		width: '100%'
	},
	cardIcon: {
		fontSize: '10vmax',
		color: theme.palette.background.default
	},
	cardActionArea: {
		display: 'flex',
		flex: '1',
		alignItems: 'flex-start',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		wordBreak: 'break-word',
		overflow: 'hidden',
		textAlign: 'left',
		width: '60%'
	}
}));

interface Props {
	handleNext: CourseListProps['handleNext'];
	handleInfo: CourseListProps['handleInfo'];
	course: Course;
	className?: string;
}

const CourseListItem = ({ course, handleNext, handleInfo }: Props) => {
	const classes = styles();

	const [progress, setProgress] = useState<number>(0);

	const courseSessions: string[] = useSelector(({ content: { sessions } }: Store) => {
		return Object.keys(sessions).filter((session) => sessions[session].courseHashID === course.hashID);
	});

	const viewedCourseSessions: string[] = useSelector(({ content: { viewed: { sessions } } }: Store) =>
		sessions.filter((session) => courseSessions.includes(session))
	);

	useEffect(() => {
		if (courseSessions.length === 0) {
			return setProgress(100);
		}
		if (viewedCourseSessions && courseSessions) {
			const percent = (viewedCourseSessions.length / courseSessions.length) * 100;
			setProgress(parseInt(percent.toFixed(0)));
		}
	}, [viewedCourseSessions, courseSessions]);

	const showImage = () => {
		if (course.image) {
			return (
				<span
					className={classNames(classes.cardMedia, 'joyride-course-item-image')}
					onClick={() => handleInfo(course)}
				>
					<CardMedia
						data-test-id="course-list-item_media"
						className={classNames(classes.cardImage, 'joyride-course-item-image')}
						image={course.image.url}
					/>
					<div className={classes.progress}>
						<LinearProgress variant="determinate" value={progress} />
					</div>
				</span>
			);
		}

		return (
			<span
				data-test-id="course-list-item_media-empty"
				className={classNames(classes.cardMedia, 'joyride-course-item-image')}
				onClick={() => handleInfo(course)}
			>
				<CourseIcon className={classes.cardIcon} />
				<div className={classes.progress}>
					<LinearProgress variant="determinate" value={progress} />
				</div>
			</span>
		);
	};

	return (
		<Card className={classes.card}>
			{showImage()}

			<CardActionArea
				className={classes.cardActionArea}
				data-test-id="course-list-item_card-content"
				onClick={() => handleNext(course)}
			>
				<CardContent className="joyride-course-item-content">
					<Typography
						data-test-id="course-item_title"
						variant="h5"
						className={classes.sessionTitle}
					>
						{course.title}
					</Typography>

					<Typography data-test-id="course-item_teacher" color="textSecondary">
						{course.teacher}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default CourseListItem;
