import * as storage from 'localforage';
import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { USER_LOGOUT } from '../actions/actionCreators';
import migrations from '../store/migrations';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

import content from './content';
import currentUser from './currentUser';
import logout from './logout';
import network from './network';
import settings from './settings';
import updates from './updates';
import { Action, Store } from '../../types';

const persistingReducer = createBlacklistFilter('content', ['isQuizSubmitting', 'spool.isFetching']);

const persistConfig = {
	key: 'root',
	transforms: [persistingReducer],
	// Refer to migrations file for versions
	version: 20230911,
	storage,
	stateReconciler: autoMergeLevel2,
	migrate: createMigrate(migrations, { debug: true })
};

const reducers = combineReducers({
	content,
	currentUser,
	network,
	updates,
	settings,
	logout
});

const rootReducer = (state: Store | undefined, action: Action) => {
	if (action.type === USER_LOGOUT) {
		state = undefined;
		console.log('Logged out. Store reset.');
	}

	return reducers(state, action);
};

export default persistReducer(persistConfig, rootReducer);
