import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { receiveValidateSessionSMS } from '../redux/actions/actionCreators';
import ValidatedSession from '../components/session/ValidatedSession';
import CourseManager from '../services/CourseManager';
import UnBlockSession from '../services/session/UnBlockSession';
import { Session, Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';

interface Params {
	unblockCode: string;
}

interface Props extends Pick<RouteComponentProps<Params>, 'match'> {}

const ValidateSessionByCodeScreen = (props: Props) => {
	const myProps = useSelector((state: Pick<Store, 'currentUser' | 'content' | 'settings'>) => {
		const {
			currentUser: { token: userHashID },
			content: { sessions },
			settings: { locale }
		} = state;

		return { sessions, locale, userHashID };
	});

	const { sessions, locale } = myProps;

	const [session, setSession] = useState<Session | null>(null);
	const [isInvalidCode, setIsInvalidCode] = useState(true);

	const dispatch = useDispatch();

	useEffect(() => {
		const { unblockCode } = props.match.params;

		const userHashID = UnBlockSession.getUserHashIDFromCode(
			unblockCode,
			process.env.REACT_APP_API_PRIVATE_KEY ?? ''
		);

		if (userHashID !== myProps.userHashID) {
			return;
		}

		const sessionHashID = UnBlockSession.getSessionHashIDFromCode(
			unblockCode,
			process.env.REACT_APP_API_PRIVATE_KEY ?? ''
		);

		const session = CourseManager.getSession(sessions, sessionHashID);

		if (session === undefined) {
			return;
		}

		setSession(session);
		setIsInvalidCode(false);
		dispatch(receiveValidateSessionSMS(session.hashID));
	}, []); //eslint-disable-line

	if (isInvalidCode) {
		return (
			<div data-test-id="token-error" className="screen-centered">
				{I18n.t('tokenError.401', { locale })}
			</div>
		);
	}

	const nextSession = session && CourseManager.getNextSession(sessions, session);

	return session && <ValidatedSession session={session} nextSession={nextSession} locale={locale} />;
};

export default ValidateSessionByCodeScreen;
