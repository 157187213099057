import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from '../components/NotFound';
import ToolbarGap from '../components/utils/ToolbarGap';
import CourseManager from '../services/CourseManager';
import FolderFragment from './FolderFragment';
import { Store } from '../types';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/core/styles';
import { ContentParams } from '../services/RouteResolver';
import { sendEventToAnalyticsEngine } from '../redux/actions/actionCreators';
import { ActivityLabel } from '../services/analytics/userActivityClient';

const styles = (theme: { spacing: Function }) => ({
	root: {
		display: 'block',
		backgroundColor: '#ffffff',
		padding: theme.spacing(2)
	},
	image: {
		margin: '10px 0px'
	}
});

interface Params extends Pick<ContentParams, 'courseHashID'> {}

interface Props extends RouteComponentProps<Params>, WithStyles<typeof styles> {}

const CourseInfoScreen = (props: Props) => {
	const dispatch = useDispatch();
	const { course, token } = useSelector(({ currentUser: { token }, content: { courses } }: Store) => {
		return {
			course: CourseManager.getCourse(courses, props.match.params.courseHashID),
			token
		};
	});

	useEffect(() => {
		if (course) {
			dispatch(
				sendEventToAnalyticsEngine({
					label: ActivityLabel.COURSE_DETAIL_PAGE_VIEW,
					description: `${token} viewed course ${course.hashID}`
				})
			);
		}
	}, []);

	const { classes } = props;
	if (!course) {
		return <NotFound {...props} />;
	}

	return (
		<Grid container direction="column">
			<Grid
				item
				container
				direction="column"
				className={classnames('joyride-course-info', classes.root)}
			>
				<Typography data-test-id="course-title" variant="h6">
					{course.title}
				</Typography>

				<Typography data-test-id="course-teacher" variant="subtitle2">
					{course.teacher}
				</Typography>

				{course.image && (
					<React.Fragment>
						<img className={classes.image} src={course.image.url} alt="" />
					</React.Fragment>
				)}

				<Typography variant="body1" color="textSecondary">
					<span dangerouslySetInnerHTML={{ __html: course.description ?? '' }} />
				</Typography>
			</Grid>

			{/* Folders & Sessions */}
			<Grid item>
				<FolderFragment />
			</Grid>

			{/* Gap to prevent safari double-click requirement when too close to page bottom */}
			<Grid item>
				<ToolbarGap />
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(CourseInfoScreen);
