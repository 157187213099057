import { Content, Module } from '../../types';

export class ModuleManager {
	static getModule(moduleItems: Content['modules'], moduleHashID: string) {
		return moduleItems[moduleHashID];
	}

	static getModuleCourses(module: Module, courseItems: Content['courses']) {
		let courses: any = {};

		for (let key in courseItems) {
			if (module.courses.includes(courseItems[key].hashID)) {
				courses[key] = courseItems[key];
			}
		}

		return courses;
	}

	static isAllCoursesInModules(modules: Content['modules'], courses: Content['courses']) {
		let result = true;
		let modulesLength = Object.keys(modules).length;

		Object.keys(courses).forEach((courseHashId) => {
			let count = 0;

			for (let key in modules) {
				if (!modules[key].courses.includes(courseHashId)) {
					count++;
				}

				if (count === modulesLength) {
					result = false;
				}
			}
		});
		return result;
	}
}

export default ModuleManager;
