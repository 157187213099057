import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableRow from '@material-ui/core/TableRow/TableRow';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import I18n from 'i18n-js';
import React from 'react';
import { NUMERICAL_RANGE_QUESTION, SEQUENCE_QUESTION, SHORT_EXACT_QUESTION } from '../../../config/constants';
import { higherNumber, lowerNumber } from '../../../services/quiz/NumericalRange';
import { Locale, Question } from '../../../types';
import { WithStyles } from '@material-ui/core/styles';

const styles = () => ({
	positiveStyle: {
		color: '#fff',
		backgroundColor: lightGreen[500]
	},
	negativeStyle: {
		color: '#fff',
		backgroundColor: red[500]
	},
	divider: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: '0.7em'
	}
});

interface Props extends WithStyles<typeof styles> {
	questionType: Question['type'];
	locale: Locale;
	answers: Question['answers'];
}

export const ResponseContent = ({ questionType, answers, classes, locale }: Props) => {
	switch (questionType) {
		case NUMERICAL_RANGE_QUESTION:
			return (
				<TableRow>
					<TableCell
						data-test-id="table_cell"
						style={{ paddingLeft: 0, paddingRight: 0 }}
						dangerouslySetInnerHTML={{
							__html: I18n.t('question.numericalRangeFeedback', {
								locale,
								lowerNumber: lowerNumber(answers[0].title, answers[1].title),
								higherNumber: higherNumber(answers[0].title, answers[1].title)
							})
						}}
					/>
				</TableRow>
			);
		case SEQUENCE_QUESTION:
			return (
				<>
					{Object.keys(answers).map((key, index) => {
						const answer = answers[index];

						return (
							<TableRow key={key} data-test-id="sequence_question">
								<TableCell padding="default">
									<Check className={classes.positiveStyle} />
								</TableCell>
								<TableCell>{answer}</TableCell>
							</TableRow>
						);
					})}
				</>
			);
		case SHORT_EXACT_QUESTION:
			return (
				<>
					{Object.keys(answers).map((key, index) => {
						const answer = answers[index];

						return (
							<TableRow key={key} data-test-id="short_exact_question">
								<TableCell padding="default">
									<Check className={classes.positiveStyle} />
								</TableCell>
								<TableCell>{answer.title}</TableCell>
							</TableRow>
						);
					})}
				</>
			);
		default:
			return (
				<>
					{Object.keys(answers).map((key, index) => {
						const answer = answers[index];

						return (
							<TableRow key={key} data-test-id="default">
								<TableCell padding="default">
									{answer.correct ? (
										<Check className={classes.positiveStyle} />
									) : (
										<Close className={classes.negativeStyle} />
									)}
								</TableCell>
								<TableCell>{answer.title}</TableCell>
							</TableRow>
						);
					})}
				</>
			);
	}
};

export default withStyles(styles)(ResponseContent);
