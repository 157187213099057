import Avatar from '@material-ui/core/Avatar/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InfoIcon from '@material-ui/icons/Info';
import I18n from 'i18n-js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Content, Store } from '../../types';
import { TransitionProps } from '@material-ui/core/transitions';

interface Props {
	title: string;
	content: Content;
}

function Transition(props: TransitionProps) {
	return <Slide direction="down" {...props} />;
}

const CustomDialog = (props: Props) => {
	const locale = useSelector(({ settings: { locale } }: Store) => locale);

	const { title, content } = props;

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Chip
				data-test-id="custom-dialog_more-info-btn"
				color="primary"
				clickable
				label={I18n.t('custom.moreInfo', { locale })}
				avatar={
					<Avatar>
						<InfoIcon />
					</Avatar>
				}
				onClick={handleOpen}
			/>
			<Dialog
				data-test-id="custom-dialog_dialog"
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				scroll="body"
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle data-test-id="custom-dialog_dialog-title" id="alert-dialog-slide-title">
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						data-test-id="custom-dialog_dialog-content-text"
						id="alert-dialog-slide-description"
					>
						{content}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button data-test-id="custom-dialog_dialog-btn" onClick={handleClose} color="primary">
						{I18n.t('custom.gotIt', { locale })}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export default CustomDialog;
