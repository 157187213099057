import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import I18n from 'i18n-js';
import React from 'react';

interface Theme {
	palette: {
		getContrastText: Function;
	};
}
const styles = makeStyles((theme: Theme) => ({
	root: {
		padding: '1em',
		backgroundColor: 'white',
		minHeight: '100vh',
		position: 'fixed',
		textAlign: 'center'
	},
	icon: {
		color: grey[700],
		fontSize: '50vmin'
	},
	reportButton: {
		backgroundColor: red[500],
		color: theme.palette.getContrastText(red[500]),
		'&:hover': {
			backgroundColor: red[700]
		}
	}
}));

interface Props {
	showReport: () => void;
	closeError: () => void;
}

export const ErrorScreen = (props: Props) => {
	const { showReport } = props;

	const classes = styles();

	const back = () => {
		props.closeError();
		return (window.location.href = '/');
	};

	return (
		<Grid container className={classes.root}>
			<Grid item container direction="column" alignItems="center" justify="center" spacing={8}>
				<Grid item>
					<div style={{ margin: 0 }}>
						<ErrorIcon className={classes.icon} />
					</div>
				</Grid>
				<Grid item>
					<Typography variant="h5" color="textSecondary" style={{ margin: '0.3em 0' }}>
						{I18n.t('error.label')}
					</Typography>
				</Grid>

				<Grid item>
					<p>
						<Button
							variant="contained"
							data-test-id="showReport"
							onClick={showReport}
							className={classes.reportButton}
						>
							{I18n.t('error.report')}
						</Button>
					</p>
				</Grid>
				<Grid item>
					<Button data-test-id="go-back" onClick={back}>
						{I18n.t('error.back')}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ErrorScreen;
